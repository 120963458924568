var metric = {
  b: "423",
  h1: "948",
  tw: "24.0",
  tf: "42.7",
  r: "30.5",
  ts: "250",
  s: "1350",
  Eg: "200000",
  Es: "30000"
};

var imperial = {
  b: "16.655", //in.
  h1: "37.33", //in.
  tw: "0.945",
  tf: "1.680",
  r: "1.2",
  ts: "10",
  s: "50",
  Eg: "29000000", //psi
  Es: "4350000"
};

export default {
  metric,
  imperial
}