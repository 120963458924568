var outputs = {
    requiredDiameter: {
        label1: 'Required Diameter',
        label2: "rd",
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Required pin diameter',
            desc: 'Required pin diameter'
        },
        value: 0
    },
    requiredThickness: {
        label1: 'Required Plate Thickness',
        label2: "rt",
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        desctiption: {
            title: 'Required plate thickness',
            desc: 'Required plate thickness'
        },
        value: 0
    },
    isFlexureSafe: {
        label1: 'Is flexure OK?',
        label2: 'f',
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Flexure Status',
            desc: 'Is flexure design safe?'
        },
        value: false
    }
}

export default outputs;