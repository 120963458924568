let outputs = {
    wASD: {
        label1: 'Fillet Weld Size (ASD)',
        value: -1,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Fillet weld size - ASD',
            desc: '(w)'
        }
    },
    wLRFD: {
        label1: 'Fillet Weld Size (LRFD)',
        value: -1,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Fillet weld size - LRFD',
            desc: '(w)'
        }
    }
}

export default outputs;