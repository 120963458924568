<template>
  <div>
    <div v-if="typeof result1[i] === 'number'">
      <v-layout wrap>
        <v-flex xs9>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-card flat height="90" class="text-xs-left" v-on="on">
                <div height="50" class="py-1 pl-2 grey lighten-5 elevation-1">
                  <div>{{result.label1}}</div>
                  <div v-if="result.label2">({{result.label2}})</div>
                  <div v-else>
                    <br />
                  </div>
                </div>
                <v-layout wrap>
                  <v-flex
                    class="my-2 mx-2 black--text text-xs-center"
                    style="font-size: 15px;"
                  >{{result1[i] | Digits(result.unit || 2) }}</v-flex>
                </v-layout>
              </v-card>
            </template>
            <span>{{result.description.title}}</span>
          </v-tooltip>
        </v-flex>
        <v-flex xs3 v-if="(result2) ? ((typeof result2[i] === 'number') ? true : false) : false">
          <div
            class="pt-1 text-xs-center elevation-5"
            id="increased"
            height="50"
            v-if="changes[i].sign === 'trending_up'"
          >
            <v-icon color="green darken-3">{{changes[i].sign}}</v-icon>
            <div class="pl-2 green darken-3 white--text">{{changes[i].result | Digits(1)}} %</div>
          </div>
          <div
            class="pt-1 white text-xs-center elevation-5"
            id="decreased"
            height="50"
            v-if="changes[i].sign === 'trending_down'"
          >
            <v-icon color="red darken-3">{{changes[i].sign}}</v-icon>
            <div class="pl-2 red darken-3 white--text">{{changes[i].result | Digits(1)}} %</div>
          </div>
          <div
            class="pt-1 white text-xs-center elevation-5"
            id="nochange"
            height="50"
            v-if="changes[i].sign === 'swap_horiz'"
          >
            <v-icon color="light-blue darken-3">{{changes[i].sign}}</v-icon>
            <div class="pl-2 light-blue darken-3 white--text">{{changes[i].result | Digits(1)}} %</div>
          </div>
          <v-flex
            class="my-2 black--text text-xs-center"
            style="font-size: 15px;"
            v-if="result1[i] !== Infinity"
          >{{result.suffix[system]}}</v-flex>
        </v-flex>
        <v-flex xs3 v-else>
          <v-card class="pt-1 white text-xs-center elevation-5" id="nochange" height="50">
            <v-icon color="light-blue darken-3">swap_horiz</v-icon>
            <div class="pl-2 light-blue darken-3 white--text">0 %</div>
          </v-card>
          <v-flex
            class="my-2 black--text text-xs-center"
            style="font-size: 15px;"
            v-if="result1[i] !== Infinity"
          >{{result.suffix[system]}}</v-flex>
        </v-flex>
      </v-layout>
    </div>
    <div v-if="typeof result1[i] === 'string'">
      <v-alert :value="true" color="error">{{result1[i]}}</v-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: ["changes", "result", "i", "result1", "result2", "system"]
};
</script>

<style scoped>
</style>