var outputs = {

    Agc: {
        label1: 'Area of Composite Section',
        label2: 'Agc',
        cat: 2,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Composite Section',
            desc: ''
        }
    },

    Igc: {
        label1: 'Section Moment of Inertia',
        label2: 'Igc',
        cat: 2,
        suffix: {
            metric: 'mm⁴',
            imperial: 'in⁴'
        },
        description: {
            title: 'Composite Section Moment of Inertia',
            desc: ''
        }
    },

    ybc: {
        label1: 'Section Geometric Center From Bottom', // 36 karakter
        label2: 'ybc',
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Composite Section Geometric Center From Bottom',
            desc: ''
        }
    },

    Sbc: {
        label1: 'Section Modulus From Bottom', // 27 karakter
        label2: 'Sbc',
        cat: 2,
        suffix: {
            metric: 'mm³',
            imperial: 'in³'
        },
        description: {
            title: 'Composite Section Modulus From Bottom',
            desc: ''
        }
    },

    Stc: {
        label1: 'Section Modulus From Top',
        label2: 'Stc',
        cat: 2,
        suffix: {
            metric: 'mm³',
            imperial: 'in³'
        },
        description: {
            title: 'Composite Section Modulus From Top',
            desc: ''
        }
    },

    Ag: {
        label1: 'Area of Girder',
        label2: 'Ag',
        cat: 1,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Girder',
            desc: ''
        }
    },

    Ig: {
        label1: 'Girder Moment of Inertia',
        label2: 'Ig',
        cat: 1,
        suffix: {
            metric: 'mm⁴',
            imperial: 'in⁴'
        },
        description: {
            title: 'Girder Moment of Inertia',
            desc: ''
        }
    },

    yb: {
        label1: 'Geometric Center From Bottom', // 28 karakter
        label2: 'yb',
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Geometric Center From Bottom',
            desc: ''
        }
    },

    Sb: {
        label1: 'Section Modulus From Bottom', // 27 karakter
        label2: 'Sb',
        cat: 1,
        suffix: {
            metric: 'mm³',
            imperial: 'in³'
        },
        description: {
            title: 'Girder Section Modulus From Bottom',
            desc: ''
        }
    },

    St: {
        label1: 'Section Modulus From Top',
        label2: 'St',
        cat: 1,
        suffix: {
            metric: 'mm³',
            imperial: 'in³'
        },
        description: {
            title: 'Girder Section Modulus From Top',
            desc: ''
        }
    }
};

export default outputs;