var metric = { // moment input olarak verildi dikkat !!!!!!
    moment: 1,
    fc: 45,
    fy: 420,
    fpu: 1860,
    gammp: 0.28,
    arst: 140,
    nst: 19,
    ntt: 32,
    ntw: 2,
    nbf: 2,
    dpu: 6850,
    dpg: 2000,
    dpa: 100,
    wt: 12000,
    b: 7000,
    tt: 300,
    tw: 400,
    tb: 1000,
    h1: 7000,
    ploss: 20,
    ardy: 75,
    pp: 40,
    table: [
        [80, 22],
        [80, 22],
        [40, 22],
        [40, 22],
        [40, 22],
    ]
};

var imperial = { // moment input olarak verildi dikkat !!!!!!
    moment: 1,
    fc: 6500,
    fy: 60000,
    fpu: 270000,
    gammp: 0.28,
    arst: 0.217,
    nst: 19,
    ntt: 32,
    ntw: 2,
    nbf: 2,
    dpu: 270,
    dpg: 80,
    dpa: 4,
    wt: 475,
    b: 275,
    tt: 12,
    tw: 16,
    tb: 40,
    h1: 275,
    ploss: 20,
    ardy: 75,
    pp: 1.5,
    table: [
        [80, 8.66],
        [80, 8.66],
        [40, 8.66],
        [40, 8.66],
        [40, 8.66],
    ]
}


export default {
    metric,
    imperial
}