var inputs = {

    aps: {
        label: 'Area of Strand',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Strand',
            desc: ''
        }
    },
    nps: {
        label: 'Number of Strands',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Strands',
            desc: ''
        }
    },
    fpu: {
        label: 'Tensile Strength',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Tensile Strength',
            desc: ''
        }
    },

    els: {
        label: 'Elastic Modulus',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Elastic Modulus',
            desc: ''
        }
    },
    jsr: {
        label: 'Jacking Stress',
        value: null,
        cat: 1,
        suffix: {
            metric: '%',
            imperial: '%'
        },
        description: {
            title: 'Jacking Stress',
            desc: ''
        }
    },
    wob: {
        label: 'Wobble Coefficient',
        value: null,
        cat: 1,
        suffix: {
            metric: '1/m',
            imperial: '1/ft'
        },
        description: {
            title: 'Wobble Coefficient',
            desc: ''
        }
    },

    anc: {
        label: 'Anchor Set',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Anchor Set',
            desc: ''
        }
    },
    jlen: {
        label: 'Jack Length',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Jack Length',
            desc: ''
        }
    },
    xd: {
        label: 'Distance Between A-B (x)',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Distance Between A-B (x)',
            desc: ''
        }
    },
    bc: {
        label: 'Distance Between B-C',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Distance Between B-C',
            desc: ''
        }
    },
    yd: {
        label: 'Offset of B from A (y)',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Offset of B from A (y)',
            desc: ''
        }
    }
};

export default inputs;