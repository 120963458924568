<template>
  <div>
    <v-layout ml-4>
      <v-flex xs12>
        <h1 class="headline">Latest News</h1>
        <br />
      </v-flex>
    </v-layout>
    <v-skeleton-loader
      :loading="loading"
      transition="scale-transition"
      type="list-item-avatar-three-line"
    >
      <NewsComponent :notifications="recentNotifications" :recentNews="true"></NewsComponent>
    </v-skeleton-loader>
    <v-layout ml-4>
      <v-flex xs12>
        <h1 class="title">Older News</h1>
        <br />
      </v-flex>
    </v-layout>
    <v-skeleton-loader
      :loading="loading"
      transition="scale-transition"
      type="list-item-avatar-three-line"
    >
      <NewsComponent :notifications="oldNotifications"></NewsComponent>
    </v-skeleton-loader>
  </div>
</template>

<script>
import NewsComponent from "@/components/NewsComponent";
import firebase from "firebase/app";
import db from "@/firebaseInit.js";

export default {
  name: "News",
  components: {
    NewsComponent
  },

  data() {
    return {
      recentNotifications: [],
      oldNotifications: [],
      loading: true
    };
  },
  async mounted() {
    await this.GetNotifications();
    this.loading = false;
  },
  methods: {
    // should we store notifications in cookie/store to prevent subsequent db queries??
    async GetNotifications() {
      const snapshot = await db.collection("notifications").orderBy("date","desc").get();
      if (!snapshot.empty) {
        for (const doc of snapshot.docs) {
          const docData = doc.data();
          if (docData.display) {
            this.recentNotifications.push(docData);
          } else {
            this.oldNotifications.push(docData);
          }
        }
      }
    }
  }
};
</script>

<style>
</style>