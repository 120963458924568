function calculate(inputs, system, table) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var moment = h('moment'),
        fc = h('fc'),
        fy = h('fy'),
        fpu = h('fpu'),
        gammp = h('gammp'),
        arst = h('arst'),
        nst = h('nst'),
        ntt = h('ntt'),
        ntw = h('ntw'),
        nbf = h('nbf'),
        dpu = h('dpu'),
        dpg = h('dpg'),
        dpa = h('dpa'),
        wt = h('wt'),
        b = h('b'),
        tt = h('tt'),
        tw = h('tw'),
        tb = h('tb'),
        h1 = h('h1'),
        ploss = h('ploss'),
        ardy = h('ardy'),
        pp = h('pp');

    moment = (moment === 1) ? true : false;
    R.moment = moment
    //SAMPLE TABLE DATA 
    /* [
        [80, 0.875],
        [],
    ] */

    var mtt = table[0],
        mtb = table[1],
        mw = table[2],
        mbt = table[3],
        mbb = table[4];

    var fpi, fse, b1, dpu, rpu, as1, as2, as3, as4, as5,
        ds1, ds2, ds3, ds4, ds5, dsc, w, wp, fps, ablo,
        ast, asc, mn1, mn2, mn, acomp, af, aw, dsc1, dsc2,
        mn3, mn4;

    var apsu = arst * nst * ntt;
    var apsg = arst * nst * ntw;
    var apsa = arst * nst * nbf;


    //*** METRIC CALCULATIONS
    function metric() {
        function compute_mnPlusmetric() { //moment = true

            ardy /= 100;
            ploss /= 100;
            dpa = h1 - dpa;
            dpg = h1 - dpg;
            ////////////////////////////////////// VARIABLES //////////////////////////////////////

            fpi = ardy * fpu; //jacking stress, fpi 
            fse = (1 - ploss) * fpi; //effective prestressing after losses, fse

            if (fc < 27.58) { //beton basınç katsayısı
                b1 = 0.85;
            } else {
                b1 = 0.85 - (fc - 27.58) / 6.895 * 0.05;
            }

            //post tension ratio, rho p 
            dpu = (apsa * dpa + apsg * dpg) / (apsa + apsg);
            rpu = (apsa + apsg) / (wt * dpu);

            //reinforcement centroid
            as5 = mbb[0] * 3.141 * (mbb[1] / 2) * (mbb[1] / 2);
            ds5 = h1 - pp;
            as4 = mbt[0] * 3.141 * (mbt[1] / 2) * (mbt[1] / 2);
            ds4 = h1 - (tb - pp);
            as3 = 2 * mw[0] * 3.141 * (mw[1] / 2) * (mw[1] / 2);
            ds3 = (h1 - tt - tb) / 2 + tt;
            dsc = (as5 * ds5 + as4 * ds4 + as3 * ds3) / (as5 + as4 + as3);

            w = (as5 + as4 + as3) * fy / (wt * dsc * fc); //computation of w

            //computation of wp
            as2 = mtb[0] * 3.141 * (mtb[1] / 2) * (mtb[1] / 2);
            as1 = mtt[0] * 3.141 * (mtt[1] / 2) * (mtt[1] / 2);
            wp = (as1 + as2) * fy / (wt * dsc * fc);

            fps = fpu * (1 - gammp / b1 * (rpu * fpu / fc + dsc / dpu * (w - wp))); //computation of fps

            //computation of a
            ablo = ((apsa + apsg) * fps + (as5 + as4 + as3 - as2 - as1) * fy) / (0.85 * fc * wt);
            ast = as5 + as4 + as3;
            asc = as2 + as1;
            ds1 = pp;
            ds2 = tt - pp;

            ////////////////////////////////////// RESULTS //////////////////////////////////////
            R.PTJS = fpi;
            R.PTESAL = fse;
            R.beta1 = b1;
            R.dp = dpu;
            R.rhoPu = rpu;
            R.d = dsc;
            R.w = w;
            R.wPrime = wp;
            R.fps = fps;
            R.a = ablo;


            //top flange bottom reinforcement is in tension
            if (ablo < ds2) {
                dsc = (as5 * ds5 + as4 * ds4 + as3 * ds3 + as2 * ds2) / (as5 + as4 + as3 + as2);
                R.d = dsc;
                w = (as5 + as4 + as3 + as2) * fy / (wt * dsc * fc);
                R.w = w;
                wp = (as1) * fy / (wt * dsc * fc);
                R.wPrime = wp;
                fps = fpu * (1 - gammp / b1 * (rpu * fpu / fc + dsc / dpa * (w - wp)));
                R.fps = fps;
                ablo = ((apsa + apsg) * fps + (as5 + as4 + as3 + as2 - as1) * fy) / (0.85 * fc * wt);
                R.a = ablo;
                ast = as5 + as4 + as3 + as2;
                asc = as1;
            }

            mn1 = (apsa + apsg) * fps * (dpu - ablo / 2);
            mn2 = ast * fy * (dsc - ablo / 2);
            mn = (mn1 + mn2) / 1000 / 1000;
            R.mn = mn;

            //check if a is within web
            if (ablo > tt) {
                acomp = ablo * wt;
                af = (wt - 2 * tw) * tt;
                aw = acomp - af;
                ablo = aw / (2 * tw);
                R.a = ablo;
                ast = as5 + as4 + as3 * (h1 - ablo) / h1;
                asc = as2 + as1 + as3 * ablo / h1;
                dsc1 = (as5 * ds5 + as4 * ds4 + as3 * (h1 - ablo / h1) * (h1 / 2 + ablo / 2)) / ast;
                dsc2 = (as1 * ds1 + as2 * ds2 + as3 * ablo / h1 * ablo / 2) / asc;
                mn1 = (apsa + apsg) * fps * dpu;
                mn2 = ast * fy * dsc1;
                mn3 = asc * fy * dsc2;
                mn4 = 0.85 * fc * (af * tt / 2 + aw * ablo / 2);
                mn = (mn1 + mn2 - mn3 - mn4) / 1000 / 1000;
                R.a = mn;
            }

        }

        function compute_mnMinusmetric() {

            ardy /= 100;
            ploss /= 100;
            ////////////////////////////////////// VARIABLES //////////////////////////////////////

            fpi = ardy * fpu; //jacking stress, fpi
            fse = (1 - ploss) * fpi; //effective prestressing after losses, fse

            if (fc < 27.58) { //beton basınç katsayısı
                b1 = 0.85;
            } else {
                b1 = 0.85 - (fc - 27.58) / 6.895 * 0.05;
            }

            //post tension ratio, rho p 
            dpa = (apsu * dpu + apsg * dpg) / (apsu + apsg);
            rpa = (apsu + apsg) / (b * dpa);

            //reinforcement centroid
            as1 = mtt[0] * 3.141 * (mtt[1] / 2) * (mtt[1] / 2);
            ds1 = h1 - pp;
            as2 = mtb[0] * 3.141 * (mtb[1] / 2) * (mtb[1] / 2);
            ds2 = h1 - tt + pp;
            as3 = 2 * mw[0] * 3.141 * (mw[1] / 2) * (mw[1] / 2);
            ds3 = (h1 - tt - tb) / 2 + tb;
            dsc = (as1 * ds1 + as2 * ds2 + as3 * ds3) / (as1 + as2 + as3);

            w = (as1 + as2 + as3) * fy / (b * dsc * fc); //computation of w

            //computation of wp
            as4 = mbt[0] * 3.141 * (mbt[1] / 2) * (mbt[1] / 2);
            as5 = mbb[0] * 3.141 * (mbb[1] / 2) * (mbb[1] / 2);
            wp = (as4 + as5) * fy / (b * dsc * fc);

            fps = fpu * (1 - gammp / b1 * (rpa * fpu / fc + dsc / dpa * (w - wp))); //computation of fps

            //computation of a
            ablo = ((apsu + apsg) * fps + (as1 + as2 + as3 - as4 - as5) * fy) / (0.85 * fc * b);
            ast = as1 + as2 + as3;
            asc = as4 + as5;
            ds5 = pp;
            ds4 = tb - pp;

            ////////////////////////////////////// RESULTS //////////////////////////////////////

            R.PTJS = fpi;
            R.PTESAL = fse;
            R.beta1 = b1;
            R.dp = dpa;
            R.rhoPu = rpa;
            R.d = dsc;
            R.w = w;
            R.wPrime = wp;
            R.fps = fps;
            R.a = ablo;

            //bottom flange top reinforcement is in tension 
            if (ablo < ds4) {
                dsc = (as1 * ds1 + as2 * ds2 + as3 * ds3 + as4 * ds4) / (as1 + as2 + as3 + as4);
                R.d = dsc;
                w = (as1 + as2 + as3 + as4) * fy / (b * dsc * fc);
                R.w = w;
                wp = (as5) * fy / (b * dsc * fc);
                R.wPrime = wp;
                fps = fpu * (1 - gammp / b1 * (rpa * fpu / fc + dsc / dpa * (w - wp)));
                R.fps = fps;
                ablo = ((apsu + apsg) * fps + (as1 + as2 + as3 + as4 - as5) * fy) / (0.85 * fc * b);
                R.a = ablo;
                ast = as1 + as2 + as3 + as4;
                asc = as5;
            }

            mn1 = (apsu + apsg) * fps * (dpa - ablo / 2);
            mn2 = ast * fy * (dsc - ablo / 2);
            mn = (mn1 + mn2) / 1000 / 1000;
            R.mn = mn;

            //check if a is within web
            if (ablo > tb) {
                acomp = ablo * b;
                af = (b - 2 * tw) * tb;
                aw = acomp - af;
                ablo = aw / (2 * tw);
                R.a = ablo;
                ast = as1 + as2 + as3 * (h1 - ablo) / h1;
                asc = as4 + as5 + as3 * ablo / h1;
                dsc1 = (as1 * ds1 + as2 * ds2 + as3 * (h1 - ablo) / h1 * (h1 / 2 + ablo / 2)) / ast;
                dsc2 = (as4 * ds4 + as5 * ds5 + as3 * ablo / h1 * ablo / 2) / asc;
                mn1 = (apsu + apsg) * fps * dpa;
                mn2 = ast * fy * dsc1;
                mn3 = asc * fy * dsc2;
                mn4 = 0.85 * fc * (af * tb / 2 + aw * ablo / 2);
                mn = (mn1 + mn2 - mn3 - mn4) / 1000 / 1000;
                R.mn = mn;
            }
        }
        if (moment) {
            compute_mnPlusmetric();
            R.mnp = mn;
        } else {
            compute_mnMinusmetric();
            R.mnn = mn;
        }
    }
    //*** IMPERIAL CALCULATIONS
    function imperial() {
        function compute_mnPlusimperial() { //moment = true

            ardy /= 100;
            ploss /= 100;
            dpa = h1 - dpa;
            dpg = h1 - dpg;
            ////////////////////////////////////// VARIABLES //////////////////////////////////////

            fpi = ardy * fpu; //jacking stress, fpi 
            fse = (1 - ploss) * fpi; //effective prestressing after losses, fse

            if (fc < 4000) { //beton basınç katsayısı, psi
                b1 = 0.85;
            } else {
                b1 = 0.85 - (fc - 4000) / 1000 * 0.05; //psi to ksi conversion
            }

            //post tension ratio, rho p 
            dpu = (apsa * dpa + apsg * dpg) / (apsa + apsg);
            rpu = (apsa + apsg) / (wt * dpu); //unitless

            //reinforcement centroid
            as5 = mbb[0] * 3.141 * (mbb[1] / 2) * (mbb[1] / 2);
            ds5 = h1 - pp;
            as4 = mbt[0] * 3.141 * (mbt[1] / 2) * (mbt[1] / 2);
            ds4 = h1 - (tb - pp);
            as3 = 2 * mw[0] * 3.141 * (mw[1] / 2) * (mw[1] / 2);
            ds3 = (h1 - tt - tb) / 2 + tt;
            dsc = (as5 * ds5 + as4 * ds4 + as3 * ds3) / (as5 + as4 + as3);

            w = (as5 + as4 + as3) * fy / (wt * dsc * fc); //computation of w

            //computation of wp
            as2 = mtb[0] * 3.141 * (mtb[1] / 2) * (mtb[1] / 2);
            as1 = mtt[0] * 3.141 * (mtt[1] / 2) * (mtt[1] / 2);
            wp = (as1 + as2) * fy / (wt * dsc * fc);

            fps = fpu * (1 - gammp / b1 * (rpu * fpu / fc + dsc / dpu * (w - wp))); //computation of fps

            //computation of a
            ablo = ((apsa + apsg) * fps + (as5 + as4 + as3 - as2 - as1) * fy) / (0.85 * fc * wt); //inch
            ast = as5 + as4 + as3;
            asc = as2 + as1;
            ds1 = pp;
            ds2 = tt - pp;

            ////////////////////////////////////// RESULTS //////////////////////////////////////
            R.PTJS = fpi;
            R.PTESAL = fse;
            R.beta1 = b1;
            R.dp = dpu;
            R.rhoPu = rpu;
            R.d = dsc;
            R.w = w;
            R.wPrime = wp;
            R.fps = fps;
            R.a = ablo;


            //top flange bottom reinforcement is in tension
            if (ablo < ds2) {
                dsc = (as5 * ds5 + as4 * ds4 + as3 * ds3 + as2 * ds2) / (as5 + as4 + as3 + as2);
                R.d = dsc;
                w = (as5 + as4 + as3 + as2) * fy / (wt * dsc * fc);
                R.w = w;
                wp = (as1) * fy / (wt * dsc * fc);
                R.wPrime = wp;
                fps = fpu * (1 - gammp / b1 * (rpu * fpu / fc + dsc / dpa * (w - wp)));
                R.fps = fps;
                ablo = ((apsa + apsg) * fps + (as5 + as4 + as3 + as2 - as1) * fy) / (0.85 * fc * wt);
                R.a = ablo;
                ast = as5 + as4 + as3 + as2;
                asc = as1;
            }

            mn1 = ((apsa + apsg) * fps * (dpu - ablo / 2)) / 1000 / 12; //pound to kips & inch to feet conversion
            mn2 = (ast * fy * (dsc - ablo / 2)) / 1000 / 12; //pound to kips & inch to feet conversion
            mn = mn1 + mn2;
            R.mn = mn;

            //check if a is within web
            if (ablo > tt) {
                acomp = ablo * wt;
                af = (wt - 2 * tw) * tt;
                aw = acomp - af;
                ablo = aw / (2 * tw);
                R.a = ablo;
                ast = as5 + as4 + as3 * (h1 - ablo) / h1;
                asc = as2 + as1 + as3 * ablo / h1;
                dsc1 = (as5 * ds5 + as4 * ds4 + as3 * (h1 - ablo / h1) * (h1 / 2 + ablo / 2)) / ast;
                dsc2 = (as1 * ds1 + as2 * ds2 + as3 * ablo / h1 * ablo / 2) / asc;
                mn1 = ((apsa + apsg) * fps * dpu) / 1000 / 12; //pound to kips & inch to feet conversion
                mn2 = (ast * fy * dsc1) / 1000 / 12; //pound to kips & inch to feet conversion
                mn3 = (asc * fy * dsc2) / 1000 / 12; //pound to kips & inch to feet conversion
                mn4 = (0.85 * fc * (af * tt / 2 + aw * ablo / 2)) / 1000 / 12; //pound to kips & inch to feet conversion
                mn = mn1 + mn2 - mn3 - mn4;
                R.a = mn;
            }

        }

        function compute_mnMinusimperial() {

            ardy /= 100;
            ploss /= 100;
            ////////////////////////////////////// VARIABLES //////////////////////////////////////

            fpi = ardy * fpu; //jacking stress, fpi
            fse = (1 - ploss) * fpi; //effective prestressing after losses, fse

            if (fc < 4000) { //beton basınç katsayısı
                b1 = 0.85;
            } else {
                b1 = 0.85 - (fc - 4000) / 1000 * 0.05;
            }

            //post tension ratio, rho p 
            dpa = (apsu * dpu + apsg * dpg) / (apsu + apsg);
            rpa = (apsu + apsg) / (b * dpa);

            //reinforcement centroid
            as1 = mtt[0] * 3.141 * (mtt[1] / 2) * (mtt[1] / 2);
            ds1 = h1 - pp;
            as2 = mtb[0] * 3.141 * (mtb[1] / 2) * (mtb[1] / 2);
            ds2 = h1 - tt + pp;
            as3 = 2 * mw[0] * 3.141 * (mw[1] / 2) * (mw[1] / 2);
            ds3 = (h1 - tt - tb) / 2 + tb;
            dsc = (as1 * ds1 + as2 * ds2 + as3 * ds3) / (as1 + as2 + as3);

            w = (as1 + as2 + as3) * fy / (b * dsc * fc); //computation of w

            //computation of wp
            as4 = mbt[0] * 3.141 * (mbt[1] / 2) * (mbt[1] / 2);
            as5 = mbb[0] * 3.141 * (mbb[1] / 2) * (mbb[1] / 2);
            wp = (as4 + as5) * fy / (b * dsc * fc);

            fps = fpu * (1 - gammp / b1 * (rpa * fpu / fc + dsc / dpa * (w - wp))); //computation of fps

            //computation of a
            ablo = ((apsu + apsg) * fps + (as1 + as2 + as3 - as4 - as5) * fy) / (0.85 * fc * b);
            ast = as1 + as2 + as3;
            asc = as4 + as5;
            ds5 = pp;
            ds4 = tb - pp;

            ////////////////////////////////////// RESULTS //////////////////////////////////////

            R.PTJS = fpi;
            R.PTESAL = fse;
            R.beta1 = b1;
            R.dp = dpa;
            R.rhoPu = rpa;
            R.d = dsc;
            R.w = w;
            R.wPrime = wp;
            R.fps = fps;
            R.a = ablo;

            //bottom flange top reinforcement is in tension 
            if (ablo < ds4) {
                dsc = (as1 * ds1 + as2 * ds2 + as3 * ds3 + as4 * ds4) / (as1 + as2 + as3 + as4);
                R.d = dsc;
                w = (as1 + as2 + as3 + as4) * fy / (b * dsc * fc);
                R.w = w;
                wp = (as5) * fy / (b * dsc * fc);
                R.wPrime = wp;
                fps = fpu * (1 - gammp / b1 * (rpa * fpu / fc + dsc / dpa * (w - wp)));
                R.fps = fps;
                ablo = ((apsu + apsg) * fps + (as1 + as2 + as3 + as4 - as5) * fy) / (0.85 * fc * b);
                R.a = ablo;
                ast = as1 + as2 + as3 + as4;
                asc = as5;
            }

            mn1 = ((apsu + apsg) * fps * (dpa - ablo / 2)) / 1000 / 12; //pound to kips & inch to feet conversion
            mn2 = (ast * fy * (dsc - ablo / 2)) / 1000 / 12; //pound to kips & inch to feet conversion
            mn = mn1 + mn2;
            R.mn = mn;

            //check if a is within web
            if (ablo > tb) {
                acomp = ablo * b;
                af = (b - 2 * tw) * tb;
                aw = acomp - af;
                ablo = aw / (2 * tw);
                R.a = ablo;
                ast = as1 + as2 + as3 * (h1 - ablo) / h1;
                asc = as4 + as5 + as3 * ablo / h1;
                dsc1 = (as1 * ds1 + as2 * ds2 + as3 * (h1 - ablo) / h1 * (h1 / 2 + ablo / 2)) / ast;
                dsc2 = (as4 * ds4 + as5 * ds5 + as3 * ablo / h1 * ablo / 2) / asc;
                mn1 = ((apsu + apsg) * fps * dpa) / 1000 / 12;
                mn2 = (ast * fy * dsc1) / 1000 / 12;
                mn3 = (asc * fy * dsc2) / 1000 / 12;
                mn4 = (0.85 * fc * (af * tb / 2 + aw * ablo / 2)) / 1000 / 12;
                mn = mn1 + mn2 - mn3 - mn4;
                R.mn = mn;
            }
        }
        if (moment) {
            compute_mnPlusimperial();
            R.mnp = mn
        } else {
            compute_mnMinusimperial();
            R.mnn = mn
        }

    }
    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

}

module.exports = {
    calculate
}