var metric = {
  x1: "700",
  x2: "1030",
  x3: "200",
  x4: "50",
  y1: "200",
  y2: "100",
  y3: "800",
  y4: "100",
  y5: "75",
  y6: "125",
  ts: "250",
  s: "1350",
  Eg: "32000",
  Es: "28000"
};

var imperial = {
  x1: "27.5",
  x2: "40.5",
  x3: "8",
  x4: "2",
  y1: "8",
  y2: "4",
  y3: "31.5",
  y4: "4",
  y5: "3",
  y6: "5",
  ts: "10",
  s: "50",
  Eg: "4650000", //(psi)
  Es: "4050000"
};

export default {
  metric,
  imperial
}