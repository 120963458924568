const testData = {
    metric: {
        PDL: 7000,
        fc: 30,
        L: 10
    },
    imperial: {
        PDL: 1575,
        fc: 5,
        L: 32
    }
}

export default testData;