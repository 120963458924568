let outputs = {
    S: {
        label1: "Shape Factor",
        value: -1,
        cat: 1,
        type: "number",
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "Shape Factor",
            desc: "S"
        }
    }
}

export default outputs;