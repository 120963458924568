var inputs = {
    Ag: {
        label: 'Area of Girder',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Girder',
            desc: ''
        }
    },
    gammaConc: {
        label: 'Unit Weigth of Reinforced Concrete',
        value: null,
        cat: 1, //34 karakter
        suffix: {
            metric: 'kN/m³',
            imperial: 'kips/ft³'
        },
        description: {
            title: 'Unit Weigth of Reinforced Concrete',
            desc: ''
        }
    },
    L: {
        label: 'Span Length',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    }
};

export default inputs