<template>
  <div>
    <v-data-table
      v-if="alternative.decks.length>0"
      :headers="tableHeaders"
      :items="displayItems"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-radio-group v-model="selectedDisplay" row class="pt-6">
            <v-radio label="Piers" value="piers"></v-radio>
            <v-radio label="Decks" value="decks"></v-radio>
          </v-radio-group>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-layout v-else>
      <v-flex xs12>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
        >This alternative is not valid for the given valley geometry.</v-alert>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "AlternativeDisplayComponent",
  props: {
    alternative: {
      type: Object,
      required: true
    }
  },
  methods: {
    drawAlternative() {}
  },
  data() {
    return {
      selectedDisplay: "piers"
    };
  },
  computed: {
    tableHeaders: function() {
      if (this.selectedDisplay === "piers") {
        return [
          { text: "Distance", value: "station.distance" },
          { text: "Height", value: "height" }
        ];
      } else {
        return [
          { text: "Start", value: "startStation.distance" },
          { text: "End", value: "endStation.distance" },
          { text: "Length", value: "length" },
          { text: "Type", value: "type" }
        ];
      }
    },
    displayItems: function() {
      return this.alternative[this.selectedDisplay];
    },
    tableTitle: function() {
      if (this.selectedDisplay === "piers") {
        return "Piers";
      } else {
        return "Decks";
      }
    }
  }
};
</script>

<style>
</style>