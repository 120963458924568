var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',[_c('v-flex',{attrs:{"xs-12":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.allNews,"single-expand":true,"expanded":_vm.expanded,"show-expand":"","item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event},"click":function($event){return _vm.rowClicked(_vm.row)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Announcements")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-4 mb-2",attrs:{"color":"primary","icon":""},on:{"click":_vm.showAddNews}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("add_circle_outline")])],1),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","icon":""},on:{"click":_vm.refreshNews}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("refresh")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add/Edit Announcement")]),_c('v-card-text',[_c('AddNewsComponent',{attrs:{"editedNews":_vm.editedItem}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.saveNews}},[_vm._v("Save")])],1)],1)],1)],1)]},proxy:true},{key:"item.display",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.displayChanged(item)}},model:{value:(item.display),callback:function ($$v) {_vm.$set(item, "display", $$v)},expression:"item.display"}})]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"info","icon":"info"}},[_vm._v("No news found")])]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.showEditNews(item)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("delete")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":"5"}},[_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(item.content))])])]}}])})],1)],1),_c('v-layout',[_c('v-flex',{attrs:{"xs-12":""}},[(_vm.result)?_c('v-alert',{staticClass:"mx-2",attrs:{"type":_vm.result.result}},[_vm._v(_vm._s(_vm.result.text))]):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color,"bottom":"","multi-line":"","timeout":_vm.timeout},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }