<template>
  <div>
    <structure
      inputType="c2_simple"
      :categoryNumber="2"
      topImage1="elongationOnStrandTendons.png"
      topImage2="elongationOnStrandTendons2.png"
      :titles="['Prestressing Steel', 'Strand Geometry']"
      :tooltips="[
                '',
                ''
            ]"
      :inputs="inputs"
      :testData="testData"
      @CALC_METRIC="calcMetric"
      @CALC_IMP="calcImperial"
      @system="systemChanged"
      @CLEAR_RESULTS="clearResults"
      @GENERATE_REPORT="generateReportEmitted"
      @INPUT_MISSING="inputMissingEmitted"
    />

    <results
      resultType="elongationOnStrandTendons"
      :outputs="outputs"
      :system="system"
      :result1="result1"
      :result2="result2"
    />
    <!-- Snackbar for errors -->
    <v-snackbar v-model="errorSnack" :timeout="3000" color="red" bottom>
      {{ errorText }}
      <v-btn color="white" text @click="errorSnack = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import structure from "@/_calculators/static/calculatorStructure";
import results from "@/_calculators/static/results";
import functions from "@/_calculators/src/elongationOnStrandTendons/functions";
import inputs from "@/_calculators/src/elongationOnStrandTendons/inputs";
import outputs from "@/_calculators/src/elongationOnStrandTendons/outputs";
import testData from "@/_calculators/src/elongationOnStrandTendons/testData";
import { GeneratePDFReport } from "@/_calculators/src/shared/report";

export default {
  components: {
    structure,
    results
  },
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      testData: testData,
      result1: null,
      result2: null,
      errorSnack: false,
      errorText: null
    };
  },
  watch: {
    system() {
      this.clearResults();
    }
  },
  methods: {
    /* Static */
    systemChanged(val) {
      this.system = val;
    },
    calcMetric() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "metric");
    },
    calcImperial() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "imperial");
    },
    clearResults() {
      this.result1 = null;
      this.result2 = null;
    },
    async generateReportEmitted() {
      try {
        let user = this.$store.getters["users/user"];
        await GeneratePDFReport(
          this.inputs,
          this.outputs,
          this.result1,
          "Elongation Computations",
          `${user.name} ${user.surname}`,
          this.system
        );
      } catch (error) {
        if (error.name === "TypeError") {
          this.errorSnack = true;
          this.errorText = "Please run the calculations before reporting.";
        } else {
          this.errorSnack = true;
          this.errorText = `The following error has occurred: ${error.name}`;
        }
      }
    },
    inputMissingEmitted() {
      this.errorSnack = true;
      this.errorText = "Please fill in all the input fields";
    }
  }
};
</script>

<style>
</style>