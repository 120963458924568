var outputs = {

    wslabw: {
        label1: 'Uniform Wet Slab Dead Load', //26 karakter
        label2: 'wslabw',
        suffix: {
            metric: 'kN/m',
            imperial: 'klf'
        },
        description: {
            title: 'Uniform Wet Slab Dead Load',
            desc: ''
        }
    },
    Mslabw: {
        label1: 'Max. Moment of Wet Slab',
        label2: 'Mslabw',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Maximum Moment of Wet Slab',
            desc: ''
        }
    }
};

export default outputs;