<template>
  <div>
    <!-- Image -->
    <v-layout wrap>
      <v-flex xs6 v-for="(img, index) in 2" :key="index">
        <div>
          <v-img :src="getImgUrl(images[index])" max-height="250" contain></v-img>
        </div>
      </v-flex>
    </v-layout>
    <!-- Metric vs Imperial -->
    <!-- Metric vs Imperial Radio Boxes -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout wrap>
            <v-flex xs12>
              <v-radio-group v-model="system" row>
                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <!-- Inputs -->
    <v-layout>
      <v-flex xs4 v-for="i in 3" :key="i">
        <div class="mt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout wrap>
            <v-flex xs10 class="my-3 pl-2">
              <div>{{i}}. {{titles[i-1]}}</div>
            </v-flex>
            <v-flex xs2>
              <v-tooltip top max-width="250" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">{{tooltips[i-1]}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <v-card class="mt-1 mr-1 elevation-2" height="310">
          <v-layout class="pa-3">
            <v-flex>
              <v-form>
                <v-layout wrap>
                  <v-flex
                    class="pl-1"
                    v-for="(input, index) in inputs[`i${i}`]"
                    :key="input.label"
                    v-bind="{[`xs${(i === 3 && index !== 'gammaConc' && index !== 'gammaConcW' ) ? 6 : 12}`]: true}"
                  >
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      type="number"
                      v-model="input.value"
                      :label="input.label"
                      hide-details
                      filled
                      required
                      :suffix="input.suffix[system]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
        <v-layout my-1 wrap>
          <v-flex xs12>
            <v-layout wrap>
              <!-- Load Test Data -->
              <v-flex xs4 class="mr-1">
                <v-tooltip top width="200" color="grey darken-3">
                  <template v-slot:activator="{ on }">
                    <v-btn block v-on="on" @click="loadTestData(i)">Test Data</v-btn>
                  </template>
                  <span style="font-size: 13px;">Default values will be filled.</span>
                </v-tooltip>
              </v-flex>
              <!-- Clear -->
              <v-flex xs4>
                <v-tooltip top width="200" color="grey darken-3">
                  <template v-slot:activator="{ on }">
                    <v-btn block v-on="on" @click="clearResults(i)">Clear</v-btn>
                  </template>
                  <span style="font-size: 13px;">All values will be erased.</span>
                </v-tooltip>
              </v-flex>
              <v-flex xs class="mx-1">
                <v-tooltip top width="300" color="grey darken-3">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      block
                      color="green darken-4"
                      dark
                      v-on="on"
                      @click="checkAllFields(i)"
                    >Calculate</v-btn>
                  </template>
                  <span style="font-size: 13px;">Section propreties will be calculated.</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <!-- Results -->

    <v-layout v-show="results[`r1`] || results[`r2`] || results[`r3`]">
      <v-flex xs12>
        <v-toolbar class="blue darken-2" dark height="50">
          <v-spacer></v-spacer>
          <div style="font-size: 18px; font-weight: bold;">RESULTS</div>
          <v-spacer></v-spacer>
        </v-toolbar>
        <div class="mt-3">
          <div class="ma-1 elevation-2 grey lighten-3">
            <v-img :src="getImgUrl(images[3])" max-height="205" contain></v-img>
          </div>
        </div>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs4 v-for="i in 3" :key="i">
        <v-flex xs12 class="my-1" v-if="results[`r${i}`]">
          <v-card class="ma-1 elevation-2">
            <v-layout class="pa-3">
              <v-layout wrap>
                <v-flex xs12 class="pl-1" v-for="(result, index) in outputs[`o${i}`]" :key="index">
                  <v-card class="mt-2 elevation-2">
                    <resultCard
                      :result="result"
                      :result1="results[`r${i}`]"
                      :result2="resultsHistory[`r${i}`]"
                      :changes="changes[`r${i}`]"
                      :i="index"
                      :system="system"
                    />
                  </v-card>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import unitConvertor from "@/_calculators/static/unitConvertor";
/* Functions */
import f1 from "@/_calculators/src/deadLoad/d1/functions";
import f2 from "@/_calculators/src/deadLoad/d2/functions";
import f3 from "@/_calculators/src/deadLoad/d3/functions";
/* Inputs */
import i1 from "@/_calculators/src/deadLoad/d1/inputs";
import i2 from "@/_calculators/src/deadLoad/d2/inputs";
import i3 from "@/_calculators/src/deadLoad/d3/inputs";
/* Outputs */
import o1 from "@/_calculators/src/deadLoad/d1/outputs";
import o2 from "@/_calculators/src/deadLoad/d2/outputs";
import o3 from "@/_calculators/src/deadLoad/d3/outputs";
/* TestData */
import testData from "@/_calculators/src/deadLoad/testData";
/* ResultCard */
import resultCard from "@/_calculators/static/resultCard";

export default {
  components: {
    resultCard
  },
  data() {
    return {
      images: [
        "deadLoad_1.png",
        "deadLoad_2.png",
        "deadLoad_3.png",
        "deadLoad_output_general.png"
      ],
      system: "metric",
      inputs: {
        i1,
        i2,
        i3
      },
      outputs: {
        o1,
        o2,
        o3
      },
      titles: [
        "Self Weight of Girder",
        "Wet Weight of Slab",
        "Superimposed Dead Loads "
      ],
      tooltips: [
        "Area of girder can be determined from “Concrete I Girder Properties” calculator. All values are positive.",
        "Girder Spacing can be determined from “Selection of Girder Spacing and Depth” calculator. All values are positive.",
        "Use the cross-section to identify the elements. All values are positive."
      ],
      testData: testData,
      results: {
        r1: null,
        r2: null,
        r3: null
      },
      resultsHistory: {
        r1: null,
        r2: null,
        r3: null
      },
      changes: {
        r1: {},
        r2: {},
        r3: {}
      }
    };
  },
  created() {
    for (let i = 1; i <= 3; i++) {
      this.loadTestData(i);
    }
  },
  computed: {
    rh1() {
      return this.resultsHistory.r1;
    },
    rh2() {
      return this.resultsHistory.r2;
    },
    rh3() {
      return this.resultsHistory.r3;
    }
  },
  watch: {
    system(val) {
      this.convertUnits(val);
      this.clearResults("all");
    },
    rh1(val) {
      if (val) {
        this.changePerc("r1");
      }
    },
    rh2(val) {
      if (val) {
        this.changePerc("r2");
      }
    },
    rh3(val) {
      if (val) {
        this.changePerc("r3");
      }
    }
  },
  methods: {
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    },
    functionBundle() {
      return [f1, f2, f3];
    },
    systemChanged(val) {
      this.system = val;
    },
    calcMetric(id) {
      if (this.results[`r${id}`]) {
        this.resultsHistory[`r${id}`] = this.results[`r${id}`];
      }
      this.results[`r${id}`] = this.functionBundle()[id - 1].calculate(
        this.inputs[`i${id}`],
        "metric"
      );
    },
    calcImperial(id) {
      if (this.results[`r${id}`]) {
        this.resultsHistory[`r${id}`] = this.results[`r${id}`];
      }
      this.results[`r${id}`] = this.functionBundle()[id - 1].calculate(
        this.inputs[`i${id}`],
        "imperial"
      );
    },

    clearResults(id) {
      if (id === "all") {
        this.results = {
          r1: null,
          r2: null,
          r3: null
        };
        this.resultsHistory = {
          r1: null,
          r2: null,
          r3: null
        };
      } else {
        this.changeInputs("clear", `i${id}`);
        this.results[`r${id}`] = null;
        this.resultsHistory[`r${id}`] = null;
      }
    },

    checkAllFields(id) {
      var inputKeys = Object.keys(this.inputs[`i${id}`]);
      var inputValues = inputKeys.map(key => this.inputs[`i${id}`][key].value);
      var isOkay = inputValues.every(
        i => i !== null && i !== undefined && i !== ""
      );
      if (isOkay) {
        inputKeys.map(
          key =>
            (this.inputs[`i${id}`][key].value = parseFloat(
              this.inputs[`i${id}`][key].value
            ))
        );
        if (this.system === "metric") {
          this.calcMetric(id);
        } else if (this.system === "imperial") {
          this.calcImperial(id);
        }
      } else {
        alert("There are error(s) in the input, please fill out all fields!");
      }
    },
    changeInputs(type, id, system) {
      var inputKeys = Object.keys(this.inputs[id]);
      if (type === "clear") {
        inputKeys.forEach(input => {
          this.inputs[id][input].value = null;
        });
      } else if (type === "test") {
        if (this.system === "metric") {
          inputKeys.forEach(input => {
            this.inputs[id][input].value = this.testData[id].metric[input];
          });
        } else {
          inputKeys.forEach(input => {
            this.inputs[id][input].value = this.testData[id].imperial[input];
          });
        }
      }
    },
    convertUnits(to) {
      for (let i = 1; i <= 3; i++) {
        var inputKeys = Object.keys(this.inputs[`i${i}`]);
        if (to === "metric") {
          inputKeys.forEach(key => {
            var value = this.inputs[`i${i}`][key].value;
            if (value !== null && value !== "" && value !== undefined) {
              var converted = unitConvertor.convertImpToMetric(
                value,
                this.inputs[`i${i}`][key].suffix.imperial
              );
              this.inputs[`i${i}`][key].value = converted.v;
            }
          });
        } else if (to === "imperial") {
          inputKeys.forEach(key => {
            var value = this.inputs[`i${i}`][key].value;
            if (value !== null && value !== "" && value !== undefined) {
              var converted = unitConvertor.convertMetricToImp(
                value,
                this.inputs[`i${i}`][key].suffix.metric
              );
              this.inputs[`i${i}`][key].value = converted.v;
            }
          });
        }
      }
    },
    loadTestData(id) {
      if (this.system === "metric") {
        this.changeInputs("test", `i${id}`, "metric");
      } else if (this.system === "imperial") {
        this.changeInputs("test", `i${id}`, "imperial");
      }
    },
    changePerc(id) {
      var keys = Object.keys(this.results[id]);
      keys.forEach(key => {
        var r1 = this.results[id][key];
        var r2 = this.resultsHistory[id][key];
        var result;
        var sign = null;
        if (r1 && r2) {
          result = (r1 * 100) / r2 - 100;

          if (result > -0.005 && result < 0.005) {
            sign = "swap_horiz";
          } else if (result > 0.005) {
            sign = "trending_up";
          } else if (result < -0.005) {
            sign = "trending_down";
          }
        } else {
          result = "";
        }
        this.changes[id][key] = {
          result,
          sign
        };
      });
    }
  }
};
</script>

<style>
</style>