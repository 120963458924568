import outputs from "@/_calculators/src/singleCircularColumn/outputs";


function calculate(inputs, system) {
    let iv = {};
    // imperial to metric conversion factors
    const imcf = {
        kip: 4.4482216,
        ksi: 6.89476,
        ft: 0.3048
    };
    const lamdbaMax = 60;
    // calculations are in SI; if system is imperial cenvert user inputs to SI
    for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
            const element = inputs[key];
            if (system === "metric") {
                iv[key] = Number(element.value);
            } else {
                iv[key] = Number(element.value) * imcf[element.suffix.imperial];
            }
        }
    }

    const reqArea = iv.PDL * 1000 / (0.1 * iv.fc);
    let d = 2 * Math.sqrt(reqArea / Math.PI);
    // check slenderness
    const rg = d / 1000 / 4;
    const lambda = 2 * iv.L / rg;
    if (lambda > lamdbaMax) {
        const rgmax = 2 * iv.L / lamdbaMax;
        d = 4 * rgmax * 1000;
    }

    if (system === "metric") {
        // round to nearest 100
        outputs.D.value = Math.ceil(d / 100) * 100;
    } else {
        // convert to inch then round to nearest 6
        outputs.D.value = Math.ceil(d / 25.4 / 6) * 6;
    }
    return outputs;
}



export default calculate;