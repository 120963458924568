function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var L = h('L'),
        s = h('s'),
        ts = h('ts'),
        Ag = h('Ag'),
        Ig = h('Ig'),
        d = h('d'),
        yb = h('yb'),
        Eg = h('Eg'),
        Es = h('Es'),
        lane = h('lane'),
        Mmax = h('Mmax');
    var eg, gMi, gmi, n, Kg;
    // Metric Calculations
    function metric() {
        eg = d + ts / 2 - yb
        n = Eg / Es
        Kg = n * (Ig + eg * eg * Ag)
        if (lane === 1)
            R.g = 0.06 + Math.pow(s / 4300, 0.4) * Math.pow(s / (L * 1000), 0.3) * Math.pow(Kg / (L * 1000 * ts * ts * ts), 0.1)
        else if (lane > 1) {
            gmi = 0.06 + Math.pow(s / 4300, 0.4) * Math.pow(s / (L * 1000), 0.3) * Math.pow(Kg / (L * 1000 * ts * ts * ts), 0.1)
            gMi = 0.075 + Math.pow(s / 2900, 0.6) * Math.pow(s / (L * 1000), 0.2) * Math.pow(Kg / (L * 1000 * ts * ts * ts), 0.1)
            R.g = Math.max(gMi, gmi)
        }
        R.Mgi = Mmax * R.g
    };


    // Imperial Calculations
    function imperial() {
        eg = d + ts / 2 - yb
        n = Eg / Es
        Kg = n * (Ig + eg * eg * Ag)
        if (lane === 1)
            R.g = 0.06 + Math.pow(s * 0.0833333333 / 14, 0.4) * Math.pow(s * 0.0833333333 / L, 0.3) * Math.pow(Kg / (12 * L * ts * ts * ts), 0.1)
        else if (lane > 1) {
            gmi = 0.06 + Math.pow((s * 0.0833333333) / 14, 0.4) * Math.pow((s * 0.0833333333) / L, 0.3) * Math.pow(Kg / (12 * L * ts * ts * ts), 0.1)
            gMi = 0.075 + Math.pow((s * 0.0833333333) / 9.5, 0.6) * Math.pow((s * 0.0833333333) / L, 0.2) * Math.pow(Kg / (12 * L * ts * ts * ts), 0.1)
            R.g = Math.max(gMi, gmi)
        }
        R.Mgi = Mmax * R.g

    };
    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
        console.error('System is missing !')
    }

}

module.exports = {
    calculate
}