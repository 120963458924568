class CalculatorRoute {
    /* Path, cID, RouteName & ComponentName */
    constructor(path, id, name) {
        this.path = path;
        this.id = id;
        this.name = name;
    }
}

/* Don't Forget to add component to router.js */
/* loeadBearingCap calculator is disabled temporarily for complete rewrite*/
var calculatorRoutes = [
    new CalculatorRoute('path1', 'd7IeMe7VGea3Rs7', 'deadLoad'),
    new CalculatorRoute('path2', 'fgMui3PNnJZNGNr', 'liveLoad1'),
    new CalculatorRoute('path3', 'kLKlKAPYXo4oL03', 'liveLoad2'),
    new CalculatorRoute('path4', 'PtK7sQL9s7sZuQs', 'concreteGirderGeometry'),
    new CalculatorRoute('path5', 'i7rHE1piYnwS4vw', 'steelWideFlangeGeometry'),
    new CalculatorRoute('path6', 'vED2hU8mbO160AC', 'selectionOfGirderDepth'),
    new CalculatorRoute('path7', 'GmtCSgbxwPPBZ3N', 'minRequiredNoOfStrands'),
    new CalculatorRoute('path8', 'djzJhT7HKUMOPxE', 'elongationOnStrandTendons'),
    new CalculatorRoute('path9', 'F5E2gZq8uP8sQ9q', 'axialOnPileGroups'),
    new CalculatorRoute('path11', 'p7Esa4ZmL7qWaS1', 'blisterDesign'),
    new CalculatorRoute('path12', 'wE4pGc2xZsQPhB7', 'cellBoxFlexural'),
    new CalculatorRoute('path13', '1tFiPqPQWq8h', 'discreteFourier'),
    new CalculatorRoute('path14', '9r1yIib3COp7', 'liveMoment'),
    new CalculatorRoute('path15', 'wfSBPyN3CS7Q', 'costEstimation'),
    new CalculatorRoute('path16', 'c1ab90616bda445', 'plateFilletWeld'),
    new CalculatorRoute('path17', '2b2d43cda88a4aa', 'singleCircularColumn'),
    new CalculatorRoute('path18', '8befc142bac346f', 'potBearing'),
    new CalculatorRoute('path19', '7ab419ae8f6645c', 'simpleInspection'),
    new CalculatorRoute('path20', '18e4a77250eb4c6', 'pierTimeHistory'),
    new CalculatorRoute('path21', 'v1ab90r4yxda908', 'simpleSupportedBeam'),
    new CalculatorRoute('path22', '66675C64ED51', 'pinDesign'),
    new CalculatorRoute('path23', '9h575C64tEd3', 'shapeFactor')
]

module.exports = calculatorRoutes