<template>
  <div class="mx-3 my-2 pa-1">
    <h2 class="mb-3">Account Details</h2>
    <v-layout v-if="premium">
      <v-flex sm12>
        <v-alert
          :value="true"
          color="info"
          icon="info"
          outlined
        >You are already a premium member; your subscription will end on {{new Date(premiumEndDate.seconds*1000)}}</v-alert>
      </v-flex>
    </v-layout>
    <v-layout v-else wrap>
      <v-container fluid grid-list-md class="pa-0">
        <v-data-iterator :items="plans" hide-default-footer>
          <template v-slot:header>
            <div class="mb-3">
              You have access to only free tier calculators. You can upgrade your account using one of the
              following plans:
            </div>
          </template>
          <template v-slot:default="props">
            <v-layout wrap>
              <v-flex v-for="item in props.items" :key="item.title" xs12 sm6 md4 lg3>
                <v-card>
                  <v-card-title>
                    <h4>{{item.title}}</h4>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content>Includes:</v-list-item-content>
                      <v-list-item-content class="justify-center">{{item.scope}}</v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Support:</v-list-item-content>
                      <v-list-item-content class="justify-center">
                        <v-icon>{{item.support}}</v-icon>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Monthly Price:</v-list-item-content>
                      <v-list-item-content class="justify-center">${{item.MonthlyPrice}}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="blue"
                      @click.stop="purchase(item.id)"
                    >PURCHASE FOR ${{item.price}}</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </v-data-iterator>
      </v-container>
      <v-flex xs12>
        <v-alert :value="error" type="error">{{error}}</v-alert>
      </v-flex>
    </v-layout>

    <v-footer color="blue darken-1">
      <v-layout wrap>
        <v-spacer></v-spacer>
        <v-flex xs8 sm5 md3>
          <v-layout wrap>
            <v-flex v-for="(image, i) in images" :key="i" py-2>
              <v-img :src="getImgUrl(image)" width="90" :aspect-ratio="1.67"></v-img>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-footer>

    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Agreements</v-card-title>
        <v-card-text>
          By clicking the "Agree and Purchase" button, you agree on the
          <a
            href="/docs/distant.pdf"
            target="_new"
          >Distant Sales Contract</a> and
          <a href="/docs/nondisc.pdf" target="_new">Nondisclosure Agreement.</a>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Disagree</v-btn>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="blue darken-1"
            text
            @click="processPayment"
          >
            Agree and Purchase
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import db from "@/firebaseInit.js";

export default {
  data() {
    return {
      images: ["mastercard@2x.png", "visa@2x.png"],
      autoRenew: false,
      checkboxes: [
        {
          value: false,
          frontMessage: "I have read and agreed to",
          link: "../docs/nondisc.pdf",
          name: "Non-disclosure Agreement"
        },
        {
          value: false,
          frontMessage: "I have read and agreed to",
          link: "../docs/distant.pdf",
          name: "Distance Sales Contract"
        }
      ],
      checkbox: false,
      error: null,
      loading: false,
      premium: false,
      premiumEndDate: null,
      selectedPlan: null,
      dialog: false,
      plans: [
        {
          id: "1-month",
          months: 1,
          title: "1 Month",
          support: "cancel",
          scope: "All Calculators",
          MonthlyPrice: "",
          price: ""
        },
        {
          id: "3-months",
          months: 3,
          title: "3 Months",
          support: "cancel",
          scope: "All Calculators",
          MonthlyPrice: "",
          price: ""
        },
        {
          id: "6-months",
          months: 6,
          title: "6 Months",
          support: "check_circle",
          scope: "All Calculators",
          MonthlyPrice: "",
          price: ""
        },
        {
          id: "12-months",
          months: 12,
          title: "12 Months",
          support: "check_circle",
          scope: "All Calculators",
          MonthlyPrice: "",
          price: ""
        }
      ]
    };
  },
  created: function() {
    let user = this.$store.getters["users/user"];
    let tier = user.tier;
    this.premiumEndDate = user.subscriptionEndDate;
    if (tier == "tier2") {
      this.premium = true;
    } else {
      this.premium = false;
    }
  },
  mounted() {
    // download the tier 2 price. In case of multiple tiers, download the prices to an array/map
    let ref = db.collection("prices").doc("tier2");
    ref
      .get()
      .then(doc => {
        if (doc.exists) {
          let priceData = doc.data();
          // update prices of all tiers
          this.plans.forEach(plan => {
            plan.price = priceData[plan.id];
            plan.MonthlyPrice =
              Math.round((plan.price / plan.months) * 100) / 100;
          });
        }
      })
      .catch(err => {
        // in case of error, use default price
      });
  },
  methods: {
    getImgUrl(image) {
      return require(`@/assets/imgs/static/logos/${image}`);
    },
    purchase(planId) {
      this.dialog = true;
      this.selectedPlan = planId;
    },
    async processPayment() {
      // reset error
      this.error = null;
      // start loading
      this.loading = true;
      // get user id
      var user = firebase.auth().currentUser;
      // get the selected plan
      let selectedPlanObject = this.plans.find(plan => {
        return plan.id == this.selectedPlan;
      });
      await axios
        .post("/payment-service", {
          conversationId: 123456,
          price: selectedPlanObject.price,
          email: user.email,
          name: user.email,
          surname: user.email,
          userId: user.uid,
          identityNumber: "12345678901",
          registrationAddress: user.email,
          city: "City",
          country: "Country",
          // basket id incorporates the user uid and the selected plan
          basketId: user.uid + "|" + selectedPlanObject.months
        })
        .then(response => {
          // if the response is successful
          if (response.status == 200 && response.data.status == "success") {
            // the response includes both the payment page code and an external payment url.
            // redirect the user to the external payment url
            window.location.assign(response.data.paymentPageUrl);
          }
          // on failure for checkoutform initialization
          else {
            this.error = response.data.message;
            this.loading = false;
            this.dialog = false;
          }
        })
        // on failure for making request
        .catch((reason, response) => {
          this.error = reason;
          this.loading = false;
          this.dialog = false;
        });
    }
  }
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>