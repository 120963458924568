function convertMetricToImp(value, unit) {
        if (typeof value !== 'string') {
                value = parseFloat(value);
        }
        var u, v, d;

        if (unit === 'm') {
                u = 'ft';
                v = 3.280840 * value;
        } else if (unit === 'm²') {
                u = 'ft²';
                v = 10.763910 * value;
        } else if (unit === 'm³') {
                u = 'ft³';
                v = 35.314667 * value;
        } else if (unit === 'm⁴') {
                u = 'ft⁴';
                v = 115.861782 * value;
        } else if (unit === 'mm') {
                u = 'in';
                v = 0.039370 * value;
        } else if (unit === 'mm²') {
                u = 'in²';
                v = 0.001550 * value;
        } else if (unit === 'mm⁴') {
                u = 'in⁴';
                v = 0.0000024025 * value;
        } else if (unit === '1/m') {
                u = '1/ft';
                d = 7;
                v = 0.3048 * value;
        } else if (unit === 'kN') {
                u = 'kips';
                v = 0.224809 * value;
        } else if (unit === 'kN/m') {
                u = 'kips/ft';
                v = 0.068522 * value;
        } else if (unit === 'kN/m³') {
                u = 'kips/ft³';
                v = 0.006423 * value;
        } else if (unit === 'kN.m') {
                u = 'kips.ft';
                v = 0.737562 * value;
        } else if (unit === 'kg') {
                u = 'slug';
                v = 0.068522 * value;
        } else if (unit === 'ton') {
                u = 'lbf(sec²)/in';
                v = 5.180158 * value;
        } else if (unit === 'N') {
                u = 'lbf';
                v = 0.224809 * value;
        } else if (unit === 'm/sec') {
                u = 'ft/sec';
                v = 3.280840 * value;
        } else if (unit === 'mm/sec') {
                u = 'in/sec';
                v = 0.039370 * value;
        } else if (unit === 'm/sec²') {
                u = 'ft/sec²';
                v = 3.280840 * value;
        } else if (unit === 'mm/sec²') {
                u = 'in/sec²';
                v = 0.039370 * value;
        } else if (unit === 'kg/m³') {
                u = 'slug/ft³';
                v = 0.001941 * value;
        } else if (unit === 'ton/mm³') {
                u = 'lbf(sec²)/in⁴';
                v = 93572.5469 * value;
        } else if (unit === 'N.m') {
                u = 'lbf.ft';
                v = 0.737562 * value;
        } else if (unit === 'N.mm') {
                u = 'lbf.in';
                v = 0.008851 * value;
        } else if (unit === 'Pa') {
                u = 'psf';
                v = 0.02089 * value;
        } else if (unit === 'MPa') {
                u = 'psi';
                v = 145.0377 * value;
        } else if (unit === 'J') {
                u = 'ft.lbf';
                v = 0.737562 * value;
        } else if (unit === 'mJ') {
                u = 'in.lbf';
                v = 0.008851 * value;
        } else if (unit === '°C') {
                u = 'F°';
                v = value * 1.8 + 32 * value;
        } else if (unit === '%') {
                u = '';
                v = value;
        } else if (unit === '') {
                u = '';
                v = value;
        } else {
                u = "";
                v = null;
                console.error(
                        'Unit is not defined !\n', `Input: ${value} ${unit}`
                );
        }

        if (v) {
                if (typeof v !== 'string') {
                        if (d) {
                                v = parseFloat(v.toFixed(d))
                        } else {
                                v = parseFloat(v.toFixed(2))
                        }
                }
        }
        return {
                u,
                v
        }
}

function convertImpToMetric(value, unit) {
        if (typeof value !== 'string') {
                value = parseFloat(value);
        }
        var u, v, d;

        if (unit === 'ft') {
                u = 'm';
                v = 0.3048 * value;
        } else if (unit === 'ft²') {
                u = 'm²';
                v = 0.092903 * value;
        } else if (unit === 'ft³') {
                u = 'm³';
                v = 0.028317 * value;
        } else if (unit === 'ft⁴') {
                u = 'm⁴';
                v = 0.008631 * value;
        } else if (unit === 'in') {
                u = 'mm';
                v = 25.4 * value;
        } else if (unit === 'in²') {
                u = 'mm²';
                v = 645.16 * value;
        } else if (unit === 'in⁴') {
                u = 'mm⁴';
                v = 416231.4256 * value;
        } else if (unit === '1/ft') {
                u = '1/m';
                d = 7;
                v = 3.280840 * value;
        } else if (unit === 'kips') {
                u = 'kN';
                v = 4.448222 * value;
        } else if (unit === 'kips/ft') {
                u = 'kN/m';
                v = 14.593899 * value;
        } else if (unit === 'kips.ft') {
                u = 'kN.m';
                v = 1.355818 * value;
        } else if (unit === 'kips/ft³') {
                u = 'kN/m³';
                v = 157.087464 * value;
        } else if (unit === 'slug') {
                u = 'kg';
                v = 14.593903 * value;
        } else if (unit === 'lbf(sec²)/in') {
                u = 'ton';
                v = 0.193044 * value;
        } else if (unit === 'lbf') {
                u = 'N';
                v = 4.448222 * value;
        } else if (unit === 'ft/sec') {
                u = 'm/sec';
                v = 0.3048 * value;
        } else if (unit === 'in/sec') {
                u = 'mm/sec';
                v = 25.4 * value;
        } else if (unit === 'ft/sec²') {
                u = 'm/sec²';
                v = 0.3048 * value;
        } else if (unit === 'in/sec²') {
                u = 'mm/sec²';
                v = 25.4 * value;
        } else if (unit === 'slug/ft³') {
                u = 'kg/m³';
                v = 515.378818 * value;
        } else if (unit === 'lbf(sec²)/in⁴') {
                u = 'ton/mm³';
                v = 0.000010687 * value;
        } else if (unit === 'lbf.ft') {
                u = 'N.m';
                v = 1.355818 * value;
        } else if (unit === 'lbf.in') {
                u = 'N.mm';
                v = 112.984829 * value;
        } else if (unit === 'psf') {
                u = 'Pa';
                v = 47.88026 * value;
        } else if (unit === 'psi') {
                u = 'MPa';
                v = 0.006895 * value;
        } else if (unit === 'ft.lbf') {
                u = 'J';
                v = 1.355818 * value;
        } else if (unit === 'in.lbf') {
                u = 'mJ';
                v = 112.984829 * value;
        } else if (unit === '°F') {
                u = '°C';
                v = (value - 32) / 1.8;
        } else if (unit === '%') {
                u = '';
                v = value;
        } else if (unit === '') {
                u = '';
                v = value;
        } else {
                u = "";
                v = null;
                console.error(
                        'Unit is not defined !\n', `Input: ${value} ${unit}`
                );
        }

        if (v) {
                if (typeof v !== 'string') {
                        if (d) {
                                v = parseFloat(v.toFixed(d))
                        } else {
                                v = parseFloat(v.toFixed(2))
                        }
                }
        }
        return {
                u,
                v
        }
}

module.exports = {
        convertMetricToImp,
        convertImpToMetric
}