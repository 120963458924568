var inputs = {

    //properties of girder type
    Ag: {
        label: 'Area of Girder',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Girder',
            desc: ''
        }
    },
    Ig: {
        label: 'Girder Moment of Inertia',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm⁴',
            imperial: 'in⁴'
        },
        description: {
            title: 'Girder Moment of Inertia',
            desc: ''
        }
    },

    yb: {
        label: 'Geometric Center From Bottom',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Geometric Center From Bottom',
            desc: ''
        }
    },

    y1: {
        label: 'Height of Bottom Flange',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Height of Bottom Flange',
            desc: ''
        }
    },
    GirderCompStr: {
        label: 'Compressive Strength',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Girder Compressive Strength',
            desc: ''
        }
    },

    //Strand Properties

    Astrand: {
        label: 'Area of Strand',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Strand',
            desc: ''
        }
    },

    TensileStrength: {
        label: 'Tensile Strength',
        value: null,
        cat: 2,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Tensile Strength',
            desc: ''
        }
    },

    loss: {
        label: 'Loss',
        value: null,
        cat: 2,
        suffix: {
            metric: '%',
            imperial: '%'
        },
        description: {
            title: 'Loss',
            desc: ''
        }
    },



    //properties of composite section

    Ac: {
        label: 'Composite Section Area',
        value: null,
        cat: 3,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Composite Section',
            desc: ''
        }
    },

    Ic: {
        label: ' Moment of Inertia',
        value: null,
        cat: 3,
        suffix: {
            metric: 'mm⁴',
            imperial: 'in⁴'
        },
        description: {
            title: 'Composite Section Moment of Inertia',
            desc: ''
        }
    },

    ybc: {
        label: 'Geometric Center From Bottom',
        value: null,
        cat: 3,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Composite Section Geometric Center From Bottom',
            desc: ''
        }
    },

    //moments

    Mgdl: {
        label: 'Due to Girder Dead Load',
        value: null,
        cat: 4,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Moment due to Girder Dead Load',
            desc: ''
        }
    },

    Mslabw: {
        label: 'Due to Slab Weight',
        value: null,
        cat: 4,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Moment due to Slab Weight',
            desc: ''
        }
    },

    Msdl: {
        label: 'Due to Superimposed Dead Load',
        value: null,
        cat: 4,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Moment due to Superimposed Dead Load',
            desc: ''
        }
    },

    Mllg: {
        label: 'Due to Live Load Plus Impact Acting on Composite Girder',
        value: null,
        cat: 4,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Moment due to Live Load Plus Impact Acting on Composite Girder',
            desc: ''
        }
    },

    alpha: {
        label: 'Combination Multiplier for Live Load',
        value: null,
        cat: 4,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Combination Multiplier for Live Load',
            desc: ''
        }
    },

};
export default inputs