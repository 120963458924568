/* D:\Users\user\Desktop\calculators\calculators\src\components\calculators\src\minRequiredNoOfStrands\testData.js */

var metric = {
    GirderCompStr: 40, //MPa
    Astrand: 140, //mm2
    loss: 20, //%
    Ag: 550000, //mm2
    Ig: 0.141e12, //mm4
    yb: 729, //mm
    y1: 200, //mm
    Ac: 810000, //mm2
    ybc: 986, //m
    Ic: 0.255e12, //m4
    Mgdl: 1550,
    Msdl: 600,
    Mslabw: 850,
    Mllg: 1500,
    alpha: 0.8,
    TensileStrength: 1860
};

var imperial = {
    GirderCompStr: 5800, //psi
    Astrand: 0.217, //in2
    loss: 20, //%
    Ag: 864, //in2
    Ig: 0.34e6, //in4
    yb: 29, //in.
    y1: 8, //in.
    Ac: 1250, //in.2
    ybc: 39, //in.
    Ic: 0.612e6, //in4
    Mgdl: 1150,
    Msdl: 450,
    Mslabw: 600,
    Mllg: 1100,
    alpha: 0.8,
    TensileStrength: 270000
};

export default {
    metric,
    imperial
}