var outputs = {

    wgDL: {
        label1: 'Uniform Girder Dead Load',
        label2: 'wgDL',
        suffix: {
            metric: 'kN/m',
            imperial: 'klf'
        },
        description: {
            title: 'Uniform Girder Dead Load',
            desc: ''
        }
    },
    MgDL: {
        label1: 'Max. Moment of Girder Dead Load', //31 karakter
        label2: 'MgDL',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Maximum Moment of Girder Dead Load',
            desc: ''
        }
    }

};

export default outputs;