<template>
  <div>
    <v-layout mx-4>
      <v-flex xs12>
        <v-alert v-if="notifications.length===0" outlined color="purple">
          <div>No notifications</div>
        </v-alert>
        <v-alert
          v-else
          v-for="(notification, index) in notifications"
          :key="index"
          text
          dense
          :color="notificationColor"
          border="left"
          :icon="notificationIcon"
        >
          <div class="title">{{ notification.title }}</div>
          <div>{{ notification.content }}</div>
          <div v-if="recentNews">
            <v-divider class="info" style="opacity: 0.22"></v-divider>
            <v-row align="center" no-gutters>
              <v-col class="grow">{{ notification.date.toDate() }}</v-col>
              <v-spacer></v-spacer>
              <v-col class="shrink">
                <v-btn :color="notificationColor" @click="navigate(notification)" text>Go</v-btn>
              </v-col>
            </v-row>
          </div>
        </v-alert>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "NewsComponent",
  props: ["notifications", "recentNews"],
  computed: {
    notificationColor: function() {
      return this.recentNews ? "teal" : "grey";
    },
    notificationIcon: function() {
      return this.recentNews ? "mail" : "drafts";
    }
  },
  methods: {
    navigate(notification) {
      if (notification.path !== "/") {
        this.$router.push({ path: notification.path });
      }
    }
  }
};
</script>

<style>
</style>