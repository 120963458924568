<template>
  <div v-if="calculator">
    <v-layout wrap>
      <v-flex class="mx-4">
        <v-toolbar flat color="grey lighten-5">
          <div class="headline" v-if="calculator.main.title.length < 100">{{calculator.main.title}}</div>
          <div v-else>
            <div class="headline">{{calculator.main.title.split('-')[0]}}</div>
            <div>{{calculator.main.title.split('-')[1]}}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn large @click="goToDetailsPage(calculator)">Calculator Details</v-btn>
        </v-toolbar>
        <router-view class="mx-1 my-3" />
      </v-flex>
    </v-layout>
    <RateDisplayComponent :calculator="calculator" />
  </div>
</template>

<script>
import calculatorList from "@/_lists/calculatorList";
import calculatorRoutes from "@/_lists/calculatorRoutes";
import RateDisplayComponent from "@/components/RateDisplayComponent"

export default {
  components: {
    RateDisplayComponent
  },
  data() {
    return {
      calculatorList,
      calculatorRoutes,
      calculator: null
    };
  },
  watch: {
    $route(to, from) {
      this.findTitle();
    }
  },
  created() {
    this.findTitle();
  },
  methods: {
    findTitle() {
      var routeName = this.$route.name;
      this.calculator = this.calculatorList.find(c => c.name === routeName);
    },
    goToDetailsPage(el) {
      var calculator = calculatorRoutes.find(c => c.name === el.name);
      this.$router.push({
        name: "memberCalcDetails",
        params: { name: calculator.path }
      });
    }
  }
};
</script>

<style scoped>
</style>