var outputs = {

    /* sb:{
        label1: '',
        label2:'sb' , 
        suffix:{
            metric:'m³',imperial:'ft³'   
        },
        description:{
            title:'Non-composite Section Modulus wrt Girder Bottom Fiber',
            desc:''
        } 
    },  

    sbc:{
        label1: '',
        label2:'sbc' , 
        suffix:{
            metric:'m³',imperial:'ft³'   
        },
        description:{
            title:'Composite Section Modulus wrt Girder Bottom Fiber',
            desc:''
        } 
    },   */

    fbt: {
        label1: 'Tensile Stress',
        label2: 'fbt',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Tensile Stress',
            desc: ''
        }
    },

    fbtn: {
        label1: 'Reduced Tensile Stress',
        label2: 'fbtn',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Reduced Tensile Stress',
            desc: ''
        }
    },

    Pp: {
        label1: 'Prestressing Force After Losses',
        label2: 'Pp',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Prestressing Force After Losses',
            desc: ''
        }
    },

    Ppstrand: {
        label1: 'Force Applied by a Single Strand',
        label2: 'Ppstrand',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Force Applied by a Single Strand',
            desc: ''
        }
    },

    e: {
        label1: 'Eccentricity',
        label2: 'e',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Eccentricity',
            desc: ''
        }
    },

    nStrands: {
        label1: 'Number of Strands',
        label2: 'Number of Strands',
        suffix: {
            metric: 'strands',
            imperial: 'strands'
        },
        description: {
            title: 'Number of Strands',
            desc: ''
        }
    },
};

export default outputs;