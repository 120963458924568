<template>
  <div>
    <structure
      inputType="c5"
      :categoryNumber="5"
      topImage="cellBoxFlexural.png"
      :titles="[
                'Materials', 'Distance from Bottom Flange Face', 'Other', 
                'Geometry', 'Area of Post-Tension', 'Mild Reinforcement'
            ]"
      :tooltips="[
                '',
                ''
            ]"
      :table="table"
      :inputs="inputs"
      :testData="testData"
      @CALC_METRIC="calcMetric"
      @CALC_IMP="calcImperial"
      @GENERATE_REPORT="generateReport"
      @system="systemChanged"
      @INPUT_MISSING="inputMissing"
      @CLEAR_RESULTS="clearResults"
      @LOAD_TEST="loadTableData(system)"
    />
    <results
      resultType="cellBoxFlexural"
      :outputs="outputs"
      :system="system"
      :result1="result1"
      :result2="result2"
    />
    <v-snackbar color="error" v-model="snackbar.visible">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.visible=false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import structure from "@/_calculators/static/calculatorStructure";
import results from "@/_calculators/static/results";
import functions from "@/_calculators/src/cellBoxFlexural/functions";
import inputs from "@/_calculators/src/cellBoxFlexural/inputs";
import outputs from "@/_calculators/src/cellBoxFlexural/outputs";
import testData from "@/_calculators/src/cellBoxFlexural/testData";

export default {
  components: {
    structure,
    results
  },
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      testData: testData,
      result1: null,
      result2: null,
      table: [
        [null, null, "Top Flange Top Reinf."],
        [null, null, "Top Flange Bot. Reinf."],
        [null, null, "Web Reinf. (for one web)"],
        [null, null, "Bottom Flange Top Reinf."],
        [null, null, "Bottom Flange Bot. Reinf."]
      ],
      snackbar: {
        visible: false,
        text: ""
      }
    };
  },
  created() {
    this.loadTableData(this.system);
  },
  watch: {
    system() {
      this.clearResults();
      this.convertTableUnits(this.system);
    }
  },
  methods: {
    /* Static */
    systemChanged(val) {
      this.system = val;
    },
    inputMissing() {
      this.displayError("Please check all input fields.");
    },
    calcMetric() {
      if (this.tableCheck()) {
        if (this.result1) {
          this.result2 = this.result1;
        }
        this.result1 = functions.calculate(this.inputs, "metric", this.table);
      } else {
        this.displayError("Please check input fields!");
      }
    },
    calcImperial() {
      if (this.tableCheck()) {
        if (this.result1) {
          this.result2 = this.result1;
        }
        this.result1 = functions.calculate(this.inputs, "imperial", this.table);
      } else {
        this.displayError("Please check input fields!");
      }
    },
    clearResults() {
      this.result1 = null;
      this.result2 = null;
      this.table = [
        [null, null, "Top Flange Top Reinf."],
        [null, null, "Top Flange Bot. Reinf."],
        [null, null, "Web Reinf. (for one web)"],
        [null, null, "Bottom Flange Top Reinf."],
        [null, null, "Bottom Flange Bot. Reinf."]
      ];
    },
    loadTableData(system) {
      this.table.forEach((row, i) => {
        row[0] = this.testData[system].table[i][0];
        row[1] = this.testData[system].table[i][1];
      });
    },
    generateReport() {
      this.displayError("Reporting for this calculator is not implemented yet.");
    },
    tableCheck() {
      var check = [];
      this.table.forEach(row => {
        check.push(row.every(input => input !== null && input !== ""));
      });
      var isTableOk = check.every(item => item === true);
      return isTableOk;
    },
    displayError(errorText) {
      this.snackbar.text = errorText;
      this.snackbar.visible = true;
    },
    convertTableUnits(to) {
      if (to === "metric") {
        this.table.forEach((row, i) => {
          row[0] = i < 2 ? 80 : 40;
          row[1] = 22;
        });
      } else if (to === "imperial") {
        this.table.forEach((row, i) => {
          row[0] = i < 2 ? 15 : 15;
          row[1] = 21;
        });
      }
    }
  }
};
</script>

<style scoped>
</style>