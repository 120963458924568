<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <div class="pt-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Bridge Information</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">Please fill in the following fields</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <div class="elevation-2">
          <v-form v-model="valid">
            <v-layout class="pa-3">
              <v-flex>
                <v-layout wrap>
                  <template v-for="(param, key, index) in inspectionData">
                    <v-flex
                      :class="param.type === 'text' ? 'xs12' : 'xs4'"
                      v-if="param.input && !Array.isArray(param)"
                      :key="index"
                    >
                      <v-text-field
                        class="numberInputArea"
                        color="blue darken-4"
                        required
                        filled
                        hide-details
                        :rules="requiredRules"
                        :type="param.type"
                        v-model="param.value"
                        :label="param.label"
                      ></v-text-field>
                    </v-flex>
                  </template>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </div>
      </v-flex>
    </v-layout>
    <div class="mt-4 pt-1 pl-2 elevation-12 grey lighten-3" height="60">
      <v-layout>
        <v-flex xs11 class="my-3 pl-2">
          <div>Bridge Inspection Data</div>
        </v-flex>
        <v-flex xs1>
          <v-tooltip top max-width="400" color="grey darken-4">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon color="grey darken-4">help</v-icon>
              </v-btn>
            </template>
            <span
              style="font-size: 13px;"
            >A New/Good score corresponds to a score of 7 and a Missing/Completely Destroyed/Very Bad score corresponds to 1</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </div>
    <v-stepper v-model="step">
      <v-stepper-header>
        <template v-for="(majorComponent, index) in inspectionData.components">
          <v-stepper-step
            :key="`${index}-step`"
            :complete="step > index+1"
            :step="index+1"
            :editable="false"
          >{{ majorComponent.name }}</v-stepper-step>
          <v-divider v-if="index + 1 !== inspectionData.components.length" :key="index"></v-divider>
        </template>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content
          v-for="(majorComponent, index) in inspectionData.components"
          :key="`${index}-content`"
          class="pa-1"
          :step="index + 1"
        >
          <v-card>
            <v-card-text class="pa-1">
              <v-container fluid pa-1>
                <v-row dense>
                  <v-col
                    v-for="(minorComponent, index2) in majorComponent.subcomponents"
                    :key="`${index2}-col`"
                    :cols="6"
                  >
                    <v-card outlined>
                      <v-card-title>{{ minorComponent.name }}</v-card-title>
                      <v-card-text>
                        <template v-for="(element, index3) in minorComponent.subcomponents">
                          <v-select
                            :items="gradeList"
                            :label="element.name"
                            filled
                            hide-details
                            v-model="element.grade"
                            :key="index3"
                          ></v-select>
                        </template>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="prevStep()">BACK</v-btn>
              <v-btn :color="nextColor" @click="nextStep()">{{ nextTitle }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div ref="resultEl">
      <v-container fluid v-if="resultVisible" class="px-0">
        <v-data-iterator :items="inspectionData.components" :items-per-page="3" hide-default-footer>
          <template v-slot:header>
            <v-toolbar color="blue darken-2" dark flat>
              <v-toolbar-title>Results</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="component in props.items" :key="component.name" cols="12" sm="6" md="4">
                <v-card>
                  <v-card-title
                    class="subheading font-weight-bold"
                  >{{ component.name }} : {{ component.grade }}</v-card-title>
                  <v-divider></v-divider>
                  <v-list dense>
                    <template v-for="subcomponent in component.subcomponents">
                      <v-list-item :key="subcomponent.name">
                        <v-layout xs12 :style="subcomponent.grade < 5 ? 'background:#FF5733;' : ''">
                          <v-flex xs11>
                            <v-list-item-content>{{ subcomponent.name }}</v-list-item-content>
                          </v-flex>
                          <v-flex xs1>
                            <v-list-item-content>{{ subcomponent.grade }}</v-list-item-content>
                          </v-flex>
                        </v-layout>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="ungradedComponents">
              <v-col cols="12" sm="12">
                <v-card outlined>
                  <v-card-title>Ungraded/Unreached Components</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-chip
                      class="ma-2"
                      v-for="compName in ungradedComponents"
                      :key="compName"
                    >{{ compName }}</v-chip>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-card outlined>
                  <v-card-title>Scoring System Information</v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-layout xs12>
                      <template v-for="grade in gradeList">
                        <v-flex
                          v-if="grade.value>0 && grade.value<8"
                          xs3
                          :key="grade.value"
                        >{{grade.value}} : {{grade.text}}</v-flex>
                      </template>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:footer>
            <v-toolbar class="mt-2" :color="resultColor" dark flat>
              <v-toolbar-title class="subheading">Overall Grade: {{ inspectionData.grade.value }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-4" outlined @click="generateReport">GENERATE REPORT</v-btn>
            </v-toolbar>
          </template>
        </v-data-iterator>
      </v-container>
    </div>
    <v-snackbar color="error" v-model="snackbar.visible">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.show=false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { InspectionData } from "@/_calculators/src/simpleInspection/InspectionData";
import { Component } from "@/_calculators/src/simpleInspection/Component";
import { gradeList } from "@/_calculators/src/simpleInspection/inputs";
import { GenerateInspectionReport } from "@/_calculators/src/shared/report";
import goTo from "vuetify/es5/services/goto";

export default {
  name: "simpleInspection",
  data() {
    return {
      inspectionData: null,
      step: 1,
      valid: false,
      gradeList: gradeList,
      ungradedComponents: null,
      requiredRules: [v => !!v || "Value is required"],
      snackbar: {
        visible: false,
        text: ""
      },
      resultVisible: false
    };
  },
  computed: {
    nextTitle() {
      return this.step === this.inspectionData.components.length
        ? "FINISH"
        : "CONTINUE";
    },
    nextColor() {
      return this.step === this.inspectionData.components.length
        ? "success"
        : "primary";
    },
    resultColor() {
      if (this.inspectionData.grade.value >= 6) {
        return "green lighten-2";
      } else if (this.inspectionData.grade.value >= 4) {
        return "orange lighten-2";
      } else {
        return "red lighten-2";
      }
    }
  },
  created() {
    this.inspectionData = new InspectionData("", "", -1, -1, 1990);
  },
  methods: {
    nextStep() {
      if (this.step === this.inspectionData.components.length) {
        if (this.valid) {
          this.runCalculation();
        } else {
          this.snackbar.text = "Please fill in all the fields";
          this.snackbar.visible = true;
        }
      } else {
        this.step++;
      }
    },
    prevStep() {
      if (this.step > 1) {
        this.step--;
      }
    },
    runCalculation() {
      this.reset();
      this.inspectionData.calculateGrade();
      const ungraded = this.inspectionData.getUngradedComponents();
      this.ungradedComponents =
        ungraded.length > 0 ? ungraded.flat(Infinity) : null;
      this.resultVisible = true;
      goTo(this.$refs.resultEl, {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic"
      });
    },
    reset() {
      this.resultVisible = false;
      this.ungradedComponents = null;
    },
    generateReport() {
      GenerateInspectionReport(this.inspectionData);
    }
  }
};
</script>

<style>
</style>