var inputs = {
    n: {
        label: 'Number of rows in x',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'n',
            desc: ''
        }
    },
    m: {
        label: 'Number of rows in y',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'm',
            desc: ''
        }
    },
    dx: {
        label: 'Spacing in x',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'dx',
            desc: ''
        }
    },

    dy: {
        label: 'Spacing in y',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'dy',
            desc: ''
        }
    },
    Pu: {
        label: 'Safe bearing capacity of one pile',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Pu',
            desc: 'Safe bearing capacity of one pile'
        }
    },
    Pv: {
        label: 'Total vertical load on group',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Pv',
            desc: 'Total vertical load on group'
        }
    },
    Mx: {
        label: 'Total moment on group around x',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Mx',
            desc: 'Total moment on group around x'
        }
    },
    My: {
        label: 'Total moment on group around y',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'My',
            desc: 'Total moment on group around y'
        }
    },
};

export default inputs