var inputs = {
    tn: {
        label: 'Number of Strands',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Strands',
            desc: ''
        }
    },
    talan: {
        label: 'Strand Area',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Strand Area',
            desc: ''
        }
    },
    fpu: {
        label: 'fpu',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Tensile Strength of Strand',
            desc: ''
        }
    },
    fc: {
        label: 'fc',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Compressive Strength of Concrete at 28 days',
            desc: ''
        }
    },
    akma: {
        label: 'fy',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Mild Steel Tensile Yield Strength',
            desc: ''
        }
    },

    en: {
        label: 'Blister Width',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Blister Width',
            desc: ''
        }
    },
    scap: {
        label: 'Tie Bar Diameter',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Tie Bar Diameter',
            desc: ''
        }
    },
    ppay: {
        label: 'Concrete Cover',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Concrete Cover',
            desc: ''
        }
    },
    av: {
        label: 'av',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    heg: {
        label: 'h',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    ycap: {
        label: 'Radius of Curvature of Tendon',
        value: null,
        cat: 3,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Radius of Curvature of Tendon',
            desc: ''
        }
    },
    ccap: {
        label: 'Main Tension Reinforcement Diameter',
        value: null,
        cat: 3,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Main Tension Reinforcement Diameter',
            desc: ''
        }
    },
    cyuz: {
        label: 'Initial Jacking Load %',
        value: null,
        cat: 3,
        suffix: {
            metric: '%',
            imperial: '%'
        },
        description: {
            title: 'Initial Jacking Load %',
            desc: ''
        }
    },
    phi: {
        label: 'φf',
        value: null,
        cat: 3,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Flexure Resistance Factor',
            desc: ''
        }
    },
    phiv: {
        label: 'φv',
        value: null,
        cat: 3,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Shear Resistance Factor',
            desc: ''
        }
    }

};

export default inputs;