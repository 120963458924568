const gradeList = [
    {
        text: "Not Applicable (NA)",
        value: 0
    },
    {
        text: "Missing/Completely Destroyed/Very Bad",
        value: 1
    },
    {
        text: "Almost Destroyed, Not Working As Intended",
        value: 2
    },
    {
        text: "Very Poor, Not Working As Intended",
        value: 3
    },
    {
        text: "Poor, Severely Limited Effectiveness",
        value: 4
    },
    {
        text: "Average, Reduced Effectiveness",
        value: 5
    },
    {
        text: "Good, Full Effectiveness",
        value: 6
    },
    {
        text: "New/Good",
        value: 7
    },
    {
        text: "Unreachable (Not Graded)",
        value: 8
    }
];

export { gradeList }; 