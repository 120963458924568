<template>
  <div>
    <v-layout>
      <v-flex xs12 md4 v-for="(category, i) in cats" :key="i">
        <v-card class="pt-1 pl-2 elevation-2" height="60" color="grey lighten-3">
          <v-card-title>Title</v-card-title>
        </v-card>
        <v-card class="ma-1 elevation-22" height="320">
          <v-layout class="pa-3">
            <v-flex>
              <v-form>
                <v-layout wrap>
                  <v-flex
                    class="pl-1"
                    v-bind="{[`xs${12 / Math.ceil(Object.keys(category).length / 5)}`]: true}"
                    v-for="input in category"
                    :key="input.label"
                  >
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      type="number"
                      v-model="input.value"
                      :label="input.label"
                      hide-details
                      filled
                      required
                      :suffix="input.suffix[system]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <!-- Results -->
      <v-flex xs12 md4 v-if="result1">
        <v-card class="pt-1 pl-2 elevation-2" height="60" dark>
          <v-card-title>Results</v-card-title>
        </v-card>
        <v-card class="ma-1 elevation-22" height="320">
          <v-layout class="pa-2">
            <v-flex>
              <v-form>
                <v-layout wrap>
                  <v-flex xs12 class="pl-2" v-for="(result, i) in outputs" :key="i">
                    <v-card class="my-2 elevation-8">
                      <!-- Component -->
                      <resultCard
                        :result="result"
                        :result1="result1"
                        :result2="result2"
                        :changes="changes"
                        :i="i"
                        :system="system"
                      />
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import resultCard from "@/_calculators/static/resultCard";
export default {
  components: {
    resultCard
  },
  props: [
    "inputs",
    "cats",
    "system",
    "outputs",
    "result1",
    "result2",
    "changes"
  ]
};
</script>

<style>
</style>