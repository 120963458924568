var grades = require("@/_calculators/src/simpleInspection/inputs");
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getBase64Image(image) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataUrl = canvas.toDataURL("image/png");
            resolve(dataUrl);
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.src = require("@/assets/imgs/report/" + image);
    })
}

// A special function is needed for inspection calculator
async function GenerateInspectionReport(inspectionData) {
    const docStyles = GenerateDocumentStyles();
    const header = GenerateHeader();
    const footer = GenerateFooter();
    let pageContent = {
        header: header,
        footer: footer,
        content: [],
        styles: docStyles
    };
    const title = {
        text: "Bridgewiz Calculators Report for Bridge Inspection",
        style: "header"
    };
    const date = {
        text: new Date().toString(),
        style: "date"
    };

    const bridgeInfoTable = await GenerateInspectionBridgeInfoTable(inspectionData);
    const inspectionTitle = {
        text: "\nInspection Data",
        style: "subheader"
    };
    const elementTable = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [["Component", "Grade"]]
        }
    };
    for (const majorComponent of inspectionData.components) {
        const rows = GenerateComponentRow(majorComponent);
        elementTable.table.body = elementTable.table.body.concat(rows);
    }
    const legendTitle = {
        text: "\nGrade Levels\n",
        style: "subsubheader"
    };
    const resultText = {
        text: `\n\nOVERALL BRIDGE GRADE: ${inspectionData.grade.value}\n\n`,
        color: getRowColor(inspectionData.grade.value),
        alignment: "center",
        fontSize: 16
    };
    let legendText = "";
    for (const grade of grades.gradeList) {
        legendText = legendText.concat(`${grade.value}: ${grade.text}\t`)
    };
    const legend = {
        text: legendText,
        italics: true,
        fontSize: 10
    }
    pageContent.content.push(
        title,
        date,
        bridgeInfoTable,
        inspectionTitle,
        elementTable,
        resultText,
        legendTitle,
        legend
    );
    pdfMake.createPdf(pageContent).open();
}

function GenerateComponentRow(component) {
    let row = [];
    if (component.subcomponents.length > 0) {
        row.push(
            [
                {
                    text: component.name,
                    bold: true,
                    fillColor: "#D6D6D6"
                },
                {
                    text: component.grade,
                    bold: true,
                    fillColor: getRowColor(component.grade)
                }
            ]);
        for (const subcomponent of component.subcomponents) {
            const val = GenerateComponentRow(subcomponent);
            row = row.concat(val);
        }
    } else {
        const rowColor = getRowColor(component.grade);
        row.push(
            [
                {
                    text: component.name,
                    noWrap: true
                },
                {
                    text: grades.gradeList.find(e => { return e.value === component.grade }).text,
                    noWrap: true,
                    fillColor: rowColor
                }
            ]
        );
    }
    return row;
}

function getRowColor(grade) {
    let color;
    if (grade > 7) {
        color = "#FAF5D8";
    } else if (grade >= 6) {
        color = null;
    } else if (grade >= 4) {
        color = "#FFC37F";
    } else if (grade >= 1) {
        color = "#FF7F7F";
    } else {
        color = null;
    }
    return color;
}

async function GenerateInspectionBridgeInfoTable(inspectionData) {
    const img = await getBase64Image("logo.png");
    const table = {
        style: "table",
        layout: "noBorders",
        table: {
            headerRows: 1,
            widths: ["auto", "*", "auto"],
            body: [
                [{ text: "Bridge Information", colSpan: 2, style: "subheader" }, {}, { image: img, width: 150, rowSpan: 6, margin: [0, 20, 0, 0] }]
            ]
        }
    };
    for (const key in inspectionData) {
        if (inspectionData.hasOwnProperty(key)) {
            const element = inspectionData[key];
            if (!Array.isArray(element) && element.input) {
                table.table.body.push([element.label, element.value, {}])
            }
        }
    }
    return table;
}

// A special function is needed for cost calculator
async function GenerateCostReport(stations, unitPrices, alternatives, system) {
    const docStyles = GenerateDocumentStyles();
    const header = GenerateHeader();
    const footer = GenerateFooter();
    let lengthUnit, weightUnit, volumeUnit = "";
    if (system === "metric") {
        lengthUnit = "meters";
        volumeUnit = "m³";
        weightUnit = "tons";
    } else {
        lengthUnit = "feet";
        volumeUnit = "ft³";
        weightUnit = "pounds";
    }
    let pageContent = {
        header: header,
        footer: footer,
        content: [],
        styles: docStyles
    };
    const img = await getBase64Image("logo.png");
    const titleTable = {
        style: "table",
        layout: "noBorders",
        table: {
            headerRows: 0,
            widths: ["*", "auto"],
            body: [
                [{
                    text: "Bridgewiz Calculators Report for Bridge Cost Estimation", style: "header"
                },
                {
                    image: img, width: 150
                }]
            ]
        }
    };
    const date = {
        text: new Date().toString(),
        style: "date"
    };
    const stationTitle = {
        text: `\nStations (in ${lengthUnit})\n\n`,
        style: "subheader"
    };
    const stationTable = GenerateStationTable(stations);
    pageContent.content.push(
        titleTable,
        date,
        stationTitle,
        stationTable
    );

    const alternativesSection = GenerateAlternativesSection(alternatives, lengthUnit, unitPrices, weightUnit, volumeUnit);
    pageContent.content.push(...alternativesSection);

    const costDisclaimer = {
        text: [
            { text: "\n\nDisclaimer:", bold: true },
            "The material quantities given in this document are solely calculated using equations obtained by multiple sets of statistical regression analyses. No structural analyses or design is performed."
        ]
    };
    pageContent.content.push(costDisclaimer);

    pdfMake.createPdf(pageContent).open();
}

function GenerateAlternativesSection(alternatives, lengthUnit, unitPrices, weightUnit, volumeUnit) {
    const result = [];
    for (const alternative of alternatives) {
        const altTitle = {
            text: `\n${alternative.name} Alternative`,
            style: "subheader"
        };
        result.push(altTitle);
        if (alternative.piers && alternative.piers.length > 0) {
            const pierTitle = {
                text: `\nPiers (in ${lengthUnit})`,
                style: "subsubheader"
            };
            const pierTable = GeneratePierTable(alternative.piers);
            result.push(pierTitle, pierTable);
            const deckTitle = {
                text: `\nDecks (in ${lengthUnit})`,
                style: "subsubheader"
            };
            const deckTable = GenerateDeckTable(alternative.decks);
            result.push(deckTitle, deckTable);

        } else {
            const infoText = {
                text: "This option is not suitable for the given valley geometry.",
                italics: true
            };
            result.push(infoText);
        }
    }
    const costTitle = {
        text: "Cost Comparison",
        style: "subheader"
    };
    const unitText = {
        text: `Concrete quantities are in ${volumeUnit} and all other quantities are in ${weightUnit}.\n\n`,
        italics: true,
        fontSize: 10
    };
    result.push(costTitle, unitText);
    const costSection = GenerateCostSection(alternatives, unitPrices);
    result.push(...costSection);
    return result;
}

function GenerateUnitPriceTable(unitPrices) {
    let table = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "*"],
            body: [
                ["Item", "Unit Price ($ per unit quantity)"]
            ]
        }
    };
    for (const key in unitPrices) {
        if (unitPrices.hasOwnProperty(key)) {
            const element = unitPrices[key];
            // infer readable name from key then camelCase=>Sentence Case
            const buffer = key.replace(/([A-Z])/g, " $1");
            const name = buffer.charAt(0).toUpperCase() + buffer.slice(1);
            table.table.body.push([
                name, element
            ]);
        }
    }
    return table;
}

function GenerateCostSection(alternatives, unitPrices) {
    const unitPriceTitle = {
        text: "\nUnit Prices",
        style: "subsubheader"
    };
    const unitPriceTable = GenerateUnitPriceTable(unitPrices);
    const materialTitle = {
        text: "Quantity Breakdown",
        style: "subsubheader"
    };
    let materialTable = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "auto", "auto", "auto", "auto"],
            body: [
                ["Alternative", "Concrete", "Reinforcement", "Strands", "Composite Steel"]
            ]
        }
    };
    const costTitle = {
        text: "Cost Breakdown (M: Million)",
        style: "subsubheader"
    };
    let costTable = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "auto", "auto", "auto", "auto", "auto"],
            body: [
                ["Alternative", "Concrete", "Reinforcement", "Strands", "Composite Steel", "Total"]
            ]
        }
    };

    for (const alternative of alternatives) {
        if (alternative.costs) {
            materialTable.table.body.push([
                alternative.name,
                fm(alternative.costs.concreteAmount, "k"),
                fm(alternative.costs.reinforcementAmount, "k"),
                fm(alternative.costs.prestressSteelAmount, "k"),
                fm(alternative.costs.compositeSteelAmount, "k"),
            ])
            costTable.table.body.push([
                alternative.name,
                fm(alternative.costs.concreteCost, "M"),
                fm(alternative.costs.reinforcementCost, "M"),
                fm(alternative.costs.prestressSteelCost, "M"),
                fm(alternative.costs.compositeSteelCost, "M"),
                fm(alternative.costs.totalCost, "M")
            ]);
        }
    }
    return [materialTitle, materialTable, unitPriceTitle, unitPriceTable, costTitle, costTable];
}

function fm(number, format) {
    if (format === 'k') {
        return { text: `${number.toFixed(0)}`, alignment: "center" };
    } else {
        return { text: `$${(number / 1000000).toFixed(2)}M`, alignment: "center" };
    };
}

function GenerateDeckTable(decks) {
    let table = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*"],
            body: [
                ["No.", "Start Distance", "End Distance", "Length", "Type"]
            ]
        }
    };
    let index = 1;
    for (const deck of decks) {
        table.table.body.push([
            index++, deck.startStation.distance, deck.endStation.distance, deck.length, deck.type
        ]);
    }
    return table;
}

function GeneratePierTable(piers) {
    let table = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "*", "*"],
            body: [
                ["No.", "Distance", "Height"]
            ]
        }
    };
    let index = 1;
    for (const pier of piers) {
        table.table.body.push([
            index++, pier.station.distance, pier.height
        ]);
    }
    return table;
}

function GenerateStationTable(stations) {
    let table = {
        style: "table",
        layout: "lightHorizontalLines",
        table: {
            headerRows: 1,
            widths: ["*", "*", "*"],
            body: [
                ["Distance", "Road Elevation", "Valley Elevation"]
            ]
        }
    };
    for (const station of stations) {
        table.table.body.push([
            station.distance, station.roadElevation, station.valleyElevation
        ]);
    }
    return table;
}

// A general function for simpler calculators
async function GeneratePDFReport(inputs, outputs, results, calculatorName, userName, units, imageName) {
    const docStyles = GenerateDocumentStyles();
    const header = GenerateHeader();
    const footer = GenerateFooter();
    let pageContent = {
        header: header,
        footer: footer,
        content: [],
        styles: docStyles
    };
    const img = await getBase64Image("logo.png");
    const titleTable = {
        style: "table",
        layout: "noBorders",
        table: {
            headerRows: 0,
            widths: ["*", "auto"],
            body: [
                [{
                    text: `Bridgewiz Calculators Report for ${calculatorName}`, style: "header"
                },
                {
                    image: img, width: 150
                }]
            ]
        }
    };
    const date = {
        text: new Date().toString(),
        style: 'date'
    };
    let reportImage = null;
    if (imageName) {
        reportImage = {
            image: await getBase64Image(imageName),
            width: 500
        };
    }
    const inputTitle = {
        text: 'Inputs\n\n',
        style: 'subheader'
    };
    let inputTable = GenerateInputTable(inputs, units, calculatorName);
    const resultsTitle = {
        text: 'Results\n\n',
        style: 'subheader'
    }
    let resultsTable = GenerateResultsTable(outputs, results, units, calculatorName);
    pageContent.content.push(
        titleTable,
        date);
    if (reportImage) {
        pageContent.content.push(reportImage);
    }
    pageContent.content.push(
        inputTitle,
        inputTable,
        resultsTitle,
        resultsTable,
    );

    // Pin design calculator has a safe/unsafe result
    if (calculatorName === 'Pin Design') {
        const resultText = GeneratePinResultText(results.isFlexureSafe);
        pageContent.content.push(resultText);
        const notesText = GeneratePinNotesText(inputs, results);
        if (notesText.length > 0)
            
            for (var i = 0; i < notesText.length; i++) {
                pageContent.content.push(notesText[i])
            }
    }

    pdfMake.createPdf(pageContent).open();
}
function GenerateHeader() {
    const header = (currentPage, pageCount) => (
        {
            text: `${currentPage.toString()} of ${pageCount}`,
            style: 'pageNumber'
        }
    );
    return header;
}

function GeneratePinNotesText(inputs, results) {
    let notes = [];
    if (results.requiredDiameter > inputs.selectedDiameter.value) {
        notes.push({
            text: "Selected diameter is less than required diameter!",
            color: "#FF0000",
            alignment: "left",
            fontSize: 13
        });
    }
    if (results.requiredThickness > inputs.selectedThickness.value) {
        notes.push({
            text: "Selected base plate thickness is less than required thickness!",
            color: "#FF0000",
            alignment: "left",
            fontSize: 13
        });
    }

    return notes;
}

function GeneratePinResultText(isFlexureSafeText) {
    let resultText;
    if (isFlexureSafeText === "OK") {
        resultText = {
            text: "Flexure stresses are OK",
            color: "#00FF00",
            alignment: "left",
            fontSize: 13
        };
    }
    else {
        resultText = {
            text: "Flexure stresses are higher than limits!",
            color: "#FF0000",
            alignment: "left",
            fontSize: 13
        };
    }
    return resultText;
}

function GenerateFooter() {
    const footer = {
        text: '\n\nBridgewiz Engineering is not liable for any and all of the consequences of events based on values on this sheet.',
        style: 'disclaimer'
    };
    return footer;
}

function GenerateDocumentStyles() {
    const docStyles =
    {
        header: {
            fontSize: 18,
            bold: true,
            alignment: 'center'
        },
        subheader: {
            fontSize: 13,
            bold: true
        },
        subsubheader: {
            fontSize: 13,
            decoration: "underline"
        },
        disclaimer: {
            fontSize: 8,
            italics: true,
            alignment: 'center'
        },
        pageNumber: {
            italics: true,
            alignment: 'right',
            fontSize: 10,
            margin: [0, 10, 10, 0]
        },
        table: {
            margin: [0, 5, 0, 15]
        },
        date: {
            alignment: 'right',
            fontSize: 10,
            italics: true
        },
        userStyle: {
            fontSize: 12
        },

    }
    return docStyles;
}
// Generates the input table based on the calculator type
/* NOTE: 
 * Several of the calculators share the same input object structure, therefore calculator parameter
  * is needed only for those that have a different structure.
*/
function GenerateInputTable(inputs, units, calculatorName) {
    // create input table
    let inputTable = {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto'],
            body: [
                ['Parameter', { text: 'Value', alignment: 'center' }, 'Unit']
            ]
        }
    };
    for (let key of Object.keys(inputs)) {
        const lineArray = [
            inputs[key].label,
            { text: inputs[key].value, alignment: 'center' },
            inputs[key].suffix[units]
        ];
        inputTable.table.body.push(lineArray);
    }
    return inputTable;
}
// Generates the results table based on the calculator type
/*
 * NOTE:
 * Several of the calculators have different output object structure, therefore the results object needs to be parsed differently for them
 */
function GenerateResultsTable(outputs, results, units, calculatorName) {
    let resultsTable = {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto'],
            body: [
                ['Parameter', { text: 'Value', alignment: 'center' }, 'Unit']
            ]
        }
    };
    let processedOutputs;
    // if the results exist, iterate over them
    if (results) {
        if (calculatorName === 'Elongation Computations') {
            // elongation calculation outputs are grouped under three keys; o1, o2, o3
            // see issue #6 
            processedOutputs = Object.assign({}, outputs.o1, outputs.o2, outputs.o3);
        } else if (calculatorName === 'Axial Load Distribution on Pile Group') {
            // see issue #8
            throw new Error("Reporting for this calculator is not implemented yet.");
        } else if (calculatorName === 'Blister Design') {
            // blister design outputs are grouped under many keys; o0, o1, o2, o3, o4, oTable, message
            // see issue #7
            processedOutputs = Object.assign({}, outputs.o0, outputs.o1, outputs.o2, outputs.o3, outputs.o4);
        } else {
            processedOutputs = outputs;
        }

        // skip the missing result fields because some calculators have optional outputs
        for (let key of Object.keys(processedOutputs)) {
            try {
                const valueLine = results[key].value ? results[key].value : results[key];
                resultsTable.table.body.push([
                    processedOutputs[key].label1,
                    { text: valueLine.toFixed(2), alignment: 'center' },
                    processedOutputs[key].suffix[units]
                ]);
            } catch (error) {
                // on error fail silently and skip to the next key
            }
        }
    }
    // if the results are blank, ask the user to perform the calculation
    else {
        throw new TypeError();
    }
    return resultsTable;
}

module.exports = {
    GeneratePDFReport, GenerateCostReport, GenerateInspectionReport
}
