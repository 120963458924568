<template>
  <div>
    <v-layout>
      <v-flex xs-12>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="allNews"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          @click="rowClicked(row)"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Announcements</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" icon class="mr-4 mb-2" @click="showAddNews">
                    <v-icon large>add_circle_outline</v-icon>
                  </v-btn>
                  <v-btn color="primary" icon class="mb-2" @click="refreshNews">
                    <v-icon large>refresh</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>Add/Edit Announcement</v-card-title>
                  <v-card-text>
                    <AddNewsComponent :editedNews="editedItem" />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="saveNews">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.display="{ item }">
            <v-switch @change="displayChanged(item)" v-model="item.display"></v-switch>
          </template>
          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="info">No news found</v-alert>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon small class="mr-2" @click="showEditNews(item)">edit</v-icon>
            <v-icon small color="red" @click="deleteItem(item)">delete</v-icon>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td colspan="5">
              <span class="font-italic">{{ item.content }}</span>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs-12>
        <v-alert class="mx-2" :type="result.result" v-if="result">{{ result.text }}</v-alert>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      bottom
      multi-line
      :timeout="timeout"
    >
      {{snackbar.text}}
      <v-btn dark text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebaseInit.js";
import AddNewsComponent from "@/components/AddNewsComponent";

export default {
  name: "EditNewsComponent",
  components: {
    AddNewsComponent
  },
  data() {
    return {
      loading: true,
      expanded: [],
      dialog: false,
      blankNews: {
        id: -1,
        title: "",
        path: "/",
        content: "",
        dateText: new Date().toISOString().substr(0, 10),
        display: true
      },
      editedItem: {
        title: "",
        path: "/",
        content: "",
        dateText: new Date().toISOString().substr(0, 10),
        display: true
      },
      defaultItem: null,
      result: null,
      timeout: 3000,
      snackbar: {
        show: false,
        color: "success",
        text: ""
      },
      headers: [
        { text: "Date", value: "dateText" },
        { text: "Title", value: "title" },
        { text: "Path", value: "path" },
        { text: "Display", value: "display" },
        { text: "Edit/Delete", value: "action" }
      ],
      allNews: []
    };
  },
  async mounted() {
    await this.FetchAllNews();
  },
  methods: {
    showAddNews() {
      // initiate a blank object from template
      this.editedItem = Object.assign({}, this.blankNews);
      this.dialog = true;
    },
    showEditNews(item) {
      // initiate a deep-copy object from target
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async saveNews() {
      let newsValid = this.VerifyEditedNews();
      if (newsValid) {
        try {
          const news = {
            title: this.editedItem.title,
            content: this.editedItem.content,
            path: this.editedItem.path === "" ? "/" : this.editedItem.path,
            date: new Date(this.editedItem.dateText),
            display: this.editedItem.display
          };
          // if a new announcement is added
          if (this.editedItem.id === -1) {
            // write announcement to db
            const newRef = await db.collection("notifications").add(news);
            // set the id of the new announcement
            this.editedItem.id = newRef.id;
            // push the new announcement to local array
            this.allNews.push(Object.assign({}, this.editedItem));
          }
          // if an existing announcement is edited
          else {
            // update announcement in db
            await db
              .collection("notifications")
              .doc(this.editedItem.id)
              .set(news, { merge: true });
            // update local announcement
            const editedIndex = this.allNews.findIndex(
              q => q.id === this.editedItem.id
            );
            if (editedIndex) {
              const itemToUpdate = this.allNews[editedIndex];
              itemToUpdate.title = news.title;
              itemToUpdate.content = news.content;
              itemToUpdate.path = news.path;
              itemToUpdate.date = this.editedItem.dateText;
              itemToUpdate.display = news.display;
            }
          }
          this.snackbar = {
            show: true,
            color: "success",
            text: "News has been published"
          };
        } catch (error) {
          this.snackbar = {
            show: true,
            color: "error",
            text: "Error saving to database: " + error.message
          };
        }
        this.dialog = false;
      } else {
        this.snackbar = {
          show: true,
          color: "error",
          text: "Please fill in the form correctly"
        };
      }
    },
    async refreshNews() {
      this.allNews = [];
      await this.FetchAllNews();
    },
    async deleteItem(item) {
      try {
        await db
          .collection("notifications")
          .doc(item.id)
          .delete();
        const index = this.allNews.findIndex(q => q.id === item.id);
        if (index !== undefined) this.allNews.splice(index, 1);
        this.snackbar = {
          show: true,
          color: "success",
          text: "Notification deleted"
        };
      } catch (error) {
        this.snackbar = {
          show: true,
          color: "error",
          text: "Delete failed!"
        };
      }
    },
    async displayChanged(item) {
      try {
        await db
          .collection("notifications")
          .doc(item.id)
          .set(
            {
              display: item.display
            },
            { merge: true }
          );
        this.snackbar = {
          show: true,
          color: "success",
          text: "Changes saved"
        };
      } catch (error) {
        this.snackbar = {
          show: true,
          color: "error",
          text: "Save failed!"
        };
      }
    },
    VerifyEditedNews() {
      return (
        this.editedItem.title &&
        this.editedItem.title.length > 10 &&
        this.editedItem.title.length < 100 &&
        this.editedItem.content &&
        this.editedItem.content.length > 20 &&
        this.editedItem.content.length < 1000 &&
        this.editedItem.path
      );
    },
    async FetchAllNews() {
      this.loading = true;
      try {
        const snapshot = await db
          .collection("notifications")
          .orderBy("date", "desc")
          .get();
        if (!snapshot.empty) {
          for (const doc of snapshot.docs) {
            const docData = doc.data();
            docData.id = doc.id;
            docData.dateText = new Date(docData.date.seconds * 1000)
              .toISOString()
              .substring(0, 10);
            this.allNews.push(docData);
          }
        }
      } catch (error) {
        this.result = {
          result: "error",
          text: "Error fetching the news: " + error.message
        };
      }
      this.loading = false;
    }
  }
};
</script>

<style>
</style>