// System imports
import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'
import { store } from './store'
import notFound from '@/views/404';

// Public domain imports
import SignUp from '@/components/notMember/SignUp'
import Login from '@/components/notMember/Login'
import CalculatorDetails from '@/views/CalculatorDetails'
import About from './views/Member/About'
import ResetPassword from '@/components/notMember/ResetPassword'
import ForgotPassword from '@/components/notMember/ForgotPassword'
import Home from './views/Home'
import Info from './views/Info'

// Private domain imports
import Member from './views/Member'
import Dashboard from './views/Member/Dashboard'
import Calculators from './views/Member/Calculators'
import Payment from './views/Member/Payment'
import PaymentResult from './views/Member/PaymentResult'
import News from './views/Member/News'

// Route imports
import calculatorRoutes from '@/_lists/calculatorRoutes'

// Admin imports
import Admin from './views/Member/Admin'

// Calculator imports
import deadLoad from '@/views/Member/Calculators/deadLoad'
import liveLoad1 from '@/views/Member/Calculators/liveLoad1'
import liveLoad2 from '@/views/Member/Calculators/liveLoad2'
import liveMoment from '@/views/Member/Calculators/liveMoment'
import concreteGirderGeometry from '@/views/Member/Calculators/concreteGirderGeometry'
import steelWideFlangeGeometry from '@/views/Member/Calculators/steelWideFlangeGeometry'
import selectionOfGirderDepth from '@/views/Member/Calculators/selectionOfGirderDepth'
import minRequiredNoOfStrands from '@/views/Member/Calculators/minRequiredNoOfStrands'
import elongationOnStrandTendons from '@/views/Member/Calculators/elongationOnStrandTendons'
import axialOnPileGroups from '@/views/Member/Calculators/axialOnPileGroups'
import blisterDesign from '@/views/Member/Calculators/blisterDesign'
import cellBoxFlexural from '@/views/Member/Calculators/cellBoxFlexural'
import discreteFourier from '@/views/Member/Calculators/discreteFourier'
import costEstimation from '@/views/Member/Calculators/costEstimation'
import plateFilletWeld from '@/views/Member/Calculators/plateFilletWeld'
import singleCircularColumn from '@/views/Member/Calculators/singleCircularColumn'
import potBearing from '@/views/Member/Calculators/potBearing'
import simpleInspection from "@/views/Member/Calculators/simpleInspection"
import pierTimeHistory from "@/views/Member/Calculators/pierTimeHistory"
import simpleSupportedBeam from "@/views/Member/Calculators/simpleSupportedBeam"
import pinDesign from "@/views/Member/Calculators/pinDesign"
import shapeFactor from "@/views/Member/Calculators/shapeFactor"

// Define all calculators
var calculators = {
  concreteGirderGeometry, steelWideFlangeGeometry, selectionOfGirderDepth,
  deadLoad, liveLoad1, liveLoad2, minRequiredNoOfStrands, elongationOnStrandTendons,
    axialOnPileGroups, blisterDesign, cellBoxFlexural, discreteFourier, liveMoment,
    costEstimation, plateFilletWeld, singleCircularColumn, potBearing, simpleInspection,
    pierTimeHistory, simpleSupportedBeam, pinDesign, shapeFactor
} 

// Create routes for the calculators defined in calculatorRoutes import and return as an array
function createCalculator() {
  var array = [];
  calculatorRoutes.forEach((cRoute) => {
    array.push({
      path: cRoute.path,
      name: cRoute.name,
      component: calculators[cRoute.name],
      meta: {
        id: cRoute.id
      }
    })
  });
  return array;
}

/* beforeEnter: (to, from ,next) { ... }  */
function detailsPageParamValidationCheck(to, from, next, goto) {
  /* Check Param is Valid  */
  var calculatorPaths = calculatorRoutes.map(c => c.path);
  var params = to.params.name
  var isParamValid = calculatorPaths.includes(params)
  if (isParamValid) {
    next();
  } else {
    /* GO TO DASHBOARD (member logged in) */
    next({
      path: goto,
      query: {
        redirect: to.fullPath
      }
    })
  }
}

Vue.use(Router)
let router = new Router({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '404'
    },
    {
      path: '/404',
      name: 'notFound',
      component: notFound,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/info',
      name: 'info',
      component: Info,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp,
      meta: {
        requiresGuest: true
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresGuest: true
      },
    },
    {
      path: '/reset',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/calculator/:name',
      name: 'calculatorDetails',
      component: CalculatorDetails,
      beforeEnter: (to, from, next) => {
        // If member logged in, use memberCalcDetails route
        if (firebase.auth().currentUser) {
          next({
            path: `/member/calculator/${to.params.name}`,
            query: {
              redirect: to.fullPath
            }
          })
        } else {
          detailsPageParamValidationCheck(to, from, next, '/login')
        }

      }
    },
    {
      path: '/member',
      name: 'member',
      component: Member,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'admin',
          name: 'admin',
          component: Admin
        },
        {
          path: 'news',
          name: 'news',
          component: News
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
        },
        {
          path: 'about',
          name: 'about',
          component: About
        },
        {
          path: 'payment',
          name: 'payment',
          component: Payment,
        },
        {
          path: 'payment-result',
          name: 'payment-result',
          component: PaymentResult
        },
        {
          path: 'calculators',
          name: 'calculators',
          component: Calculators,
          children: createCalculator()
        },
        {
          path: 'calculator/:name',
          name: 'memberCalcDetails',
          component: CalculatorDetails,
          beforeEnter: (to, from, next) => {
            detailsPageParamValidationCheck(to, from, next, '/member/dashboard')
          }
        },
      ]
    }
  ]
})

// Nav Guards
router.beforeEach((to, from, next) => {
  // Check for requires AUTH guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NOT logged in
    if (!firebase.auth().currentUser) {
      // Go to login page
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      // Path Array ["", member, calculators, cID]
      var path = to.path.split('/')
      // remove first element since it is empty string
      path.shift()
      // if the target route is a calculators page
      if (path.length === 3 && path[1] === 'calculators') {
        var routeId = to.meta.id;
        var routeName = to.name;
        // get the calculators available to the user
        var userCalculators = store.state.general.calculators;
        // get the paths of the available calculators
        var cIDs = userCalculators.map(c => c.id);
        // check if the array of available calculator paths include the target path
        var isMatched = cIDs.includes(routeId);
        // if the array includes, the user purchased the tier
        if (isMatched) {
          // find the calculator object
          var calculator = userCalculators.find(c => c.id === routeId)
          // perform a little consistency check
          if (calculator.name === routeName) {
            // if consistency is ok
            console.log('😁 OOOOKAY')
          }
          else {
            // if consistency is not ok, reroute to dashboard
            console.log('😡 we have a little hacker')
            next({
              path: '/member/dashboard',
              query: {
                redirect: to.fullPath
              }
            })
          }
        } else {
          // if the array of available calculator paths does not include the target path, 
          // the user has not subscribed to the target tier
          console.log('😵 NO AUTH');
          // redirect user to payment page
          next({
            path: `/member/payment`,
            query: {
              redirect: to.fullPath
            }
          });
        }
      }
      // if the target route is not a calculators page,
      else if (to.name == 'admin') {
        let user = store.getters['users/user'];
        let tier = user.tier;
        // allow navigation to admin only for tier9
        if (tier == 'tier9') {
          next();
        }
        else {
          next({
            path: '/member/dashboard'
          });
        }
      }
      // in any other condition, proceed regularly
      next();
    }
  }

  // Check for requires GUEST guard
  else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if logged in 
    if (firebase.auth().currentUser) {
      next({
        path: '/member/dashboard'
      })
    } else {
      // Proceed to route
      next()
    }
  }
  else {
    // Proceed to route
    next();
  }
});

export default router