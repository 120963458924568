<template>
  <v-layout wrap v-if="result1">
    <v-flex xs12>
      <v-toolbar class="blue" dark>
        <v-spacer></v-spacer>
        <div class="headline">Results</div>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-flex>
    <v-flex xs12 class="my-2">
      <div v-if="nStrandArranged < 1">
        <v-alert :value="true" type="error">
          <span class="headline">Error: Check Your Inputs</span>
        </v-alert>
      </div>
      <div v-else>
        <v-alert :value="true" type="success">
          <span class="headline"> Number Of Strands: # {{nStrandArranged}}</span>
        </v-alert>
      </div>
      <v-layout wrap>
        <v-flex xs4>
          <div class="mt-1 mb-3 elevation-4 grey lighten-3" height="310">
            <v-img :src="getImgUrl(img1)" height="310" contain></v-img>
          </div>
        </v-flex>
        <v-flex xs8>
          <v-card class="ml-1 mt-1 mb-3 elevation-4 " height="310">
            <v-layout wrap class="px-2">
              <v-flex xs6 class="px-1" v-for="(result, i) in outputs" :key="i">
                <v-card class="mt-2 elevation-4" v-if="i !== 'nStrands'">
                  <!-- Component -->
                  <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                    :system="system" />
                </v-card>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  import resultCard from '@/_calculators/static/resultCard'


  export default {
    components: {
      resultCard
    },
    props: ['outputs', 'system', 'result1', 'result2', 'changes', 'img1'],
    computed: {
      nStrandArranged() {
        return Math.ceil(this.result1.nStrands)
      }
    },
    methods: {
      getImgUrl(pic) {
        return require('@/assets/imgs/calculators/' + pic)
      }
    },
  }
</script>

<style>
  
</style>