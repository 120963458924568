<template>
    <div>
        <v-layout>
            <v-flex xs12>
                <v-data-table v-model="selected"
                              show-select
                              :headers="loadHeaders"
                              :items="distributedLoads"
                              item-key="key">
                    <!--Top slot-->
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Distributed Loads ({{ suffix }})</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="loadAddDialog" max-width="500px">
                                <template v-slot:activator="{ on }">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary"
                                                   icon
                                                   class="mr-4 mb-2"
                                                   v-on="on"
                                                   @click="showAddLoad">
                                                <v-icon large>add_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Distributed Load</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="error" icon class="mb-2" v-on="on" @click="deleteLoads">
                                                <v-icon large>delete_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Selected Loads</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="green" icon class="mb-2" v-on="on" @click="updateFigure">
                                                <v-icon large>refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Update Figure</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="blue" icon class="mb-2" v-on="on" @click="loadSampleData">
                                                <v-icon large>call_received</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Load Sample Data</span>
                                    </v-tooltip>
                                </template>
                                <v-card>
                                    <v-card-title>Add Distributed Load</v-card-title>
                                    <v-card-text>
                                        <v-form v-model="loadValid">
                                            <v-text-field v-model="newLoad.startDistance"
                                                          :rules="numberRules"
                                                          label="Start Distance"
                                                          required
                                                          type="number">
                                            </v-text-field>
                                            <v-text-field v-model="newLoad.endDistance"
                                                          :rules="numberRules"
                                                          label="End Distance"
                                                          required
                                                          type="number">
                                            </v-text-field>
                                            <v-text-field v-model="newLoad.startLoad"
                                                          :rules="numberRules"
                                                          label="Start Load"
                                                          required
                                                          type="number">
                                            </v-text-field>
                                            <v-text-field v-model="newLoad.endLoad"
                                                          :rules="numberRules"
                                                          label="End Load"
                                                          required
                                                          type="number">
                                            </v-text-field>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" text @click="loadAddDialog=false">Close</v-btn>
                                        <v-btn color="primary" text @click="addLoad">Add</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!--Item slots-->
                    <template v-slot:item.startLoad="props">
                        <v-edit-dialog :return-value.sync="props.item.startLoad" large persistent>
                            <div>{{props.item.startLoad}}</div>
                            <template v-slot:input>
                                <div class="mt-4 title">Update</div>
                            </template>
                            <template v-slot:input>
                                <v-text-field v-model="props.item.startLoad"
                                              :rules="numberRules"
                                              label="Edit"
                                              type="number"
                                              aria-required="true"
                                              single-line
                                              autofocus>
                                </v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:item.endLoad="props">
                        <v-edit-dialog :return-value.sync="props.item.endLoad" large persistent>
                            <div>{{props.item.endLoad}}</div>
                            <template v-slot:input>
                                <div class="mt-4 title">Update</div>
                            </template>
                            <template v-slot:input>
                                <v-text-field v-model="props.item.endLoad"
                                              :rules="numberRules"
                                              label="Edit"
                                              type="number"
                                              aria-required="true"
                                              single-line
                                              autofocus>
                                </v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>
                    <!--No data slot-->
                    <template v-slot:no-data>
                        <v-alert :value="true" color="info" icon="info" dense outlined>No loads defined</v-alert>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="snackbar.show"
                    :color="snackbar.color"
                    bottom
                    multi-line
                    :timeout="snackbar.timeout">
            {{snackbar.text}}
            <v-btn dark text @click="snackbar.show=false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import { DistributedLoad } from "@/_calculators/src/simpleSupportedBeam/inputs"
    export default {
        name: "DistrLoadsComponent",
        props: ["distributedLoads", "system"],
        data() {
            return {
                units: {
                    metric: "N, m",
                    imperial: "lb, ft"
                },
                loadHeaders: [
                    { text: "Start Distance", value: "startDistance" },
                    { text: "End Distance", value: "endDistance" },
                    { text: "Start Load", value: "startLoad" },
                    { text: "End Load", value: "endLoad" }
                ],
                newLoad: new DistributedLoad(0, 1, 1, 1),
                localLoads: [],
                loadAddDialog: false,
                loadValid: true,
                numberRules: [v => !!v || "This field is required"],
                selected: [],
                snackbar: {
                    show: false,
                    color: "success",
                    text: "Success",
                    timeout: 3000
                }
            };
        },
        computed: {
            suffix: function () {
                return this.units[this.system];
            }
        },
        created() {
            this.localLoads = this.distributedLoads;
        },
        methods: {
            loadSampleData() {
                this.localLoads = [
                    new DistributedLoad(0, 5, 10, 10),
                    new DistributedLoad(6, 9, 3, 5)
                ];
                this.$emit("update-distributed-loads", this.localLoads);
            },
            showSnackbar(color, text) {
                this.snackbar.color = color;
                this.snackbar.text = text;
                this.snackbar.show = true;
            },
            showAddLoad() {
                this.loadAddDialog = true;
            },
            deleteLoads() {
                this.localLoads = this.localLoads.filter(
                    s => !this.selected.includes(s)
                );
                this.selected = [];
                this.$emit("update-distributed-loads", this.localLoads);
            },
            updateFigure() {
                this.$emit("update-figure");
            },
            addLoad() {
                try {
                    const exists = this.localLoads.some(e =>
                        e.key === this.newLoad.key
                    );
                    if (exists) {
                        this.showSnackbar("error", "Load already exists");
                    }
                    else {
                        this.localLoads.push(this.newLoad.clone());
                        // sort local loads
                        this.localLoads.sort((a, b) => {
                            if (a.startDistance > b.startDistance) return 1;
                            if (a.startDistance < b.startDistance) return -1;
                            return 0;
                        });
                        this.$emit("update-distributed-loads", this.localLoads);
                    }
                } catch (e) {
                    this.showSnackbar("error", e);
                }
            }
        }
    };
</script>