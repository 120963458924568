let outputs = {
    wallWeight: {
        label1: "Wall Weight",
        label2: "",
        value: 0,
        cat: 1,
        suffix: {
            metric: "ton",
            imperial: "kips"
        },
        description: {
            title: "Wall Weight",
            desc: "Ww"
        }
    },
    baseWeight: {
        label1: "Base Weight",
        label2: "",
        value: 0,
        cat: 1,
        suffix: {
            metric: "ton",
            imperial: "kips"
        },
        description: {
            title: "Base Weight",
            desc: "Wb"
        }
    },
    pistonWeight: {
        label1: "Piston Weight",
        label2: "",
        value: 0,
        cat: 1,
        suffix: {
            metric: "ton",
            imperial: "kips"
        },
        description: {
            title: "Piston Weight",
            desc: "Wp"
        }
    },
    guideWeight: {
        label1: "Guide Weight",
        label2: "",
        cat: 1,
        value: 0,
        suffix: {
            metric: "ton",
            imperial: "kips"
        },
        description: {
            title: "Guide Weight",
            desc: "Wg"
        }
    },
    topWeight: {
        label1: "Top Weight",
        label2: "",
        value: 0,
        cat: 1,
        suffix: {
            metric: "ton",
            imperial: "kips"
        },
        description: {
            title: "Top Weight",
            desc: "Wtop"
        }
    },
    totalWeight: {
        label1: "Total Weight",
        label2: "",
        value: 0,
        cat: 1,
        suffix: {
            metric: "ton",
            imperial: "kips"
        },
        description: {
            title: "Total Weight",
            desc: "Wtotal"
        }
    },
    Dp: {
        label1: "Diameter of Elastomer",
        label2: "Dp",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Diameter of Elastomer",
            desc: "Dp"
        }
    },
    hr: {
        label1: "Elastomer Thickness",
        label2: "hr",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Elastomer Thickness",
            desc: "hr"
        }
    },
    vd: {
        label1: "Vertical Displacement",
        label2: "vd",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Vertical Displacement",
            desc: "vd"
        }
    },
    tw: {
        label1: "Pot Wall Thickness",
        label2: "tw",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Pot Wall Thickness",
            desc: "tw"
        }
    },
    tb: {
        label1: "Pot Base Thickness",
        label2: "tb",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Pot Base Thickness",
            desc: "tb"
        }
    },
    hw: {
        label1: "Pot Wall Free Height",
        label2: "hw",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Pot Wall Free Height",
            desc: "hw"
        }
    },
    hp2: {
        label1: "Pot Wall Top Wall Space",
        label2: "hp2",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Pot Wall Top Wall Space",
            desc: "hp2"
        }
    },
    hp1: {
        label1: "Pot Depth",
        label2: "hp1",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Pot Depth",
            desc: "hp1"
        }
    },
    c: {
        label1: "Piston Wall Distance",
        label2: "c",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Piston Wall Distance",
            desc: "c"
        }
    },
    tt: {
        label1: "Top Plate Thickness",
        label2: "tt",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Top Plate Thickness",
            desc: "tt"
        }
    },
    thfb: {
        label1: "Total Height, Fixed Bearing",
        label2: "",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Total Height, Fixed Bearing",
            desc: "h"
        }
    },
    ptfe: {
        label1: "PTFE Thickness",
        label2: "",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "PTFE Thickness",
            desc: "h"
        }
    },
    thgb: {
        label1: "Total Height, Guided Bearing",
        label2: "abbreviation",
        value: 0,
        cat: 2,
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Total Height, Guided Bearing",
            desc: ""
        }
    },
    s: {
        label1: "Shape Factor",
        label2: "S",
        value: 0,
        cat: 2,
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "Shape Factor",
            desc: "S"
        }
    },
    A325Bolt: {
        label1: "32mm A325 Bolt Count",
        label2: "",
        value: 0,
        cat: 2,
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "32mm A325 Bolt Count",
            desc: "n"
        }
    }
}

export default outputs;