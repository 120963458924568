<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 lg10>
        <v-container fill-height>
          <v-layout wrap>
            <v-flex xs12 sm4 md5>
              <v-card height="100%" class="back elevation-22" dark></v-card>
            </v-flex>
            <v-flex xs12 sm8 md7>
              <v-card height="100%" class="elevation-22">
                <v-card-title>
                  <span class="grey--text title">Reset Password</span>
                </v-card-title>
                <v-card-text>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field
                        @keyup.enter="resetPassword"
                        color="blue"
                        v-model="password"
                        label="New Password"
                        type="password"
                        required
                      ></v-text-field>
                      <v-text-field
                        @keyup.enter="resetPassword"
                        color="blue"
                        v-model="passwordConfirmation"
                        label="Confirm New Password"
                        type="password"
                        required
                      ></v-text-field>
                      <p v-show="error" style="color:red">{{error}}</p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="info"
                    @click.prevent="resetPassword"
                  >
                    Reset Password
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
                <v-snackbar
                  v-model="snackbar"
                  color="success"
                  :timeout="timeout"
                >Password reset successful, redirecting to login page</v-snackbar>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      error: null,
      passwordConfirmation: "",
      password: "",
      loading: false,
      snackbar: false,
      timeout: 2000
    };
  },
  methods: {
    resetPassword() {
      // clear any errors
      this.error = null;
      // start loading animations
      this.loading = true;
      if (!this.password || !this.passwordConfirmation) {
        this.error = "Please enter and confirm a new password";
        this.loading = false;
      } else {
        if (this.password == this.passwordConfirmation) {
          // get the mode and oobCode from the query string
          var code = this.$route.query.oobCode;
          // if there is code query parameter
          if (code) {
            //     check if the code is valid - this throws error every time??
            firebase
              .auth()
              .verifyPasswordResetCode(code)
              // on success, try to reset the password
              .then(() => {
                firebase
                  .auth()
                  .confirmPasswordReset(code, this.password)
                  // on success, inform the user and redirect him to login page
                  .then(() => {
                    this.snackbar = true;
                    setTimeout(() => {
                      this.$router.push("/login");
                    }, 3000);
                  })
                  .catch(error => {
                    this.error = error.message;
                    this.loading = false;
                  });
              })
              // on failure, warn the user that the link is invalid/expired
              .catch(() => {
                this.error =
                  "This link has been expired. Please send a new password reset request";
                this.loading = false;
              });
          }
          // if the code query parameter is missing
          else {
            this.error =
              "Please send a password reset link using 'Forgot Password' button";
            this.loading = false;
          }
        } else {
          this.error = "Password confirmation does not match";
          this.loading = false;
        }
      }
    }
  }
};
</script>


<style scoped>
.back {
  background-image: url("https://images.pexels.com/photos/285283/pexels-photo-285283.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=409&w=1060");
  background-size: cover;
}

.link {
  color: darkcyan;
  text-decoration: none;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>