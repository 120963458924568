<template>
  <v-layout>
    <v-flex xs12>
      <apexchart
        height="250"
        type="line"
        :options="chartOptions"
        :series="chartSeries"
      ></apexchart>
    </v-flex>
  </v-layout>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
    name: "ValleyChartComponent",
    components: {
        VueApexCharts
    },
    props: ["stations"],
    created() {
        this.updateChart();
    },
    data() {
        return {
            chartOptions: {
                chart: {
                    id: "valley-chart",
                    zoom: { enabled: false },
                    animations: { enabled : false }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: "straight",
                    width: 1
                },
                title: {
                    text: "Valley Geometry",
                    align: "center",
                    style: {
                        fontSize: "16px"
                    }
                },
                xaxis: {
                    type: "numeric",
                    labels: { show: true },
                    axisTicks: { show: false }
                },
                    yaxis: {
                    type: "numeric",
                    labels: { show: false },
                    axisTicks: { show: false }
                },
                tooltip: {
                    enabled: false
                }
            },
            chartSeries: []
        }
    },
    methods: {
        // This method creates tight coupling with parent component.
        updateChart() {
        // STATION DATA IS ASSUMED TO BE SORTED!
        const firstRoadElevation = this.stations[0].roadElevation;
        const firstValleyElevation = this.stations[0].valleyElevation;
        const roadArray = [];
        const valleyArray = [];
        for (let index = 0; index < this.stations.length; index++) {
            const element = this.stations[index];
            roadArray.push([element.distance, element.roadElevation - firstRoadElevation]);
            valleyArray.push([element.distance, element.valleyElevation - firstValleyElevation]);
        }
        this.chartSeries = [
            {
                name: "Road",
                data: roadArray
            },
            {
                name: "Valley",
                data: valleyArray
            }
        ];
        }
    }

}
</script>

<style>
</style>