import db from '@/firebaseInit'
import firebase from 'firebase/app'
import 'firebase/auth'
import router from '@/router'

const userRef = db.collection('users');

const state = {
  user: null
}
const getters = {
  user: state => state.user,
}
const mutations = {
  SET_USER(state, data) {
    state.user = data;
  }
}
const actions = {
  async autoLogin({
    commit
  }, uid) {
    var result = await this.dispatch('users/fetchCurrentUser', uid)
  },
  fetchCurrentUser({
    commit,
    state
  }, uid) {
    return new Promise((resolve, reject) => {
      userRef.doc(uid).get()
        .then(async doc => {
          commit('SET_USER', doc.data())
          var tier = doc.data().tier
          if (tier) {
            var result = await this.dispatch('general/fetchCalculatorList', tier)
          }
          resolve('Done')
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  logout({
    commit
  }) {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      commit('SET_USER', null);
      router.push('/');
    }, error => {
    });
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}