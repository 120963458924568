<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 lg10>
                <v-container fill-height>
                    <v-layout wrap>
                        <v-flex xs12 sm4 md5>
                            <v-card height="100%" class="back elevation-22" dark>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 md7>
                            <v-card height="100%" class="elevation-22">
                                <v-card-title>
                                    <span class="grey--text title">Forgot Password</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-layout>
                                        <v-flex xs 12>
                                            <span>Please type your registered email address and click "Send Reset Email"
                                                button, and then follow the instructions on the email.</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout>
                                        <v-flex xs12>
                                            <v-text-field @keyup.enter="sendMail" color="blue" v-model="email"
                                                :rules="emailRules" label="Email Address" type="email" required>
                                            </v-text-field>
                                            <p v-show="error" :class="infoClass">{{error}}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text color="info" to="/signup">Register</v-btn>
                                    <v-btn text color="info" to="/login">Login</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn :loading="loading" :disabled="loading" color="info" @click="sendMail">
                                        Send Reset Email
                                        <template v-slot:loader>
                                            <span class="custom-loader">
                                                <v-icon light>cached</v-icon>
                                            </span>
                                        </template>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import firebase from 'firebase/app'
    import 'firebase/auth'

    export default {
        data() {
            return {
                error: null,
                email: '',
                infoClass: 'error--text',
                loading: false,
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid'
                ]
            }
        },
        methods: {
            sendMail() {
                // start loading animations
                this.loading = true;

                if (this.email) {
                    // try to send a reset email
                    this.infoClass = "success--text";
                    firebase.auth().sendPasswordResetEmail(this.email)
                        // to prevent mail address trial and errors, report the same status on both success and failure
                        .then(() => {
                            this.error = "Password reset instructions have been sent, redirecting to login page";
                            this.loading = false;
                            // redirect the user to the login page?
                            setTimeout(() => {
                                this.$router.push('/login');
                            }, 3000);
                        })
                        // on failure inform the user
                        .catch((error) => {
                            this.error = "Password reset instructions have been sent, redirecting to login page";
                            this.loading = false;
                            // redirect the user to the login page?
                            setTimeout(() => {
                                this.$router.push('/login');
                            }, 3000);
                        });

                } else {
                    this.infoClass = "error--text";
                    this.error = "Please enter a valid email address";
                    this.loading = false;
                }
            }
        }
    }
</script>

<style scoped>
    .back {
        background-image: url(https://images.pexels.com/photos/285283/pexels-photo-285283.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=409&w=1060);
        background-size: cover;
    }

    .link {
        color: darkcyan;
        text-decoration: none;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }
</style>