class Component {
    // Creates a new component with a blank subcomponent list and a grade of 7
    constructor(name, weight) {
        this.name = name;
        this.weight = weight;
        this.grade = 7;
        this.subcomponents = [];
    }

    updateGrade() {
        if (this.subcomponents.length > 0) {
            let sum = 0;
            let count = 0;
            for (const subcomponent of this.subcomponents) {
                const subGrade = subcomponent.updateGrade();
                if (subGrade) {
                    if (subGrade > 0 && subGrade < 8) {
                        sum += (subGrade * subcomponent.weight);
                        count += subcomponent.weight;
                    }
                }
            }
            const res = count = 0 ? 0 : Number((sum / count).toPrecision(3));
            this.grade = res;
            return res;
        } else {
            return Number(this.grade);
        }
    }

    getUngraded() {
        const list = [];
        if (this.subcomponents.length > 0) {
            for (const subcomponent of this.subcomponents) {
                const ungraded = subcomponent.getUngraded();
                if (ungraded) {
                    list.push(subcomponent.getUngraded());
                }
            }
        } else {
            if (this.grade === 8) {
                return this.name;
            }
        }
        const res = list.length > 0 ? list : null;
        return res;
    }
}

export { Component }