<template>
    <div>
        <v-layout wrap>
            <v-flex xs2>
                <div class="grey lighten-2 elevation-2" height="60">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-radio-group v-model="system" row>
                                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                                <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex xs12>
                <div class="pt-1 pl-2 elevation-2 grey lighten-3" height="60">
                    <v-layout>
                        <v-flex xs11 class="my-3 pl-2">
                            <div>Bridge Information</div>
                        </v-flex>
                        <v-flex xs1>
                            <v-tooltip top max-width="400" color="grey darken-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on">
                                        <v-icon color="grey darken-4">help</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 13px;">Please fill in the following fields</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="elevation-2">
                    <v-form v-model="valid">
                        <v-layout class="pa-3">
                            <v-flex>
                                <v-layout wrap>
                                    <v-text-field class="numberInputArea"
                                                  color="blue darken-4"
                                                  required
                                                  filled
                                                  hide-details
                                                  :suffix="suffixes[system]"
                                                  type="number"
                                                  v-model="beamLength"
                                                  :rules="numberRules"
                                                  label="Beam Length">

                                    </v-text-field>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </div>
            </v-flex>
        </v-layout>
        <div class="mt-4 pt-1 pl-2 pr-2 pb-2 elevation-4 grey lighten-3" height="60">
            <v-layout>
                <v-flex xs11 class="my-3 pl-2">
                    <div>Load Data</div>
                </v-flex>
                <v-flex xs1>
                    <v-tooltip top max-width="400" color="grey darken-4">
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon color="grey darken-4">help</v-icon>
                            </v-btn>
                        </template>
                        <span style="font-size: 13px;">Add loads here</span>
                    </v-tooltip>
                </v-flex>
            </v-layout>
            <div class="elevation-2">
                <PointLoadsComponent :pointLoads="pointLoads"
                                     :system="system"
                                     @update-point-loads="updatePointLoads"
                                     @update-figure="updateFigure">
                </PointLoadsComponent>
            </div>
            <div class="elevation-2">
                <DistrLoadsComponent :distributedLoads="distributedLoads"
                                     :system="system"
                                     @update-distributed-loads="updateDistributedLoads"
                                     @update-figure="updateFigure">
                </DistrLoadsComponent>
            </div>
        </div>

        <!--Buttons-->
        <v-layout mt-2 wrap>
            <v-flex xs12>
                <v-layout wrap>
                    <!--Calculate-->
                    <v-flex xs12 class="pr-2">
                        <v-tooltip top width="300" color="grey darken-3">
                            <template v-slot:activator="{ on }">
                                <v-btn block
                                       color="green lighten-4"
                                       v-on="on"
                                       :loading="running"
                                       :disabled="running"
                                       @click="runAnalysis">
                                    Calculate
                                    <template v-slot:loader>
                                        <span>Calculating...</span>
                                        <span class="custom-loader">
                                            <v-icon light>hourglass_full</v-icon>
                                        </span>
                                    </template>
                                </v-btn>
                            </template>
                            <span style="font-size: 13px;">The calculations will be started.</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <br />
        <!--Results-->
        <v-layout v-if="showResults" wrap>
            <v-flex xs12>
                <v-toolbar class="blue darken-2" dark height="50">
                    <v-spacer></v-spacer>
                    <div style="font-size: 18px; font-weight: bold;">RESULTS</div>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-flex>
            <v-flex mt-4 xs12 offset-xs-6>
                <v-select :items="resultsList" label="Output Parameter" outlined v-model="selectedResult"></v-select>
            </v-flex>
            <v-flex xs12>
                <apexchart height="250" type="line" :options="chartOptions" :series="chartData"></apexchart>
            </v-flex>
            <v-flex xs12>
                <p class="text-center">{{ maxValueText }}</p>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="snackbar.visible" multi-line :timeout="6000" color="red" bottom>
            {{ snackbar.text }}
            <v-btn color="white" text @click="snackbar.visible = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import PointLoadsComponent from "@/components/simpleSupportedBeam/PointLoadsComponent";
    import DistrLoadsComponent from "@/components/simpleSupportedBeam/DistrLoadsComponent";
    import { PointLoad, DistributedLoad, InputParameters } from "@/_calculators/src/simpleSupportedBeam/inputs";
    import calculate from "@/_calculators/src/simpleSupportedBeam/functions";
    import VueApexCharts from "vue-apexcharts";


    export default {
        name: "simpleSupportedBeam",
        components: {
            PointLoadsComponent,
            DistrLoadsComponent,
            VueApexCharts
        },
        data() {
            return {
                system: "metric",
                running: false,
                results: null,
                valid: true,
                beamLength: 10,
                pointLoads: [],
                distributedLoads: [],
                suffixes: {
                    metric: "m",
                    imperial: "ft"
                },
                snackbar: {
                    visible: false,
                    text: ""
                },
                resultsList: [
                    { text: "Shear Force", value: "shears" },
                    { text: "Moment", value: "moments" }
                ],
                selectedResult: "moments",
                showResults: false,
                numberRules: [
                    v => !!v || "Value is required",
                    v => (v && v >= 0) || "Value must be greater than 0"
                ],
                chartOptions: {
                    chart: {
                        id: "internal-force-chart",
                    },
                    stroke: {
                        curve: "straight",
                        width: 1
                    },
                    xaxis: {
                        type: "numeric",
                        title: {
                            text: "distance"
                        },
                        labels: {
                            show: true,
                            formatter: function (v) {
                                return Number(v);
                            }
                        },
                        axisTicks: {
                            show: true
                        }
                    },
                    yaxis: {
                        type: "numeric",
                        labels: {
                            show: true,
                            formatter: function (value) {
                                return Number(value).toFixed(1);
                            }
                        }
                    },
                }
            };
        },
        computed: {
            chartData() {
                return [
                    {
                        name: "result",
                        data: this.results[this.selectedResult]
                    }
                ];
            },
            maxValueText() {
                const max = Math.max(...this.results[this.selectedResult]);
                const min = Math.min(...this.results[this.selectedResult]);
                const r = Math.abs(max) > Math.abs(min) ? max : min;
                const t = (this.results[this.selectedResult].indexOf(r) + 1) * this.results.dl;
                return `The maximum is ${r.toFixed(1)} at L=${t.toFixed(1)}`;
            }
        },
        methods: {
            updatePointLoads(newPointLoads) {
                this.pointLoads = newPointLoads;
            },
            updateFigure() {
                console.log("update figure here");
            },
            updateDistributedLoads(newDistrLoads) {
                this.distributedLoads = newDistrLoads;
            },
            async runAnalysis() {
                try {
                    this.clearResults();
                    const inputParams = new InputParameters(this.beamLength, this.pointLoads, this.distributedLoads);
                    this.running = true;
                    const res = await calculate(this.system, inputParams);
                    this.results = res;
                    this.chartOptions.xaxis.labels.formatter = function (v) {
                        return (Number(v) * res.dl).toFixed(1);
                    }
                    this.showResults = true;
                    this.running = false;
                } catch (error) {
                    this.displayError(error);
                    this.running = false;
                }
            },
            displayError(errorText) {
                this.snackbar.text = errorText;
                this.snackbar.visible = true;
            },
            clearResults() {
                this.showResults = false;
                this.result = null;
            }
        }
    }

</script>

<style>
    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }
</style>