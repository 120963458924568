<template>
    <v-layout>
        <v-flex>
            <div class="elevation-1 grey lighten-3" height="377">
                <v-img v-if="selected === 'Yes'" :src="getImgUrl(img2)" max-height="377" contain></v-img>
                <v-img v-if="selected === 'No'" :src="getImgUrl(img1)" max-height="377" contain></v-img>
            </div>
        </v-flex>
        <v-flex xs12 md4 v-for="(category, i) in cats" :key="i">
            <div class="mx-1 elevation-2 grey lighten-3" height="60">
                <v-layout wrap>
                    <v-flex xs10 class="my-3 pl-2">
                        <div>{{titles[i]}}</div>
                    </v-flex>
                    <v-flex xs2>
                        <v-tooltip top max-width="400" color="grey darken-4">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon color="grey darken-4">help</v-icon>
                                </v-btn>
                            </template>
                            <span style="font-size: 13px;">{{tooltips[i]}}</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
            <v-card class="mx-1 mt-1 elevation-2" height="320">
                <v-layout class="pa-3">
                    <v-flex>
                        <v-form>
                            <v-layout wrap>
                                <v-flex class="pl-1"
                                    v-bind="{[`xs${12 / Math.ceil(Object.keys(category).length / 5)}`]: true}"
                                    v-for="input in category" :key="input.label">
                                    <v-select v-if="input.type === 'combobox'" v-model="selected" :items="items"
                                        :label="input.label" hide-details filled required></v-select>
                                    <v-text-field v-else v-show="input.needToCheck || input.cat !== 2"
                                        class="numberInputArea" color="blue darken-4" type="number"
                                        v-model="input.value" :label="input.label" hide-details filled required
                                        :suffix="input.suffix[system]"></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: ['cats', 'system', 'img1', 'img2', 'titles', 'tooltips'],
        data() {
            return {
                items: ['Yes', 'No'],
                selected: 'No',
            }
        },
        watch: {
            selected(val) {
                this.cats[1].composite.value = val;
                var set = ['ts', 's', 'Eg', 'Es']
                if (val === 'Yes') {
                    set.forEach(e => {
                        this.cats[1][e].needToCheck = true;
                    });
                } else {
                    set.forEach(e => {
                        this.cats[1][e].needToCheck = false;
                    });
                }
            }
        },
        methods: {
            getImgUrl(pic) {
                return require('@/assets/imgs/calculators/' + pic)
            }
        },
    }
</script>