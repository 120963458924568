<template>
    <div>
        <v-layout>
            <v-flex xs12 md4 v-for="(category, i) in cats" :key="i">
                <div v-if="i === 2">
                    <div class="mx-1 mt-1 elevation-1 grey lighten-3" height="406">
                        <br>
                        <v-img :src="getImgUrl(img1)" max-height="406" contain></v-img>
                    </div>
                </div>
                <div v-else>
                    <div class="mt-1 mr-1 elevation-2 grey lighten-3" height="60">
                        <v-layout  wrap>
                            <v-flex xs9 class="my-3 pl-2">
                                <div>{{titles[i]}}</div>
                            </v-flex>
                            <v-flex xs3>
                                <v-tooltip top max-width="400" color="grey darken-4">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon color="grey darken-4">help</v-icon>
                                        </v-btn>
                                    </template>
                                    <span style="font-size: 13px;">{{tooltips[i]}}</span>
                                </v-tooltip>
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-card class="mt-1 mr-1 elevation-2" height="370">
                        <v-layout class="pa-3">
                            <v-flex>
                                <v-form>
                                    <v-layout wrap>
                                        <v-flex class="pl-1" v-for="input in category" :key="input.label">
                                            <v-text-field class="numberInputArea" color="blue darken-4" type="number"
                                                v-model="input.value" :label="input.label" hide-details filled required
                                                :suffix="input.suffix[system]"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-form>
                            </v-flex>
                        </v-layout>
                    </v-card>
                </div>
            </v-flex>
            <!-- Results -->
            <v-flex xs12 md5 v-if="result1">
                <div class="mt-1 mr-1 elevation-2 grey darken-1" height="60">
                    <v-layout wrap>
                        <v-flex xs9 class="my-3 pl-2">
                            <div class="white--text">Results</div>
                        </v-flex>
                    </v-layout>
                </div>
                <v-card class="mt-1 mr-1 elevation-2" height="370">
                    <v-layout class="pa-2">
                        <v-flex>
                            <v-layout wrap>
                                <v-flex xs12 class="pl-2" v-for="(result, i) in outputs" :key="i">
                                    <v-card class="my-1 elevation-4">
                                        <!-- Component -->
                                        <resultCard :result="result" :result1="result1" :result2="result2"
                                            :changes="changes" :i="i" :system="system" />
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import resultCard from '@/_calculators/static/resultCard'
    export default {
        components: {
            resultCard
        },
        props: ["cats", "system", "outputs", "result1", "result2", "changes", "img1", 'titles', 'tooltips'],
        methods: {
            getImgUrl(pic) {
                return require('@/assets/imgs/calculators/' + pic)
            }
        }
    }
</script>

<style>

</style>