function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var aps = h('aps'),
        nps = h('nps'),
        fpu = h('fpu'),
        els = h('els'),
        jsr = h('jsr'),
        wob = h('wob'),
        anc = h('anc'),
        jlen = h('jlen'),
        xd = h('xd'),
        bc = h('bc'),
        yd = h('yd');

    // 1/mm to 1/m conversion


    // METRIC CALCULATIONS
    function metric() {


        ///////////////////////          VARIABLES          ///////////////////////

        // increase -- teta to converge to input yd
        var teta = 0.001;

        for (let i = 1; i <= 1570; i++) {

            var yd1 = xd * (1 - Math.cos(teta)) / Math.sin(teta);

            if (yd > yd1) {
                teta += 0.001;
            } else {

                break;
            }
        };

        var rd = xd / Math.sin(teta),
            tmp = rd;

        // compute -- tendon length to determine friction coefficient

        var sr = rd * teta,
            tl = 2 * sr + bc,
            frc;

        if (tl < 200) {
            frc = 0.15;
        } else {
            frc = 0.2;
        }

        // compute -- First Pull A: Elongation

        // Point A
        var jsa = jsr * fpu / 100, //MPa 
            tmpa = jsa;

        // Point B
        var jsb = jsa * Math.exp(-frc * teta - wob * sr),
            tmpbl = sr,
            tmpbs = jsb;

        // Point mid B-C
        var lgt = sr + bc / 2,
            jsbc = jsa * Math.exp(-frc * teta - wob * lgt),
            tmpbcl = lgt,
            tmpbcs = jsbc;

        // Point C
        var lgtc = sr + bc,
            jsc = jsa * Math.exp(-frc * teta - wob * lgtc),
            tmpcl = lgtc,
            tmpcs = jsc;

        // Point D
        var lgtd = 2 * sr + bc,
            jsd = jsa * Math.exp(-frc * 2 * teta - wob * lgtd),
            tmpdl = lgtd,
            tmpds = jsd;

        //Total Jacked Tendon Length Including Jack Part
        var lstre = lgtd + jlen,
            tmp1 = lstre;

        // Total Stress Loss from A to D
        var tlos = jsa - jsd,
            tmp2 = tlos;

        // Total Elongation at A before anchor set, at A
        var elona = (jsa + jsd) / 2 * lstre * 1000 / els,
            tmp3 = elona;

        // Average Rate of Stress Loss per Length
        var alos = tlos / lstre,
            tmp4 = alos;

        // Length of Tendon Affected with Anchor Set
        var talg = Math.sqrt(els * anc * lstre * 1000 / (jsa - jsd)),
            tmp5 = talg / 1000;

        // Stress loss at A due to Anchor Set
        var anlos = 2 * (jsa - jsd) * talg / lstre / 1000,
            tmp6 = anlos;

        // Final Stress at A
        var jsaa = jsa - anlos,
            tmp7 = jsaa;

        // Average Stress from First Pull
        var jsavr = 0.5 * (jsa + jsd);
        tmp8 = jsavr;

        // Point of Movement from End D	
        var jsed = jsavr - jsbc,
            slop = (jsb - jsc) / bc,
            pnm;

        if (jsed < 0) {
            pnm = sr + bc / 2 - jsed / slop;
        } else {
            pnm = sr + bc / 2 + jsed / slop;
        }

        var tmp9 = pnm;

        // Elongation at D after Pull	
        var elond = (jsa - jsavr) / 2 * pnm * 1000 / els,
            tmp10 = elond;

        ///////////////////////          RESULTS          ///////////////////////            

        // Geometry
        R.teta = teta;
        R.radius = tmp;

        // Prestressing Steel
        R.frc = frc;

        // First Pull A : Elongation

        // Point A
        R.jackA = [0.000, 0.00, tmpa];
        // Point B
        R.stressB = [teta, tmpbl, tmpbs];
        // Point mid B-C
        R.stressBC = [0.000, tmpbcl, tmpbcs];
        // Point C
        R.stressC = [0.000, tmpcl, tmpcs];
        // Point D
        R.stressD = [teta, tmpdl, tmpds];
        // Total Jacked Tendon Length Including Jack Part
        R.totalJacked = tmp1;
        // Total Stress Loss from A to D
        R.totalLoss = tmp2;
        // Total Elongation at A before anchor set, at A
        R.totalElon = tmp3;
        // Average Rate of Stress Loss per Length
        R.aveStress = tmp4;
        // Length of Tendon Affected with Anchor Set
        R.lenTendon = tmp5;
        // Stress loss at A due to Anchor Set
        R.stressLoss = tmp6;
        // Final Stress at A
        R.finalStress = tmp7;

        // Second Pull B - Elongation

        // Average Stress from First Pull	
        R.bAveStress = tmp8;
        // Point of Movement from End D	
        (tmp9 > Math.pow(10, 10)) ? tmp9 = Infinity : tmp9;
        R.bPointMove = tmp9;
        // Elongation at D after Pull
        (tmp10 > Math.pow(10, 10)) ? tmp10 = Infinity : tmp10;
        R.belonD = tmp10;
    }


    // IMPERIAL CALCULATIONS
    function imperial() {
        // ksi to psi conversion
        fpu /= 1000;
        els /= 1000;
        // ksi to psi conversion

        /* xd *= 0.3048,
        yd *= 0.3048,
        bc *= 0.3048,
        wob *= 0.0032808399; */

        ///////////////////////          VARIABLES          ///////////////////////

        // increase -- teta to converge to input yd
        var teta = 0.001;

        for (let i = 1; i <= 1570; i++) {

            var yd1 = xd * (1 - Math.cos(teta)) / Math.sin(teta);

            if (yd > yd1) {
                teta += 0.001;
            } else {

                break;
            }
        };

        var rd = xd / Math.sin(teta),
            tmp = rd;

        // compute -- tendon length to determine friction coefficient 
        var sr = rd * teta,
            tl = 2 * sr + bc,
            frc;

        if (tl < 656.167979) {
            frc = 0.15;
        } else {
            frc = 0.2;
        }

        /* aps *= 25.4 * 25.4,
        fpu *= 6.895,
        els *= 6.895,
        anc *= 25.4,
        jlen *= 0.3048; */

        // compute -- First Pull A: Elongation

        // Point A
        var jsa = jsr * fpu / 100,
            tmpa = jsa;

        // Point B
        var jsb = jsa * Math.exp(-frc * teta - wob * sr),
            tmpbl = sr,
            tmpbs = jsb;

        // Point mid B-C
        var lgt = sr + bc / 2,
            jsbc = jsa * Math.exp(-frc * teta - wob * lgt),
            tmpbcl = lgt,
            tmpbcs = jsbc;

        // Point C
        var lgtc = sr + bc,
            jsc = jsa * Math.exp(-frc * teta - wob * lgtc),
            tmpcl = lgtc,
            tmpcs = jsc;

        // Point D
        var lgtd = 2 * sr + bc,
            jsd = jsa * Math.exp(-frc * 2 * teta - wob * lgtd),
            tmpdl = lgtd,
            tmpds = jsd;

        //Total Jacked Tendon Length Including Jack Part
        var lstre = lgtd + jlen,
            tmp1 = lstre;

        // Total Stress Loss from A to D
        var tlos = jsa - jsd,
            tmp2 = tlos;

        // Total Elongation at A before anchor set, at A
        var elona = (jsa + jsd) / 2 * lstre * 12 / els,
            tmp3 = elona;

        // Average Rate of Stress Loss per Length
        var alos = tlos / lstre,
            tmp4 = alos; //psi/ft

        // Length of Tendon Affected with Anchor Set
        var talg = Math.sqrt(els * anc * lstre * 12 / (jsa - jsd)),
            tmp5 = talg / 12; //ft

        // Stress loss at A due to Anchor Set
        var anlos = 2 * (jsa - jsd) * talg / 12 / lstre,
            tmp6 = anlos; //psi

        // Final Stress at A
        var jsaa = jsa - anlos,
            tmp7 = jsaa;

        // Average Stress from First Pull
        var jsavr = 0.5 * (jsa + jsd);
        tmp8 = jsavr;

        // Point of Movement from End D	
        var jsed = jsavr - jsbc,
            slop = (jsb - jsc) / bc,
            pnm;

        if (jsed < 0) {
            pnm = sr + bc / 2 - jsed / slop;
        } else {
            pnm = sr + bc / 2 + jsed / slop;
        }

        var tmp9 = pnm;

        // Elongation at D after Pull	
        var elond = (jsa - jsavr) / 2 * pnm * 12 / els,
            tmp10 = elond;

        ///////////////////////          RESULTS          ///////////////////////            

        // Geometry
        R.teta = teta;
        R.radius = tmp;

        // Prestressing Steel
        R.frc = frc;

        // First Pull A : Elongation

        // Point A
        R.jackA = [0.000, 0.00, tmpa * 1000];
        // Point B
        R.stressB = [teta, tmpbl, tmpbs * 1000];
        // Point mid B-C
        R.stressBC = [0.000, tmpbcl, tmpbcs * 1000];
        // Point C
        R.stressC = [0.000, tmpcl, tmpcs * 1000];
        // Point D
        R.stressD = [teta, tmpdl, tmpds * 1000];
        // Total Jacked Tendon Length Including Jack Part
        R.totalJacked = tmp1;
        // Total Stress Loss from A to D
        R.totalLoss = tmp2 * 1000;
        // Total Elongation at A before anchor set, at A
        R.totalElon = tmp3;
        // Average Rate of Stress Loss per Length
        R.aveStress = tmp4 * 1000;
        // Length of Tendon Affected with Anchor Set
        R.lenTendon = tmp5;
        // Stress loss at A due to Anchor Set
        R.stressLoss = tmp6 * 1000;
        // Final Stress at A
        R.finalStress = tmp7 * 1000;

        // Second Pull B - Elongation

        // Average Stress from First Pull	
        R.bAveStress = tmp8 * 1000;
        // Point of Movement from End D	
        (tmp9 > Math.pow(10, 10)) ? tmp9 = Infinity : tmp9;
        R.bPointMove = tmp9;
        // Elongation at D after Pull
        (tmp10 > Math.pow(10, 10)) ? tmp10 = Infinity : tmp10;
        R.belonD = tmp10;
    }

    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

}

module.exports = {
    calculate
}