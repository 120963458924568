let outputs = {
    D: {
        label1: 'Column Diameter',
        value: -1,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Column Diameter',
            desc: '(D)'
        }
    }
}

export default outputs;