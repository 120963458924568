var inputs = {
    loadType: {
        label: 'loadType',
        value: null,
        cat: 1,
        type: 'combobox',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Load Type',
            desc: ''
        }
    },
    P1: {
        label: 'Axle Load, P1',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Axle Load, P1',
            desc: ''
        }
    },
    x1: {
        label: 'Axle Distance, x1',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Axle Distance',
            desc: ''
        }
    },
    P2: {
        label: 'Axle Load, P2',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Axle Load',
            desc: ''
        }
    },
    x2: {
        label: 'Axle Distance, x2',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Axle Distance',
            desc: ''
        }
    },
    P3: {
        label: 'Axle Load, P3',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Axle Load',
            desc: ''
        }
    },
    L: {
        label: 'Span Length',
        cat: 2,
        type: 'notDisabled',
        value: '30',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    }
};

var trucks = [
    {
    name: 'HL-93',
    data: {
        metric: {
            P1: 35,
            P2: 145,
            P3: 145,
            x1: 4.3,
            x2: 9.3
        },
        imperial: {
            P1: 8,
            P2: 32,
            P3: 32,
            x1: 14,
            x2: 14
        }

    }
},
{
    name: 'KGM-45',
    data: {
        metric: {
            P1: 50,
            P2: 200,
            P3: 200,
            x1: 10,
            x2: 10
        },
        imperial: {
            P1: 11,
            P2: 45,
            P3: 45,
            x1: 14,
            x2: 14
        }

    }
}
];

export {inputs, trucks};