function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var L = h('L'),
        d = h('d'),
        p;

    // Metric Calculations
    function metric() {
        p = L / d
        if (L / d <= 27 && L / d >= 25) {
            (R.s) = (27 - p) * (1.5 - 1) / (27 - 25) + 1;

        }

        if (L / d < 25 && L / d >= 22) {
            (R.s) = (25 - p) * (2 - 1.5) / (25 - 22) + 1.5;

        }

        if (L / d < 22 && L / d >= 19) {
            (R.s) = (22 - p) * (2.5 - 2) / (22 - 19) + 2;

        }

        if (L / d < 19 && L / d >= 17) {
            (R.s) = (19 - p) * (3 - 2.5) / (19 - 17) + 2.5;

        }

        if (L / d < 17) {
            R.s = 'Please Increase Span Length or Reduce Girder Depth'
        }

        if (L / d > 27) {
            R.s = 'Please Reduce Span Length or Increase Girder Depth'
        }
    };
    // Imperial Calculations
    function imperial() {
        p = L / d;
        if (L / d <= 27 && L / d >= 25) {
            R.s = (27 - p) * (5 - 3.3) / (27 - 25) + 3.3

        }

        if (L / d < 25 && L / d >= 22) {
            R.s = (25 - p) * (6.6 - 5) / (25 - 22) + 5

        }

        if (L / d < 22 && L / d >= 19) {
            R.s = (22 - p) * (8.2 - 6.6) / (22 - 19) + 6.6

        }

        if (L / d < 19 && L / d >= 17) {
            R.s = (19 - p) * (10 - 8.2) / (19 - 17) + 8.2

        }

        if (L / d < 17) {
            R.s = 'Please Increase Span Length or Reduce Girder Depth'
        }

        if (L / d > 27) {
            R.s = 'Please Reduce Span Length or Increase Girder Depth'
        }
    };

    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
        console.error('System is missing !')
    }
}

module.exports = {
    calculate
}