var metric = {
  Mmax: 3800, //kN.m
  L: 30,
  Ag: 548625, //mm2
  Ig: 141e9, //mm4
  d: 1400, //mm
  yb: 729, //mm
  ts: 250, //mm
  s: 1350, //mm
  Eg: 32000, //MPa
  Es: 28000, //MPa
  lane: 3,
};

var imperial = {
  Mmax: 2800, //kips.ft
  L: 100, //ft
  Ag: 864, //in2
  Ig: 347e3, //in4
  d: 55.5, //in
  yb: 29, //in
  ts: 10, //in
  s: 50, //in.
  Eg: 4650000, //psi
  Es: 4050000, //psi
  lane: 3,
};

export default {
  metric,
  imperial
}