<template>
    <v-container fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 lg10>
                <v-container fill-height>
                    <v-layout wrap>
                        <v-flex xs12 sm4 md5>
                            <v-card height="100%" class="back elevation-22" dark>
                            </v-card>
                        </v-flex>
                        <v-flex xs12 sm8 md7>
                            <v-card height="100%" class="elevation-22">
                                <v-card-title>
                                    <span class="grey--text title">Log In</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-layout>
                                        <v-flex xs12>
                                            <v-text-field @keyup.enter="login" color="blue" v-model="email"
                                                :rules="emailRules" label="E-mail" required></v-text-field>
                                            <v-text-field @keyup.enter="login" color="blue" v-model="password"
                                                label="Password" type="password" required></v-text-field>
                                            <p v-show="error" style="color:red">{{error}}</p>
                                        </v-flex>
                                    </v-layout>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn text color="info" to="/signup">Register</v-btn>
                                    <v-btn text color="info" to="/forgot-password">Forgot Password</v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn :loading="loading" :disabled="loading" color="info" @click.prevent="login">
                                        Sign In
                                        <template v-slot:loader>
                                            <span class="custom-loader">
                                                <v-icon light>cached</v-icon>
                                            </span>
                                        </template>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import firebase from 'firebase/app'
    import 'firebase/auth'

    export default {
        data() {
            return {
                error: null,
                email: '',
                password: '',
                loading: false,
                emailRules: [
                    v => !!v || 'E-mail is required',
                    v => /.+@.+/.test(v) || 'E-mail must be valid'
                ]
            }
        },
        methods: {
            login() {
                // clear any errors
                this.error = null;
                // start loading animations
                this.loading = true;
                // try to authenticate user
                firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                    // on success, direct the user to member dashboard
                    .then(user => {
                        this.$router.push('/member/dashboard');
                    })
                    // on failure, show the failure message
                    .catch(err => {
                        this.loading = false;
                        this.error = err.message
                    });
            }
        }
    }
</script>

<style scoped>
    .back {
        background-image: url(https://images.pexels.com/photos/220762/pexels-photo-220762.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=7090&w=1260);
        background-size: cover;
    }

    .link {
        color: darkcyan;
        text-decoration: none;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }

        to {
            transform: rotate(360deg);
        }
    }
</style>