<template>
  <div>
    <div class="my-4">
      <h2 class="ma-3">About Us</h2>
      <v-layout mx-2 row wrap>
        <v-flex sm6>
          <v-card class="pa-5 elevation-2" height="100%" dark :img="getImgUrl('about-left.jpg')">
            <div class="my-5">
              <h3>Smart Calculators</h3>
              <p>We are providing practical calculators for bridge engineering professionals, instructors and students.</p>
            </div>
          </v-card>
        </v-flex>
        <v-flex sm6>
          <v-card class="pa-5 elevation-2" height="100%">
            <p>
              BridgeWiz provides unique solutions and a speed learning platform for structural engineers, catering to
              different Millenial learning habits while ensuring a trusted source for the prior generations. We aim to
              influence self-learning through global structural engineer community.
            </p>
            <p>
              The BridgeWiz will be providing free Bridge Engineering educational tools including Essential Tutorials,
              Case Studies, Smart Calculators, On-Demand Webinars as well as Exclusive Discounts on Co-Sponsored
              Conferences.
            </p>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
    <div>
      <v-layout mx-2 row wrap>
        <v-flex xs12 md6 v-for="(address, i) in addresses" :key="i">
          <v-card height="100%" class="px-5 py-3 elevation-2">
            <h3>{{address.location}}</h3>
            <div>{{address.desc}}</div>
            <div>{{address.phone}}</div>
            <div class="mt-4" v-if="i === 0">
              <v-divider></v-divider>
              <div class="mt-2">
                <v-icon>mail</v-icon>info [@] bridgewiz.com
              </div>
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addresses: [
        {
          desc:
            "ODTÜ Teknokent BIM Mustafa Kemal Mahallesi Dumlupınar Bulvarı 280/G No:202 06530 Çankaya",
          location: "Ankara, TURKEY",
          phone: "+90 (312) 503-3503"
        },
        {
          desc: "",
          location: "New York, USA",
          phone: "+1 516 344 9228"
        }
      ]
    };
  },
  methods: {
    getImgUrl(image) {
      return require(`@/assets/imgs/static/backgrounds/${image}`);
    }
  }
};
</script>