var outputs = {
    g: {
        label1: 'Distribution Factor',
        label2: 'g',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Distribution Factor',
            desc: ''
        }
    },
    Mgi: {
        label1: 'Interior Girder Moment',
        label2: 'Mgi',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Interior Girder Moment',
            desc: ''
        }
    }
};

export default outputs;