var o1 = {

    totalJacked: {
        label1: 'Jacked Tendon Length Including Jack', // 46 karakter
        label2: '',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Jacked Tendon Length Including Jack',
            desc: ''
        }
    },

    totalLoss: {
        label1: 'Total Stress Loss (A -> D)', // 26 karakter
        label2: '',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Total Stress Loss from A to D',
            desc: ''
        }
    },

    totalElon: {
        label1: 'Total Elongation at A',
        label2: '',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Total Elongation at A before anchor set, at A',
            desc: ''
        }
    },

    aveStress: {
        label1: 'Average Rate of Stress Loss per Length', // 38 karakter
        label2: '',
        suffix: {
            metric: 'MPa/m',
            imperial: 'psi/ft'
        },
        description: {
            title: 'Average Rate of Stress Loss per Length',
            desc: ''
        }
    },

    lenTendon: {
        label1: 'Length of Tendon Affected with Anchor', // 41 karakter
        label2: '',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Length of Tendon Affected with Anchor Set',
            desc: ''
        }
    },

    stressLoss: {
        label1: 'Stress loss at A',
        label2: '',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Stress loss at A due to Anchor Set',
            desc: ''
        }
    },

    finalStress: {
        label1: 'Final Stress at A',
        label2: '',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Final Stress at A',
            desc: ''
        }
    }
};

var o2 = {

    bAveStress: {
        label1: 'Average Stress from First Pull', // 30 karakter
        label2: '',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Average Stress from First Pull',
            desc: ''
        }
    },

    bPointMove: {
        label1: 'Point of Movement from End D', // 28 karakter
        label2: '',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Point of Movement from End D',
            desc: ''
        }
    },

    belonD: {
        label1: 'Elongation at D after Pull', // 26 karakter
        label2: '',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Elongation at D after Pull',
            desc: ''
        }
    }
};

var o3 = {
    teta: {
        label1: 'θ',
        label2: '',
        suffix: {
            metric: 'rad',
            imperial: 'rad'
        },
        description: {
            title: 'θ',
            desc: ''
        }
    },

    radius: {
        label1: 'Radius',
        label2: '',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Radius',
            desc: ''
        }
    },

    frc: {
        label1: 'Friction Coefficient',
        label2: '',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Friction Coefficient',
            desc: ''
        }
    },
}

export default {
    o1,
    o2,
    o3
};