let inputs = {
    verticalFactored: {
        label: "Factored Vertical Load",
        value: 100,
        cat: 1,
        type: "number",
        suffix: {
            metric: "kN",
            imperial: "kip"
        },
        description: {
            title: "Factored Vertical Load",
            desc: "fv"
        }
    },
    verticalUnfactored: {
        label: "Unfactored Vertical Load",
        value: 80,
        cat: 1,
        type: "number",
        suffix: {
            metric: "kN",
            imperial: "kip"
        },
        description: {
            title: "Unfactored Vertical Load",
            desc: "uv"
        }
    },
    longitudinalFactored: {
        label: "Factored Longitudinal Load",
        value: 100,
        cat: 1,
        type: "number",
        suffix: {
            metric: "kN",
            imperial: "kip"
        },
        description: {
            title: "Factored Longitudinal Load",
            desc: "fl"
        }
    },
    longitudinalUnfactored: {
        label: "Unfactored Longitudinal Load",
        value: 80,
        cat: 1,
        type: "number",
        suffix: {
            metric: "kN",
            imperial: "kip"
        },
        description: {
            title: "Unfactored Longitudinal Load",
            desc: "ul"
        }
    },
    transverseFactored: {
        label: "Factored Transverse",
        value: 100,
        cat: 1,
        type: "number",
        suffix: {
            metric: "kN",
            imperial: "kip"
        },
        description: {
            title: "Factored Transverse Load",
            desc: "ft"
        }
    },
    transverseUnfactored: {
        label: "Unfactored Transverse Load",
        value: 80,
        cat: 1,
        type: "number",
        suffix: {
            metric: "kN",
            imperial: "kip"
        },
        description: {
            title: "Unfactored Transverse Load",
            desc: "ut"
        }
    },
    elastomerType: {
        label: "Type",
        value: "Fixed",
        values: ["Fixed", "Guided"],
        cat: 2,
        type: "combobox",
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "Elastomer type",
            desc: "Elastomer type"
        }
    },
    allowedCompression: {
        label: "Allowed Compression",
        value: 1000,
        cat: 2,
        type: "number",
        suffix: {
            metric: "MPa",
            imperial: "ksi"
        },
        description: {
            title: "Elastomer Allowed Compression",
            desc: ""
        }
    },
    rotation: {
        label: "Rotation",
        value: 1,
        cat: 2,
        type: "number",
        suffix: {
            metric: "rad",
            imperial: "rad"
        },
        description: {
            title: "Rotation",
            desc: ""
        }
    },
    shearModulus: {
        label: "Elastomer Shear Modulus",
        value: 100,
        cat: 2,
        type: "number",
        suffix: {
            metric: "MPa",
            imperial: "ksi"
        },
        description: {
            title: "Elastomer Shear Modulus",
            desc: ""
        }
    },
    yieldStrength: {
        label: "Steel Yield Strength",
        value: 420,
        cat: 2,
        type: "number",
        suffix: {
            metric: "MPa",
            imperial: "ksi"
        },
        description: {
            title: "Steel Yield Strength",
            desc: ""
        }
    },
    concreteStrength: {
        label: "Concrete Compressive Strength",
        value: 30,
        cat: 2,
        type: "number",
        suffix: {
            metric: "MPa",
            imperial: "ksi"
        },
        description: {
            title: "Concrete Compressive Strength",
            desc: "fc"
        }
    },
    horizontalMovement: {
        label: "Horizontal Movement",
        value: 0,
        cat: 2,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Horizontal Movement",
            desc: "description"
        }
    }
}


export default inputs