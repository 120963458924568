<template>
  <HomeComponent />
</template>

<script>
import HomeComponent from '../components/HomeComponent'

export default {
    name: 'Info',
    components: {
        HomeComponent
    }
}
</script>