<template>
  <div class="mb-4">
    <!-- Metric / Imperial - Clear / Calculate Buttons -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout wrap>
            <v-flex xs12>
              <v-radio-group v-model="system" row>
                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex xs8 class="mt-1">
        <v-layout wrap>
          <v-flex xs3></v-flex>
          <!-- Clear -->
          <v-flex xs3 class="px-2">
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="reset">Clear</v-btn>
              </template>
              <span style="font-size: 13px;">All values will be erased.</span>
            </v-tooltip>
          </v-flex>
          <!-- Calculate -->
          <v-flex xs6>
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block color="green darken-4" dark v-on="on" @click="checkAllFields">Calculate</v-btn>
              </template>
              <span style="font-size: 13px;">Section properties will be calculated.</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Inputs Card -->
    <v-layout>
      <!-- Live Load 1 -->
      <!-- xs5 - xs7 -->
      <v-flex v-for="(category, i) in cats" :key="i" v-bind="{[`xs${i === 0 ? 5 : 7}`]: true}">
        <div v-if="i === 1" class="pt-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout wrap>
            <v-flex xs10 class="my-3 pl-2">
              <div>Live Load Moment</div>
            </v-flex>
            <v-flex xs2>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">
                  Axle Loads will be entered. All values are positive. This
                  calculator will be used to compute the changing moment for one lane of loading.
                </span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>

        <div
          class="elevation-2"
          :class="i === 0 ?  'mr-2' : ''"
          :height="(i === 0) ? '510' : '450'"
        >
          <v-layout>
            <v-flex>
              <v-layout class="pa-3" wrap>
                <v-flex
                  class="pl-1"
                  v-bind="{[`xs${12 / Math.ceil(Object.keys(category).length / 5)}`]: true}"
                  v-for="input in category"
                  :key="input.label"
                >
                  <!-- Images -->
                  <div v-if="input.type === 'combobox'" class="mb-3 mt-4">
                    <v-img height="165" :src="getImgUrl(image.main)" contain></v-img>

                    <v-img
                      height="165"
                      v-if="selected === 'Tandem'"
                      :src="getImgUrl(image.tandem)"
                      contain
                    ></v-img>
                    <v-img
                      height="165"
                      v-else-if="selected === 'Truck'"
                      :src="getImgUrl(image.truck)"
                      contain
                    ></v-img>
                  </div>
                  <v-layout>
                    <v-flex class="mx-1">
                      <!-- Load Type -->
                      <v-select
                        v-if="input.type === 'combobox'"
                        v-model="selected"
                        :items="items"
                        :label="input.label"
                        hide-details
                        filled
                        required
                      ></v-select>
                    </v-flex>
                    <v-flex class="mx-1" v-if="input.type === 'combobox'">
                      <!-- Live Load Modal -->
                      <v-select
                        v-if="selected === 'Truck'"
                        v-model="liveLoadModal"
                        :items="llModal_TRUCK"
                        label="Live Load Model"
                        hide-details
                        filled
                        required
                      ></v-select>
                      <v-select
                        v-if="selected === 'Tandem'"
                        v-model="liveLoadModal"
                        :items="llModal_TANDEM"
                        label="Live Load Model"
                        hide-details
                        filled
                        required
                      ></v-select>
                    </v-flex>
                  </v-layout>

                  <v-text-field
                    v-if="input.type !== 'combobox' && input.type !== 'notDisabled'"
                    class="numberInputArea"
                    color="blue darken-4"
                    type="number"
                    v-model="input.value"
                    :label="input.label"
                    hide-details
                    filled
                    :disabled="!liveLoadModal || liveLoadModal !== 'Manual'"
                    required
                    :suffix="input.suffix[system]"
                  ></v-text-field>

                  <v-text-field
                    v-if="input.type === 'notDisabled'"
                    class="numberInputArea"
                    color="blue darken-4"
                    type="number"
                    v-model="input.value"
                    :label="input.label"
                    hide-details
                    filled
                    required
                    :suffix="input.suffix[system]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- RESULTS -->
              <div v-if="i === 1 && result1">
                <div class="mt-3">
                  <v-layout wrap v-if="result1">
                    <v-flex xs12>
                      <div class="elevation-2 grey lighten-2" height="60">
                        <v-layout xs12>
                          <v-flex class="my-3 pl-3">
                            <div>Results</div>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex
                          xs6
                          :class="(i === 'xmax') ? 'pl-3 pr-2' : 'pr-3 pl-2'"
                          v-for="(result, i) in outputs"
                          :key="i"
                        >
                          <v-card class="my-2 elevation-2" flat>
                            <!-- Component -->
                            <resultCard
                              :result="result"
                              :result1="result1"
                              :result2="result2"
                              :changes="changes"
                              :i="i"
                              :system="system"
                            />
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import unitConvertor from "@/_calculators/static/unitConvertor";
import functions from "@/_calculators/src/liveLoad/L1/functions";
import inputs from "@/_calculators/src/liveLoad/L1/inputs";
import outputs from "@/_calculators/src/liveLoad/L1/outputs";
import resultCard from "@/_calculators/static/resultCard";

export default {
  components: {
    resultCard
  },
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      result1: null,
      result2: null,
      excludedList: ["loadType"],
      cats: [],
      categoryNo: 2,

      changes: {},

      items: ["Truck", "Tandem"],
      llModal_TRUCK: ["HL-93", "KGM-45", "Manual"],
      llModal_TANDEM: ["HL-93", "Manual"],
      selected: "Truck",
      liveLoadModal: "",
      image: {
        main: "liveLoad_main.png",
        tandem: "liveLoad_tandem.png",
        truck: "liveLoad_truck.png"
      }
    };
  },
  created() {
    this.createCats();
    this.cats[0].loadType.value = "Truck";
  },
  computed: {
    loadType() {
      return this.inputs.loadType.value;
    }
  },
  watch: {
    selected(value) {
      this.cats[0].loadType.value = value;
      this.liveLoadModal = "";
      var keys = Object.keys(this.cats[1]);
      keys.forEach((key, index) => {
        this.cats[1][key].value = null;
      });
    },
    liveLoadModal(type) {
      this.changeInp(type);
    },
    system(val) {
      this.convertUnits(val);
      if (this.liveLoadModal !== "Manual") {
        this.changeInp(this.liveLoadModal);
        this.clearResults();
      }
    },
    result2(val) {
      if (val) {
        this.changePerc();
      }
    }
  },
  methods: {
    /* Static */
    calcMetric() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "metric");
    },
    calcImperial() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "imperial");
    },
    clearResults() {
      this.result1 = null;
      this.result2 = null;
    },
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    },
    createCats() {
      var inputKeys = Object.keys(this.inputs);
      var groups = [];
      for (let i = 1; i <= this.categoryNo; i++) {
        groups.push(inputKeys.filter(key => this.inputs[key].cat === i));
      }
      var res = [];
      groups.forEach(group => {
        var cat = {};
        group.forEach(input => {
          cat[input] = this.inputs[input];
        });
        res.push(cat);
      });
      this.cats = res;
    },
    checkAllFields() {
      var inputKeys = Object.keys(this.inputs);
      /* IF needToCheck: false -> Remove from inputKeys*/
      var notNeedCheck = inputKeys.filter(
        key => this.inputs[key].needToCheck === false
      );
      notNeedCheck.forEach(e => {
        var index = inputKeys.indexOf(e);
        if (index !== -1) {
          inputKeys.splice(index, 1);
        }
      });

      /* Excluded List for combobox */
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }
      var inputValues = inputKeys.map(key => this.inputs[key].value);
      var isOkay = inputValues.every(
        i => i !== null && i !== undefined && i !== ""
      );
      if (isOkay) {
        inputKeys.map(
          key => (this.inputs[key].value = parseFloat(this.inputs[key].value))
        );
        if (this.system === "metric") {
          this.calcMetric();
        } else if (this.system === "imperial") {
          this.calcImperial();
        }
      } else {
        alert("There are error(s) in the input, please fill out all fields!");
      }
    },
    changeInputs(type, system) {
      var inputKeys = Object.keys(this.inputs);
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }

      if (type === "clear") {
        inputKeys.forEach(input => {
          this.inputs[input].value = null;
        });
      }
    },
    convertUnits(to) {
      var inputKeys = Object.keys(this.inputs);
      if (to === "metric") {
        inputKeys.forEach(key => {
          var value = this.inputs[key].value;
          if (value !== null && value !== "" && value !== undefined) {
            var converted = unitConvertor.convertImpToMetric(
              value,
              this.inputs[key].suffix.imperial
            );
            this.inputs[key].value = converted.v;
          }
        });
      } else if (to === "imperial") {
        inputKeys.forEach(key => {
          var value = this.inputs[key].value;
          if (value !== null && value !== "" && value !== undefined) {
            var converted = unitConvertor.convertMetricToImp(
              value,
              this.inputs[key].suffix.metric
            );
            this.inputs[key].value = converted.v;
          }
        });
      }
    },
    reset() {
      this.changeInputs("clear");
      this.clearResults();
    },

    changeInp(type) {
      var keys = Object.keys(this.cats[1]);
      /* L and IM excluded (keys array remove last 2 elements) */
      keys.splice(6, 2);

      var data = {
        imperial: {
          "Truck_HL-93": [8, 0.64, 32, 14, 32, 14],
          "Truck_KGM-45": [11, 0.7, 45, 14, 45, 14],
          "Tandem_HL-93": [0, 25, 25, 0.64, 0, 4]
        },
        metric: {
          "Truck_HL-93": [35, 9.3, 145, 9.3, 145, 4.3],
          "Truck_KGM-45": [50, 4.25, 200, 10, 200, 10],
          "Tandem_HL-93": [0, 110, 110, 9.3, 0, 1.2]
        }
      };

      if (this.selected === "Truck" && type === "KGM-45") {
        keys.forEach((key, index) => {
          this.cats[1][key].value = data[this.system]["Truck_KGM-45"][index];
        });
      } else if (this.selected === "Truck" && type === "HL-93") {
        keys.forEach((key, index) => {
          this.cats[1][key].value = data[this.system]["Truck_HL-93"][index];
        });
      } else if (this.selected === "Tandem" && type === "HL-93") {
        keys.forEach((key, index) => {
          this.cats[1][key].value = data[this.system]["Tandem_HL-93"][index];
        });
      } else if (type === "Manual") {
        keys.forEach((key, index) => {
          this.cats[1][key].value = null;
        });
      }
    },
    changePerc() {
      var keys = Object.keys(this.result1);
      keys.forEach(key => {
        var r1 = this.result1[key];
        var r2 = this.result2[key];
        var result;
        var sign = null;
        if (r1 && r2) {
          result = (r1 * 100) / r2 - 100;
          if (result > -0.005 && result < 0.005) {
            sign = "swap_horiz";
          } else if (result > 0.005) {
            sign = "trending_up";
          } else if (result < -0.005) {
            sign = "trending_down";
          }
        } else {
          result = "";
        }
        this.changes[key] = {
          result,
          sign
        };
      });
    }
  }
};
</script>

<style>
</style>