var outputs = {
    s: {
        label1: 'Max. Girder Spacing',
        label2: 's',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Maximum Girder Spacing',
            desc: ''
        }
    }

}

export default outputs;