var inputs = {
    L: {
        label: 'Span Length',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    },
    d: {
        label: 'Girder Depth',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Girder Depth',
            desc: ''
        }
    }
}

export default inputs;