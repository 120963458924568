<template>
  <div>
    <v-card>
      <v-card-title>
        Registered Users
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-layout>
        <v-flex xs-12>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :search="search"
            :items="users"
            class="elevation-1"
          >
            <template v-slot:item.tier="props">
              <v-edit-dialog
                :return-value.sync="props.item.tier"
                large
                persistent
                @save="save(props.item)"
                @cancel="cancel(props.item)"
                @open="open(props.item)"
                @close="close"
              >
                <div>{{ props.item.tier }}</div>

                <template v-slot:input>
                  <div class="mt-4 title">Update Tier</div>
                </template>
                <template v-slot:input>
                  <v-select :items="tiers" label="Tier" v-model="props.item.tier"></v-select>
                  <v-text-field
                    v-show="props.item.tier !=='tier1'"
                    v-model="duration"
                    :rules="durationRules"
                    type="number"
                    label="Duration (Months)"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info">No users found.</v-alert>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-card>

    <v-snackbar
      v-model="result.show"
      :color="result.color"
      bottom
      multi-line
      :timeout="result.timeout"
    >
      {{result.text}}
      <v-btn dark text @click="result.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import db from "@/firebaseInit.js";
import * as moment from "moment";

export default {
  name: "EditUsersComponent",
  data() {
    return {
      loading: true,
      dateFormatString: "YYYY-MM-DD",
      originalTier: "",
      search:"",
      users: [],
      result: {
        show: false,
        color: "error",
        text: "An error occured.",
        timeout: 3000
      },
      duration: 1,
      durationRules: [
        v => !!v || "Required",
        v => v >= 1 || "Minimum 1",
        v => v <= 12 || "Maximum 12"
      ],
      tiers: ["tier1", "tier2"],
      headers: [
        { text: "Name", value: "name" },
        { text: "Surname", value: "surname" },
        { text: "E-Mail", value: "email" },
        { text: "Company", value: "company" },
        { text: "Tier", value: "tier" },
        { text: "Last Purchase Date", value: "purchaseDateText" },
        { text: "Subscription End Date", value: "subscriptionEndDateText" }
      ]
    };
  },
  methods: {
    async save(item) {
      if (
        item.tier === "tier1" ||
        (this.duration && this.duration >= 1 && this.duration <= 12)
      ) {
        try {
          // if the target tier is tier1, subscription end date is now, else it is now + user-entered duration
          const endDate =
            item.tier === "tier1"
              ? moment()
              : moment().add(this.duration, "months");
          // update db
          await db
            .collection("users")
            .doc(item._id)
            .set(
              {
                tier: item.tier,
                subscriptionEndDate: endDate.toDate()
              },
              {
                merge: true
              }
            );
          // update local date (tier is already databound)
          item.subscriptionEndDateText = endDate.format(this.dateFormatString);
          this.result.show = true;
          this.result.color = "success";
          this.result.text = "Changes are saved";
        } catch (error) {
          // revert local to original tier
          item.tier = this.originalTier;
          this.result.show = true;
          this.result.color = "error";
          this.result.text = "An error occured during saving";
        }
      }
    },
    cancel(item) {
      // set the tier of the item to the original - it may be modified, and changes are synced directly
      item.tier = this.originalTier;
    },
    open(item) {
      // backup the original tier so if user clicks cancel it can be reset.
      this.originalTier = item.tier;
    },
    close() {
      // reset duration
      this.duration = 1;
    }
  },
  async mounted() {
    this.loading = true;
    try {
      // download the existing users
      const userRef = await db.collection("users").get();
      if (!userRef.empty) {
        for (const userData of userRef.docs) {
          let user = userData.data();
          user.purchaseDateText = moment
            .unix(user.lastPurchaseDate.seconds)
            .format(this.dateFormatString);
          user.subscriptionEndDateText = moment
            .unix(user.subscriptionEndDate.seconds)
            .format(this.dateFormatString);
          this.users.push(user);
        }
      }
    } catch (error) {
      this.result.show = true;
      this.result.color = "error";
      this.result.text = "An error occured during fetching users";
    }
    this.loading = false;
  }
};
</script>

<style>
</style>