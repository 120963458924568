import outputs from "@/_calculators/src/potBearing/outputs";

function calculate(inputs, system) {
    let iv = {};
    // calculations are in SI; if system is imperial convert inputs to SI first
    for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
            const element = inputs[key];
            if (system === "metric") {
                iv[key] = element.value;
            } else {
                switch (element.suffix.imperial) {
                    case "kip":
                        iv[key] = element.value * 4.4482216;
                        break;
                    case "ksi":
                        iv[key] = element.value * 6.89476;
                        break;
                    case "in":
                        iv[key] = element.value * 25.4
                        break;
                    // non-numeric values fall into here
                    default:
                        iv[key] = element.value;
                        break;
                }
            }
        }
    }
    const yk = Math.sqrt(iv.longitudinalUnfactored ** 2 + iv.transverseUnfactored ** 2);
    let yf = Math.sqrt(iv.longitudinalFactored ** 2 + iv.transverseFactored ** 2);
    const yfLimit = 0.15 * iv.verticalUnfactored;
    yf = yf > yfLimit ? yf : yfLimit;
    // diameter of elastomer
    const dpo = 2 * Math.sqrt(iv.verticalUnfactored * 1000 / Math.PI / iv.allowedCompression);
    outputs.Dp.value = dpo;
    // elastomer thickness
    const eth = 3.34 * dpo * iv.rotation;
    outputs.hr.value = eth;
    // shape factor
    const shp = iv.shearModulus * dpo / (4 * eth);
    outputs.s.value = shp;
    const area = Math.PI * ((dpo / 2) ** 2);
    const cf = iv.verticalFactored * 1000 / area;
    // vertical displacement
    const dv = eth * cf / (6 * iv.shearModulus * shp ** 2);
    outputs.vd.value = dv;
    const ck = iv.verticalUnfactored * 1000 / area;
    // pot wall thickness
    let tw = dpo * ck / (1.25 * iv.yieldStrength);
    tw = tw < 19.05 ? 19.05 : tw;
    const twl = Math.sqrt(25000 * yf * iv.rotation / iv.yieldStrength);
    tw = tw < twl ? twl : tw;
    outputs.tw.value = tw;
    // pot base thickness
    let tb = 0.06 * dpo;
    tb = tb < 19.05 ? 19.05 : tb;
    tb = tb < twl ? twl : tb;
    outputs.tb.value = tb;
    // pot wall free height
    let hw = 1.5 * yf * 1000 / dpo / iv.yieldStrength;
    hw = hw < 3.175 ? 3.175 : hw;
    const hwl = 0.03 * dpo;
    hw = hw < hwl ? hwl : hw;
    outputs.hw.value = hw;
    // pot wall & top plate space
    const hp2 = (tw + dpo / 2) * iv.rotation + 2 * dv + 3.175;
    outputs.hp2.value = hp2;
    // pot depth
    const hp1 = 0.5 * dpo * iv.rotation + eth + hw;
    outputs.hp1.value = hp1;
    // piston wall distance
    let cp = iv.rotation * (hw - (dpo * iv.rotation) / 2);
    cp = cp < 0.508 ? 0.508 : cp;
    outputs.c.value = cp;
    // top plate thickness
    const fpc = 1.7 * iv.concreteStrength;
    const b2 = dpo + 60;
    const b1 = dpo * 0.93;
    const rd = (b2 - b1) / 2;
    const amu = fpc * (rd ** 2) / 2;
    let apt = Math.sqrt(4 * amu / (0.9 * iv.yieldStrength));
    const aptLimit = 1.1 * tb;
    apt = apt < aptLimit ? aptLimit : apt;
    outputs.tt.value = apt;
    // total height
    if (iv.elastomerType === "Fixed") {
        const poth = 2 * tb + hp1 + hp2 + 2 * apt;
        outputs.thfb.value = poth;
        outputs.ptfe.value = 0;
        outputs.thgb.value = 0;
    } else {
        const ptfe = 3.175;
        const totGui = 2 * tb + hp1 + hp2 + 2 * apt + tb + ptfe;
        outputs.ptfe.value = ptfe;
        outputs.thgb.value = totGui;
        outputs.thfb.value = 0;
    }
    // 32mm diameter a325 bolt count
    const ankb = Math.ceil(yf / 196.6016);
    outputs.A325Bolt.value = ankb;
    const wFactor = 1000 ** 3;
    // Wall weight
    const potw = Math.PI * (((dpo + 2 * tw) ** 2) - (dpo ** 2)) / 4 * apt * 8 / wFactor;
    outputs.wallWeight.value = potw;
    // Base weight
    const potbs = Math.PI * (((dpo + 2 * tw) ** 2) / 4) * tb * 8 / wFactor;
    outputs.baseWeight.value = potbs;
    // Piston weight
    const psto = Math.PI * (dpo ** 2) / 4 * (hw + hp2) * 8 / wFactor;
    outputs.pistonWeight.value = psto;
    // Guide weight
    const pgui = iv.elastomerType === "Fixed" ? 0 : psto * 1.1;
    outputs.guideWeight.value = pgui;
    // Top plate weight
    const topwt = (dpo + 2 * tw) * (dpo + 2 * tw + 2 * iv.horizontalMovement * 1.1) * apt * 8 / wFactor;
    outputs.topWeight.value = topwt;
    // Total weight
    const totwt = potw + potbs + psto + pgui + topwt;
    outputs.totalWeight.value = totwt;

    //calculations are in SI; if system is imperial convert results to imperial first
    if (system === "imperial") {
        for (const key in outputs) {
            if (outputs.hasOwnProperty(key)) {
                const element = outputs[key];
                switch (element.suffix.metric) {
                    case "mm":
                        element.value = element.value / 25.4;
                        break;
                    case "ton":
                        element.value = element.value * 2.2046226218488;
                    // no units will fall here
                    default:
                        break;
                }
            }
        }
    }
    return outputs;
}

export default calculate;