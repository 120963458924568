<template>
  <div>
    <!-- Metric vs Imperial Radio Boxes -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout>
            <v-flex>
              <v-form>
                <v-layout wrap class>
                  <v-flex xs12>
                    <v-radio-group v-model="system" row>
                      <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                      <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <!-- Inputs -->
    <component
      :is="irType"
      :inputs="inputs"
      :cats="cats"
      :system="system"
      :outputs="outputs"
      :result1="result1"
      :result2="result2"
      :changes="changes"
      :img1="img1"
      :titles="titles"
      :tooltips="tooltips"
    ></component>
    <v-layout mt-1 wrap>
      <v-flex xs12>
        <v-layout wrap>
          <!-- Load Test Data -->
          <v-flex xs3>
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="loadTestData" v-if="!noTestData">Test Data</v-btn>
              </template>
              <span style="font-size: 13px;">Default values will be filled.</span>
            </v-tooltip>
          </v-flex>
          <!-- Clear -->
          <v-flex xs3 class="px-2">
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="reset">Clear</v-btn>
              </template>
              <span style="font-size: 13px;">All values will be erased.</span>
            </v-tooltip>
          </v-flex>
          <!-- Calculate -->
          <v-flex xs6>
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block color="green darken-4" dark v-on="on" @click="checkAllFields">Calculate</v-btn>
              </template>
              <span style="font-size: 13px;">Section propreties will be calculated.</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
/* Unit Convertor */
import unitConvertor from "@/_calculators/static/unitConvertor";
/* Types */
import i2r1 from "./irTypes/i2r1";
import p1i2r1 from "./irTypes/p1i2r1";
import p2ir1 from "./irTypes/p2ir1";

export default {
  components: {
    i2r1,
    p1i2r1,
    p2ir1
  },
  props: [
    "inputs",
    "testData",
    "categoryNumber",
    "irType",
    "topImage",
    "noTestData",
    "excludedList",
    "img1",
    "outputs",
    "result1",
    "result2",
    "titles",
    "tooltips"
  ],
  data() {
    return {
      /* Inputs */
      categoryNo: this.categoryNumber,
      system: "metric",
      cats: [],

      /* Results */
      changes: {}
    };
  },
  created() {
    this.createCats();
    this.changeInputs("test", "metric");
  },
  watch: {
    /* Inputs */
    system(val) {
      this.$emit("system", val);
      this.convertUnits(val);
    },

    /* Results */
    result2(val) {
      if (val) {
        this.changePerc();
      }
    }
  },
  methods: {
    /* Inputs */
    createCats() {
      var inputKeys = Object.keys(this.inputs);
      var groups = [];
      for (let i = 1; i <= this.categoryNo; i++) {
        groups.push(inputKeys.filter(key => this.inputs[key].cat === i));
      }
      var res = [];
      groups.forEach(group => {
        var cat = {};
        group.forEach(input => {
          cat[input] = this.inputs[input];
        });
        res.push(cat);
      });
      this.cats = res;
    },
    checkAllFields() {
      var inputKeys = Object.keys(this.inputs);
      /* IF needToCheck: false -> Remove from inputKeys*/
      var notNeedCheck = inputKeys.filter(
        key => this.inputs[key].needToCheck === false
      );
      notNeedCheck.forEach(e => {
        var index = inputKeys.indexOf(e);
        if (index !== -1) {
          inputKeys.splice(index, 1);
        }
      });
      /* Excluded List for combobox */
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }
      var inputValues = inputKeys.map(key => this.inputs[key].value);
      var isOkay = inputValues.every(
        i => i !== null && i !== undefined && i !== ""
      );
      if (isOkay) {
        inputKeys.map(
          key => (this.inputs[key].value = parseFloat(this.inputs[key].value))
        );
        if (this.system === "metric") {
          this.calc_metric();
        } else if (this.system === "imperial") {
          this.calc_imperial();
        }
      } else {
        alert("There are error(s) in the input, please fill out all fields!");
      }
    },
    changeInputs(type, system) {
      var inputKeys = Object.keys(this.inputs);
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }

      if (type === "clear") {
        inputKeys.forEach(input => {
          this.inputs[input].value = null;
        });
      } else if (type === "test") {
        if (this.system === "metric") {
          inputKeys.forEach(input => {
            this.inputs[input].value = this.testData.metric[input];
          });
        } else {
          inputKeys.forEach(input => {
            this.inputs[input].value = this.testData.imperial[input];
          });
        }
      }
    },
    convertUnits(to) {
      var inputKeys = Object.keys(this.inputs);
      if (to === "metric") {
        inputKeys.forEach(key => {
          var value = this.inputs[key].value;
          if (value !== null && value !== "" && value !== undefined) {
            var converted = unitConvertor.convertImpToMetric(
              value,
              this.inputs[key].suffix.imperial
            );
            this.inputs[key].value = converted.v;
          }
        });
      } else if (to === "imperial") {
        inputKeys.forEach(key => {
          var value = this.inputs[key].value;
          if (value !== null && value !== "" && value !== undefined) {
            var converted = unitConvertor.convertMetricToImp(
              value,
              this.inputs[key].suffix.metric
            );
            this.inputs[key].value = converted.v;
          }
        });
      }
    },
    reset() {
      this.changeInputs("clear");
      this.$emit("CLEAR_RESULTS");
    },
    loadTestData() {
      if (this.system === "metric") {
        this.changeInputs("test", "metric");
      } else if (this.system === "imperial") {
        this.changeInputs("test", "imperial");
      }
    },
    calc_metric() {
      this.result = this.$emit("CALC_METRIC");
    },
    calc_imperial() {
      this.result = this.$emit("CALC_IMP");
    },

    /* Results */
    changePerc() {
      var keys = Object.keys(this.result1);

      keys.forEach(key => {
        var r1 = this.result1[key];
        var r2 = this.result2[key];

        var result;
        var sign = null;
        if (r1 && r2) {
          result = (r1 * 100) / r2 - 100;

          if (result > -0.005 && result < 0.005) {
            sign = "swap_horiz";
          } else if (result > 0.005) {
            sign = "trending_up";
          } else if (result < -0.005) {
            sign = "trending_down";
          }
        } else {
          result = "";
        }
        this.changes[key] = {
          result,
          sign
        };
      });
    }
  }
};
</script>

<style scoped>
/* input[type='number'] {
    -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
} */
</style>