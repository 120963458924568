<template>
    <v-layout>
        <v-flex xs12 md4 v-for="(category, i) in cats" :key="i">
            <div class="mr-1 mt-1 elevation-2 grey lighten-3" height="60">
                <v-layout wrap>
                    <v-flex xs9 class="my-3 pl-2">
                        <div>{{titles[i]}}</div>
                    </v-flex>
                    <v-flex xs3>
                        <v-tooltip top max-width="400" color="grey darken-4">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon color="grey darken-4">help</v-icon>
                                </v-btn>
                            </template>
                            <span style="font-size: 13px;">{{tooltips[i]}}</span>
                        </v-tooltip>
                    </v-flex>
                </v-layout>
            </div>
            <v-card class="mr-1 mt-1 elevation-2 " height="320">
                <v-layout class="pa-3">
                    <v-flex>
                        <v-form>
                            <v-layout wrap>
                                <v-flex
                                    v-bind="{[`xs${12 / Math.ceil(Object.keys(category).length / 5)}`]: true}"
                                    v-for="input in category" :key="input.label">
                                    <v-text-field class="numberInputArea" color="blue darken-4" type="number"
                                        v-model="input.value" :label="input.label" hide-details filled required
                                        :suffix="input.suffix[system]"></v-text-field>

                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: ['cats', 'system', 'titles', 'tooltips'],
    }
</script>