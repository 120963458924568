<template>
  <v-layout wrap class="mt-1">
    <v-flex
      xs12
      v-bind="{[i === 3 || i === 4 ? 'md3' : 'md4']: true}"
      v-for="(category, i) in cats"
      :key="i"
    >
      <div v-if="i === 0" class="cyan lighten-1" height="55">
        <v-select
          :items="[{title: 'Mn (+)' , value: 1}, {title: 'Mn (-)', value: 0}]"
          v-model="inputs.moment.value"
          filled
          hide-details
          label="Moment Selection"
          item-text="title"
          item-value="value"
        ></v-select>
      </div>
      <v-card v-else-if="i === 1 || i === 2" class="transparent" flat height="55"></v-card>
      <div class="mr-1 mt-2 elevation-2 grey lighten-3" height="60">
        <v-layout wrap>
          <v-flex xs10 class="my-3 pl-2">
            <div>{{titles[i]}}</div>
          </v-flex>
          <v-flex xs2>
            <v-tooltip top max-width="400" color="grey darken-4">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon color="grey darken-4">help</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 13px;">{{tooltips[i]}}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </div>
      <v-card class="mr-1 mt-1 elevation-2" :height="i < 3 ? '270' : '370'">
        <v-layout class="pa-3">
          <v-flex>
            <v-form>
              <v-layout wrap>
                <v-flex class="pl-1" xs12 v-for="(input, index) in category" :key="index">
                  <div
                    v-if="index === 'ntt'"
                    class="mt-4 mb-2 pl-1"
                    style="font-weight: 500"
                  >Number of Tendons</div>
                  <v-text-field
                    class="numberInputArea"
                    color="blue darken-4"
                    type="number"
                    v-model="input.value"
                    :label="input.label"
                    hide-details
                    filled
                    required
                    :suffix="input.suffix[system]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <!-- Cat 6 -->
    <v-flex xs12 md6>
      <div class="mr-1 mt-2 elevation-2 grey lighten-3" height="60">
        <v-layout wrap>
          <v-flex xs10 class="my-3 pl-2">
            <div>{{titles[5]}}</div>
          </v-flex>
          <v-flex xs2>
            <v-tooltip top max-width="400" color="grey darken-4">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon color="grey darken-4">help</v-icon>
                </v-btn>
              </template>
              <span style="font-size: 13px;">{{tooltips[5]}}</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </div>
      <v-card class="mr-1 mt-1 elevation-2" height="370">
        <v-layout class="pa-3">
          <v-flex>
            <v-form>
              <v-layout wrap>
                <v-flex class="pl-1">
                  <v-layout wrap>
                    <v-flex xs12 v-for="(row, i) in table" :key="i">
                      <v-layout wrap>
                        <v-flex xs5>
                          <div style="font-size: 16px;" class="text-xs-left mt-4">{{row[2]}}</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            class="numberInputArea"
                            color="blue darken-4"
                            type="number"
                            v-model="row[0]"
                            hide-details
                            filled
                            required
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs1>
                          <div class="headline mt-3 mx-3">φ</div>
                        </v-flex>
                        <v-flex xs3>
                          <v-text-field
                            class="numberInputArea"
                            color="blue darken-4"
                            type="number"
                            v-model="row[1]"
                            hide-details
                            filled
                            required
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-form>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["inputs", "cats", "system", "tooltips", "titles", "table"]
};
</script>