<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Superstructure Pretension Concrete"
                  v-model="unitPrices.pretensionConcrete"
                  :rules="numberRules"
                  type="number"
                  :suffix="concreteUnit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Superstructure Composite Concrete"
                  v-model="unitPrices.compositeConcrete"
                  :rules="numberRules"
                  type="number"
                  :suffix="concreteUnit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Superstructure Post-tension Concrete"
                  v-model="unitPrices.postTensionConcrete"
                  :rules="numberRules"
                  type="number"
                  :suffix="concreteUnit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Substructure Concrete"
                  v-model="unitPrices.substructureConcrete"
                  :rules="numberRules"
                  type="number"
                  :suffix="concreteUnit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Pretension Strand"
                  v-model="unitPrices.pretensionStrand"
                  :rules="numberRules"
                  type="number"
                  :suffix="steelUnit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Composite Steel"
                  v-model="unitPrices.compositeSteel"
                  :rules="numberRules"
                  type="number"
                  :suffix="steelUnit"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Reinforcement Steel"
                  v-model="unitPrices.reinforcementSteel"
                  :rules="numberRules"
                  type="number"
                  :suffix="steelUnit"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "UnitPriceComponent",
  props: ["unitPrices", "system"],
  data() {
    return {
      units: {
        metric: {
          concrete: "$/m³",
          steel: "$/ton"
        },
        imperial: {
          concrete: "$/ft³",
          steel: "$/pound"
        }
      },
      numberRules: [v => !!v || "Required", v => v >= 0 || "Must be positive"]
    };
  },
  computed: {
    concreteUnit: function() {
      return this.units[this.system].concrete;
    },
    steelUnit: function() {
      return this.units[this.system].steel;
    }
  }
};
</script>

<style>
</style>