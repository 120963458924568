var o0 = {

    mnp: {
        label1: 'Nominal Moment Strength (+)',
        label2: 'Mn +',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Nominal Moment Strength (+)',
            desc: ''
        }
    },
    mnn: {
        label1: 'Nominal Moment Strength (-)',
        label2: 'Mn -',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Nominal Moment Strength (-)',
            desc: ''
        }
    },
};

var o1 = {

    PTJS: {
        label1: 'Post-Tension Jacking Stress',
        label2: '',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Post-Tension Jacking Stress',
            desc: ''
        }
    },

    PTESAL: {
        label1: 'Post-Tension Effective Stress After Losses',
        label2: '',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Post-Tension Effective Stress After Losses',
            desc: ''
        }
    },

    beta1: {
        label1: 'Concrete Compression Block Constant',
        label2: 'β1',
        unit: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Concrete Compression Block Constant',
            desc: ''
        }
    },

    dp: {
        label1: 'Center of Post-Tension from Extreme Compression Fiber',
        label2: 'dp',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Center of Post-Tension from Extreme Compression Fiber',
            desc: ''
        }
    },
    rhoPu: {
        label1: 'Post-Tension Area Ratio',
        label2: 'ρpu',
        unit: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Post-Tension Area Ratio',
            desc: ''
        }
    },

    d: {
        label1: 'Center of Tension Reinf. from Extreme Compression Fiber',
        label2: 'd',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Center of Tension Reinforcement from Extreme Compression Fiber',
            desc: ''
        }
    },

    w: {
        label1: 'w',
        label2: '',
        unit: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'w',
            desc: ''
        }
    },

    wPrime: {
        label1: "w'",
        label2: '',
        unit: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: "w'",
            desc: ''
        }
    },

    fps: {
        label1: 'Post-tension Stress to be Used in Flexural Resistance Comps',
        label2: 'fps',
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Post-tension Stress to be Used in Flexural Sesistance Comps',
            desc: ''
        }
    },

    a: {
        label1: 'Equivalent Concrete Compression Block Depth',
        label2: 'a',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Equivalent Concrete Compression Block Depth',
            desc: ''
        }
    }
};

export default {
    o0,
    o1
};