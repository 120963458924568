<template>
  <div>
    <v-layout row wrap>
      <v-flex xs12 md6>
        <v-img :src="getImgUrl('fourier.png')" max-height="300" contain></v-img>
      </v-flex>
      <v-flex xs12 md6 class="pr-2">
        <div class="pt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Input Parameters</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">Please fill in the following fields</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <div class="mr-1 elevation-2" height="320">
          <v-layout class="pa-3">
            <v-flex>
              <v-form>
                <v-layout wrap>
                  <v-flex xs11>
                    <v-file-input
                      show-size
                      required
                      label="Acceleration Record file"
                      @change="loadTextFromFile"
                    ></v-file-input>
                  </v-flex>
                  <v-flex xs1 pt-3>
                    <v-dialog v-model="accInfo" width="640">
                      <template v-slot:activator="{on}">
                        <v-btn color="error lighten-1" text v-on="on">HELP</v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          class="headline grey lighten-2 primary-title"
                        >Acceleration File Details</v-card-title>
                        <v-card-text>
                          <v-list disabled>
                            <v-list-item
                              v-for="(item, index) in accelerationHelpItems"
                              :key="index"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="warning"
                            download
                            href="../../northridge.txt"
                            text
                          >Download Sample Record</v-btn>
                          <v-btn color="primary" text @click="accInfo = false">OK</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      v-model="numberOfHeaderLines"
                      readonly
                      hide-details
                      filled
                      required
                      label="Number of header lines to skip"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      v-model="numberOfValuesPerLine"
                      readonly
                      hide-details
                      filled
                      required
                      label="Number of values per line"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      v-model="timeStep"
                      hide-details
                      filled
                      required
                      label="Time step between each measurement (milliseconds)"
                      type="number"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <v-layout class="mt-4 elevation-4">
      <v-flex xs12>
        <apexchart
          v-if="inputSeries"
          height="250"
          type="line"
          :options="chartOptionsInput"
          :series="inputSeries"
        ></apexchart>
      </v-flex>
    </v-layout>
    <!-- Buttons -->
    <v-layout mt-2 wrap>
      <v-flex xs12>
        <v-layout wrap>
          <!-- Load Test Data -->
          <!-- Clear -->
          <!-- Calculate -->
          <v-flex xs12 class="pr-2">
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block color="green darken-4" dark @click="Calculate" v-on="on">Calculate</v-btn>
              </template>
              <span style="font-size: 13px;">DFT will be performed</span>
            </v-tooltip>
          </v-flex>
          <!-- Report -->
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout class="mx-1" row>
      <v-flex xs12>
        <h2>Results</h2>
      </v-flex>
      <v-layout class="mt-4 elevation-4">
        <v-flex xs12>
          <apexchart
            v-if="resultSeries"
            height="250"
            type="line"
            :options="chartOptionsResult"
            :series="resultSeries"
          ></apexchart>
        </v-flex>
      </v-layout>
    </v-layout>
    <!-- Snackbar for errors -->
    <v-snackbar v-model="errorSnack" :timeout="3000" color="red" bottom>
      {{ errorText }}
      <v-btn color="white" text @click="errorSnack = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { forwardAbsoluteTransform } from "@/_calculators/src/discreteFourier/functions";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },
  data() {
    return {
      errorSnack: false,
      errorText: null,
      numberOfHeaderLines: 1,
      numberOfValuesPerLine: 1,
      inputArray: null,
      resultArray: null,
      accInfo: false,
      accelerationHelpItems: [
        {
          icon: "slow_motion_video",
          text: "The unit of accelerations should be meter/second² (not g)."
        },
        {
          icon: "settings_ethernet",
          text: "Each row should contain only one acceleration recording."
        },
        {
          icon: "schedule",
          text: "Time step between each measurement should be constant."
        },
        {
          icon: "format_line_spacing",
          text:
            "If there are any header lines, they should be defined in inputs."
        },
        {
          icon: "cloud_download",
          text: "You may find sample Northridge Earthquake recording below."
        }
      ],
      timeStep: 20,
      chartOptionsInput: {
        chart: {
          id: "input-data-chart",
          zoom: { enabled: false },
          animations: { enabled: false }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 1
        },
        title: {
          text: "Input Acceleration Series",
          align: "center"
        },
        xaxis: {
          type: "numeric",
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          type: "numeric",
          labels: {
            show: true,
            formatter: function(value) {
              return Number(value).toFixed(1);
            }
          }
        },
        tooltip: {
          enabled: false
        }
      },
      chartOptionsResult: {
        chart: {
          id: "result-data-chart",
          zoom: { enabled: true },
          animations: { enabled: false }
        },
        dataLabels: { enabled: false },
        stroke: {
          curve: "straight",
          width: 1
        },
        title: {
          text: "Resultant Fourier Spectrum",
          align: "center"
        },
        xaxis: {
          type: "numeric",
          labels: {
            show: true,
            formatter: function(value) {
              return Number(value).toFixed(2);
            }
          },
          axisTicks: { show: false },
          title: { text: "Hz" }
        },
        yaxis: {
          type: "numeric",
          labels: { show: false },
          axisTicks: { show: false }
        },
        tooltip: {
          enabled: true,
          x: { show: true }
        }
      },
      inputSeries: null,
      resultSeries: null
    };
  },
  methods: {
    loadTextFromFile(ev) {
      const reader = new FileReader();
      // filereader does not support async/await
      // no need for a wrapper for now
      reader.onload = async e => {
        await this.processFileInput(e.target.result);
      };
      if (ev) {
        try {
          reader.readAsText(ev);
        } catch (error) {
          this.errorSnack = true;
          this.errorText = error.message;
        }
      } else {
        this.inputSeries = null;
        this.resultSeries = null;
      }
    },
    Calculate() {
      try {
        if (
          this.inputSeries &&
          this.numberOfHeaderLines &&
          this.numberOfValuesPerLine &&
          this.timeStep
        ) {
          this.resultArray = forwardAbsoluteTransform(
            this.inputArray,
            this.timeStep
          );
          this.resultSeries = [
            {
              name: "results",
              data: this.resultArray
            }
          ];
        } else {
          throw new Error("Please fill in all the input parameters!");
        }
      } catch (error) {
        this.displayError(error.message);
      }
    },
    async processFileInput(fileContents) {
      let tempArray = this.splitLines(fileContents);
      tempArray.splice(0, this.numberOfHeaderLines);
      this.inputArray = tempArray.map(Number);
      this.inputSeries = [
        {
          name: "input",
          data: this.inputArray
        }
      ];
    },

    displayError(message) {
      this.errorSnack = true;
      this.errorText = message;
    },
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    },
    splitLines(t) {
      return t.split(/\r\n|\r|\n/);
    }
  }
};
</script>

<style>
</style>