<template>
  <v-layout wrap v-if="result1">
    <v-flex xs12>
      <v-toolbar class="blue darken-2" dark height="50">
        <v-spacer></v-spacer>
        <div style="font-size: 18px; font-weight: bold; ">RESULTS</div>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-flex>

    <v-layout wrap>
      <v-flex xs12>
        <v-layout>
          <v-flex xs8 class="mt-1">
            <v-card class="elevation-4 mb-4">
              <div class="elevation-2 grey lighten-2" height="60">
                <v-layout wrap>
                  <v-flex xs12 class="my-3 pl-2">
                    <div>First Pull A: Elongation</div>
                  </v-flex>
                </v-layout>
              </div>
              <div class="wrap mt-2 mx-1">
                <v-flex xs12>
                  <table>
                    <tr>
                      <th></th>
                      <th v-for="(dtTitle, index) in dataTableTitle" :key="index">
                        <h2>{{dtTitle.title}}</h2>
                        <h4 v-if="dtTitle[system]">({{dtTitle[system]}})</h4>
                      </th>
                    </tr>
                    <tr v-for="(tableKey,i) in dataTableKeys" :key="i">
                      <td class="td-left">{{dataTableLabels[i]}}</td>
                      <td
                        v-for="(dtTitle, index) in dataTableTitle"
                        :key="index"
                      >{{result1[tableKey][index] | Digits(2) | FixDigits}}</td>
                    </tr>
                  </table>
                </v-flex>
              </div>
              <v-layout>
                <v-flex>
                  <v-layout wrap>
                    <v-flex xs6 v-for="(result, i) in outputs.o1" :key="i" class="px-1">
                      <v-card class="my-1 elevation-4">
                        <resultCard
                          :result="result"
                          :result1="result1"
                          :result2="result2"
                          :changes="changes"
                          :i="i"
                          :system="system"
                        />
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs4 class="mt-1 ml-1">
            <v-card class="elevation-4 pb-4">
              <div class="elevation-2 grey lighten-2" height="60">
                <v-layout wrap>
                  <v-flex xs12 class="my-3 pl-2">
                    <div>Strand Geometry Properties</div>
                  </v-flex>
                </v-layout>
              </div>
              <v-flex xs12 class="px-3" v-for="(result, i) in outputs.o3" :key="i">
                <v-card class="mt-2 elevation-4">
                  <!-- Component -->
                  <resultCard
                    :result="result"
                    :result1="result1"
                    :result2="result2"
                    :changes="changes"
                    :i="i"
                    :system="system"
                  />
                </v-card>
              </v-flex>
            </v-card>
            <v-card class="elevation-4 pb-4">
              <!-- Category 2 -->
              <div class="elevation-2 grey lighten-2 mt-1" height="60">
                <v-layout wrap>
                  <v-flex xs12 class="my-3 pl-2">
                    <div>Second Pull B: Elongation</div>
                  </v-flex>
                </v-layout>
              </div>
              <v-flex xs12 class="px-3" v-for="(result, i) in outputs.o2" :key="i">
                <v-card class="mt-2 elevation-4">
                  <!-- Component -->
                  <resultCard
                    :result="result"
                    :result1="result1"
                    :result2="result2"
                    :changes="changes"
                    :i="i"
                    :system="system"
                  />
                </v-card>
              </v-flex>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import resultCard from "@/_calculators/static/resultCard";
export default {
  components: {
    resultCard
  },
  props: ["outputs", "system", "result1", "result2", "changes"],
  data() {
    return {
      dataTableKeys: ["jackA", "stressB", "stressBC", "stressC", "stressD"],
      dataTableTitle: [
        {
          title: "θ",
          metric: "",
          imperial: ""
        },
        {
          title: "Length",
          metric: "m",
          imperial: "ft"
        },
        {
          title: "Stress",
          metric: "MPa",
          imperial: "psi"
        }
      ],
      dataTableLabels: [
        "Jacking at A - before anchor set",
        "Stress at B",
        "Stress mid of path B-C",
        "Stress at C",
        "Stress at D"
      ]
    };
  }
};
</script>

<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

th {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
  background: #0277bd;
  color: white;
}

.td-left {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}
</style>