var inputs = {
    L: {
        label: 'Span Length',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    },
    N: {
        label: 'Number of Girder',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Girder',
            desc: ''
        }
    },
    wspan: {
        label: 'Span Width',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Width',
            desc: ''
        }
    },
    tws: {
        label: 'Wearing Layer Thickness',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Wearing Surface Thickness',
            desc: ''
        }
    },
    tsidew: {
        label: 'Sidewalk Thickness Avg.',
        value: null,
        cat: 2, //26 karakter
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Sidewalk Average Thickness',
            desc: ''
        }
    },
    wside: {
        label: 'Sidewalk Width',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Sidewalk Width',
            desc: ''
        }
    },
    gammaConc: {
        label: 'Unit Weigth of Reinforced Concrete',
        value: null,
        cat: 1, //30 karakter
        suffix: {
            metric: 'kN/m³',
            imperial: 'kips/ft³'
        },
        description: {
            title: 'Unit Weigth of Reinforced Concrete',
            desc: ''
        }
    },
    gammaConcW: {
        label: 'Unit Weigth of Wearing Layer Concrete    ',
        value: null,
        cat: 2, //39 karakter
        suffix: {
            metric: 'kN/m³',
            imperial: 'kips/ft³'
        },
        description: {
            title: 'Unit Weigth of Wearing Surface Concrete',
            desc: ''
        }
    },
}
export default inputs