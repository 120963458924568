class Calculator {
    constructor(name, main, dbDetails, details) {
        this.name = name;
        this.main = main;
        this.dbDetails = dbDetails;
        this.details = details;
    }
}

class MainProps {
    constructor(no, title, catID) {
        this.no = no;
        this.title = title;
        this.catID = catID;
    }
}

class DashboardDetails {
    constructor(image, shortDesc, subTitle) {
        this.image = image;
        this.shortDesc = shortDesc;
    }
}

class Details {
    constructor(video, longDesc, carousel, example) {
        this.video = video;
        this.longDesc = longDesc;
        this.carousel = carousel;
        this.example = example;
    }
}



module.exports = [
    // Image(.png): /assets/imgs/dashboard/
    /*
        new Calculator(
            'name',
            new MainProps(no, 'title', catID),
            new DashboardDetails(
                'image', 
                'shortDesc',
            )
        ),
     */

    /* concreteGirderGeometry */
    new Calculator(
        'concreteGirderGeometry',
        new MainProps(1, 'Concrete I Girder Properties', 1),
        new DashboardDetails(
            'main_concreteGirderGeometry',
            'To calculate properties of girder such as area, moment of inertia, geometric center and section modulus for both concrete I girder section and composite section with concrete deck.',
        ),
        new Details(
            '1jJm2t7OHU4',
            `
                This calculator will help engineers to compute the needed geometric properties to be used in stress analysis.
                The engineer shall decide on the geometric dimensions of the concrete girder and enter the proper values to the related boxes. Xi dimension is in horizontal, and Yi dimensions are in the vertical direction of the girder. The calculator can also help to compute composite section properties of girder and deck with different material properties. The composite properties will be based on girder material properties. All values shall be positive. The output of this calculator can be used at “Dead Load,” “Live Load on One Interior Girder” and “Minimum Required Number of Strands” calculators.
            `,
            4,
            'concreteGirderGeometry'
        ),
    ),
    /* steelWideFlangeGeometry */
    new Calculator(
        'steelWideFlangeGeometry',
        new MainProps(2, 'Steel Wide Flange Girder Properties', 1),
        new DashboardDetails(
            'main_steelWideFlange',
            'To calculate properties of girder such as area, moment of inertia, geometric center and section modulus for both steel wide flange girder section and composite section with concrete deck.',
        ),
        new Details(
            'yM1rbpcSYyY',
            `
                This calculator will help engineers to compute the needed geometric properties to be
                used in stress analysis. The engineer shall decide on the geometric dimensions of the steel girder
                and enter the proper values to the related boxes. The calculator can also help to compute composite
                section properties of steel girder and concrete deck with different material properties. The
                composite properties will be based on girder material properties. All values shall be positive.
            `,
            4,
            'steelWideFlangeGeometry'
        ),
    ),
    /* selectionOfGirderDepth */
    new Calculator(
        'selectionOfGirderDepth',
        new MainProps(3, 'Selection of Girder Depth and Spacing', 1),
        new DashboardDetails(
            'main_selectionOfGirderDepth',
            'To calculate maximum girder spacing by using span length and girder depth values.',
        ),
        new Details(
            'dz_hVay4vXk',
            `
                This calculator will help engineers to determine the maximum spacing of girders for a
                given span length and girder depth. The output of this calculator can be used at “Dead Load” and
                “Live Load on One Interior Girder” and “Minimum Required Number of Strands” calculators. All
                values shall be positive.
            `,
            4,
            'selectionOfGirderDepth'
        ),
    ),
    /* deadLoad */
    new Calculator(
        'deadLoad',
        new MainProps(4, 'Dead Load', 1),
        new DashboardDetails(
            'main_deadLoad',
            'To calculate maximum moment due to self weight of girder, wet weight of slab(deck) and superimposed dead loads.',
        ),
        new Details(
            '4bL2XgV1vwk',
            `
                This calculator will be helpful to determine the maximum positive moment at the mid-
                span of the girder for three different types of dead load. Girder, slab and superimposed dead loads
                are the three components of the dead load moment. The first two will act on the girder, and the
                third one will act on the composite section. Some of the input can be determined from “Concrete I
                Girder Properties” and “Selection of Girder Depth and Spacing” calculators. The output of this
                calculator can be used at the “Minimum Required Number of Strands” calculator. All values are
                positive.
            `,
            11,
            'deadLoad'
        ),
    ),
    /* liveLoad1 */
    new Calculator(
        'liveLoad1',
        new MainProps(5, 'Total Live Load', 1),
        new DashboardDetails(
            'main_liveLoad1',
            'To calculate maximum positive moment due to live load with impact per lane of bridge.',
        ),
        new Details(
            'U5brb4peKio',
            `
                This calculator will help engineers to compute the maximum lane load moment at the
                mid-span independent of the structural configuration. Two truck models are available for
                computation and custom truck models can be added. All values are positive.
            `,
            13,
            'liveLoad1'
        ),
    ),
    /* liveLoad2 */
    new Calculator(
        'liveLoad2',
        new MainProps(6, 'Live Load on One Interior Girder', 1),
        new DashboardDetails(
            'main_liveLoad2',
            'To calculate total live load on one of the interior girders using live load distribution factor.',
        ),
        new Details(
            '8tYePqZYWs8',
            `
                This calculator is based on the AASHTO approximate method for live load lane moment
                distribution on girders. The output of “Concrete I Girder Properties” and “Selection of Girder Depth
                and Spacing” calculators can be input into proper boxes. The determined live load girder moment
                can be used at the “Minimum Required Number of Strands” calculator. All values are positive.
            `,
            13,
            'liveLoad2'
        ),
    ),
    /* minRequiredNoOfStrands */
    new Calculator(
        'minRequiredNoOfStrands',
        new MainProps(7, 'Minimum Required Number of Strands', 1),
        new DashboardDetails(
            'main_minRequiredNoOfStrands',
            'To make a calculation on the estimation of minimum required number of strands.',
        ),
        new Details(
            '8tYePqZYWs8',
            `
                This calculator computes the minimum number of required strands for the girder.
                Some of the input data can be obtained from the “Concrete I Girder Properties,” “Dead Load” and
                “Live Load on One Interior Girder” calculator. All values are positive.
            `,
            12,
            'minRequiredNoOfStrands'
        ),
    ),
    /* elongationOnStrandTendons */
    new Calculator(
        'elongationOnStrandTendons',
        new MainProps(8, 'Elongation Computations', 1),
        new DashboardDetails(
            'main_elongationOnStrandTendons',
            'To calculate elongations strand tendons for post-tensioned box girder balanced cantilever bridge.',
        ),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to compute the elongations and stresses along a tendon jacked along a path. It reports the stresses, elongations, stress losses, and several other parameters using the inputs on strand geometry and prestressing steel mechanical properties. The calculator can generate a PDF report which summarizes all the inputs and results.cls",
            4,
            'elongationOnStrandTendons'
        ),
    ),



    /* axialOnPileGroups */
    new Calculator(
        'axialOnPileGroups',
        new MainProps(9, 'Axial Load Distribution on Pile Group', 3),
        new DashboardDetails(
            'main_axialOnPileGroups',
            'To calculate maximum axial load on pile and pile coordinates-axial load on individual pile table by using pile number and total vertical load-moment values.',
        ),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to compute the axial load distribution on a pile group, connected with a pile cap. It also compares the maximum axial load on the pile with safe bearing capacity of one pile, and warns the user if safetly limit is exceeded. The safe bearing capacity of a single pile should be calculated by the user and supplied to the calculator, as well as the total vertical load acting on pile group and total moment in both axes. The axial force in each and every pile is also tabulated as an output.",
            3,
            'axialOnPileGroups'
        ),
    ),
    /* blisterDesign */
    new Calculator(
        'blisterDesign',
        new MainProps(11, 'Blister Design', 1),
        new DashboardDetails(
            'main_blisterDesign',
            'To be used for reinforcement computation of blisters under post-tension load.',
        ),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to design blisters, the concrete sections where the post-tensioning strands are jacked and anchored. The calculator returns the design tension loads, rib and blaster reinforcement details, crack control reinforcements, in-plane design forces and curved tendon parameters, and tie details."
            ,
            4,
            'blisterDesign'
        ),
    ),
    /* cellBoxFlexural */
    new Calculator(
        'cellBoxFlexural',
        new MainProps(12, 'Single Cell Box Section - Flexural Resistance', 1),
        new DashboardDetails(
            'main_cellBoxFlexural',
            'To compute flexural resistance for post-tensioned box girder bridges.'
        ),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to calculate the flexural resistance of single cell box sections. It requires material, geometrical, pot-tensioning and reinforcement details and returns the sectional response under positive and negative moments. ",
            3,
            'cellBoxFlexural'
        )
    ),
    new Calculator(
        'discreteFourier',
        new MainProps(13, 'Discrete Fourier Transform', 1),
        new DashboardDetails(
            'main_fourier',
            'To calculate frequencies of a structure based on the acceleration recording.'
        ),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to calculate the modal frequencies of a structure from an acceleration recording. The calculator requires an acceleration recording evenly-spaced in time, and tt uses Fast Fourier Transform (FFT) to convert the input time domain signal to frequency domain. The calculator displays an interactive chart of the resultant frequency spectrum for the user to pinpoint the peaks that correspond to structural frequencies. "
            ,
            3,
            'discreteFourier'
        )
    ),
    new Calculator(
        'liveMoment',
        new MainProps(14, 'Live Load Moment', 1),
        new DashboardDetails(
            'main_liveMoment',
            'To visualize the effect of movement of live load over the bending moment'
        ),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to visualize the change of bending moment along a simply-supported bridge while a design truck passes over it. The user can select a code-based design truck or define a loading scheme of his own (tandem and uniform loads are not supported).",
            2,
            'liveMoment'
        )
    ),
    new Calculator(
        'costEstimation',
        new MainProps(15, 'Cost Estimation', 1),
        new DashboardDetails('main_costEstimation', 'To estimate the total cost of a bridge'),
        new Details(
            '8tYePqZYWs8',
            "This calculator is created to estimate the total cost of different bridge alternatives (namely a prestressed, a post-tensioned, and a composite type) over a user-defined valley and road profile. It uses equations developed by statistical regression analyses and does not employ any structural analyses. It can be used to compare the magnitudes of material quantities (concrete, reinforcement, strands...etc.) and cost breakdowns.",
            8,
            'costEstimation')
    ),

    new Calculator('plateFilletWeld',
        new MainProps(16, 'Plate Girder Fillet Weld', 1),
        new DashboardDetails('main_plateFilletWeld', 'To check minimum fillet weld size based on maximum thickness of parts'),
        new Details('8tYePqZYWs8', 'This calculator is created to check and verify the minimum size of fillet welds on plate girders. The minimum fillet weld size is calculated based on thicknesses of connecting parts.', 3, 'plateFilletWeld')),

    new Calculator('singleCircularColumn',
        new MainProps(17, 'Preliminary Single Circular Bridge Column Design', 3),
        new DashboardDetails('main_circularColumn', 'To perform a preliminary design of a single circular bridge column'),
        new Details('8tYePqZYWs8', 'This calculator is created to perform a preliminary design of a single circular bridge column. It is purely based on engineering practices and does not perform any structural calculations', 3, 'singleCircularColumn')),

    new Calculator('potBearing',
        new MainProps(18, 'Pot Bearing Design', 2),
        new DashboardDetails('main_potBearing', 'To perform a preliminary design of a pot bearing'),
        new Details('8tYePqZYWs8', 'This calculator is created to perform a preliminary design of a single pot bearing.', 3, 'potBearing')),

    new Calculator('simpleInspection',
        new MainProps(19, 'Bridge Inspector', 4),
        new DashboardDetails('main_simpleInspection', 'To perform simple inspection on a bridge'),
        new Details('8tYePqZYWs8', 'This calculator is created to perform inspections on a bridge.', 3, 'potBearing')),

    new Calculator('pierTimeHistory',
        new MainProps(20, 'Pier Time-History', 1),
        new DashboardDetails('main_pierTimeHistory', 'To perform time history analysis on a pier'),
        new Details('8tYePqZYWs8', 'This calculator is created to perform time-history analysis on a bridge pier.', 3, 'pierTimeHistory')),

    new Calculator('simpleSupportedBeam',
        new MainProps(21, 'Simple Beam Analysis', 1),
        new DashboardDetails('main_simpleSupportedBeam', 'To perform simple supported beam analysis.'),
        new Details('8tYePqZYWs8', 'This calculator is created to perform simple supported beam analysis under point and up to first order distributed loads.', 0, 'simpleSupportedBeam')),

    new Calculator('pinDesign',
        new MainProps(22, 'Pin Design', 1),
        new DashboardDetails('main_pinDesign', 'To perform design of pins.'),
        new Details('8tYePqZYWs8', 'This calculator is created to perform designing pins .', 0, 'pinDesign')),

    new Calculator('shapeFactor',
        new MainProps(23, 'Shape Factor', 1),
        new DashboardDetails('main_shapeFactor', 'To calculate shape factor.'),
        new Details('8tYePqZYWs8', 'This calculator is created calculate shape factor for elastomeric bearings .', 0, 'shapeFactor'))
]