<template>
  <div>
    <!-- Metric vs Imperial Radio Boxes -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout>
            <v-flex>
              <v-form>
                <v-layout wrap class>
                  <v-flex xs12>
                    <v-radio-group v-model="system" row>
                      <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <div class="pt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Pier Information</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">Please fill in the following fields</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <div class="elevation-2 mr-1">
          <v-layout class="pa-3">
            <v-flex>
              <v-layout wrap>
                <template v-for="(param, key, index) in inputs">
                  <v-flex xs6 v-if="param.type==='number' && param.cat === 1" :key="index">
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      required
                      filled
                      hide-details
                      :suffix="param.suffix[system]"
                      type="number"
                      v-model="inputs[key].value"
                      :rules="numberRules"
                      :label="param.description.title"
                    ></v-text-field>
                  </v-flex>
                </template>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <div class="pt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Loading Information</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">Please fill in the following fields</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <div class="elevation-2 mr-1">
          <v-layout class="pa-3">
            <v-flex xs9>
              <v-layout wrap>
                <v-flex xs12>
                  <v-radio-group row v-model="inputs.loadingType.value">
                    <template v-for="type in loadingTypes">
                      <v-radio :label="type.text" :value="type.value" :key="type.value"></v-radio>
                    </template>
                  </v-radio-group>
                </v-flex>
                <v-flex ml-3 xs12>
                  <v-layout row>
                    <v-flex xs9>
                      <v-layout>
                        <v-flex xs11>
                          <v-file-input
                            v-if="inputs.loadingType.value ==='acceleration'"
                            show-size
                            required
                            label="Click to Select Acceleration Record File"
                            @change="loadTextFromFile"
                          ></v-file-input>
                        </v-flex>
                        <v-flex xs1 pt-3 v-if="inputs.loadingType.value ==='acceleration'">
                          <v-dialog v-model="accInfo" width="640">
                            <template v-slot:activator="{on}">
                              <v-btn color="error lighten-1" text v-on="on">HELP</v-btn>
                            </template>
                            <v-card>
                              <v-card-title
                                class="headline grey lighten-2 primary-title"
                              >Acceleration File Details</v-card-title>
                              <v-card-text>
                                <v-list disabled>
                                  <v-list-item
                                    v-for="(item, index) in accelerationHelpItems"
                                    :key="index"
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="item.icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.text"></v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                              </v-card-text>
                              <v-divider></v-divider>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="warning"
                                  download
                                  href="../../northridge.txt"
                                  text
                                >Download Sample Record</v-btn>
                                <v-btn color="primary" text @click="accInfo = false">OK</v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                      <template
                        v-for="(param, key, index) in inputs[inputs.loadingType.value].parameters"
                      >
                        <v-flex xs12 v-if="param.type==='number'" :key="index">
                          <v-text-field
                            class="numberInputArea"
                            color="blue darken-4"
                            required
                            filled
                            hide-details
                            :suffix="param.suffix[system]"
                            type="number"
                            v-model="inputs[inputs.loadingType.value].parameters[key].value"
                            :rules="numberRules"
                            :label="param.description.title"
                          ></v-text-field>
                        </v-flex>
                      </template>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs ml-4>
              <v-img contain max-height="200" :src="loadTypeImage"></v-img>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 v-if="inputs.loadingType.value === 'acceleration'">
              <apexchart
                v-if="inputSeries"
                height="250"
                type="line"
                :options="chartOptionsInput"
                :series="inputSeries"
              ></apexchart>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <!-- BUTTONS -->
    <v-layout mt-2 wrap>
      <v-flex xs12>
        <v-layout wrap>
          <!-- Calculate -->
          <v-flex xs12 class="pr-2">
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn
                  block
                  color="green lighten-4"
                  v-on="on"
                  :loading="running"
                  :disabled="running"
                  @click="runAnalysis"
                >
                  Calculate
                  <template v-slot:loader>
                    <span>Calculating...</span>
                    <span class="custom-loader">
                      <v-icon light>hourglass_full</v-icon>
                    </span>
                  </template>
                </v-btn>
              </template>
              <span style="font-size: 13px;">The calculations will be started.</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <!-- Results -->
    <v-layout v-if="showResults" wrap>
      <v-flex xs12>
        <v-toolbar class="blue darken-2" dark height="50">
          <v-spacer></v-spacer>
          <div style="font-size: 18px; font-weight: bold;">RESULTS</div>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
      <v-flex mt-4 xs12 offset-xs-6>
        <v-select :items="resultsList" label="Output Parameter" outlined v-model="selectedResult"></v-select>
      </v-flex>
      <v-flex xs12>
        <apexchart height="250" type="line" :options="chartOptionsResult" :series="chartData"></apexchart>
      </v-flex>
      <v-flex xs12>
        <p class="text-center">{{ maxValueText }}</p>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar.visible" multi-line :timeout="6000" color="red" bottom>
      {{ snackbar.text }}
      <v-btn color="white" text @click="snackbar.visible = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import {
  inputs,
  loadingTypes
} from "@/_calculators/src/pierTimeHistory/inputs";
import VueApexCharts from "vue-apexcharts";
import calculate from "@/_calculators/src/pierTimeHistory/functions";

export default {
  name: "pierTimeHistory",
  components: {
    VueApexCharts
  },
  data() {
    return {
      system: "metric",
      inputSeries: null,
      showResults: false,
      dt: 0.05,
      results: null,
      accInfo: false,
      maxValue: 0,
      resultsList: [
        { text: "Displacement (m)", value: "displacements" },
        { text: "Shear Force (kN)", value: "shears" },
        { text: "Moment (kNm)", value: "moments" }
      ],
      accelerationHelpItems: [
        {
          icon: "slow_motion_video",
          text: "The unit of accelerations should be meter/second² (not g)."
        },
        {
          icon: "settings_ethernet",
          text: "Each row should contain only one acceleration recording."
        },
        {
          icon: "schedule",
          text: "Time step between each measurement should be constant."
        },
        {
          icon: "format_line_spacing",
          text:
            "If there are any header lines, they should be defined in inputs."
        },
        {
          icon: "cloud_download",
          text: "You may find sample Northridge Earthquake recording below."
        }
      ],
      selectedResult: "displacements",
      chartOptionsResult: {
        chart: {
          id: "output-data-chart",
          zoom: { enabled: false },
          animations: { enabled: false }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 1
        },
        xaxis: {
          type: "numeric",
          title: {
            text: "seconds"
          },
          labels: {
            show: true,
            formatter: function(v) {
              return Number(v);
            }
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          type: "numeric",
          labels: {
            show: true,
            formatter: function(value) {
              return Number(value).toFixed(3);
            }
          }
        },
        tooltip: {
          enabled: false
        }
      },
      chartOptionsInput: {
        chart: {
          id: "input-data-chart",
          zoom: { enabled: false },
          animations: { enabled: false }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 1
        },
        title: {
          text: "Input Acceleration Series",
          align: "center"
        },
        xaxis: {
          type: "numeric",
          labels: {
            show: false
          },
          axisTicks: {
            show: false
          }
        },
        yaxis: {
          type: "numeric",
          labels: {
            show: true,
            formatter: function(value) {
              return Number(value).toFixed(1);
            }
          }
        },
        tooltip: {
          enabled: false
        }
      },
      snackbar: {
        visible: false,
        text: ""
      },
      numberRules: [
        v => !!v || "Value is required",
        v => (v && v >= 0) || "Value must be greater than 0"
      ],
      inputs: inputs,
      running: false,
      loadingTypes: loadingTypes,
      images: [
        {
          key: "acceleration",
          name: "pierTimeHistory_2.png"
        },
        {
          key: "explosion",
          name: "pierTimeHistory_1.png"
        }
      ]
    };
  },

  computed: {
    loadTypeImage() {
      const v = this.images.find(
        ({ key }) => key === this.inputs.loadingType.value
      );
      return this.getImgUrl(v.name);
    },
    chartData() {
      return [
        {
          name: "result",
          data: this.results[this.selectedResult]
        }
      ];
    },
    maxValueText() {
      const max = Math.max(...this.results[this.selectedResult]);
      const min = Math.min(...this.results[this.selectedResult]);
      const r = Math.abs(max) > Math.abs(min) ? max : min;
      const t = this.results[this.selectedResult].indexOf(r) * this.results.dt;
      return `The maximum is ${r.toFixed(3)} at ${t.toFixed(3)} seconds`;
    }
  },
  methods: {
    async runAnalysis() {
      try {
        this.verifyInputs();
        this.clearResults();
        this.running = true;
        const res = await calculate(this.system, this.inputs);
        this.results = res;
        this.chartOptionsResult.xaxis.labels.formatter = function(v) {
          return (Number(v) * res.dt).toFixed(2);
        };
        this.showResults = true;
        this.running = false;
      } catch (error) {
        this.displayError(error.message);
        this.running = false;
      }
    },
    verifyInputs() {
      if (inputs.loadingType.value === "acceleration") {
        if (!this.inputSeries) {
          throw new Error("Please select an acceleration recording");
        }
        if (
          !this.inputs.acceleration.parameters.dt.value
        ) {
          throw new Error("Please fill in all the input parameters");
        }
      } else {
        if (
          !this.inputs.explosion.parameters.p0.value ||
          !this.inputs.explosion.parameters.T.value
        ) {
          throw new Error("Please fill in all the input parameters");
        }
      }
    },
    clearResults() {
      this.showResults = false;
      this.displacementSeries = null;
    },
    loadTextFromFile(ev) {
      const reader = new FileReader();
      // FileReader does not support async/await
      // no need for a wrapper for now
      reader.onload = async e => {
        await this.processFileInput(e.target.result);
        this.inputSeries = [
          {
            name: "inputs",
            data: this.inputs.acceleration.parameters.accelerations.value
          }
        ];
      };
      if (ev) {
        try {
          reader.readAsText(ev);
        } catch (error) {
          this.displayError(error.message);
        }
      } else {
        this.inputSeries = null;
      }
    },
    async processFileInput(fileContents) {
      try {
        const tempArray = this.splitLines(fileContents);
        tempArray.splice(0, this.inputs.acceleration.parameters.headerLines);
        this.inputs.acceleration.parameters.accelerations.value = tempArray.map(
          Number
        );
      } catch (error) {
        this.displayError(error.message);
      }
    },
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    },
    splitLines(t) {
      return t.split(/\r\n|\r|\n/);
    },
    displayError(errorText) {
      this.snackbar.text = errorText;
      this.snackbar.visible = true;
    }
  }
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>