var inputs = {

    L: {
        label: 'Span Length',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    },

    Mmax: {
        label: 'Max. Live Load Moment',
        value: null,
        cat: 1,
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Maximum Live Load Moment',
            desc: ''
        }
    },


    Ag: {
        label: 'Area of Girder',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Girder',
            desc: ''
        }
    },
    Ig: {
        label: 'Girder Moment of Inertia',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm⁴',
            imperial: 'in⁴'
        },
        description: {
            title: 'Girder Moment of Inertia',
            desc: ''
        }
    },

    d: {
        label: 'Girder Depth , d',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Depth',
            desc: ''
        }
    },

    yb: {
        label: 'Geometric Center From Bottom',
        value: null,
        cat: 2, //35 karakter
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Geometric Center From Bottom',
            desc: ''
        }
    },

    ts: {
        label: 'Slab Thickness , ts',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Slab Thickness',
            desc: ''
        }
    },

    s: {
        label: 'Girder Spacing ,s ',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Spacing',
            desc: ''
        }
    },

    Eg: {
        label: 'Girder Modulus of Elasticity',
        value: null,
        cat: 1, //28 karakter
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Girder Modulus of Elasticity',
            desc: ''
        }
    },

    Es: {
        label: 'Slab Modulus of Elasticity',
        value: null,
        cat: 1, //26 karakter
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Slab Modulus of Elasticity',
            desc: ''
        }
    },

    lane: {
        label: 'Lane Number',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Lane Number',
            desc: ''
        }
    }
};
export default inputs