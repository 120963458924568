export class InputParameters {
    constructor(beamLength, pointLoads, distributedLoads) {
        this.beamLength = beamLength;
        this.pointLoads = pointLoads;
        this.distributedLoads = distributedLoads;
    }
}
export class PointLoad {
    constructor(distance, load) {
        this.distance = Number(distance);
        this.load = Number(load);
    }
    clone() {
        return new PointLoad(this.distance, this.load);
    }

    get key() {
        return this.distance + "-" + this.load;
    }
}

export class DistributedLoad {
    constructor(startDistance, endDistance, startLoad, endLoad) {
        this.startDistance = Number(startDistance);
        this.endDistance = Number(endDistance);
        this.startLoad = Number(startLoad);
        this.endLoad = Number(endLoad);

        if (this.startDistance > this.endDistance)
            throw "Start distance should be less than end distance!";

        if (this.startDistance < 0 || this.endDistance < 0) {
            throw "Start and end distances should be greater than zero!"
        }
    }
    // key for unique data table index
    get key() {
        return this.startDistance + "-" + this.endDistance;
    }
    // equivalent point load
    get equivalentLoad() {
        return (this.startLoad + this.endLoad) / 2 * (this.endDistance - this.startDistance);
    }
    // equivalent point load application point from left support
    get equivalentDistance() {
        const e1 = (this.endDistance - this.startDistance) / 3;
        const e2 = (2 * this.startLoad + this.endLoad) / (this.startLoad + this.endLoad);
        return this.endDistance - (e1 * e2);
    }

    clone() {
        return new DistributedLoad(this.startDistance, this.endDistance, this.startLoad, this.endLoad);
    }

    getValueAt(distance) {
        const m = (this.startLoad - this.endLoad) / (this.startDistance - this.endDistance);
        const n = this.startLoad - m * this.startDistance;
        return m * distance + n;
    }
}