function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var gammaConc = h('gammaConc'),
        L = h('L'),
        wspan = h('wspan'),
        wside = h('wside'),
        tws = h('tws'),
        tsidew = h('tsidew'),
        gammaConcW = h('gammaConcW'),
        N = h('N');

    // Metric Calculations
    function metric() {
        wWear = tws * (wspan - 2 * wside) * L * gammaConcW / N / L
        wBar = 4 * 30 / L / N
        wSide = tsidew * L * wside * 2 * gammaConc / N / L
        wFence = 0.167 * L * 24 / N / L
        R.wsDL = wWear + wBar + wSide + wFence
        R.MsDL = R.wsDL * L * L * 0.125;

    }

    // Imperial Calculations
    function imperial() {
        wWear = tws * (wspan - 2 * wside) * L * gammaConcW / N / L
        wBar = 27 / L / N
        wSide = tsidew * L * wside * 2 * gammaConc / N / L
        wFence = 1.777 * L * 0.16 / N / L
        R.wsDL = wWear + wBar + wSide + wFence
        R.MsDL = R.wsDL * L * L * 0.125;


    }
    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

}

/* PASTE above */
module.exports = {
    calculate
}