<template>
  <div>
    <v-layout mx-3>
      <v-flex xs-12>
          <v-container>
            <v-row>
              <v-text-field
                v-model="editedNews.title"
                :rules="newsTitleRules"
                :counter="100"
                label="News Title"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-textarea
                v-model="editedNews.content"
                label="News Content"
                :rules="newsContentRules"
                :counter="1000"
                required
              ></v-textarea>
            </v-row>
            <v-row>
              <v-text-field v-model="editedNews.path" label="News Path" required></v-text-field>
            </v-row>
            <v-row>
              <v-col>
                <v-menu
                  ref="newsDateMenu"
                  v-model="newsDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="editedNews.newsDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="editedNews.dateText" label="News Date" readonly v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="editedNews.dateText" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="newsDateMenu = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="$refs.newsDateMenu.save(editedNews.dateText)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox v-model="editedNews.display" label="Display News on App Bar"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebaseInit";

export default {
  name: "AddNewsComponent",
  props: ["editedNews"],
  data() {
    return {
      newsValid: false,
      newsTitleRules: [
        v => !!v || "Title is required",
        v => v.length >= 10 || "Title should be at least 10 characters",
        v => v.length <= 100 || "Title should be at most 100 characters"
      ],
      newsContentRules: [
        v => !!v || "Content is required",
        v => v.length >= 20 || "Content should be at least 20 characters",
        v => v.length <= 1000 || "Content should be at most 1000 characters"
      ],
      newsDateMenu: false
    };
  }
};
</script>
