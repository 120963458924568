<template>
  <div>
    <!-- Top Image -->
    <v-layout row wrap v-if="topImage1 && topImage2">
      <v-flex xs6>
        <v-img :src="getImgUrl(topImage1)" max-height="250" contain></v-img>
      </v-flex>
      <v-flex xs6>
        <v-img :src="getImgUrl(topImage2)" max-height="250" contain></v-img>
      </v-flex>
    </v-layout>
    <v-layout row wrap v-if="topImage">
      <v-flex xs12>
        <v-img :src="getImgUrl(topImage)" max-height="300" contain></v-img>
      </v-flex>
    </v-layout>
    <!-- Metric vs Imperial Radio Boxes -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout wrap>
            <v-flex xs12>
              <v-radio-group v-model="system" row>
                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <!-- Inputs -->
    <component
      :is="inputType"
      :cats="cats"
      :system="system"
      :img1="img1"
      :img2="img2"
      :inputs="inputs"
      :titles="titles"
      :tooltips="tooltips"
      :table="table"
    ></component>
    <v-layout mt-2 wrap>
      <v-flex xs12>
        <v-layout wrap>
          <!-- Load Test Data -->
          <v-flex xs2>
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="loadTestData" v-if="!noTestData">Test Data</v-btn>
              </template>
              <span style="font-size: 13px;">Default values will be filled.</span>
            </v-tooltip>
          </v-flex>
          <!-- Clear -->
          <v-flex xs2 class="px-2">
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="reset">Clear</v-btn>
              </template>
              <span style="font-size: 13px;">All values will be erased.</span>
            </v-tooltip>
          </v-flex>
          <!-- Calculate -->
          <v-flex xs4 class="pr-2">
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block color="green darken-4" dark v-on="on" @click="checkAllFields">Calculate</v-btn>
              </template>
              <span style="font-size: 13px;">Section propreties will be calculated.</span>
            </v-tooltip>
          </v-flex>
          <!-- Report -->
          <v-flex xs4>
            <v-btn block color="blue lighten-4" @click="createReport">REPORT</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
  </div>
</template>

<script>
/* Unit Convertor */
import unitConvertor from "@/_calculators/static/unitConvertor";
/* Input Types */
import c5 from "./inputTypes/c5";
import cats3simple from "./inputTypes/cats3simple";
import c4simple from "./inputTypes/c4simple";
import c3_combobox from "./inputTypes/c3_combobox";
import c2_simple from "./inputTypes/c2_simple";
import c2i1simple from "./inputTypes/c2i1simple";

export default {
  components: {
    cats3simple,
    c3_combobox,
    c2_simple,
    c4simple,
    c2i1simple,
    c5
  },
  props: [
    "inputs",
    "testData",
    "categoryNumber",
    "inputType",
    "topImage",
    "topImage1",
    "topImage2",
    "noTestData",
    "excludedList",
    "img1",
    "img2",
    "titles",
    "tooltips",
    "table",
  ],
  data() {
    return {
      categoryNo: this.categoryNumber,
      system: "metric",
      cats: []
    };
  },
  created() {
    this.createCats();
    this.changeInputs("test", "metric");
  },
  watch: {
    system(val) {
      this.$emit("system", val);
      this.convertUnits(val);
    }
  },
  methods: {
    createCats() {
      var inputKeys = Object.keys(this.inputs);
      var groups = [];
      for (let i = 1; i <= this.categoryNo; i++) {
        groups.push(inputKeys.filter(key => this.inputs[key].cat === i));
      }
      var res = [];
      groups.forEach(group => {
        var cat = {};
        group.forEach(input => {
          cat[input] = this.inputs[input];
        });
        res.push(cat);
      });
      this.cats = res;
    },

    checkInputs() {
      var inputKeys = Object.keys(this.inputs);
      /* IF needToCheck: false -> Remove from inputKeys*/
      var notNeedCheck = inputKeys.filter(
        key => this.inputs[key].needToCheck === false
      );
      notNeedCheck.forEach(e => {
        var index = inputKeys.indexOf(e);
        if (index !== -1) {
          inputKeys.splice(index, 1);
        }
      });

      /* Excluded List for combobox */
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }
      var inputValues = inputKeys.map(key => this.inputs[key].value);
      var isOkay = inputValues.every(
        i => i !== null && i !== undefined && i !== ""
      );
      return isOkay;
    },
    
    checkAllFields() {
      // for some reason, calling checkInputs here does not work; the results section does not become visible even though the results are calculated
      var inputKeys = Object.keys(this.inputs);
      /* IF needToCheck: false -> Remove from inputKeys*/
      var notNeedCheck = inputKeys.filter(
        key => this.inputs[key].needToCheck === false
      );
      notNeedCheck.forEach(e => {
        var index = inputKeys.indexOf(e);
        if (index !== -1) {
          inputKeys.splice(index, 1);
        }
      });

      /* Excluded List for combobox */
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }
      var inputValues = inputKeys.map(key => this.inputs[key].value);
      var isOkay = inputValues.every(
        i => i !== null && i !== undefined && i !== ""
      );
      if (isOkay) {
        inputKeys.map(
          key => (this.inputs[key].value = parseFloat(this.inputs[key].value))
        );
        if (this.system === "metric") {
          this.calc_metric();
        } else if (this.system === "imperial") {
          this.calc_imperial();
        }
      } else {
        this.$emit("INPUT_MISSING");
      }
    },
    changeInputs(type, system) {
      var inputKeys = Object.keys(this.inputs);
      if (this.excludedList) {
        this.excludedList.forEach(q => {
          var index = inputKeys.findIndex(v => v === q);
          inputKeys.splice(index, 1);
        });
      }

      if (type === "clear") {
        inputKeys.forEach(input => {
          this.inputs[input].value = null;
        });
      } else if (type === "test") {
        if (this.system === "metric") {
          inputKeys.forEach(input => {
            this.inputs[input].value = this.testData.metric[input];
          });
        } else {
          inputKeys.forEach(input => {
            this.inputs[input].value = this.testData.imperial[input];
          });
        }
      }
    },
    convertUnits(to) {
      var inputKeys = Object.keys(this.inputs);
      if (to === "metric") {
        inputKeys.forEach(key => {
          var value = this.inputs[key].value;
          if (value !== null && value !== "" && value !== undefined) {
            var converted = unitConvertor.convertImpToMetric(
              value,
              this.inputs[key].suffix.imperial
            );
            this.inputs[key].value = converted.v;
          }
        });
      } else if (to === "imperial") {
        inputKeys.forEach(key => {
          var value = this.inputs[key].value;
          if (value !== null && value !== "" && value !== undefined) {
            var converted = unitConvertor.convertMetricToImp(
              value,
              this.inputs[key].suffix.metric
            );
            this.inputs[key].value = converted.v;
          }
        });
      }
    },
    reset() {
      this.changeInputs("clear");
      this.$emit("CLEAR_RESULTS");
    },
    createReport() {
      if (this.checkInputs()) {
        this.$emit("GENERATE_REPORT");
      }
      else {
        this.$emit("INPUT_MISSING");
      }
    },
    loadTestData() {
      this.$emit("LOAD_TEST", this.system);
      if (this.system === "metric") {
        this.changeInputs("test", "metric");
      } else if (this.system === "imperial") {
        this.changeInputs("test", "imperial");
      }
    },
    calc_metric() {
      this.result = this.$emit("CALC_METRIC");
    },
    calc_imperial() {
      this.result = this.$emit("CALC_IMP");
    },
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    }
  }
};
</script>

<style>
</style>