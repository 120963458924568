<template>
  <div>
    <v-layout wrap>
      <v-flex xs2>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout wrap>
            <v-flex xs12>
              <v-radio-group v-model="system" row>
                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step editable :complete="step>1" step="1">Station Data</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable :complete="step>2" step="2">Unit Prices</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step editable step="3">
          Settings
          <small>Optional</small>
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="pl-0">
          <v-card class="elevation-0">
            <v-card-text>
              <StationDataComponent
                :stations="stations"
                :system="system"
                @update-stations="updateStations"
                @update-chart="updateChart"
              ></StationDataComponent>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="step++">Continue</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2" class="pl-0">
          <v-card class="elevation-0">
            <v-card-text>
              <UnitPriceComponent :unitPrices="unitPrices" :system="system"></UnitPriceComponent>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="step--">Back</v-btn>
              <v-btn color="primary" @click="step++">Continue</v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
      <v-stepper-content step="3" class="pl-0">
        <v-card class="elevation-0">
          <v-card-text>
            <OptionsComponent :options="options" :system="system"></OptionsComponent>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="step--">Back</v-btn>
            <v-btn color="primary" @click="runAnalysis">Run Analysis</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper>
    <v-layout class="mt-4">
      <v-flex xs12>
        <ValleyChartComponent v-if="stations.length>=3" :stations="stations" :key="chartKey"></ValleyChartComponent>
        <v-alert color="primary" v-else dense text>
          Please add at least 3 stations and click refresh chart button
          <v-icon small color="green">refresh</v-icon>above the station table to update the valley geometry
        </v-alert>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 ref="resultEl">
        <v-toolbar v-show="results.length>0" class="blue">
          <v-spacer></v-spacer>
          <div class="headline">Results (in {{ suffix }})</div>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
      <v-flex xs12 v-show="results.length>0">
        <v-subheader>{{ unitString }}</v-subheader>
      </v-flex>

      <v-flex xs12 v-if="results.length>0">
        <v-tabs vertical>
          <v-tab>Pretension</v-tab>
          <v-tab>Composite</v-tab>
          <v-tab>Post-Tension</v-tab>
          <v-tab>Comparison</v-tab>
          <v-tab>Visualization</v-tab>
          <v-tab-item v-for="n in 3" :key="n">
            <AlternativeDisplayComponent :alternative="results[n-1]"></AlternativeDisplayComponent>
          </v-tab-item>
          <v-tab-item>
            <AlternativeChartComponent :alternatives="results"></AlternativeChartComponent>
          </v-tab-item>
          <v-tab-item>
            <AlternativeVisualizationComponent :stationList="stations" :alternativeList="results"></AlternativeVisualizationComponent>
          </v-tab-item>
        </v-tabs>
        <v-btn block color="primary lighten-3" @click="generateReport">Generate Report</v-btn>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      bottom
      multi-line
      :timeout="snackbar.timeout"
    >
      {{snackbar.text}}
      <v-btn dark text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import StationDataComponent from "@/components/costEstimation/StationDataComponent";
import OptionsComponent from "@/components/costEstimation/OptionsComponent";
import UnitPriceComponent from "@/components/costEstimation/UnitPriceComponent";
import ValleyChartComponent from "@/components/costEstimation/ValleyChartComponent";
import AlternativeDisplayComponent from "@/components/costEstimation/AlternativeDisplayComponent";
import AlternativeChartComponent from "@/components/costEstimation/AlternativeChartComponent";
import AlternativeVisualizationComponent from "@/components/costEstimation/AlternativeVisualizationComponent";
import Calculate from "@/_calculators/src/costEstimation/functions";
import goTo from "vuetify/es5/services/goto";
import { GenerateCostReport } from "@/_calculators/src/shared/report.js";

import {
  Station,
  CostEstimationOptions,
  UnitPrices
} from "@/_calculators/src/costEstimation/inputs";

export default {
  name: "costEstimation",
  components: {
    StationDataComponent,
    OptionsComponent,
    UnitPriceComponent,
    ValleyChartComponent,
    AlternativeDisplayComponent,
    AlternativeChartComponent,
    AlternativeVisualizationComponent
  },
  data() {
    return {
      system: "metric",
      results: [],
      stations: [],
      selectedResult: null,
      chartKey: 0,
      step: 1,
      suffixes: {
        metric: "meters",
        imperial: "feet"
      },
      options: new CostEstimationOptions(40, 80, 30, 12),
      unitPrices: new UnitPrices(50, 50, 60, 40, 2000, 1600, 1000),
      snackbar: {
        show: false,
        color: "success",
        text: "Success",
        timeout: 3000
      }
    };
  },
  methods: {
    runAnalysis() {
      if (this.stations.length < 3) {
        this.showSnackbar("error", "At least 3 stations are required");
      } else {
        if (this.VerifyInputs()) {
          try {
            const result = Calculate(
              this.stations,
              this.options,
              this.unitPrices,
              this.system
            );
            this.results = result;
            goTo(this.$refs.resultEl, {
              duration: 300,
              offset: 0,
              easing: "easeInOutCubic"
            });
          } catch (error) {
            this.showSnackbar("error", error.message);
          }
        } else {
          this.showSnackbar(
            "error",
            "All settings parameters should be positive"
          );
        }
      }
    },
    updateStations(newStations) {
      this.stations = newStations;
    },
    updateChart() {
      this.chartKey++;
    },
    VerifyInputs() {
      let verified = false;
      if (
        this.options.maxPretensionSpan !== "" &&
        this.options.maxCompositeSpan !== "" &&
        this.options.maxPierheight !== "" &&
        this.options.deckWidth !== ""
      ) {
        if (
          this.options.maxPretensionSpan > 0 &&
          this.options.maxCompositeSpan > 0 &&
          this.options.maxPierHeight > 0 &&
          this.options.deckWidth > 0
        ) {
          verified = true;
        }
      }
      return verified;
    },
    generateReport() {
      GenerateCostReport(
        this.stations,
        this.unitPrices,
        this.results,
        this.system
      );
    },
    showSnackbar(color, text) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.show = true;
    }
  },
  computed: {
    suffix: function() {
      return this.suffixes[this.system];
    },
    unitString: function() {
      if (this.system === "metric") {
        return "Concrete quantity is in m³ and all other quantities are in tons.";
      } else {
        return "Concrete quantity is in ft³ and all other quantities are in pounds.";
      }
    }
  }
};
</script>

<style>
</style>