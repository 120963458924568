var outputs1 = {

    pu: {
        label: 'Pu',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Design Tension Load',
            desc: ''
        }
    },

    FuInplane: {
        label: 'Fu',
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    Vr: {
        label: 'Vr',
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    reqRein1: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    reqRein2: {
        label: '',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    FuOutPlane: {
        label: 'Fu',
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    outplaneMsg: {
        label: '',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    crackCntrl1: {
        label: '',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    crackCntrl2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    mu: {
        label: 'Mu',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    compRein1: {
        label: 'Computed Reinforcement',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Computed Reinforcement',
            desc: ''
        }
    },

    compRein2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    minReqRein1: {
        label: 'Minimum Required Reinforcement',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Minimum Required Reinforcement',
            desc: ''
        }
    },

    minReqRein2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    expRein1: {
        label: 'As',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Expected Reinforcement',
            desc: ''
        }
    },

    expRein2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    tensRein1: {
        label: 'An',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Tension Reinforcement',
            desc: ''
        }
    },

    tensRein2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    outBlisterMsg: {
        label: '',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    reqTieRein1: {
        label: 'Ah',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Required Tie Reinforcement',
            desc: ''
        }
    },

    reqTieRein2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    reqMainRein1: {
        label: 'As',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Required Main Reinforcement',
            desc: ''
        }
    },

    reqMainRein2: {
        label: 'φ',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },

    beta1: {
        label: 'β1',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Concrete Strength Factor',
            desc: ''
        }
    },

    c: {
        label: 'Compression Block c',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Compression Block c',
            desc: ''
        }
    },

    a: {
        label: 'a',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    phiMn: {
        label: 'φMn',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    Vn02: {
        label: 'Vn (0.2 fc b d)',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    Vn55: {
        label: 'Vn (5.5 b d)',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    phiVn: {
        label: 'φVn',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: '',
            desc: ''
        }
    },



};

var o0 = {
    pu: {
        label1: 'Design Tension Load',
        label2: 'Pu',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Design Tension Load',
            desc: ''
        }
    },
}

var o1 = {
    FuInplane: {
        label1: 'Fu',
        label2: '',
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: 'Fu',
            desc: ''
        }
    },

    Vr: {
        label1: 'Vr',
        label2: '',
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: 'Vr',
            desc: ''
        }
    },
}

var o2 = {
    FuOutPlane: {
        label1: 'Fu',
        label2: '',
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: 'Fu',
            desc: ''
        }
    },
}

var o3 = {
    mu: {
        label1: 'Mu',
        label2: '',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Mu',
            desc: ''
        }
    },

    beta1: {
        label1: 'Concrete Strength Factor',
        label2: 'β1',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Concrete Strength Factor',
            desc: ''
        }
    },

    c: {
        label1: 'Compression Block',
        label2: 'c',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Compression Block c',
            desc: ''
        }
    },

    a: {
        label1: 'a',
        label2: '',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'a',
            desc: ''
        }
    },

    phiMn: {
        label1: 'φMn',
        label2: '',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'φMn',
            desc: ''
        }
    },
}

var o4 = {

    Vn02: {
        label1: 'Vn (0.2 fc b d)',
        label2: '',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Vn (0.2 fc b d)',
            desc: ''
        }
    },

    Vn55: {
        label1: 'Vn (5.5 b d)',
        label2: '',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Vn (5.5 b d)',
            desc: ''
        }
    },

    phiVn: {
        label1: 'φVn',
        label2: '',
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'φVn',
            desc: ''
        }
    }
}

var oTable = [
    ['compRein1', 'compRein2'],
    ['minReqRein1', 'minReqRein2'],
    ['expRein1', 'expRein2'],
    ['tensRein1', 'tensRein2'],
    ['reqTieRein1', 'reqTieRein2'],
    ['reqMainRein1', 'reqMainRein2']
]

var message = [
    'outplaneMsg',
    'outBlisterMsg'
]

export default {
    outputs1,
    o0,
    o1,
    o2,
    o3,
    o4,
    oTable,
    message
};