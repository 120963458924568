<template>
    <v-layout wrap v-if="result1">
        <v-flex xs12>
            <v-toolbar class="blue darken-2" dark height="50">
                <v-spacer></v-spacer>
                <div style="font-size: 18px; font-weight: bold; ">RESULTS</div>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-flex>
        <v-layout wrap>
            <v-flex xs12 class="mt-1">
                <v-flex xs4 offset-xs4 class="px-3" v-for="(result, i) in outputs.o0" :key="i">
                    <v-card class="mt-2 elevation-4">
                        <!-- Component -->
                        <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                            :system="system" />
                    </v-card>
                </v-flex>
            </v-flex>
            <!-- Category 1 -->
            <v-flex xs4 class="pl-3">
                <v-card class="elevation-2" height="100%">

                    <div class="elevation-2 grey lighten-2 mt-2" height="60">
                        <v-layout wrap>
                            <v-flex xs12 class="my-3 pl-2">
                                <div>Curved Tendon - Inplane Design Force</div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-flex xs12 class="px-3" v-for="(result, i) in outputs.o1" :key="i">
                        <v-card class="mt-2 elevation-2">
                            <!-- Component -->
                            <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                                :system="system" />
                        </v-card>
                    </v-flex>
                    <v-flex xs12 class="px-3">
                        <v-card class="elevation-2 mt-2">
                            <v-layout wrap>
                                <v-flex xs12>
                                    <div class="ml-1 mt-2 py-2">Required Reinforcement</div>
                                    <v-card class="pa-3">
                                        <v-layout wrap>
                                            <v-flex xs1 class="text-xs-right reqRein">
                                                φ
                                            </v-flex>
                                            <v-flex xs4 class="text-xs-center reqRein">
                                                {{result1['reqRein1'] | Digits(0)}}
                                            </v-flex>
                                            <v-flex xs1 class="text-xs-center reqRein">
                                                /
                                            </v-flex>
                                            <v-flex xs4 class="text-xs-center reqRein">
                                                {{result1['reqRein2']}}
                                            </v-flex>
                                            <v-flex xs1 v-if="system === 'metric'" class="reqRein">
                                                mm
                                            </v-flex>
                                            <v-flex xs1 v-else class="reqRein">
                                                in
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                </v-card>
            </v-flex>
            <!-- Category 2 -->
            <v-flex xs4 class="pl-1">
                <v-card class="elevation-2 pb-4" height="100%">
                    <div class="elevation-2 grey lighten-2 mt-2" height="60">
                        <v-layout wrap>
                            <v-flex xs12 class="my-3 pl-2">
                                <div>Curved Tendon - Out of Plane Design Force</div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-flex xs12 class="px-3" v-for="(result, i) in outputs.o2" :key="i">
                        <v-card class="mt-2 elevation-2">
                            <!-- Component -->
                            <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                                :system="system" />
                        </v-card>
                    </v-flex>
                    <v-flex xs12 class="px-3">
                        <div class="elevation-2 mt-3 pa-2 py-4 grey lighten-4">
                            <div class="headline text-xs-center">{{result1[outputs.message[0]]}} </div>
                        </div>
                    </v-flex>
                </v-card>
            </v-flex>
            <!-- Category 3 -->
            <v-flex xs4 class="pl-1">
                <v-card class="elevation-2 pb-4" height="100%">

                    <div class="elevation-2 grey lighten-2 mt-2" height="60">
                        <v-layout wrap>
                            <v-flex xs12 class="my-3 pl-2">
                                <div>Crack Control Reinforcement</div>
                            </v-flex>
                        </v-layout>
                    </div>
                    <v-flex xs12 class="px-3">
                        <v-card class="elevation-2 mt-2">
                            <div class="ml-1 pt-2">Required Reinforcement</div>
                            <v-card class="mt-2 pa-3 elevation-2">
                                <v-layout wrap>
                                    <v-flex xs5 class="text-xs-right reqRein">
                                        {{result1['crackCntrl1'] | Digits(0)}}
                                    </v-flex>
                                    <v-flex xs2 class="text-xs-center reqRein">
                                        φ
                                    </v-flex>
                                    <v-flex xs5 class="text-xs-left reqRein">
                                        {{result1['crackCntrl2'] | Digits(0)}}
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </v-card>
                    </v-flex>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 class="pl-3 mt-3">
                <div class="elevation-2 grey lighten-2 mt-2" height="60">
                    <v-layout wrap>
                        <v-flex xs12 class="my-3 pl-2">
                            <div>Blister and Rib Reinforcement</div>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
            <!-- Table-->
            <v-flex xs6 class="pl-3">
                <v-card class="elevation-2 pb- mt-1">
                    <v-card class="elevation-2 px-3 pb-3 pt-2">
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-card v-for="(title, index) in blisterTable" :key="index" class="pa-3 mt-1">
                                    <v-layout wrap>
                                        <v-flex xs7>
                                            {{title}}
                                        </v-flex>
                                        <v-flex xs2 class="text-xs-right headline">
                                            {{result1[outputs.oTable[index][0]] | Digits(0)}}
                                        </v-flex>
                                        <v-flex xs1 class="text-xs-center headline">
                                            φ
                                        </v-flex>
                                        <v-flex xs2 class="text-xs-left headline">
                                            {{result1[outputs.oTable[index][1]] | Digits(0)}}
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-card>
                    <div class="elevation-2 mt-1 py-4 grey lighten-4">
                        <div class="headline text-xs-center">{{result1[outputs.message[1]]}} </div>
                    </div>
                </v-card>
            </v-flex>
            <v-flex xs6 class="mt-1 pl-1">
                <v-card class="elevation-2 pb-4">
                    <!-- Category 3 -->
                    <v-layout wrap>
                        <v-flex xs6 class="px-3" v-for="(result, i) in outputs.o3" :key="i">
                            <v-card class="mt-2 elevation-2">
                                <!-- Component -->
                                <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes"
                                    :i="i" :system="system" />
                            </v-card>
                        </v-flex>
                    </v-layout>
                    <br>
                    <div class="ml-3 subTitle">Concrete Shear Resistance</div>
                    <!-- Category 4 -->
                    <v-layout wrap>
                        <v-flex xs6 class="px-3" v-for="(result, i) in outputs.o4" :key="i">
                            <v-card class="mt-2 elevation-2">
                                <!-- Component -->
                                <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes"
                                    :i="i" :system="system" />
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
        </v-layout>
    </v-layout>
</template>

<script>
    import resultCard from '@/_calculators/static/resultCard'
    export default {
        components: {
            resultCard
        },
        props: ['outputs', 'system', 'result1', 'result2', 'changes'],
        data() {
            return {
                blisterTable: [
                    'Computed Reinforcement: ',
                    'Minimum Required Reinforcement: ',
                    'Expected Reinforcement, As:	',
                    'Tension Reinforcement, An: ',
                    'Required Tie Reinforcement, Ah: ',
                    'Required Main Reinforcement, As: '
                ]
            }
        }
    }
</script>

<style scoped>
    .reqRein {
        font-size: 16px;
        font-weight: 400;
    }

    .subTitle {
        font-size: 16px;
        font-weight: 400;
    }
</style>