var inputs = {
    composite: {
        label: 'Is It Composite ?',
        value: 'No',
        cat: 2,
        type: 'combobox',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },
    b: {
        label: 'Flange Width , b',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    h1: {
        label: 'Girder Depth , h',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    tw: {
        label: 'Web Thickness , tw',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    tf: {
        label: 'Flange Thickness , tf',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    r: {
        label: 'Fillet Radius , r',
        value: null,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    ts: {
        label: 'Slab Thickness , ts',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Slab Thickness',
            desc: ''
        }
    },

    s: {
        label: 'Girder Spacing ,s ',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Spacing',
            desc: ''
        }
    },
    Eg: {
        label: 'Steel Girder Modulus of Elasticity',
        value: null,
        cat: 2,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Girder Modulus of Elasticity',
            desc: ''
        }
    },
    Es: {
        label: 'Concrete Slab Modulus of Elasticity',
        value: null,
        cat: 2,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Slab Modulus of Elasticity',
            desc: ''
        }
    }
};

export default inputs;