<template>
  <div class="mx-3 my-2 pa-1">
    <h2 class="mb-3">Payment Result</h2>
    <v-layout>
      <v-flex sm12>
        <v-alert :value="true" :color="color" :icon="icon" outlined>{{ text }}</v-alert>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: null,
      color: null,
      icon: null
    };
  },
  mounted() {
    // get the result string
    var res = this.$route.query.res;
    // if the result is success
    if (res == "success") {
      this.text =
        "Your payment has been received and your account is upgraded to premium. You may need to log out and log in for changes to take effect.";
      this.color = "success";
      this.icon = "check_circle";
    }
    // if the result is fail
    else if (res == "fail") {
      // get the error code
      var errCode = this.$route.query.err;
      this.color = "error";
      this.icon = "warning";
      // for error code definitions, refer to "Payment - Error Codes" section of the documentation.
      if (errCode == "FIRESTORE_NOT_UPDATED") {
        this.text =
          "Your payment has been received, but your account cannot be upgraded to premium. Please contact BridgeWiz with your payment information.";
      } else {
        this.text =
          "Your payment cannot be received, with the following error code: " +
          errCode;
      }
    }
  }
};
</script>

<style>
</style>