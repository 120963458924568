<template>
  <div>
    <structure
      inputType="c4simple"
      :categoryNumber="4"
      :titles="['Girder Properties', 'Strand Properties', 'Composite Sect. Properties', 'Superstructure Moments']"
      :tooltips="[
                'Girder area, girder moment of inertia, girder center from bottom can be determined from “Concrete I Girder Properties” calculator. All values are positive.',
                'All values are positive.',
                'Properties can be determined from from “Concrete I Girder Properties” calculator. All values are postive.',
                'Girder, Slab and Superimposed Dead load moments can be determined from “Dead Load” calculator. Live load moment can be determined from “Live Load on One Interior Girder” calculator. All values are positive.'
            ]"
      topImage1="minRequiredNoOfStrands1.png"
      topImage2="minRequiredNoOfStrands2.png"
      :inputs="inputs"
      :testData="testData"
      :errorSnack.sync="errorSnack"
      :errorText.sync="errorText"
      @CALC_METRIC="calcMetric"
      @CALC_IMP="calcImperial"
      @system="systemChanged"
      @CLEAR_RESULTS="clearResults"
      @GENERATE_REPORT="generateReportEmitted"
      @INPUT_MISSING="inputMissingEmitted"
    />

    <results
      resultType="minReqNoOfStrands"
      img1="minRequiredNoOfStrands3.png"
      :outputs="outputs"
      :system="system"
      :result1="result1"
      :result2="result2"
    />

    <!-- Snackbar for errors -->
    <v-snackbar v-model="errorSnack" :timeout="3000" color="red" bottom>
      {{ errorText }}
      <v-btn color="white" text @click="errorSnack = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import structure from "@/_calculators/static/calculatorStructure";
import results from "@/_calculators/static/results";
import functions from "@/_calculators/src/minRequiredNoOfStrands/functions";
import inputs from "@/_calculators/src/minRequiredNoOfStrands/inputs";
import outputs from "@/_calculators/src/minRequiredNoOfStrands/outputs";
import testData from "@/_calculators/src/minRequiredNoOfStrands/testData";
import { GeneratePDFReport } from "@/_calculators/src/shared/report";

export default {
  components: {
    structure,
    results
  },
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      testData: testData,
      result1: null,
      result2: null,
      errorSnack: false,
      errorText: null
    };
  },
  watch: {
    system() {
      this.clearResults();
    }
  },
  methods: {
    /* Static */
    systemChanged(val) {
      this.system = val;
    },
    calcMetric() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "metric");
    },
    calcImperial() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "imperial");
    },
    clearResults() {
      this.result1 = null;
      this.result2 = null;
    },
        inputMissingEmitted() {
        this.errorSnack = true;
        this.errorText = "Please fill in all the input fields";
    },
    async generateReportEmitted() {
      try {
        let user = this.$store.getters["users/user"];
        await GeneratePDFReport(
          this.inputs,
          this.outputs,
          this.result1,
          "Minimum Number of Strands",
          `${user.name} ${user.surname}`,
          this.system
        );
      } catch (error) {
        if (error.name === "TypeError") {
          this.errorSnack = true;
          this.errorText = "Please run the calculations before reporting.";
        } else {
          this.errorSnack = true;
          this.errorText = `The following error has occurred: ${error.name}`;
        }
      }
    }
  }
};
</script>

<style>
</style>