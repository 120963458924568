import db from '@/firebaseInit'
import 'firebase/auth'
import calculatorList from '@/_lists/calculatorList'

const tierRef = db.collection('tiers');

const state = {
    calculators: []
}
const getters = {

}
const mutations = {
    SET_CALCULATORS(state, data) {
        state.calculators = data
    }
}
const actions = {
    fetchCalculatorList({
        commit
    }, tierNo) {
        return new Promise((resolve, reject) => {
            // an array that holds the keys of the calculators fetched from "tiers" collection, within map collection named c
            var tierCalcs = [];
            /* tc is an array of key-calue pairs (map array) in the form
                {demoPremium: "sd5gf4w5e4sxd4x", demoPremium2: "df47r8rtxc5sdf8"}
                where the key is the calculator unique name and the value is the calculator hash
            */
            var tc = null;
            // an array that holds the calculator objects that are in the given tier
            var calculators;
            // get the calculator maps from "tier" collection
            tierRef.doc(tierNo).get()
                .then(doc => {
                    tc = doc.data().c;
                    tierCalcs = Object.keys(tc);
                })
                .then(() => {
                    calculators = tierCalcs.map(calc => {
                        var c = calculatorList.find(c => c.name === calc) || {};
                        c.id = tc[calc];
                        return c;
                    });
                    commit('SET_CALCULATORS', calculators)
                    resolve('Fetched calculator list')
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}