var inputs = {
    yieldStrength: {
        label: 'Yield Strength',
        value: 360,
        cat: 0,
        suffix: {
            metric: 'MPa',
            imperial: 'ksi'
        },
        description: {
            title: 'Yield Strength',
            desc: 'Yield Strength'
        }
    },
    selectedDiameter: {
        label: 'Selected Diameter',
        value: 0,
        cat: 11,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Selected Diameter',
            desc: 'Selected Diameter'
        }
    },
    shearForce: {
        label: 'Shear Force',
        value: '',
        cat: 1,
        suffix: {
            metric: 'kN',
            imperial: 'kip'
        },
        description: {
            title: 'Shear Force',
            desc: 'Shear Force'
        }
    },
    selectedThickness: {
        label: 'Selected Plate Thickness',
        value: 0,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Selected Plate Thickness',
            desc: 'Selected Plate Thickness'
        }
    },
    moment: {
        label: 'Moment',
        value: 31000,
        cat: 1,
        suffix: {
            metric: 'kNm',
            imperial: 'kipft'
        },
        description: {
            title: 'Moment',
            desc: 'Moment'
        }
    },
    isDoubleShear: {
        label: 'Double Shear',
        value: true,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Double Shear',
            desc: 'Double Shear'
        }
    }
}

export default inputs