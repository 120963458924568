<template>
  <div>
    <v-layout>
      <v-flex xs12 m6>
        <div class="elevation-2 mt-1 mr-1 grey lighten-4" height="380">
          <v-img :src="getImgUrl(img1)" height="380" contain></v-img>
        </div>
      </v-flex>
      <v-flex xs12 md6 v-for="(category, i) in cats" :key="i">
        <div class="mt-1 mx-1 elevation-2 grey lighten-3" height="60">
          <v-layout wrap>
            <v-flex xs10 class="my-3 pl-2">
              <div>{{titles[i]}}</div>
            </v-flex>
            <v-flex xs2>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">{{tooltips[i]}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <v-card class="elevation-2 mt-1 mx-1">
          <v-layout class="pa-3">
            <v-flex>
              <v-form>
                <v-layout wrap>
                  <v-flex
                    class="pl-1"
                    v-bind="{[`xs${12 / Math.ceil(Object.keys(category).length / 5)}`]: true}"
                    v-for="input in category"
                    :key="input.label"
                  >
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      type="number"
                      v-model="input.value"
                      :label="input.label"
                      hide-details
                      filled
                      required
                      :suffix="input.suffix[system]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
          <!-- Results -->
          <v-layout>
            <v-flex v-if="result1">
              <div class="mt-1 elevation-2 grey darken-1" height="60">
                <v-layout wrap>
                  <v-flex xs9 class="my-3 pl-2">
                    <div class="white--text">Results</div>
                  </v-flex>
                </v-layout>
              </div>
              <v-card flat color="grey lighten-3">
                <v-layout class="pa-2">
                  <v-flex>
                    <v-form>
                      <v-layout wrap>
                        <v-flex xs12 class="pl-2" v-for="(result, i) in outputs" :key="i">
                          <v-card class="my-2 elevation-4">
                            <!-- Component -->
                            <resultCard
                              :result="result"
                              :result1="result1"
                              :result2="result2"
                              :changes="changes"
                              :i="i"
                              :system="system"
                            />
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import resultCard from "@/_calculators/static/resultCard";
export default {
  components: {
    resultCard
  },
  props: [
    "inputs",
    "cats",
    "system",
    "outputs",
    "result1",
    "result2",
    "changes",
    "titles",
    "tooltips"
  ],
  data() {
    return {
      img1: "selectionOfGirderDepth.png"
    };
  },
  methods: {
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    }
  }
};
</script>

<style scoped>
</style>