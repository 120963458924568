<template>
  <div>
    <v-tabs v-model="tab" background-color="blue lighten-1" class="elevation-2" dark centered grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="(tabHeader, index) in tabHeaders"
        :key="index"
        :href="`#${tabHeader}`"
      >{{ tabHeader }}</v-tab>
      <!-- Edit Announcements tab -->
      <v-tab-item :value="tabHeaders[0]">
        <EditNewsComponent />
      </v-tab-item>
      <!-- Edit Users tab -->
      <v-tab-item :value="tabHeaders[1]">
        <EditUsersComponent />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import EditUsersComponent from "@/components/EditUsersComponent";
import EditNewsComponent from "@/components/EditNewsComponent";

export default {
  components: {
    EditUsersComponent,
    EditNewsComponent
  },
  data() {
    return {
      tab: null,
      tabHeaders: ["Edit News", "Edit Users"]
    };
  }
};
</script>

<style>
</style>