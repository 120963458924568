function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var Ag = h('Ag'),
        gammaConc = h('gammaConc'),
        L = h('L');

    // Metric Calculations
    function metric() {
        R.wgDL = Ag * gammaConc / Math.pow(10, 6);
        R.MgDL = R.wgDL * L * L * 0.125;
    }

    // Imperial Calculations
    function imperial() {
        R.wgDL = Ag * gammaConc / 144;
        R.MgDL = R.wgDL * L * L * 0.125;
    }

    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

};

module.exports = {
    calculate
}