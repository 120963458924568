function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var P1 = h('P1'),
        P2 = h('P2'),
        P3 = h('P3'),
        wllu = h('wllu'),
        x1 = h('x1'),
        x2 = h('x2'),
        L = h('L'),
        IM = h('IM'),
        loadType = h('loadType');


    // Metric Calculations
    function metric() {

        IM = (IM / 100) + 1
        R.Mmax = mFindMmax(loadType)

        function mFindMmax(load) {
            var result;
            if (load === 'Truck') {
                if (L <= 7.408) {
                    R.xmax = L * 0.5
                    result = (IM * P3 * L) / 4 + wllu * L * L / 8
                } else if (7.408 < L && L <= 10.115) {
                    P1 = 0
                    x1 = 0
                    R.xmax = L * 0.5 + ((IM * P3 * x2) / (2 * IM * (P2 + P3) + wllu * L))
                    result = (2 * L * IM * (P1 + P2 + P3) - 4 * IM * (P3 * x2 + P1 * x1) + wllu * L * L + (4 * (IM * P3 * x2 - IM * P1 * x1) * (IM * P3 * x2 - IM * P1 * x1)) / (L * (2 * IM * (P1 + P2 + P3) + wllu * L))) * 0.125
                } else if (L > 10.115) {
                    R.xmax = L * 0.5 + ((IM * P3 * x2 - IM * P1 * x1) / (2 * IM * (P1 + P2 + P3) + wllu * L))
                    result = (2 * L * IM * (P1 + P2 + P3) - 4 * IM * (P3 * x2 + P1 * x1) + wllu * L * L + (4 * (IM * P3 * x2 - IM * P1 * x1) * (IM * P3 * x2 - IM * P1 * x1)) / (L * (2 * IM * (P1 + P2 + P3) + wllu * L))) * 0.125

                }

            } else if (load === 'Tandem') {

                if (L <= 2.094) {
                    R.xmax = L * 0.5
                    result = (IM * P3 * L / 4 + wllu * L * L) / 8

                } else if (L > 2.094) {
                    R.xmax = L * 0.5 + ((IM * P3 * x2) / (2 * IM * (P2 + P3) + wllu * L))
                    result = ((2 * L * IM * (P2 + P3) - 2 * IM * (P3 * x2) + wllu * L * L) * (2 * L * IM * (P2 + P3) - 2 * IM * (P3 * x2) + wllu * L * L) / (L * (2 * IM * (P2 + P3) + wllu * L))) * 0.125
                }

            }
            return result;

        }
    };
    // Imperial Calculations
    function imperial() {

        IM = (IM / 100) + 1
        R.Mmax = iFindMmax(loadType)

        function iFindMmax(load) {
            var result;

            if (load === 'Truck') {
                if (L <= 24.31) {
                    R.xmax = L * 0.5
                    result = IM * P3 * L / 4 + wllu * L * L / 8

                } else if (24.31 < L && L <= 33.19) {
                    P1 = 0
                    x1 = 0
                    R.xmax = L * 0.5 + ((IM * P3 * x2) / (2 * IM * (P2 + P3) + wllu * L))
                    result = (2 * L * IM * (P1 + P2 + P3) - 4 * IM * (P3 * x2 + P1 * x1) + wllu * L * L + (4 * (IM * P3 * x2 - IM * P1 * x1) * (IM * P3 * x2 - IM * P1 * x1)) / (L * (2 * IM * (P1 + P2 + P3) + wllu * L))) * 0.125


                } else if (L > 33.19) {
                    R.xmax = L * 0.5 + ((IM * P3 * x2 - IM * P1 * x1) / (2 * IM * (P1 + P2 + P3) + wllu * L))
                    result = (2 * L * IM * (P1 + P2 + P3) - 4 * IM * (P3 * x2 + P1 * x1) + wllu * L * L + (4 * (IM * P3 * x2 - IM * P1 * x1) * (IM * P3 * x2 - IM * P1 * x1)) / (L * (2 * IM * (P1 + P2 + P3) + wllu * L))) * 0.125

                }
            } else if (load === 'Tandem') {

                if (L <= 6.87) {
                    R.xmax = L * 0.5
                    result = IM * P3 * L / 4 + wllu * L * L / 8
                } else if (L > 6.87) {
                    R.xmax = L * 0.5 + ((IM * P3 * x2) / (2 * IM * (P2 + P3) + wllu * L))
                    result = ((2 * L * IM * (P2 + P3) - 2 * IM * (P3 * x2) + wllu * L * L) * (2 * L * IM * (P2 + P3) - 2 * IM * (P3 * x2) + wllu * L * L) / (L * (2 * IM * (P2 + P3) + wllu * L))) * 0.125
                }

            }
            return result;
        }
    };
    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
        console.error('System is missing !')
    }

}

module.exports = {
    calculate
}