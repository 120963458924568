function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var composite = h('composite'),
        b = h('b'),
        h1 = h('h1'),
        tw = h('tw'),
        tf = h('tf'),
        r = h('r'),
        ts = h('ts'),
        s = h('s'),
        Eg = h('Eg'),
        Es = h('Es');

    // Metric Calculations
    function metric() {
        shared();
    }

    // Imperial Calculations
    function imperial() {
        shared();
    }

    function shared() {

        var d = (h1 - 2 * tf),
            A1 = b * tf,
            A2 = 0.215 * 2 * r * r,
            A3 = d * tw,
            A4 = 0.215 * 2 * r * r,
            A5 = b * tf,
            yb1 = tf / 2,
            yb2 = tf + 3 * r / 10,
            yb3 = tf + d / 2,
            yb4 = tf + d - 3 * r / 10,
            yb5 = tf + d + tf / 2,
            Ix1 = b * tf * tf * tf / 12,
            Ix2 = 37 * r * r * r * r * 2 / 2100,
            Ix3 = tw * d * d * d / 12,
            Ix4 = 37 * r * r * r * r * 2 / 2100,
            Ix5 = b * tf * tf * tf / 12,
            n = Es / Eg;


        R.Ag = A1 + A2 + A3 + A4 + A5;
        R.yb = h1 / 2;
        R.Ig = (Ix1 + A1 * (R.yb - yb1) * (R.yb - yb1) + Ix2 + A2 * (R.yb - yb2) * (R.yb - yb2) + Ix3 + A3 * (R.yb - yb3) * (R.yb - yb3) + Ix4 + A4 * (R.yb - yb4) * (R.yb - yb4) + Ix5 + A5 * (R.yb - yb5) * (R.yb - yb5));
        R.Sb = R.Ig / R.yb;
        R.St = R.Ig / (h1 - R.yb);

        if (composite === 'Yes') {

            R.Agc = R.Ag + s * ts * n;
            R.ybc = (A1 * yb1 + A2 * yb2 + A3 * yb3 + A4 * yb4 + A5 * yb5 + (s * ts * n) * ((ts / 2) + h1)) / R.Agc;
            R.Igc = R.Ig + R.Ag * (R.ybc - R.yb) * (R.ybc - R.yb) + (s * n) * ts * ts * ts / 12 + s * ts * n * (h1 + ts / 2 - R.ybc) * (h1 + ts / 2 - R.ybc);
            R.Sbc = R.Igc / R.ybc;
            R.Stc = R.Igc / (h1 + ts - R.ybc);

        }
    }

    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

}

module.exports = {
    calculate
}