var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-layout',{attrs:{"wrap":""}},_vm._l((2),function(img,index){return _c('v-flex',{key:index,attrs:{"xs6":""}},[_c('div',[_c('v-img',{attrs:{"src":_vm.getImgUrl(_vm.images[index]),"max-height":"250","contain":""}})],1)])}),1),_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('div',{staticClass:"grey lighten-2 elevation-2",attrs:{"height":"60"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.system),callback:function ($$v) {_vm.system=$$v},expression:"system"}},[_c('v-radio',{staticClass:"pl-5",attrs:{"color":"indigo darken-3","label":"SI","value":"metric"}}),_c('v-radio',{attrs:{"color":"indigo darken-3","label":"Imperial","value":"imperial"}})],1)],1)],1)],1)])],1),_c('v-layout',_vm._l((3),function(i){return _c('v-flex',{key:i,attrs:{"xs4":""}},[_c('div',{staticClass:"mt-1 mr-1 pl-2 elevation-2 grey lighten-3",attrs:{"height":"60"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"my-3 pl-2",attrs:{"xs10":""}},[_c('div',[_vm._v(_vm._s(i)+". "+_vm._s(_vm.titles[i-1]))])]),_c('v-flex',{attrs:{"xs2":""}},[_c('v-tooltip',{attrs:{"top":"","max-width":"250","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{attrs:{"color":"grey darken-4"}},[_vm._v("help")])],1)]}}],null,true)},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.tooltips[i-1]))])])],1)],1)],1),_c('v-card',{staticClass:"mt-1 mr-1 elevation-2",attrs:{"height":"310"}},[_c('v-layout',{staticClass:"pa-3"},[_c('v-flex',[_c('v-form',[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.inputs[("i" + i)]),function(input,index){
var _obj;
return _c('v-flex',_vm._b({key:input.label,staticClass:"pl-1"},'v-flex',( _obj = {}, _obj[("xs" + ((i === 3 && index !== 'gammaConc' && index !== 'gammaConcW' ) ? 6 : 12))] = true, _obj ),false),[_c('v-text-field',{staticClass:"numberInputArea",attrs:{"color":"blue darken-4","type":"number","label":input.label,"hide-details":"","filled":"","required":"","suffix":input.suffix[_vm.system]},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})],1)}),1)],1)],1)],1)],1),_c('v-layout',{attrs:{"my-1":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mr-1",attrs:{"xs4":""}},[_c('v-tooltip',{attrs:{"top":"","width":"200","color":"grey darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":""},on:{"click":function($event){return _vm.loadTestData(i)}}},on),[_vm._v("Test Data")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("Default values will be filled.")])])],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-tooltip',{attrs:{"top":"","width":"200","color":"grey darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":""},on:{"click":function($event){return _vm.clearResults(i)}}},on),[_vm._v("Clear")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("All values will be erased.")])])],1),_c('v-flex',{staticClass:"mx-1",attrs:{"xs":""}},[_c('v-tooltip',{attrs:{"top":"","width":"300","color":"grey darken-3"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"block":"","color":"green darken-4","dark":""},on:{"click":function($event){return _vm.checkAllFields(i)}}},on),[_vm._v("Calculate")])]}}],null,true)},[_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v("Section propreties will be calculated.")])])],1)],1)],1)],1)],1)}),1),_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.results["r1"] || _vm.results["r2"] || _vm.results["r3"]),expression:"results[`r1`] || results[`r2`] || results[`r3`]"}]},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-toolbar',{staticClass:"blue darken-2",attrs:{"dark":"","height":"50"}},[_c('v-spacer'),_c('div',{staticStyle:{"font-size":"18px","font-weight":"bold"}},[_vm._v("RESULTS")]),_c('v-spacer')],1),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"ma-1 elevation-2 grey lighten-3"},[_c('v-img',{attrs:{"src":_vm.getImgUrl(_vm.images[3]),"max-height":"205","contain":""}})],1)])],1)],1),_c('v-layout',_vm._l((3),function(i){return _c('v-flex',{key:i,attrs:{"xs4":""}},[(_vm.results[("r" + i)])?_c('v-flex',{staticClass:"my-1",attrs:{"xs12":""}},[_c('v-card',{staticClass:"ma-1 elevation-2"},[_c('v-layout',{staticClass:"pa-3"},[_c('v-layout',{attrs:{"wrap":""}},_vm._l((_vm.outputs[("o" + i)]),function(result,index){return _c('v-flex',{key:index,staticClass:"pl-1",attrs:{"xs12":""}},[_c('v-card',{staticClass:"mt-2 elevation-2"},[_c('resultCard',{attrs:{"result":result,"result1":_vm.results[("r" + i)],"result2":_vm.resultsHistory[("r" + i)],"changes":_vm.changes[("r" + i)],"i":index,"system":_vm.system}})],1)],1)}),1)],1)],1)],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }