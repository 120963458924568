function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var composite = h('composite'),
        x1 = h('x1'),
        x2 = h('x2'),
        x3 = h('x3'),
        x4 = h('x4'),
        y1 = h('y1'),
        y2 = h('y2'),
        y3 = h('y3'),
        y4 = h('y4'),
        y5 = h('y5'),
        y6 = h('y6'),
        ts = h('ts'),
        s = h('s'),
        Eg = h('Eg'),
        Es = h('Es');

    // Metric Calculations
    function metric() {
        shared();
    }



    // Imperial Calculations
    function imperial() {
        shared();
    }

    function shared() {

        var A1 = x1 * y1,
            A2 = (x1 - x3) * 0.5 * y2,
            A3 = x3 * y2,
            A4 = x3 * y3,
            A51 = (x4 * y4 * 0.5) * 2,
            A6 = x3 * y4,
            A71 = (x2 - x3 - 2 * x4) * 0.5 * y5,
            A8 = (x3 + 2 * x4) * y5,
            A9 = x2 * y6,
            Ix1 = x1 * y1 * y1 * y1 / 12,
            Ix2 = (x1 - x3) * y2 * y2 * y2 / 36,
            Ix3 = x3 * y2 * y2 * y2 / 12,
            Ix4 = x3 * y3 * y3 * y3 / 12,
            /* Ix5 = (x2 - x3) * y4 * y4 * y4 / 36, */
            Ix6 = x3 * y4 * y4 * y4 / 12,
            /* Ix7 = x2 * y5 * y5 * y5 / 12, */
            Ix51 = x4 * y4 * y4 * y4 / 36,
            Ix71 = (x2 - x3 - 2 * x4) * y5 * y5 * y5 / 36,
            Ix8 = (x3 + 2 * x4) * y5 * y5 * y5 / 12,
            Ix9 = x2 * y6 * y6 * y6 / 12,
            yb1 = y1 / 2,
            yb2 = y1 + y2 / 3,
            yb3 = y1 + y2 / 2,
            yb4 = y1 + y2 + y3 / 2,
            /* yb5 = y1 + y2 + y3 + 2 * y4 / 3, */
            yb6 = y1 + y2 + y3 + y4 / 2,
            /* yb7 = y1 + y2 + y3 + y4 + y5 / 2, */
            yb51 = y1 + y2 + y3 + y4 * 2 / 3,
            yb71 = y1 + y2 + y3 + y4 + y5 * 2 / 3,
            yb8 = y1 + y2 + y3 + y4 + y5 / 2,
            yb9 = y1 + y2 + y3 + y4 + y5 + y6 / 2,
            depth = y1 + y2 + y3 + y4 + y5 + y6,
            n = Es / Eg;

        R.Ag = A1 + A2 + A3 + A4 + A51 + A6 + A71 + A8 + A9;
        R.yb = (A1 * yb1 + A2 * yb2 + A3 * yb3 + A4 * yb4 + A51 * yb51 + A6 * yb6 + A71 * yb71 + A8 * yb8 + A9 * yb9) / R.Ag;
        R.Ig = (Ix1 + A1 * (R.yb - yb1) * (R.yb - yb1) + Ix2 + A2 * (R.yb - yb2) * (R.yb - yb2) + Ix3 + A3 * (R.yb - yb3) * (R.yb - yb3) + Ix4 + A4 * (R.yb - yb4) * (R.yb - yb4) +
            Ix51 + A51 * (R.yb - yb51) * (R.yb - yb51) + Ix6 + A6 * (R.yb - yb6) * (R.yb - yb6) + Ix71 + A71 * (R.yb - yb71) * (R.yb - yb71) + Ix8 + A8 * (R.yb - yb8) * (R.yb - yb8) + Ix9 + A9 * (R.yb - yb9) * (R.yb - yb9));
        R.Sb = R.Ig / R.yb;
        R.St = R.Ig / (depth - R.yb);



        if (composite === 'Yes') {

            R.Agc = R.Ag + s * ts * n;
            R.ybc = (A1 * yb1 + A2 * yb2 + A3 * yb3 + A4 * yb4 + A51 * yb51 + A6 * yb6 + A71 * yb71 + A8 * yb8 + A9 * yb9 + (s * ts * n) * ((ts / 2) + depth)) / R.Agc;
            R.Igc = R.Ig + R.Ag * (R.ybc - R.yb) * (R.ybc - R.yb) + (s * n) * ts * ts * ts / 12 + s * ts * n * (depth + ts / 2 - R.ybc) * (depth + ts / 2 - R.ybc);
            R.Sbc = R.Igc / R.ybc;
            R.Stc = R.Igc / (depth - R.ybc);

        }

    }

    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

}

module.exports = {
    calculate
}