function calculateMinDiameter(shearForce, yieldStrength, isDoubleShear, system) {
    // assuming double shear
    if (system === 'metric') {
        const actualShear = isDoubleShear ? shearForce / 2.0 : shearForce;
        const a = actualShear * 1000 / (0.4 * yieldStrength);
        const diam = 2 * Math.sqrt(a / Math.PI);
        return Math.round(diam);
    }
    else {
        return -1;
    }
}

function calculateMinThickness(shearForce, yieldStrength, selectedDiameter, isDoubleShear, system) {
    if (system === 'metric') {
        const actualShear = isDoubleShear ? shearForce / 2.0 : shearForce;
        const a = actualShear * 1000 / (0.4 * yieldStrength);
        const t = a / selectedDiameter;
        return Math.round(t);
    }
    else {
        return -1;
    }

}

function calculateMomentStatus(moment, yieldStrength, selectedDiameter, system) {
    try {
        if (system === 'metric') {
            let s = Math.PI * Math.pow(selectedDiameter, 3) / 32;
            let limit = 0.8 * yieldStrength;
            let sigma = moment * 1000 / s;
            return sigma < limit;
        }
        else {
            return false;
        }
    } catch (e) {
        return false;
    }
    
}

module.exports = {
    calculateMinDiameter, calculateMinThickness, calculateMomentStatus
}