function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var n = h('n'),
        m = h('m'),
        dx = h('dx'),
        dy = h('dy'),
        Pu = h('Pu'),
        Pv = h('Pv'),
        Mx = h('Mx'),
        My = h('My');

    // Metric Calculations
    function metric() {
        shared();
    }

    // Imperial Calculations
    function imperial() {
        shared();
    }

    function shared() {
        class Pile {
            constructor(id, x, y) {
                this.id = id;
                this.x = x;
                this.y = y;
            }
        }

        var piles = [];

        var noOfPiles = n * m;
        var L = (n - 1) * dx;
        var H = (m - 1) * dy;
        var id = 1;

        for (let j = 1; j <= m; j++) {
            var x, y;
            for (let i = 1; i <= n; i++) {
                x = -L / 2 + (i - 1) * dx;
                y = H / 2 - (j - 1) * dy;
                piles.push(new Pile(id, x, y))
                id++;
            }
        }

        piles.forEach(pile => {
            pile.xsquare = Math.pow(pile.x, 2);
            pile.ysquare = Math.pow(pile.y, 2);
        })

        var sumXSqr = piles.reduce((acc, cur) => acc + cur.xsquare, 0);
        var sumYSqr = piles.reduce((acc, cur) => acc + cur.ysquare, 0);

        piles.forEach(pile => {
            pile.Pi = (Pv / noOfPiles) + ((Mx * pile.y / sumYSqr) + (My * pile.x / sumXSqr))
        })

        var tempPi = piles.map(pile => pile.Pi)
        var PiMax = Math.max(...tempPi)

        var message =
            (PiMax >= Pu) ? 'NOT OK' : 'OK'

        R = {
            table: piles,
            sumXSqr, // m2   /   ft2
            sumYSqr, // m2   /   ft2
            PiMax, // Maximum Axial Load on Pile (kN  /   kips)
            message,
            /* 
                OK:        Maximum Axial Load on Pile < Safe Bearing Capacity of One Pile
                NOT OK:    Maximum Axial Load on Pile ≥ Safe Bearing Capacity of One Pile
            */
        }
    }

    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
        console.error('System is missing !')
    }

}

module.exports = {
    calculate
}