<template>
  <div>
    <!-- Metric vs Imperial Radio Boxes -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout>
            <v-flex>
              <v-form>
                <v-layout wrap class>
                  <v-flex xs12>
                    <v-radio-group v-model="system" row>
                      <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                      <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex sm12 md6>
        <v-img contain :src="getImgUrl('plateFilletWeld1.png')" max-height="400"></v-img>
      </v-flex>

      <v-flex sm12 md6>
        <div class="pt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Input Parameters</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">Please fill in the following fields</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <div class="mr-1 elevation-2" height="320">
          <v-layout class="pa-3">
            <v-flex>
              <v-form v-model="valid">
                <v-layout wrap>
                  <template v-for="(param, key, index) in inputs">
                    <v-flex xs12 v-if="param.type==='number'" :key="index">
                      <v-text-field
                        class="numberInputArea"
                        color="blue darken-4"
                        required
                        filled
                        hide-details
                        :suffix="param.suffix[system]"
                        type="number"
                        v-model="inputs[key].value"
                        :rules="numberRules"
                        :label="param.description.title"
                      ></v-text-field>
                    </v-flex>
                  </template>
                  <v-flex xs12>
                    <v-select
                      :items="electrodes"
                      :label="inputs.electrode.label"
                      filled
                      v-model="inputs.electrode.value"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <v-layout mt-2 wrap>
      <v-flex xs12>
        <v-layout wrap>
          <!-- Load Test Data -->
          <v-flex xs2>
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="loadTestData">Test Data</v-btn>
              </template>
              <span style="font-size: 13px;">Default values will be filled.</span>
            </v-tooltip>
          </v-flex>
          <!-- Clear -->
          <v-flex xs2 class="px-2">
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="reset">Clear</v-btn>
              </template>
              <span style="font-size: 13px;">All values will be erased.</span>
            </v-tooltip>
          </v-flex>
          <!-- Calculate -->
          <v-flex xs4 class="pr-2">
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block color="green darken-4" dark v-on="on" @click="runAnalysis">Calculate</v-btn>
              </template>
              <span style="font-size: 13px;">The calculations will be started.</span>
            </v-tooltip>
          </v-flex>
          <!-- Report -->
          <v-flex xs4 class="pr-2">
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn
                  block
                  color="blue lighten-3"
                  dark
                  v-on="on"
                  @click="generateReport"
                >Create Report</v-btn>
              </template>
              <span style="font-size: 13px;">Report Generation will be started.</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <v-layout v-if="newResults" wrap>
      <v-flex xs12>
        <v-toolbar class="blue darken-2" dark height="50">
          <v-spacer></v-spacer>
          <div style="font-size: 18px; font-weight: bold;">RESULTS</div>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
      <v-flex xs12>
        <div></div>
      </v-flex>
      <v-flex sm12 md4>
        <v-img contain :src="getImgUrl('plateFilletWeld2.png')" max-height="350"></v-img>
      </v-flex>
      <template v-for="(param, key, index) in outputs">
        <v-flex sm12 md4 :key="index">
          <v-layout wrap>
            <v-flex xs9>
              <v-card flat height="90" class="text-xs-left elevation-2 ml-1 mt-3">
                <div class="py-1 pl-2 elevation-1">
                  <div>{{param.description.title}}</div>
                  <div>{{param.description.desc}}</div>
                </div>
                <v-layout wrap>
                  <v-flex
                    class="my-2 mx-2 black--text text-xs-center"
                    style="font-size: 15px;"
                  >{{ newResults[key].value | Digits(2)}}</v-flex>
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs3>
              <v-card flat height="90" class="text-xs-left elevation-2 mt-3">
                <div class="pt-1 text-xs-center elevation-2">
                  <v-icon :color="`${changes[key].color} darken-3`">{{changes[key].sign}}</v-icon>
                  <div
                    :class="`pl-2 ${changes[key].color} darken-3 white--text`"
                  >{{changes[key].value | Digits(1)}} %</div>
                </div>
                <v-flex class="pt-2" style="font-size: 15px;">{{param.suffix[system]}}</v-flex>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
    <v-snackbar color="error" v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn dark text @click="snackbar.show=false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { inputs, electrodes } from "@/_calculators/src/plateFilletWeld/inputs";
import testData from "@/_calculators/src/plateFilletWeld/testData";
import calculate from "@/_calculators/src/plateFilletWeld/functions";
import outputs from "@/_calculators/src/plateFilletWeld/outputs";
import { GeneratePDFReport } from "@/_calculators/src/shared/report";

export default {
  name: "plateFilletWeld",
  data() {
    return {
      system: "metric",
      valid: true,
      inputs: inputs,
      electrodes: electrodes,
      testData: testData,
      changes: {},
      outputs: outputs,
      newResults: null,
      oldResults: null,
      snackbar: {
        visible: false,
        text: ""
      },
      numberRules: [
        v => !!v || "Value is required",
        v => (v && v > 0) || "Value must be positive"
      ]
    };
  },
  created() {
    this.resetChanges();
  },
  watch: {
    newResults(val) {
      if (val) {
        this.updateChanges();
      }
    }
  },
  methods: {
    resetChanges() {
      for (const key in this.outputs) {
        if (this.outputs.hasOwnProperty(key)) {
          const element = this.outputs[key];
          this.changes[key] = {
            value: 0,
            color: "blue",
            sign: "swap_horiz"
          };
        }
      }
    },
    showError(errorText) {
      this.snackbar.text = errorText;
      this.snackbar.show = true;
    },
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    },
    loadTestData() {
      for (const key in this.inputs) {
        if (this.inputs.hasOwnProperty(key)) {
          const element = this.inputs[key];
          if (element.type === "number") {
            element.value = this.testData[this.system][key];
          }
        }
      }
    },
    reset() {
      for (const key in this.inputs) {
        if (this.inputs.hasOwnProperty(key)) {
          const element = this.inputs[key];
          if (element.type === "number") {
            element.value = null;
          }
        }
      }
      this.resetChanges();
      this.newResults = null;
      this.oldResults = null;
    },
    generateReport() {
      if (this.newResults) {
        GeneratePDFReport(
          this.inputs,
          this.outputs,
          this.newResults,
          "Plate Girder Fillet Weld Minimum Thickness Calculator",
          "",
          this.system
        );
      } else {
        this.showError("Please run calculation before reporting");
      }
    },
    runAnalysis() {
      if (this.valid) {
        if (this.newResults) {
          // deep copy
          this.oldResults = JSON.parse(JSON.stringify(this.newResults));
        }
        const res = calculate(this.inputs, this.system);
        this.newResults = { ...res };
      } else {
        this.showError("Please check input parameters");
      }
    },
    updateChanges() {
      if (this.oldResults) {
        for (const key in this.newResults) {
          if (this.newResults.hasOwnProperty(key)) {
            const element = this.newResults[key];
            const rNew = element.value;
            const rOld = this.oldResults[key].value;
            const change = {
              value: 0,
              color: "blue",
              sign: "swap_horiz"
            };

            let result = -1;
            if (rOld && rNew) {
              result = ((rNew - rOld) / rOld) * 100;
              if (result > 0.005) {
                change.value = result;
                change.color = "green";
                change.sign = "trending_up";
              } else if (result < -0.005) {
                change.value = result;
                change.color = "red";
                change.sign = "trending_down";
              }
            }
            this.changes[key] = change;
          }
        }
      }
    }
  }
};
</script>

<style>
</style>