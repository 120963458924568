<template>
  <div>
    <v-card style="margin-left:20px;margin-right:20px;">
      <v-card-text>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-layout align-center row wrap mx-2 v-on="on">
              <v-flex md12 lg2>
                <span class="headline">Average Ratings</span>
              </v-flex>
              <v-flex md12 lg1 text-lg-right>
                <span class="subtitle-1">Ease Of Use</span>
              </v-flex>
              <v-flex md12 lg2>
                <v-rating readonly half-increments v-model="easeOfUseAverage"></v-rating>
              </v-flex>
              <v-flex md12 lg1 text-lg-right>
                <span class="subtitle-1">Content</span>
              </v-flex>
              <v-flex md12 lg2>
                <v-rating readonly half-increments v-model="contentAverage"></v-rating>
              </v-flex>
              <v-flex md12 lg1 text-lg-right>
                <span class="subtitle-1">Accuracy</span>
              </v-flex>
              <v-flex md12 lg2>
                <v-rating readonly half-increments v-model="accuracyAverage"></v-rating>
              </v-flex>
              <v-flex pl-5 md12 lg1>
                <v-dialog v-model="dialog" max-width="344">
                  <template v-slot:activator="{on}">
                    <v-btn outlined class="mr-2 blue accent-1" v-on="on">Rate</v-btn>
                  </template>
                  <RateComponent :calculator="calculator" v-bind:dialog.sync="dialog" />
                </v-dialog>
              </v-flex>
            </v-layout>
          </template>
          <span>Click RATE button on the right to rate this calculator</span>
        </v-tooltip>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebaseInit.js";
import RateComponent from "@/components/RateComponent";
export default {
  name: "RateDisplayComponent",
  props: ["calculator"],
  components: {
    RateComponent
  },
  data() {
    return {
      accuracyAverage: 0,
      contentAverage: 0,
      easeOfUseAverage: 0,
      dialog: false
    };
  },
  async mounted() {
    await this.GetRatingAverages();
  },
  watch: {
    calculator: async function(oldVal, newVal) {
      await this.GetRatingAverages();
    }
  },
  methods: {
    async GetRatingAverages() {
      let aggregateDocRef = db
        .collection("ratingAggregates")
        .doc(this.calculator.name);
      let doc = await aggregateDocRef.get();
      // if there is calculator aggregate document, calculate the averages
      if (doc.exists) {
        let ratingData = doc.data();
        this.accuracyAverage =
          ratingData.accuracyCount / ratingData.ratingCount;
        this.contentAverage = ratingData.contentCount / ratingData.ratingCount;
        this.easeOfUseAverage =
          ratingData.easeOfUseCount / ratingData.ratingCount;
      }
      // if not, use default zero values
      else {
        this.accuracyAverage = 0;
        this.contentAverage = 0;
        this.easeOfUseAverage = 0;
      }
    }
  }
};
</script>

<style>
</style>
