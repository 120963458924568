<template>
  <div>
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout>
            <v-flex>
              <v-form>
                <v-layout wrap class>
                  <v-flex xs12>
                    <v-radio-group v-model="system" row>
                      <v-radio
                        class="pl-5"
                        color="indigo darken-3"
                        label="SI"
                        value="metric"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <div class="pt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Bearing Information</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px"
                  >Please fill in the following fields</span
                >
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>
        <div class="elevation-2 mr-1">
          <v-layout class="pa-3">
            <v-flex>
              <v-layout wrap>
                <template v-for="(param, key, index) in inputs">
                  <v-flex
                    xs6
                    v-if="param.type === 'number' && param.cat === 1"
                    :key="index"
                  >
                    <v-text-field
                      class="numberInputArea"
                      color="blue darken-4"
                      required
                      filled
                      hide-details
                      :suffix="param.suffix[system]"
                      type="number"
                      v-model="inputs[key].value"
                      :rules="numberRules"
                      :label="param.description.title"
                    ></v-text-field>
                  </v-flex>
                </template>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-flex xs12>
        <div class="pt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout>
            <v-flex xs11 class="my-3 pl-2">
              <div>Geometry Information</div>
            </v-flex>
            <v-flex xs1>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px"
                  >Please fill in the following fields</span
                >
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>

        <div class="elevation-2 mr-1">
          <v-layout class="pa-3">
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12>
                  <v-radio-group row v-model="inputs.shapeType.value">
                    <template v-for="type in shapeTypes">
                      <v-radio
                        :label="type.text"
                        :value="type.value"
                        :key="type.vale"
                      />
                    </template>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12>
                  <v-layout row>
                    <v-flex xs12>
                      <div v-if="inputs.shapeType.value === 'rectangular'">
                        <v-layout pa-3 wrap>
                          <template v-for="(param, key, index) in inputs">
                            <v-flex
                              xs6
                              v-if="param.type === 'number' && param.cat === 3"
                              :key="index"
                            >
                              <v-text-field
                                class="numberInputArea"
                                color="blue darken-4"
                                required
                                filled
                                hide-details
                                :suffix="param.suffix[system]"
                                type="number"
                                v-model="inputs[key].value"
                                :rules="numberRules"
                                :label="param.description.title"
                              ></v-text-field>
                            </v-flex>
                          </template>
                        </v-layout>
                      </div>
                      <div v-else>
                        <v-layout pa-3 wrap>
                          <template v-for="(param, key, index) in inputs">
                            <v-flex
                              xs6
                              v-if="param.type === 'number' && param.cat === 4"
                              :key="index"
                            >
                              <v-text-field
                                class="numberInputArea"
                                color="blue darken-4"
                                required
                                filled
                                hide-details
                                :suffix="param.suffix[system]"
                                type="number"
                                v-model="inputs[key].value"
                                :rules="numberRules"
                                :label="param.description.title"
                              ></v-text-field>
                            </v-flex>
                          </template>
                        </v-layout>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>

    <!-- BUTTONS -->
    <v-layout mt-2 wrap>
      <v-flex xs12>
        <v-layout wrap>
          <!-- Calculate -->
          <v-flex xs12 class="pr-2">
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn
                  block
                  color="green lighten-4"
                  v-on="on"
                  @click="runAnalysis"
                >
                  Calculate
                </v-btn>
              </template>
              <span style="font-size: 13px"
                >The calculations will be started.</span
              >
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <!-- Results -->
    <v-layout v-if="newResults" wrap>
      <v-flex xs12>
        <v-toolbar class="blue darken-2" dark height="50">
          <v-spacer></v-spacer>
          <div style="font-size: 18px; font-weight: bold">RESULTS</div>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
      <template v-for="(param, key, index) in outputs">
        <v-flex sm12 :key="index">
          <v-layout wrap>
            <v-flex xs9>
              <v-card
                flat
                height="90"
                class="text-xs-left elevation-2 ml-1 mt-3"
              >
                <div class="py-1 pl-2 elevation-1">
                  <div>{{ param.description.title }}</div>
                  <div>{{ param.description.desc }}</div>
                </div>
                <v-layout wrap>
                  <v-flex
                    class="my-2 mx-2 black--text text-xs-center"
                    style="font-size: 15px"
                    >{{ newResults[key].value | Digits(2) }}</v-flex
                  >
                </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs3>
              <v-card flat height="90" class="text-xs-left elevation-2 mt-3">
                <div class="pt-1 text-xs-center elevation-2">
                  <v-icon :color="`${changes[key].color} darken-3`">{{
                    changes[key].sign
                  }}</v-icon>
                  <div
                    :class="`pl-2 ${changes[key].color} darken-3 white--text`"
                  >
                    {{ changes[key].value | Digits(1) }} %
                  </div>
                </div>
                <v-flex class="pt-2" style="font-size: 15px">{{
                  param.suffix[system]
                }}</v-flex>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
    <v-snackbar
      v-model="snackbar.visible"
      multi-line
      :timeout="6000"
      color="red"
      bottom
    >
      {{ snackbar.text }}
      <v-btn color="white" text @click="snackbar.visible = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { inputs, shapeTypes } from "@/_calculators/src/shapeFactor/inputs";
import calculate from "@/_calculators/src/shapeFactor/functions";
import outputs from "@/_calculators/src/shapeFactor/outputs";

export default {
  name: "shapeFactor",
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      newResults: null,
      oldResults: null,
      changes: {},
      shapeTypes: shapeTypes,
      showResults: false,
      snackbar: {
        visible: false,
        text: "",
      },
      shapeFactor: -1,
      numberRules: [
        (v) => !!v || "Value is required",
        (v) => (v && v >= 0) || "Value must be greater than 0",
      ],
    };
  },
  watch: {
    newResults(val) {
      if (val) {
        this.updateChanges();
      }
    }
  },
  created() {
    this.resetChanges();
  },
  methods: {
    resetChanges() {
      for (const key in this.outputs) {
        if (this.outputs.hasOwnProperty(key)) {
          const element = this.outputs[key];
          this.changes[key] = {
            value: 0,
            color: "blue",
            sign: "swap_horiz"
          };
        }
      }
    },
    runAnalysis() {
      console.log(this.inputs);
      if (this.newResults) {
        this.oldResults = JSON.parse(JSON.stringify(this.newResults));
      }
      const res = calculate(this.inputs);
      this.newResults = { ...res };
      this.showResults = true;
    },
    updateChanges() {
      if (this.oldResults) {
        for (const key in this.newResults) {
          if (this.newResults.hasOwnProperty(key)) {
            const element = this.newResults[key];
            const rNew = element.value;
            const rOld = this.oldResults[key].value;
            const change = {
              value: 0,
              color: "blue",
              sign: "swap_horiz",
            };

            let result = -1;
            if (rOld && rNew) {
              result = ((rNew - rOld) / rOld) * 100;
              if (result > 0.005) {
                change.value = result;
                change.color = "green";
                change.sign = "trending_up";
              } else if (result < -0.005) {
                change.value = result;
                change.color = "red";
                change.sign = "trending_down";
              }
            }
            this.changes[key] = change;
          }
        }
      }
    },
  },
};
</script>
