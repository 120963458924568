<template>
  <HomeComponent />
</template>

<script>
  import HomeComponent from '../components/HomeComponent'

  export default {
    name: 'Home',
    data() {
      return {
      }
    },
    components: {
      HomeComponent
    }
  }
</script>
