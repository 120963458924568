<template>
    <v-layout wrap v-if="result1">
        <v-flex xs12>
            <v-toolbar class="blue" dark>
                <v-spacer></v-spacer>
                <div class="headline">Results</div>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-flex>

        <v-flex xs12 md6 class="pr-1">
            <v-alert v-if="result1.message === 'OK'" :value="true" type="success">
                Maximum Axial Load on Pile Safe Bearing Capacity of One Pile, Pu </v-alert>
            <v-alert v-else :value="true" type="error">
                Maximum Axial Load on Pile ≥ Safe Bearing Capacity of One Pile, Pu
            </v-alert>
            <v-card height="65" style="font-size: 16px" class="my-1 py-3 text-xs-center elevation-1">
                <v-layout>
                    <v-flex xs8>
                        <div class="ml-2 py-1">
                            Maximum Axial Load on Pile :
                        </div>
                    </v-flex>
                    <v-flex xs3>
                        <div class="grey darken-3 white--text pl-2 py-1">{{result1.PiMax | Digits(2)}} </div>
                    </v-flex>
                    <v-flex xs1>
                        <div class="py-1">
                            <span v-if="system === 'metric'">kN</span>
                            <span v-if="system === 'imperial'">kips</span>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card>
            <v-card height="75" style="font-size: 16px" class="my-1 text-xs-center" flat>
                <v-layout>
                    <v-flex xs5 offset-xs1>
                        <div class="py-4 mx-2 grey darken-3 white--text elevation-4">
                            <v-layout px-1>
                                <v-flex xs4>
                                    Σ x<sup>2</sup> :
                                </v-flex>
                                <v-flex xs4 class="text-xs-right">
                                    {{result1.sumXSqr | Digits(2)}}
                                </v-flex>
                                <v-flex xs2 class="text-xs-right">
                                    <span v-if="system === 'metric'">m</span>
                                    <span v-if="system === 'imperial'">ft</span>
                                    <sup>2</sup>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                    <v-flex xs5>
                        <div class="py-4 mx-2 grey darken-3 white--text elevation-4">
                            <v-layout px-1>
                                <v-flex xs4>
                                    Σ y<sup>2</sup> :
                                </v-flex>
                                <v-flex xs4 class="text-xs-right">
                                    {{result1.sumYSqr | Digits(2)}}
                                </v-flex>
                                <v-flex xs2 class="text-xs-right">
                                    <span v-if="system === 'metric'">m</span>
                                    <span v-if="system === 'imperial'">ft</span>
                                    <sup>2</sup>

                                </v-flex>
                            </v-layout>
                        </div>
                    </v-flex>
                </v-layout>
            </v-card>
        </v-flex>

        <!-- Data Table -->
        <v-flex xs12 md6 class="mt-1">
            <v-data-table :headers="headers" :items="result1.table" class="elevation-1" >
                <template slot="headerCell" slot-scope="props">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <span v-on="on">
                                {{ props.header.text }}
                            </span>
                        </template>
                        <span>
                            {{ props.header.text }}
                        </span>
                    </v-tooltip>
                </template>
                <template v-slot:items="props">
                    <td class="text-xs-center">{{ props.item.id }}</td>
                    <td class="text-xs-center">{{ props.item.x | Digits(2)  }}</td>
                    <td class="text-xs-center">{{ props.item.y | Digits(2)  }}</td>
                    <td class="text-xs-center">{{ props.item.Pi | Digits(2) }}</td>
                </template>
            </v-data-table>
        </v-flex>
    </v-layout>
</template>

<script>
    import resultCard from '@/_calculators/static/resultCard'
    export default {
        components: {
            resultCard
        },
        props: ['system', 'result1'],
        data() {
            return {
                headers: [{
                        text: 'Pile Number',
                        sortable: false,
                        align: 'center',
                        value: 'id'
                    },
                    {
                        text: 'x',
                        value: 'x',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'y',
                        value: 'y',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'Pi',
                        value: 'Pi',
                        align: 'center',
                        sortable: false
                    },
                ],
            }
        },
        methods: {
            getImgUrl(pic) {
                return require('@/assets/imgs/calculators/' + pic)
            }
        },
    }
</script>

<style scoped>
</style>