<template>
  <v-layout wrap>
    <v-flex xs12 v-for="category in categories" :key="category.id" class="mb-2">
      <div class="mx-2">
        <v-toolbar @click="category.isOpen = !category.isOpen" height="60" class="elevation-8" style="cursor: pointer;">
          <div class="headline">
            <v-icon>menu</v-icon>
            {{category.name}}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon v-if="category.isOpen">
            <v-icon large>arrow_drop_up</v-icon>
          </v-btn>
          <v-btn icon v-if="!category.isOpen">
            <v-icon large>arrow_drop_down</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <v-container fluid grid-list-sm class="px-2 py-1" v-show="category.isOpen">
        <v-layout wrap>
          <v-flex sm12 md6 lg4 v-for="calculator in category.calculators" :key="calculator.name">
            <v-card class="elevation-4">
              <v-img contain :src="getImgUrl(calculator.dbDetails.image)"></v-img>

              <v-badge :value="!calculator.id" overlap color="blue darken-2" icon="lock" right offset-y="3.5em">
                <v-card-title>
                  <span style="height:2em;">{{ calculator.main.title }}</span>
                </v-card-title>
              </v-badge>
              <v-card-text style="height:10em;" class="mt-3">
                <span>{{ calculator.dbDetails.shortDesc }}</span>
              </v-card-text>
              <v-card-actions class="blue">
                <v-btn text dark @click="goToDetailsPage(calculator)">Details</v-btn>
                <v-btn text dark :href="'../docs/verification/'+calculator.name+'.pdf'" target="_new">Verification
                </v-btn>
                <v-btn text dark @click="goToCalculator(calculator)">{{calculator.id?"Use":"Subscribe"}}</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
  import calculatorList from "@/_lists/calculatorList";
  import calculatorRoutes from "@/_lists/calculatorRoutes";
  export default {
    data() {
      return {
        calculatorList,
        calculatorRoutes,
        categories: [{
            id: 1,
            name: "Superstructure",
            calculators: [],
            isOpen: true
          },
          {
            id: 4,
            name: "Inspection & Maintenance",
            calculators: [],
            isOpen: true
          },
          {
            id: 2,
            name: "Joints & Bearings",
            calculators: [],
            isOpen: true
          },
          {
            id: 3,
            name: "Substructure",
            calculators: [],
            isOpen: true
          }
        ]
      };
    },
    created() {
      // sort calculators based on their number
      this.calculatorList.sort((a, b) => a.main.no - b.main.no);
      this.calculatorList.forEach(calculator => {
        var category = this.categories.find(
          category => category.id === calculator.main.catID
        );
        category.calculators.push(calculator);
      });
    },
    methods: {
      getImgUrl(pic) {
        return require("@/assets/imgs/dashboard/" + pic + ".png");
      },
      goToCalculator(el) {
        this.$router.push({
          name: el.name
        });
      },
      goToDetailsPage(el) {
        var calculator = calculatorRoutes.find(c => c.name === el.name);
        this.$router.push({
          name: "memberCalcDetails",
          params: {
            name: calculator.path
          }
        });
      }
    }
  };
</script>