module.exports = {
    Digits(value, digit){
        return parseFloat(value.toFixed(digit));
    },
    FixDigits(value){
        var v = '' + value;
        var splited = v.split('.');
        if(splited.length === 1){
            return splited[0] + '.00';
        } else {
            return splited[0] + '.' + splited[1];
        }
    }
}