import { Component } from "@/_calculators/src/simpleInspection/Component";

class InspectionData {
    // Creates a new inspection data instance with component lists in this file
    constructor(bridgeName, address, latitude, longitude, constructionYear) {
        this.bridgeName = {
            label: "Bridge Name",
            value: bridgeName,
            type: "text",
            input: true
        };
        this.address = {
            label: "Bridge Address",
            value: address,
            type: "text",
            input: true
        };
        this.latitude = {
            label: "Latitude",
            value: latitude,
            type: "number",
            input: true
        };
        this.longitude = {
            label: "Longitude",
            value: longitude,
            type: "number",
            input: true

        };
        this.constructionYear = {
            label: "Construction Year",
            value: constructionYear,
            type: "number",
            input: true
        };
        this.grade = {
            label: "Weighed Average Grade",
            value: -1,
            type: "number",
            input: false
        };
        this.components = processElementList(majorComponentList);
    }

    calculateGrade() {
        // average major component grades 
        let sum = 0;
        let count = 0;
        for (const component of this.components) {
            // update all level component grades (major and minor)
            const compGrade = component.updateGrade();
            sum += compGrade * component.weight;
            count += component.weight;
        }
        this.grade.value = count === 0 ? 0 : (sum / count).toPrecision(3);
    }

    getUngradedComponents() {
        const list = [];

        for (const component of this.components) {
            const ungraded = component.getUngraded();
            if (ungraded) {
                list.push(ungraded);
            }
        }
        return list;
    }
}

export { InspectionData };

// Return an array with component structure for the given list
function processElementList(itemList) {
    const componentList = [];
    for (const item of itemList) {
        const compName = item[0];
        const compWeight = item[1];
        const subComps = item[2];
        const newComp = new Component(compName, compWeight, []);
        // recursive call
        if (subComps.length > 0) {
            newComp.subcomponents = processElementList(subComps);
        }
        componentList.push(newComp);
    }
    return componentList;
}


// Element lists in the format [elementName, weight, []] - The last empty array is to preserve consistency among minor and major component lists
const deckList = [["Cracks", 1, []], ["Concrete Disintegration", 1, []], ["Apparent Reinforcement", 1, []], ["Holes and cavities", 1, []], ["Water leakage", 1, []]];
const beamList = [["Deformation", 1, []], ["Cracks", 1, []], ["Rusting (S)", 1, []], ["Bolts and Rivets (S)", 1, []], ["Welding (S)", 1, []], ["Concrete Disintegration (C)", 1, []], ["Apparent Reinforcement (C)", 1, []], ["Holes and cavities (C)", 1, []]];
const supportList = [["Main Damage", 1, []], ["Support Bed", 1, []], ["Loss of elements (M)", 1, []], ["Anchorage (M)", 1, []], ["Surface arrangement (E)", 1, []], ["Deformation (E)", 1, []]];
const pierList = [["Deformation", 1, []], ["Cracks", 1, []], ["Concrete Disintegration", 1, []], ["Apparent Reinforcement", 1, []], ["Holes and cavities", 1, []], ["Water and debris abrasion", 1, []], ["Scour in foundation", 1, []]];
const abutmentList = [["Deformation", 1, []], ["Cracks", 1, []], ["Concrete Disintegration", 1, []], ["Apparent Reinforcement", 1, []], ["Holes and cavities", 1, []], ["Water and debris abrasion", 1, []], ["Scour in foundation", 1, []]];
const approachList = [["Settlement and Slump", 1, []], ["Erosion on road platform", 1, []], ["Erosion in fill", 1, []]];
const stabilizationList = [["Settlement and Slump", 1, []], ["Erosion", 1, []], ["Scour in bed level", 1, []]];
const coatingList = [["Waving", 1, []], ["Tire tracks", 1, []], ["Cracks", 1, []], ["Holes and cavities", 1, []]];
const borderList = [["Cracks in the concrete (B)", 1, []], ["Concrete Disintegration (B)", 1, []], ["Apparent Reinforcement (B)", 1, []], ["Deformation in railing (R)", 1, []], ["Rusting in railing (R)", 1, []], ["Deficiency in railing (R)", 1, []]];
const expansionList = [["Noise", 1, []], ["Water leakage", 1, []], ["Deformation", 1, []], ["Holes and cavities", 1, []], ["Loss of elements", 1, []], ["Loss of function", 1, []]];
const drainageList = [["Pipe Damage", 1, []], ["Blockage", 1, []], ["Cleanout", 1, []]];

// minor component lists in the format [componentName, weight, elementList]
const mainBodyList = [["Deck", 1, deckList], ["Support (M: Metal E: Elastomeric)", 1, supportList], ["Beams (S: Steel C: Concrete)", 1, beamList], ["Piers", 1, pierList]];
const earthRetainingList = [["Stabilization", 1, stabilizationList], ["Approach Fill", 1, approachList], ["Abutment", 1, abutmentList]];
const serviceabilityList = [["Coating", 1, coatingList], ["Drainage", 1, drainageList], ["Border - Railing", 1, borderList], ["Expansion Joint", 1, expansionList]];

// major component lists in the formant [componentName, weight, minorComponentList]
const majorComponentList = [["Main Body Components", 1, mainBodyList], ["Earth Retaining Components", 1, earthRetainingList], ["Serviceability Components", 0.5, serviceabilityList]];
