<template>
  <v-app>
    <v-content>
      <router-view />

      <vue-cookie-accept-decline
        :ref="'cookiePanel'"
        :elementId="'cookiePanel'"
        :debug="false"
        :position="'bottom-right'"
        :type="'floating'"
        :disableDecline="false"
        :transitionName="'slideFromBottom'"
        :showPostponeButton="false"
        @status="cookieStatus"
        @clicked-accept="cookieClickedAccept"
        @clicked-decline="cookieClickedDecline"
      >
        <div slot="message">
          <p>We use tracking and identity cookies to ensure user management and traffic analysis. You can change your consent from eye icon on right bottom of page.</p>
        </div>
        <div slot="acceptContent">ACCEPT</div>
        <div slot="declineContent">LEAVE</div>
      </vue-cookie-accept-decline>
      <v-bottom-sheet>
        <template v-slot:activator="{on}">
          <v-fab-transition>
            <v-btn fab small right bottom fixed v-show="cookieConsentStatus" v-on="on">
              <v-icon color="blue-grey darken-2">visibility</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <v-sheet class="text-center pt-4">
          <div>
            <div>
              <span
                class="caption"
              >This site uses cookies to manage user accounts and track webiste analytics. Collected data is used for development purposes only.</span>
            </div>

            <div>
              <span
                :class="cookieConsentStatus=='accept'?'green--text':'red--text'"
              >{{CookieStatusLong}}</span>
            </div>
          </div>

          <v-btn outlined color="error" class="my-2" @click="revoke">Revoke Consent</v-btn>
        </v-sheet>
      </v-bottom-sheet>
    </v-content>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Bridgewiz Calculators",
    // all titles will be injected into this template
    titleTemplate: "%s | Bridgewiz Calculators"
  },
  mounted: function() {
    // todo - init when the user is in EU only? How to get location before consent??
    this.$refs.cookiePanel.init();
  },
  computed: {
    CookieStatusLong() {
      let statusText;
      if (!this.cookieConsentStatus) {
        statusText = "Not set";
      } else if (this.cookieConsentStatus == "accept") {
        statusText = "Cookies accepted";
      } else {
        statusText = "Cookies declined";
      }
      return statusText;
    }
  },
  methods: {
    cookieClickedAccept() {
      this.cookieConsentStatus = "accept";
    },
    cookieClickedDecline() {
      this.cookieConsentStatus = "decline";
      window.location.href = "https://gdpr.eu/";
    },
    cookieStatus(status) {
      this.cookieConsentStatus = status;
      // if the user denied the request previously
      if (status == "decline") {
        // revoke access and requery the user
        this.revoke();
      }
    },
    revoke() {
      // remove the cookie consent cookie
      this.$refs.cookiePanel.removeCookie();
      // re-display the cookie consent dialog
      this.$refs.cookiePanel.init();
    }
  },
  data() {
    return {
      cookieConsentStatus: null
    };
  }
};
</script>
