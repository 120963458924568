<template>
  <div>
    <v-card class="mx-auto" max-width="344" :loading="loading">
      <v-card-title>
        <v-layout column>
          <span class="grey--text subtitle-1">REVIEW</span>
          <div>{{ calculator.main.title }}</div>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-layout align-center wrap>
          <v-flex>
            <div style="width:100px;">Ease of Use</div>
          </v-flex>
          <v-flex>
            <v-rating
              v-model="easeOfUse"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$vuetify.icons.ratingFull"
              half-increments
              hover
            ></v-rating>
          </v-flex>
        </v-layout>
        <v-layout align-center wrap>
          <v-flex>
            <div style="width:100px;">Content</div>
          </v-flex>
          <v-flex xs-10>
            <v-rating
              v-model="content"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$vuetify.icons.ratingFull"
              half-increments
              hover
            ></v-rating>
          </v-flex>
        </v-layout>
        <v-layout align-center wrap>
          <v-flex>
            <div style="width:100px;">Accuracy</div>
          </v-flex>
          <v-flex>
            <v-rating
              v-model="accuracy"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$vuetify.icons.ratingFull"
              half-increments
              hover
            ></v-rating>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex>
            <div class="text-center">
              Send your comments to
              <a
                href="mailto:info@bridgewiz.com?subject=Calculators%20Review%Comments"
                target="_new"
              >info@bridgewiz.com</a>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="PostRating">Submit</v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="2000" color="success">
      Thank you for your review.
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebaseInit.js";
export default {
  name: "RateComponent",
  props: ["calculator", "dialog"],
  data() {
    return {
      easeOfUse: 4.5,
      accuracy: 4.5,
      content: 4.5,
      loading: false,
      snackbar: false,
      pastRatingId: null,
      pastRating: null
    };
  },
  async mounted() {
    await this.GetRating();
  },
  watch: {
    calculator: async function(oldVal, newVal) {
      await this.GetRating();
    }
  },
  methods: {
    async GetRating() {
      try {
        // disable card while trying to get past data
        this.loading = true;
        // mounted is called only when activator helper is called in the dialog on the parent page; not when the parent page is mounted
        // try to get the rating given by the user in the past, if there is any
        let ratingsRef = db.collection("ratings");
        let user = this.$store.getters["users/user"];
        let queryRef = ratingsRef
          .where("userId", "==", user._id)
          .where("calculatorId", "==", this.calculator.name);
        let snapshot = await queryRef.get();
        // if there are past ratings, set them to local
        if (!snapshot.empty) {
          // we should have only one document
          this.pastRatingId = snapshot.docs[0].id;
          // past rating is used to calculate the overall net change when a user votes for the second time
          this.pastRating = snapshot.docs[0].data();
          this.accuracy = this.pastRating.accuracy;
          this.easeOfUse = this.pastRating.easeOfUse;
          this.content = this.pastRating.content;
        }
        // if there are no past ratings, use default values
        else {
          this.accuracy = 4.5;
          this.easeOfUse = 4.5;
          this.content = 4.5;
        }
        // enable card
        this.loading = false;
      } catch (error) {
        // in case of error, use default values and enable card
        this.loading = false;
      }
    },
    async PostRating() {
      try {
        this.loading = true;
        // get a ref to firestore collection that holds user rating
        let ratingRef = db.collection("ratings");
        // get current user
        let user = this.$store.getters["users/user"];
        // get a ref to rating aggregate document of current calculator
        let aggregateRef = db
          .collection("ratingAggregates")
          .doc(this.calculator.name);
        // reference to document that holds the rating
        let ratingDocRef = null;
        // new instance of rating
        let newRating;
        // new instance of ratingAggregate changes
        let aggregateChanges;

        // start batch write
        let batch = db.batch();
        // if the pastRatingId is not null; there is a past rating in the db
        //  in this case, the overall change is newScore-oldScore and ratingCount is stagnant
        if (this.pastRatingId) {
          ratingDocRef = ratingRef.doc(this.pastRatingId);
          newRating = {
            userId: user._id,
            calculatorId: this.calculator.name,
            accuracy: this.accuracy,
            easeOfUse: this.easeOfUse,
            content: this.content
          };
          aggregateChanges = {
            accuracyCount: this.accuracy - this.pastRating.accuracy,
            easeOfUseCount: this.easeOfUse - this.pastRating.easeOfUse,
            contentCount: this.content - this.pastRating.content,
            ratingCount: 0
          };
        }
        // if the pastRatingid is null, there is no past rating, add a new record and increment ratingCount
        else {
          ratingDocRef = ratingRef.doc();
          newRating = {
            userId: user._id,
            calculatorId: this.calculator.name,
            accuracy: this.accuracy,
            easeOfUse: this.easeOfUse,
            content: this.content
          };
          aggregateChanges = {
            accuracyCount: this.accuracy,
            easeOfUseCount: this.easeOfUse,
            contentCount: this.content,
            ratingCount: 1
          };
        }
        // update rating
        batch.set(ratingDocRef, newRating, {
          merge: true
        });
        // update aggregates
        batch.set(
          aggregateRef,
          {
            accuracyCount: firebase.firestore.FieldValue.increment(
              aggregateChanges.accuracyCount
            ),
            contentCount: firebase.firestore.FieldValue.increment(
              aggregateChanges.contentCount
            ),
            easeOfUseCount: firebase.firestore.FieldValue.increment(
              aggregateChanges.easeOfUseCount
            ),
            ratingCount: firebase.firestore.FieldValue.increment(
              aggregateChanges.ratingCount
            )
          },
          {
            merge: true
          }
        );
        await batch.commit();
        this.loading = false;
        // show the snackbar on success
        this.snackbar = true;
        // emit the update to the dialog in case for the need of closing the dialog after 2 seconds
        setTimeout(() => {
          // emit the update to the dialog in case for the need of closing a dialog
          this.$emit("update:dialog", false);
        }, 1500);
      } catch (error) {
        // enable card again
        this.loading = false;
      }
    }
  }
};
</script>

<style>
</style>
