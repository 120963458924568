const inputs = {
    pierHeight: {
        label: "Pier Height",
        value: 10,
        cat: 1,
        type: "number",
        suffix: {
            metric: "m",
            imperial: "ft"
        },
        description: {
            title: "Pier Height",
            desc: "Total height of the pier"
        }
    },
    modulusOfElasticity: {
        label: "Material Modulus of Elasticity",
        value: 20000,
        cat: 1,
        type: "number",
        suffix: {
            metric: "MPa",
            imperial: "ksi"
        },
        description: {
            title: "Material Modulus of Elasticity",
            desc: "Material Modulus of Elasticity"
        }
    },
    inertia: {
        label: "Section Moment of Inertia",
        value: 0.04167,
        cat: 1,
        type: "number",
        suffix: {
            metric: "m⁴",
            imperial: "in⁴"
        },
        description: {
            title: "Section Moment of Inertia",
            desc: "Section Moment of Inertia"
        }
    },
    mass: {
        label: "Total Mass over Pier",
        value: 10,
        cat: 1,
        type: "number",
        suffix: {
            metric: "tons",
            imperial: "kips"
        },
        description: {
            title: "Total Mass over Pier",
            desc: "Total Mass Over Pier"
        }
    },
    dampingRatio: {
        label: "Damping Ratio",
        value: 0,
        cat: 1,
        type: "number",
        suffix: {
            metric: "%",
            imperial: "%"
        },
        description: {
            title: "Damping Ratio",
            desc: "Damping Ratio"
        }
    },
    loadingType: {
        label: "Loading Type",
        value: "explosion",
        cat: 2,
        type: "radioBox",
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "Loading Type",
            desc: "Loading Type"
        }
    },
    explosion: {
        label: "Explosion Parameters",
        value: null,
        cat: 3,
        type: "composite",
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "",
            desc: ""
        },
        parameters: {
            p0: {
                label: "P0",
                value: 500,
                cat: 3,
                type: "number",
                suffix: {
                    metric: "kN",
                    imperial: "ksi"
                },
                description: {
                    title: "P0",
                    desc: "P0"
                }
            },
            T: {
                label: "T",
                value: 0.5,
                cat: 3,
                type: "number",
                suffix: {
                    metric: "seconds",
                    imperial: "seconds"
                },
                description: {
                    title: "T",
                    desc: "T"
                }
            }
        }
    },
    acceleration: {
        label: "Acceleration Parameters",
        value: null,
        cat: 4,
        type: "composite",
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "Acceleration Parameters",
            desc: "Acceleration Parameters"
        },
        parameters: {
            accelerations: {
                label: "Accelerations",
                value: [],
                cat: 4,
                type: "array",
                suffix: {
                    metric: "m/s/s",
                    imperial: "ft/s/s"
                },
                description: {
                    title: "Accelerations",
                    desc: "Accelerations"
                }
            },
            dt: {
                label: "Time Step",
                value: 0.02,
                cat: 4,
                type: "number",
                suffix: {
                    metric: "seconds",
                    imperial: "seconds"
                },
                description: {
                    title: "Time Step",
                    desc: "Time Step"
                }
            },
            headerLines: {
                label: "Header Lines to Skip",
                value: 0,
                cat: 4,
                type: "number",
                suffix: {
                    metric: "",
                    imperial: ""
                },
                description: {
                    title: "Header Lines to Skip",
                    desc: "Header Lines to Skip"
                }
            }
        }
    }
};

const loadingTypes = [
    {
        text: "Explosion (Simplified Ramp)",
        value: "explosion"
    }
    ,
    {
        text: "Ground Acceleration",
        value: "acceleration"
    }
];


export { inputs, loadingTypes }