var metric = {
  n: 20,
  m: 10,
  dx: 3,
  dy: 7,
  Pu: 464,
  Pv: 4354,
  Mx: 454,
  My: 345
};

var imperial = {
  n: 20,
  m: 10,
  dx: 3,
  dy: 7,
  Pu: 464,
  Pv: 4354,
  Mx: 454,
  My: 345
};

export default {
  metric,
  imperial
}