import outputs from "@/_calculators/src/plateFilletWeld/outputs";

function calculate(inputs, system) {
    let iv = {};
    // calculations are in SI, if system is imperial convert to SI
    for (const key in inputs) {
        if (inputs.hasOwnProperty(key)) {
            const element = inputs[key];
            if (system === "metric") {
                iv[key] = element.value;
            } else {
                if (key === "electrode") {
                    iv[key] = element.value * 4.44822;
                } else {
                    iv[key] = element.value * 25.4;
                }
            }
        }
    }
    const tmax = iv.t1 > iv.t2 ? iv.t1 : iv.t2;
    let wmin = 0;
    if (tmax < 6) {
        wmin = 3;
    } else if (tmax >= 6 && tmax < 12) {
        wmin = 5;
    } else if (tmax >= 12 && tmax < 18) {
        wmin = 7;
    } else {
        wmin = 8;
    }
    const Q1 = iv.b * iv.t1 * (iv.D / 2 + iv.t1 / 2);
    const I = 1 / 12 * Math.pow(iv.D, 3) * iv.t2 + 2 * iv.b * iv.t1 * Math.pow(iv.D / 2 + iv.t1 / 2, 2);
    // ASD
    let qd = iv.V * 1000 * Q1 / I;
    const wreqASD = qd / (2 * 0.707 * iv.electrode * 0.3);
    const wASD = wreqASD < wmin ? wmin : wreqASD;
    // LRFD
    qd = iv.V * 1.5 * 1000 * Q1 / I;
    const wreqLRFD = qd / (2 * 0.707 * iv.electrode * 0.6 * 0.75);
    const wLRFD = wreqLRFD < wmin ? wmin : wreqLRFD;
    if (system === "imperial") {
        outputs.wASD.value = wASD / 25.4;
        outputs.wLRFD.value = wLRFD / 25.4;
    } else {
        outputs.wASD.value = wASD;
        outputs.wLRFD.value = wLRFD;
    }


    return outputs;
}

export default calculate;