<template>
    <div>
        <v-layout>
            <v-flex xs12>
                <v-data-table v-model="selected"
                              show-select
                              :headers="loadHeaders"
                              :items="pointLoads"
                              item-key="key">
                    <!--Top slot-->
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-toolbar-title>Point Loads ({{ suffix }})</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog v-model="loadAddDialog" max-width="500px">
                                <template v-slot:activator="{ on }">
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="primary"
                                                   icon
                                                   class="mr-4 mb-2"
                                                   v-on="on"
                                                   @click="showAddLoad">
                                                <v-icon large>add_circle_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Add New Point Load</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="error" icon class="mb-2" v-on="on" @click="deleteLoads">
                                                <v-icon large>delete_outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete Selected Loads</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="green" icon class="mb-2" v-on="on" @click="updateFigure">
                                                <v-icon large>refresh</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Update Figure</span>
                                    </v-tooltip>
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-btn color="blue" icon class="mb-2" v-on="on" @click="loadSampleData">
                                                <v-icon large>call_received</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Load Sample Data</span>
                                    </v-tooltip>
                                </template>
                                <v-card>
                                    <v-card-title>Add Point Load</v-card-title>
                                    <v-card-text>
                                        <v-form v-model="loadValid">
                                            <v-text-field v-model="newLoad.distance"
                                                          :rules="numberRules"
                                                          label="Distance"
                                                          required
                                                          typeof="number">
                                            </v-text-field>
                                            <v-text-field v-model=newLoad.load
                                                          :rules="numberRules"
                                                          label="Value"
                                                          required
                                                          type="number"></v-text-field>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="error" text @click="loadAddDialog=false">Close</v-btn>
                                        <v-btn color="primary" text @click="addLoad">Add</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <!--item slots-->
                    <!--TODO: The following edit does not work properly; gets dismissed after key press and converts value to string-->
                    <!--<template v-slot:item.load="props">
                        <v-edit-dialog :return-value.sync="props.item.load" large persistent>
                            <div>{{props.item.load}}</div>
                            <template v-slot:input>
                                <v-text-field v-model="props.item.load"
                                              :rules="numberRules"
                                              label="Edit"
                                              type="number"
                                              aria-required="true"
                                              single-line
                                              autofocus>
                                </v-text-field>
                            </template>
                        </v-edit-dialog>
                    </template>-->
                    <!--No data slot-->
                    <template v-slot:no-data>
                        <v-alert :value="true" color="info" icon="info" dense outlined>No loads defined</v-alert>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="snackbar.show"
                    :color="snackbar.color"
                    bottom
                    multi-line
                    :timeout="snackbar.timeout">
            {{snackbar.text}}
            <v-btn dark text @click="snackbar.show=false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import { PointLoad } from "@/_calculators/src/simpleSupportedBeam/inputs";
    export default {
        name: "PointLoadsComponent",
        props: ["pointLoads", "system"],
        data() {
            return {
                units: {
                    metric: "N, m",
                    imperial: "lb, ft"
                },
                loadHeaders: [
                    { text: "Distance", value: "distance" },
                    { text: "Load", value: "load" }
                ],
                newLoad: new PointLoad(10, 5),
                localLoads: [],
                loadAddDialog: false,
                loadValid: true,
                numberRules: [v => !!v || "This field is required"],
                selected: [],
                snackbar: {
                    show: false,
                    color: "success",
                    text: "Success",
                    timeout: 3000
                }
            };
        },
        computed: {
            suffix: function () {
                return this.units[this.system];
            }
        },
        created() {
            this.localLoads = this.pointLoads
        },
        methods: {
            loadSampleData() {
                this.localLoads = [
                    new PointLoad(5, 5),
                    new PointLoad(10, 5)
                ];
                this.$emit("update-point-loads", this.localLoads);
            },
            showSnackbar(color, text) {
                this.snackbar.color = color;
                this.snackbar.text = text;
                this.snackbar.show = true;
            },
            showAddLoad() {
                this.loadAddDialog = true;
            },
            deleteLoads() {
                this.localLoads = this.localLoads.filter(
                    s => !this.selected.includes(s)
                );
                this.selected = [];
                this.$emit("update-point-loads", this.localLoads);
            },
            updateFigure() {
                this.$emit("update-figure");
            },
            addLoad() {
                const exists = this.localLoads.some(e =>
                    e.key === this.newLoad.key
                );
                if (exists) {
                    this.showSnackbar("error", "Load already exists");
                }
                else {
                    this.localLoads.push(this.newLoad.clone());
                    // sort local loads
                    this.localLoads.sort((a, b) => {
                        if (a.distance > b.distance) return 1;
                        if (a.distance < b.distance) return -1;
                        return 0;
                    });
                    this.$emit("update-point-loads", this.localLoads);
                }
            }
        }
    };
</script>