<template>
  <div>
    <!-- concreteGirderGeometry -->
    <structure
      inputType="c3_combobox"
      :categoryNumber="2"
      :excludedList="excludedList"
      img1="concreteGirderGeometry_i_noncomp.png"
      img2="concreteGirderGeometry_i_comp.png"
      :titles="['Section Dimensions', 'Composite Section Dimensions']"
      :tooltips="[
                'Please fill the parameters with proper values that are positive.', 
                'Composite section will be computed for deck and girder having different material properties. The composite section properties will be based on girder material properties. All values shall be positive.'
            ]"
      :inputs="inputs"
      :testData="testData"
      @CALC_METRIC="calcMetric"
      @CALC_IMP="calcImperial"
      @system="systemChanged"
      @CLEAR_RESULTS="clearResults"
      @GENERATE_REPORT="generateReportEmitted"
      @INPUT_MISSING="inputMissingEmitted"
    />

    <results
      resultType="c2"
      img1="concreteGirderGeometry_o_noncomp.png"
      img2="concreteGirderGeometry_o_comp.png"
      :titles="['Section Properties', 'Composite Section Properties']"
      :outputs="outputs"
      :system="system"
      :result1="result1"
      :result2="result2"
    />

    <!-- Snackbar for errors -->
    <v-snackbar v-model="errorSnack" :timeout="3000" color="red" bottom>
      {{ errorText }}
      <v-btn color="white" text @click="errorSnack = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import structure from "@/_calculators/static/calculatorStructure";
import results from "@/_calculators/static/results";
import functions from "@/_calculators/src/concreteGirderGeometry/functions";
import inputs from "@/_calculators/src/concreteGirderGeometry/inputs";
import outputs from "@/_calculators/src/concreteGirderGeometry/outputs";
import testData from "@/_calculators/src/concreteGirderGeometry/testData";
import { GeneratePDFReport } from "@/_calculators/src/shared/report";

export default {
  components: {
    structure,
    results
  },
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      testData: testData,
      result1: null,
      result2: null,
      excludedList: ["composite"],
      errorSnack: false,
      errorText: null
    };
  },
  created() {
    this.resetInitial();
  },
  computed: {
    composite() {
      return this.inputs.composite.value;
    }
  },
  watch: {
    system() {
      this.clearResults();
    },
    /* Dynamic */
    composite(val) {
      this.clearResults();

      if (val === "Yes") {
        this.inputs.ts.value = null;
        this.inputs.s.value = null;
        this.inputs.Eg.value = null;
        this.inputs.Es.value = null;
      }
    }
  },
  methods: {
    /* Static */
    systemChanged(val) {
      this.system = val;
    },
    calcMetric() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "metric");
    },
    calcImperial() {
      if (this.result1) {
        this.result2 = this.result1;
      }
      this.result1 = functions.calculate(this.inputs, "imperial");
    },
    clearResults() {
      this.result1 = null;
      this.result2 = null;
    },
    /* Dynamic */
    resetInitial() {
      this.inputs.composite.value = "No";
      this.inputs.ts.value = null;
      this.inputs.s.value = null;
      this.inputs.Eg.value = null;
      this.inputs.Es.value = null;

      this.inputs.ts.needToCheck = false;
      this.inputs.s.needToCheck = false;
      this.inputs.Eg.needToCheck = false;
      this.inputs.Es.needToCheck = false;
    },
    async generateReportEmitted() {
      try {
        let user = this.$store.getters["users/user"];
        await GeneratePDFReport(
          this.inputs,
          this.outputs,
          this.result1,
          "Concrete Girder Geometry",
          `${user.name} ${user.surname}`,
          this.system
        );
      } catch (error) {
        if (error.name === "TypeError") {
          this.errorSnack = true;
          this.errorText = "Please run the calculations before reporting.";
        } else {
          this.errorSnack = true;
          this.errorText = `The following error has occurred: ${error.name}`;
        }
      }
    },
    inputMissingEmitted() {
        this.errorSnack = true;
        this.errorText = "Please fill in all the input fields";
    }
  }
};
</script>

<style>
</style>