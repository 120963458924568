<template>
    <v-layout wrap v-if="result1">
        <v-flex xs12>
            <v-toolbar class="blue darken-2" dark height="50">
                <v-spacer></v-spacer>
                <div style="font-size: 18px; font-weight: bold;">RESULTS</div>
                <v-spacer></v-spacer>
            </v-toolbar>
            <!-- Category 0 -->
            <v-layout wrap>
                <v-flex xs12 class="mt-1 pl-3">
                    <v-flex xs6 offset-xs3 class="px-3" v-for="(result, i) in outputs.o0" :key="i">
                        <div class="mt-1 elevation-2">
                            <!-- Component -->
                            <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                                :system="system" />
                        </div>
                    </v-flex>
                </v-flex>
            </v-layout>
            <v-layout wrap>
                <!-- Category 1-->
                <v-flex xs6 class="pl-3" v-for="(result, i) in outputs.o1" :key="i">
                    <div class="mt-2 elevation-2">
                        <!-- Component -->
                        <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                            :system="system" />
                    </div>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
    import resultCard from '@/_calculators/static/resultCard'
    export default {
        components: {
            resultCard
        },
        props: ['outputs', 'system', 'result1', 'result2', 'changes'],
        data() {
            return {
            }
        }
    }
</script>

<style>

</style>