import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from './App.vue'
import router from './router'
import { store } from './store'
import firebase from 'firebase/app'
import Filters from '@/Filters'
import 'firebase/auth'
import VueAnalytics from 'vue-analytics'
import Meta from 'vue-meta'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false;

Vue.filter('Digits', Filters.Digits);
Vue.filter('FixDigits', Filters.FixDigits);
Vue.use(VueAnalytics, {
  id: 'UA-146361740-1',
  router
});
Vue.use(Meta, {
  refreshOnceOnNavigation: true
});
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline);
Vue.component('apexchart', VueApexCharts);
let app;
firebase.auth().onAuthStateChanged(async user => {
  if (user) {
    await store.dispatch('users/autoLogin', user.uid)
  } else {
  }
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: function (h) { return h(App) }
    }).$mount('#app')
  }
})