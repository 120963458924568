import { cr } from "./shared"

export class Station {
    constructor(distance, roadElevation, valleyElevation) {
        this.distance = Number(distance);
        this.roadElevation = Number(roadElevation);
        this.valleyElevation = Number(valleyElevation);
    }
    clone() {
        return new Station(this.distance, this.roadElevation, this.valleyElevation);
    }
}

export class CostEstimationOptions {
    constructor(maxPretensionSpan, maxCompositeSpan, maxPierHeight, deckWidth) {
        this.maxPretensionSpan = maxPretensionSpan;
        this.maxCompositeSpan = maxCompositeSpan;
        this.maxPierHeight = maxPierHeight;
        this.deckWidth = deckWidth;
    }
}

export class UnitPrices {
    constructor(pretensionConcrete, compositeConcrete, postTensionConcrete, substructureConcrete, pretensionStrand, compositeSteel, reinforcementSteel) {
        this.pretensionConcrete = pretensionConcrete;
        this.compositeConcrete = compositeConcrete;
        this.postTensionConcrete = postTensionConcrete;
        this.substructureConcrete = substructureConcrete;
        this.pretensionStrand = pretensionStrand;
        this.compositeSteel = compositeSteel;
        this.reinforcementSteel = reinforcementSteel;
    }
}

export class Pier {
    constructor(station) {
        this.station = station;
    }

    get height() {
        return cr(Number(this.station.roadElevation) - Number(this.station.valleyElevation));
    }

    clone() {
        return new Pier(this.station.clone());
    }
}

export class Deck {
    constructor(startStation, endStation, type) {
        this.startStation = startStation;
        this.endStation = endStation;
        this.type = type
    }

    get length() {
        return cr(this.endStation.distance - this.startStation.distance);
    }

}

export class Cost {
    constructor(concreteAmount, concreteCost, prestressSteelAmount, prestressSteelCost, reinforcementAmount, reinforcementCost, compositeSteelAmount, compositeSteelCost) {
        this.concreteAmount = concreteAmount;
        this.concreteCost = concreteCost;
        this.prestressSteelAmount = prestressSteelAmount;
        this.prestressSteelCost = prestressSteelCost;
        this.reinforcementAmount = reinforcementAmount;
        this.reinforcementCost = reinforcementCost;
        this.compositeSteelAmount = compositeSteelAmount;
        this.compositeSteelCost = compositeSteelCost;
    }

    get totalCost() {
        return cr(this.concreteCost + this.prestressSteelCost + this.reinforcementCost + this.compositeSteelCost);
    }

    static BlankCost() {
        return new Cost(0, 0, 0, 0, 0, 0, 0, 0);
    }
}