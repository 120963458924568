const testData = {
    metric: {
        b: 1250,
        D: 2890,
        t1: 55,
        t2: 20,
        V: 1922
    },
    imperial: {
        b: 50,
        D: 115,
        t1: 2.17,
        t2: 0.8,
        V: 432
    }
}

export default testData;