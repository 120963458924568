const inputs = {
    t1: {
        label: 'Web Thickness',
        value: 55,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Web thickness (t2)',
            desc: 'Web thickness'
        }
    },
    t2: {
        label: 'Flange Thickness',
        value: 20,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Flange Thickness (t1)',
            desc: 'Flange Thickness'
        }
    },
    b: {
        label: 'Flange Width',
        value: 1250,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Flange width (b)',
            desc: 'Flange width'
        }
    },
    D: {
        label: 'Web Height',
        value: 2890,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Web height (D)',
            desc: 'Web height'
        }
    },
    V: {
        label: 'Unfactored Shear Force',
        value: 1922,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'kN',
            imperial: 'kip'
        },
        description: {
            title: 'Unfactored shear force on section',
            desc: 'Unfactored shear force on section'
        }
    },
    electrode: {
        label: 'Minimum Tensile Strength (Electrode)',
        value: 413,
        type: 'string',
        suffix: {
            metric: 'MPa',
            imperial: 'ksi'
        }
    }
}

const electrodes = [
    { text: "E60", value: 413 },
    { text: "E70", value: 482 },
    { text: "E80", value: 552 },
    { text: "E100", value: 689 }
];

export { inputs, electrodes }