var inputs = {
    composite: {
        label: 'Is It Composite ?',
        value: 'No',
        cat: 2,
        type: 'combobox',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },
    x1: {
        label: 'x1',
        value: 700,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    x2: {
        label: 'x2',
        value: 1030,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    x3: {
        label: 'x3',
        value: 200,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    x4: {
        label: 'x4',
        value: 50,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    y1: {
        label: 'y1',
        value: 200,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    y2: {
        label: 'y2',
        value: 100,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    y3: {
        label: 'y3',
        value: 800,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    y4: {
        label: 'y4',
        value: 100,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    y5: {
        label: 'y5',
        value: 75,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    y6: {
        label: 'y6',
        value: 125,
        cat: 1,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },

    ts: {
        label: 'Slab Thickness , ts',
        value: 250,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Slab Thickness',
            desc: ''
        }
    },

    s: {
        label: 'Girder Spacing , s',
        value: 1350,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Girder Spacing',
            desc: ''
        }
    },
    Eg: {
        label: 'Girder Modulus of Elasticity',
        value: 32000,
        cat: 2,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Girder Modulus of Elasticity',
            desc: ''
        }
    },
    Es: {
        label: 'Slab Modulus of Elasticity',
        value: 28000,
        cat: 2,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Slab Modulus of Elasticity',
            desc: ''
        }
    }

};

var categoryNames = [
    'Prestressing Steel',
    'Geometry',
    ''
]

export default inputs