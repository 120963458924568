const inputs = {
    L: {
        label: "Plan Dimension perpendicular to axis of rotation",
        value: 700,
        cat: 3,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Plan Dimension perpendicular to axis of rotation",
            desc: "Plan Dimension perpendicular to axis of rotation",
        }
    },
    W: {
        label: "Plan Dimension parallel to axis of rotation",
        value: 700,
        cat: 3,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Plan Dimension parallel to axis of rotation",
            desc: "Plan Dimension parallel to axis of rotation",
        }
    },
    hri: {
        label: "Single rubber layer thickness",
        value: 12,
        cat: 1,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Single rubber layer thickness",
            desc: "Single rubber layer thickness",
        }
    },
    D: {
        label: "Plan Diameter",
        value: 700,
        cat: 4,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Plan Diameter",
            desc: "Plan Diameter",
        }
    },
    d: {
        label: "Diameter of holes",
        value: 0,
        cat: 1,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Diameter of holes",
            desc: "Diameter of holes",
        }
    },
    dt: {
        label: "Number of holes",
        value: 0,
        cat: 1,
        type: "number",
        suffix: {
            metric: "mm",
            imperial: "in"
        },
        description: {
            title: "Number of holes",
            desc: "Number of holes",
        }
    },
    shapeType: {
        label: "Shape Type",
        value: "rectangular",
        cat: 2,
        type: "radioBox",
        suffix: {
            metric: "",
            imperial: ""
        },
        description: {
            title: "Shape Type",
            desc:  "Shape Type"
        }
    }
}

const shapeTypes = [
    {
        text: "Circular",
        value: "circular"
    },
    {
        text: "Rectangular",
        value: "rectangular"
    }
]

export {inputs, shapeTypes}