const inputs = {
    PDL: {
        label: 'Unfactored Dead Load',
        value: 7000,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'kN',
            imperial: 'kip'
        },
        description: {
            title: 'Unfactored Dead Load (PDL)',
            desc: 'Unfactored Dead Load'
        }
    },
    fc: {
        label: '28 Day Compressive Strength',
        value: 30,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'MPa',
            imperial: 'ksi'
        },
        description: {
            title: '28 Day Compressive Strength (fc)',
            desc: '28 Day Compressive Strength'
        }
    },
    L: {
        label: 'Column Height',
        value: 10,
        cat: 1,
        type: 'number',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Column Height (L)',
            desc: 'Column Height'
        }
    }
}

export { inputs }