<template>
  <div id="leftBar">
    <v-list flat>
      <!-- Title Box of The Sidebar -->
      <v-list-item :to="{routeName: 'dashboard'}" active-class="white--text">
        <v-list-item-action>
          <v-icon>home</v-icon>
        </v-list-item-action>
        <v-list-item-content id="leftBarTitle">
          <v-list-item-title>BridgeWIZ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <br>
      <!-- Menu List Items of The Sidebar -->
      <div v-for="item in items" :key="item.title">
        <v-list-item v-if="!item.children" active-class="white--text grey darken-4" :to="{name: item.path}">
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content class="font-weight-light">
            <v-list-item-title subtitle-2>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-else>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="font-weight-light">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item active-class="white--text grey darken-4" v-for="(child, i) in item.children" :key="i"
            :to="{name: child.name}">
            <v-list-item-action>
              <div class="calcList ml-1">
                <h6 v-if="i+1 < 10">0{{i+1}}</h6>
                <h6 v-else>{{i+1}}</h6>
              </div>
            </v-list-item-action>
            <v-list-item-content class="font-weight-light">
              <v-list-item-title class="subtitle-2">{{ child.main.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </div>
    </v-list>
  </div>
</template>

<script>
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        items: [{
            title: "Dashboard",
            icon: "dashboard",
            path: "dashboard"
          },
          {
            title: "Calculators",
            icon: "event_available",
            path: "calculators",
            children: []
          },
          {
            title: 'My Account',
            icon: 'verified_user',
            path: 'payment'
          },
          {
            title: 'News',
            icon: 'email',
            path: 'news'
          },
          {
            title: "About Us",
            icon: "info",
            path: "about"
          }
        ]
      };
    },
    computed: {
      ...mapState("general", ["calculators"])
    },
    mounted() {
      this.items[1].children = this.calculators.sort(
        (a, b) => a.main.no - b.main.no
      );
    }
  };
</script>

<style>

</style>