var inputs = {
    gammaConc: {
        label: 'Unit Weigth of Reinforced Concrete',
        value: null,
        cat: 1, //34 karakter
        suffix: {
            metric: 'kN/m³',
            imperial: 'kips/ft³'
        },
        description: {
            title: 'Unit Weigth of Reinforced Concrete',
            desc: ''
        }
    },
    L: {
        label: 'Span Length',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    },

    tslab: {
        label: 'Slab Thickness , ts',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Slab Thickness',
            desc: ''
        }
    },
    s: {
        label: 'Girder Spacing , s',
        value: null,
        cat: 1,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Girder Spacing',
            desc: ''
        }
    }
}
export default inputs