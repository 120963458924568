import outputs from "../shapeFactor/outputs";

function calculate(inputs) {
    let result = -1;
    const L = inputs.L.value;
    const W = inputs.W.value;
    const hri = inputs.hri.value;
    const numOfHoles = inputs.dt.value
    const d = inputs.d.value;
    const D = inputs.D.value;

    if (inputs.shapeType.value === "rectangular") {
        if (numOfHoles === 0) {
            result = ((L * W) / (2 * hri * (L+W)));
        }
        else {
            const sum1 = Math.PI * Math.pow(d, 2) / 4 * numOfHoles;
            const sum2 = Math.PI * d * numOfHoles;
            result = ((L * W - sum1) / (hri * (2*L+2*W+sum2)));
        }
    }
    else {
        if (numOfHoles === 0) {
            result = D / (4 * hri);
        }
        else {
            const sum1 = Math.pow(d, 2) * numOfHoles;
            const sum2 = d * numOfHoles;
            result = ((Math.pow(D, 2) - sum1) / (4*hri*(D+sum2)));
        }
    }
    outputs.S.value = result;

    return outputs;
}

export default calculate;