var metric = {
  aps: 140,
  nps: 19,
  fpu: 1860,
  els: 197500,
  jsr: 75,
  wob: 6.6e-4, //1/m
  anc: 2,
  jlen: 1,
  xd: 2.2,
  bc: 60,
  yd: 0.3
};

var imperial = {
  aps: 0.217,
  nps: 19,
  fpu: 270000,
  els: 28500000,
  jsr: 75,
  wob: 0.0002, // 1/ft
  anc: 0.1,
  jlen: 3.3,
  xd: 7,
  bc: 200,
  yd: 1
};

export default {
  metric,
  imperial
}