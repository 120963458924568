var inputs = {
    loadType: {
        label: 'loadType',
        value: null,
        cat: 1,
        type: 'combobox',
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Load Type',
            desc: ''
        }
    },
    P1: {
        label: 'Axle Load, P1',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Axle Load, P1',
            desc: ''
        }
    },

    wllu: {
        label: 'Uniform Loading',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN/m',
            imperial: 'kips/ft'
        },
        description: {
            title: 'Uniform Loading',
            desc: ''
        }
    },

    P2: {
        label: 'Axle Load, P2',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Axle Load',
            desc: ''
        }
    },

    x1: {
        label: 'Axle Distance, x1',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Axle Distance',
            desc: ''
        }
    },

    P3: {
        label: 'Axle Load, P3',
        value: null,
        cat: 2,
        suffix: {
            metric: 'kN',
            imperial: 'kips'
        },
        description: {
            title: 'Axle Load',
            desc: ''
        }
    },

    x2: {
        label: 'Axle Distance, x2',
        value: null,
        cat: 2,
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Axle Distance',
            desc: ''
        }
    },
    L: {
        label: 'Span Length',
        cat: 2,
        type: 'notDisabled',
        value: '30',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Span Length',
            desc: ''
        }
    },

    IM: {
        label: 'Dynamic Load Allowance',
        cat: 2,
        type: 'notDisabled',
        value: '33',
        suffix: {
            metric: '%',
            imperial: '%'
        },
        description: {
            title: 'Dynamic Load Allowance',
            desc: ''
        }
    }
};
export default inputs