<template>
  <v-layout>
    <v-flex xs12>
      <v-card class="elevation-0">
        <v-card-title>
          <v-radio-group v-model="selectedDisplay" row class="mt-0 pt-0 ml-0 pl-0">
            <v-radio label="Materials" value="materials"></v-radio>
            <v-radio label="Cost" value="cost"></v-radio>
          </v-radio-group>
        </v-card-title>
        <v-card-text>
          <apexchart height="250" type="bar" :options="chartOptions" :series="chartSeries"></apexchart>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { Cost } from "@/_calculators/src/costEstimation/inputs";

export default {
  name: "AlternativeChartComponent",
  components: {
    VueApexCharts
  },
  props: {
    alternatives: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedDisplay: "materials",
      chartOptions: {
        chart: {
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: undefined
        },
        xaxis: {
          categories: ["Pretension", "Post-tension", "Composite"],
          labels: {
            formatter: function(val) {
              return `${val / 1000}k`;
            }
          }
        },
        yaxis: {
          title: {
            text: undefined
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val.toFixed(2);
            }
          }
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  },
  computed: {
    chartSeries: function() {
      let pretensionCost = this.alternatives.find(q => q.name === "Pretension")
        .costs;
      // if an alternative is not valid, its cost will be null
      if (!pretensionCost) pretensionCost = Cost.BlankCost();
      const compositeCost = this.alternatives.find(q => q.name === "Composite")
        .costs;
      if (!compositeCost) compositeCost = Cost.BlankCost();
      const posttensionCost = this.alternatives.find(
        q => q.name === "Post-tension"
      ).costs;
      if (!posttensionCost) posttensionCost = Cost.BlankCost();
      if (this.selectedDisplay === "cost") {
        // only first level is reactive
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: ["Pretension", "Post-tension", "Composite"],
              labels: {
                formatter: function(val) {
                  return `$${val / 1000000}M`;
                }
              }
            }
          }
        };
        const concrete = {
          name: "Concrete",
          data: [
            pretensionCost.concreteCost,
            posttensionCost.concreteCost,
            compositeCost.concreteCost
          ]
        };
        const strands = {
          name: "Prestress Cables",
          data: [
            pretensionCost.prestressSteelCost,
            posttensionCost.prestressSteelCost,
            compositeCost.prestressSteelCost
          ]
        };
        const compSteel = {
          name: "Composite Steel",
          data: [
            pretensionCost.compositeSteelCost,
            posttensionCost.compositeSteelCost,
            compositeCost.compositeSteelCost
          ]
        };
        const reinf = {
          name: "Reinforcement",
          data: [
            pretensionCost.reinforcementCost,
            posttensionCost.reinforcementCost,
            compositeCost.reinforcementCost
          ]
        };
        return [concrete, reinf, strands, compSteel];
      } else {
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: ["Pretension", "Post-tension", "Composite"],
              labels: {
                formatter: function(val) {
                  return `${val / 1000}k`;
                }
              }
            }
          }
        };
        const concrete = {
          name: "Concrete",
          data: [
            pretensionCost.concreteAmount,
            posttensionCost.concreteAmount,
            compositeCost.concreteAmount
          ]
        };
        const strands = {
          name: "Prestress Cables",
          data: [
            pretensionCost.prestressSteelAmount,
            posttensionCost.prestressSteelAmount,
            compositeCost.prestressSteelAmount
          ]
        };
        const compSteel = {
          name: "Composite Steel",
          data: [
            pretensionCost.compositeSteelAmount,
            posttensionCost.compositeSteelAmount,
            compositeCost.compositeSteelAmount
          ]
        };
        const reinf = {
          name: "Reinforcement",
          data: [
            pretensionCost.reinforcementAmount,
            posttensionCost.reinforcementAmount,
            compositeCost.reinforcementAmount
          ]
        };
        return [concrete, reinf, strands, compSteel];
      }
    }
  }
};
</script>

<style>
</style>