/* D:\Users\user\Desktop\calculators\calculators\src\components\calculators\src\minRequiredNoOfStrands\functions.js
 */
function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */

    GirderCompStr = h('GirderCompStr'),
        Astrand = h('Astrand'),
        loss = h('loss'),
        Ag = h('Ag'),
        Ig = h('Ig'), //mm3
        yb = h('yb'), //mm
        y1 = h('y1'),
        Ac = h('Ac'),
        ybc = h('ybc'), //mm
        Ic = h('Ic'),
        Mgdl = h('Mgdl'), //kN.m
        Msdl = h('Msdl'), //kN.m
        Mslabw = h('Mslabw'), //kN.m
        Mllg = h('Mllg'), //kN.m
        alpha = h('alpha'),
        TensileStrength = h('TensileStrength');

    var fallt;
    // Metric Calculations
    function metric() {
        // *** Calculation of Section Modulus
        R.sb = Ig / yb;
        R.sbc = Ic / ybc;

        // *** Calculation of R.fbt (Tensile Stresses)
        R.fbt = ((Mgdl + Mslabw) / R.sb) + ((Msdl + alpha * Mllg) / R.sbc); // kN.m/mm3
        R.fbt = R.fbt * 1000000; // MPa

        // *** Calculation of R.fbtn (Reduced Tensile Stresses)
        fallt = 0.48 * Math.sqrt(GirderCompStr);
        R.fbtn = R.fbt - fallt;

        // *** Calculation of Pp (Prestressing Force after losses)
        //Generally geometric center of strands is at the half height of bottom flange(y1) 
        R.e = yb - y1 / 2; //mm
        R.Pp = Ag * R.sb / (R.sb + Ag * R.e) * R.fbtn / 1000; //kN

        // *** Calculation of R.Ppstrand(Force applied by single strand)
        R.Ppstrand = 0.75 * TensileStrength * (Astrand) * (1 - loss / 100) / 1000;

        // *** Calculation of R.nStrands (Number of Strands)
        R.nStrands = R.Pp / R.Ppstrand;
    };


    // Imperial Calculations
    function imperial() {

        GirderCompStr = GirderCompStr / 1000 //psi to ksi

        // *** Calculation of Section Modulus
        R.sb = Ig / yb; //in3
        R.sbc = Ic / ybc; //in3

        // *** Calculation of fbt (Tensile Stresses)
        R.fbt = ((Mgdl + Mslabw) * 12 / R.sb) + ((Msdl + alpha * Mllg) * 12 / R.sbc); //ksi

        // *** Calculation of fbtn (Reduced Tensile Stresses)
        /* Tensile stress limits at service limit state (SLS-III) after losses (LRFD Table 5.9.4.2.2-1):
            a) for components with bonded prestressing tendons or reinforcement that are
            subjected to not worse than moderate corrosion condition, 0.19*(fc')^0.5 ksi */
        fallt = 0.19 * Math.sqrt(GirderCompStr); //ksi
        R.fbtn = R.fbt - fallt; //ksi

        // *** Calculation of Pp (Prestressing Force after losses)
        //Generally geometric center of strands is at the half height of bottom flange(y1)
        R.e = yb - y1 / 2; //in
        R.Pp = Ag * R.sb / (R.sb + Ag * R.e) * R.fbtn; //kips

        // *** Calculation of Ppstrand(Force applied by single strand)
        R.Ppstrand = 0.75 * TensileStrength * Astrand * (1 - loss / 100) / 1000; //kips

        // *** Calculation of nStrands (Number of Strands)
        R.nStrands = R.Pp / R.Ppstrand //kips
        R.fbt = R.fbt * 1000;
        R.fbtn = R.fbtn * 1000;

    };
    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
    }

}

module.exports = {
    calculate
}