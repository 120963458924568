<template>
  <div id="app">
    <div class="application--wrap">
      <v-app-bar
        app
        dark
        :color="!isScrolling?'':'primary'"
        :flat="!isScrolling"
        v-scroll="onScroll"
      >
        <v-toolbar-title>
          <img src="@/assets/logo.png" />
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text to="/login">Login</v-btn>
          <v-btn text to="/signUp">Register</v-btn>
          <v-btn href="http://bridgewiz.com/" target="_blank" text>ABOUT US</v-btn>
        </v-toolbar-items>
      </v-app-bar>
      <div class="v-responsive v-image" style="height: 40vh;">
        <div class="v-responsive__sizer" style="padding-bottom: 40%;"></div>
        <div
          class="v-image__image v-image__image--cover"
          style="background-image: linear-gradient(to top, rgba(160, 90, 70, 0.85), rgba(30, 40, 30, 0.85)), url(&quot;https://bridgewiz.com/wp-content/uploads/2017/02/path-to-infinity-1156x430.jpg&quot;); background-position: center center;"
        ></div>
        <v-container bg fill-height grid-list-md>
          <v-layout wrap align-center>
            <v-flex xs12 text-center>
              <h1 class="display-2 white--text">BridgeWiz Design Aid Calculators</h1>
              <div
                class="subheading white--text"
              >Please visit the links below to get more information on our available calculators</div>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <main>
        <div>
          <v-layout wrap>
            <v-flex xs12 v-for="category in categories" :key="category.id" class="mb-2">
              <div class="mx-2">
                <v-toolbar
                  @click="category.isOpen = !category.isOpen"
                  height="60"
                  class="elevation-8"
                  style="cursor:pointer;"
                >
                  <div class="headline">
                    <v-icon>menu</v-icon>
                    {{ category.name }}
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn icon v-if="category.isOpen">
                    <v-icon large>arrow_drop_up</v-icon>
                  </v-btn>
                  <v-btn icon v-if="!category.isOpen">
                    <v-icon large>arrow_drop_down</v-icon>
                  </v-btn>
                </v-toolbar>
              </div>
              <v-container fluid grid-list-sm class="px-2 py-1" v-show="category.isOpen">
                <v-layout wrap>
                  <v-flex
                    xs12
                    sm6
                    md4
                    lg3
                    v-for="calculator in category.calculators"
                    :key="calculator.name"
                  >
                    <v-card class="elevation-4">
                      <v-img contain :src="getImgUrl(calculator.dbDetails.image)"></v-img>
                      <v-card-title>
                        <span style="height:2em;">{{ calculator.main.title }}</span>
                      </v-card-title>
                      <v-card-text style="height:10em;" class="mt-3">
                        <span>{{ calculator.dbDetails.shortDesc }}</span>
                      </v-card-text>
                      <v-card-actions class="blue">
                        <v-btn text dark @click="goToDetailsPage(calculator)">More Details</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import calculatorList from "@/_lists/calculatorList";
import calculatorRoutes from "@/_lists/calculatorRoutes";

export default {
  metaInfo: {
    title: "Home",
    meta: [
      {
        name: "description",
        content: "Design calculators for various bridge design aspects"
      },
      { name: "robots", content: "index, follow" },
      {
        name: "keywords",
        content:
          "bridge, calculator, girder, design, beam, post-tension, prestress, deck, blister"
      }
    ]
  },
  data() {
    return {
      isScrolling: false,
      calculatorList,
      calculatorRoutes,
      categories: [
        {
          id: 1,
          name: "Superstructure Calculators",
          calculators: [],
          isOpen: false
        },
        {
          id: 4,
          name: "Inspection & Maintenance",
          calculators: [],
          isOpen: true
        },
        {
          id: 2,
          name: "Joints and Bearings",
          calculators: [],
          isOpen: false
        },
        {
          id: 3,
          name: "Substructure Calculators",
          calculators: [],
          isOpen: false
        }
      ]
    };
  },
  created() {
    // sort the calculators
    this.calculatorList.sort((a, b) => a.main.no - b.main.no);
    // add the calculators to respective lists of the categories
    this.calculatorList.forEach(calculator => {
      var category = this.categories.find(
        category => category.id === calculator.main.catID
      );
      category.calculators.push(calculator);
    });
  },
  mounted() {
      // for prerendering
    document.dispatchEvent(new Event('custom-render-trigger'));
    },
  methods: {
    // returns the qualified name of the given image
    getImgUrl(img) {
      return require("@/assets/imgs/dashboard/" + img + ".png");
    },
    goToDetailsPage(el) {
      var calculator = calculatorRoutes.find(c => c.name === el.name);
      this.$router.push({
        name: "calculatorDetails",
        params: { name: calculator.path }
      });
    },
    onScroll() {
      this.isScrolling =
        (window.pageYOffset || document.documentElement.scrollTop || 0) > 100;
    }
  }
};
</script>

<style>
</style>