var outputs = {

    wsDL: {
        label1: 'Uniform Superimposed Dead Load', //30 karakter
        label2: 'wsdl',
        suffix: {
            metric: 'kN/m',
            imperial: 'klf'
        },
        description: {
            title: 'Uniform Superimposed Dead Load',
            desc: ''
        }
    },
    MsDL: {
        label1: 'Max. Moment of Superimposed Load', //37 karakter
        label2: 'Msdl',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Maximum Moment of Superimposed Dead Load',
            desc: ''
        }
    }
};

export default outputs;