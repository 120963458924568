<template>
  <div>
    <v-app-bar :collapse-on-scroll="true" app height="57" dark id="topBar">
      <v-app-bar-nav-icon @click="$emit('mobileOpenClose')"></v-app-bar-nav-icon>
      <v-spacer />
      <v-menu offset-x offset-y>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-badge overlap>
              <template v-slot:badge>{{ notifications.length }}</template>
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-if="notifications.length===0">
            <v-list-item-title>No new notifications</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            @click="redirectTo(index)"
            v-for="(item, index) in notifications"
            :key="index"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn class="ml-2" v-if="user" text @click="logout"> Sign Out</v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import firebase from "firebase/app";
import db from "@/firebaseInit.js";
export default {
  name: "TopBar",
  data() {
    return {
      notifications: []
    };
  },
  async mounted() {
    await this.GetNotifications();
  },
  computed: {
    ...mapState("users", ["user"])
  },
  methods: {
    ...mapActions("users", ["logout"]),
    redirectTo(index) {
      const path = this.notifications[index].path;
      if (path !== "/") {
        this.$router.push({ path: path });
      } else {
        this.$router.push({ path: "/member/news" });
      }
    },
    async GetNotifications() {
      // get the notifications with display==true from firestore
      const snapshot = await db
        .collection("notifications")
        .orderBy("date", "desc")
        .where("display", "==", true)
        .get();
      if (!snapshot.empty) {
        for (const doc of snapshot.docs) {
          this.notifications.push(doc.data());
        }
      }
    }
  }
};
</script>

<style>
</style>