<template>
  <div class="mb-4">
    <!-- Metric / Imperial - Clear / Calculate Buttons -->
    <v-layout wrap>
      <v-flex xs4>
        <div class="grey lighten-2 elevation-2" height="60">
          <v-layout wrap>
            <v-flex xs12>
              <v-radio-group :disabled="processing" v-model="system" row>
                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                <v-radio color="indigo darken-3" label="Imperial" value="imperial"></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex xs8 class="mt-1">
        <v-layout wrap>
          <v-flex xs3></v-flex>
          <!-- Clear -->
          <v-flex xs3 class="px-2">
            <v-tooltip top width="200" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn block v-on="on" @click="reset">Clear</v-btn>
              </template>
              <span style="font-size: 13px;">All values will be erased.</span>
            </v-tooltip>
          </v-flex>
          <!-- Calculate -->
          <v-flex xs6>
            <v-tooltip top width="300" color="grey darken-3">
              <template v-slot:activator="{ on }">
                <v-btn
                  block
                  color="green darken-4"
                  dark
                  v-on="on"
                  @click="startCalculation"
                >Calculate</v-btn>
              </template>
              <span style="font-size: 13px;">Moments will be calculated.</span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- Inputs Card -->
    <v-layout>
      <!-- Live Load 1 -->
      <!-- xs5 - xs7 -->
      <v-flex v-for="(category, i) in cats" :key="i" v-bind="{[`xs${i === 0 ? 5 : 7}`]: true}">
        <div v-if="i === 1" class="pt-1 pl-2 elevation-2 grey lighten-3" height="60">
          <v-layout wrap>
            <v-flex xs10 class="my-3 pl-2">
              <div>Live Load Moment</div>
            </v-flex>
            <v-flex xs2>
              <v-tooltip top max-width="400" color="grey darken-4">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon color="grey darken-4">help</v-icon>
                  </v-btn>
                </template>
                <span style="font-size: 13px;">
                  Axle Loads will be entered. All values are positive. This
                  calculator will be used to compute the changing moment for one lane of loading.
                </span>
              </v-tooltip>
            </v-flex>
          </v-layout>
        </div>

        <div
          class="elevation-2"
          :class="i === 0 ?  'mr-2' : ''"
          :height="(i === 0) ? '510' : '450'"
        >
          <v-layout>
            <v-flex>
              <v-layout class="pa-3" wrap>
                <v-flex
                  class="pl-1"
                  v-bind="{[`xs${12 / Math.ceil(Object.keys(category).length / 5)}`]: true}"
                  v-for="input in category"
                  :key="input.label"
                >
                  <!-- Images -->
                  <div v-if="input.type === 'combobox'" class="mb-3 mt-4">
                    <v-img height="165" :src="getImgUrl(image.main)" contain></v-img>
                    <v-img height="165" :src="getImgUrl(image.truck)" contain></v-img>
                  </div>
                  <v-layout>
                    <v-flex class="mx-1">
                      <!-- Load Type -->
                      <v-select
                        v-if="input.type === 'combobox'"
                        v-model="selected"
                        :items="items"
                        label="Load Type"
                        :disabled="processing"
                        hide-details
                        filled
                        required
                      ></v-select>
                    </v-flex>
                    <v-flex class="mx-1" v-if="input.type === 'combobox'">
                      <!-- Live Load Modal -->
                      <v-select
                        v-if="selected === 'Truck'"
                        v-model="liveLoadModel"
                        :items="llModelTrucks"
                        label="Live Load Model"
                        :disabled="processing"
                        hide-details
                        filled
                        required
                      ></v-select>
                    </v-flex>
                  </v-layout>

                  <v-text-field
                    v-if="input.type !== 'combobox' && input.type !== 'notDisabled'"
                    class="numberInputArea"
                    color="blue darken-4"
                    type="number"
                    v-model="input.value"
                    :label="input.label"
                    hide-details
                    filled
                    :disabled="processing || !liveLoadModel || liveLoadModel !== 'Manual'"
                    required
                    :suffix="input.suffix[system]"
                  ></v-text-field>

                  <v-text-field
                    v-if="input.type === 'notDisabled'"
                    class="numberInputArea"
                    color="blue darken-4"
                    type="number"
                    :disabled="processing"
                    v-model="input.value"
                    :label="input.label"
                    hide-details
                    filled
                    required
                    :suffix="input.suffix[system]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <br />
    <v-alert
      :value="alert"
      color="error"
      dark
      transition="scale-transition"
    >Please select a truck and fill in all the required fields.</v-alert>
    <v-layout wrap v-show="result">
      <v-flex xs12>
        <v-toolbar class="blue darken-2" dark height="50">
          <v-spacer></v-spacer>
          <div style="font-size:18px; font-weight:bold;">RESULTS</div>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-flex>
      <br />
      <v-flex xs12 class="pt-1 elevation-2 grey lighten-3">
        <apexchart
          v-if="result"
          height="250"
          type="area"
          :options="chartOptionsResult"
          :series="resultSeries"
        ></apexchart>
      </v-flex>
      <v-flex xs12 class="pt-1">
        <p class="text-center"><var>Max. Moment</var>: <var>{{ maxMoment }}</var></p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import functions from "@/_calculators/src/liveMoment/functions";
import { inputs, trucks } from "@/_calculators/src/liveMoment/inputs";
import outputs from "@/_calculators/src/liveMoment/outputs";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts
  },
  data() {
    return {
      system: "metric",
      inputs: inputs,
      outputs: outputs,
      excludedList: ["loadType"],
      cats: [],
      categoryNo: 2,
      processing: false,
      items: ["Truck"],
      llModelTrucks: ["HL-93", "KGM-45", "Manual"],
      selected: "Truck",
      liveLoadModel: "",
      alert: false,
      offset: 0,
      result: null,
      intervalId: null,
      maxMoment: 0,
      image: {
        main: "liveLoad_main.png",
        truck: "liveLoad_truck_moving.png"
      },
      chartOptionsResult: {
        chart: {
          id: "result-data-chart",
          zoom: { enabled: false },
          animations: {
            enabled: true,
            dynamicAnimation: { enabled: true }
          }
        },
        dataLabels: { enabled: false },
        stroke: {
          curve: "straight",
          width: 2
        },
        title: {
          text: "Moment Diagram",
          align: "center"
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
          }
        },
        xaxis: {
          type: "numeric",
          offsetY: 0,
          position: "top",
          labels: {
            show: true,
            formatter: function(value) {
              return Number(value).toFixed(1);
            }
          },
          axisTicks: { show: true },
          title: { show: false }
        },
        yaxis: {
          type: "numeric",
          min: 0,
          max: 0,
          labels: {
            show: true,
            formatter: function(value) {
              return Number(value).toFixed(0);
            }
          },
          axisTicks: { show: true },
          title: { text: "Moment" }
        },
        tooltip: {
          enabled: false
        },
        annotations: {
          xaxis: [
            {
              x: 0,
              strokeDashArray: 0,
              borderColor: "#775DD0",
              label: {
                borderColor: "#775DD0",
                style: {
                  color: "#fff",
                  background: "#775DD0"
                },
                text: "P1",
                offsetY: -20
              }
            },
            {
              x: -5,
              strokeDashArray: 0,
              borderColor: "#775DD0",
              label: {
                borderColor: "#775DD0",
                style: {
                  color: "#fff",
                  background: "#775DD0"
                },
                text: "P2",
                offsetY: -28
              }
            },
            {
              x: -10,
              strokeDashArray: 0,
              borderColor: "#775DD0",
              label: {
                borderColor: "#775DD0",
                style: {
                  color: "#fff",
                  background: "#775DD0"
                },
                text: "P3",
                offsetY: -28
              }
            }
          ]
        }
      },
      resultSeries: null
    };
  },
  created() {
    this.createCats();
    this.cats[0].loadType.value = "Truck";
  },
  computed: {
    loadType() {
      return this.inputs.loadType.value;
    }
  },
  watch: {
    liveLoadModel(newModel) {
      this.updateTruckData();
    },
    system(newSystem) {
      this.updateTruckData();
    }
  },
  methods: {
    updateTruckData() {
      const truck = trucks.filter(t => t.name === this.liveLoadModel)[0];
      if (truck) {
        const truckData = truck.data[this.system];
        for (const key in truckData) {
          if (truckData.hasOwnProperty(key)) {
            this.cats[1][key].value = truckData[key];
          }
        }
      }
    },
    reset() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
      this.offset = 0;
      this.alert = false;
      this.result = null;
      this.resultSeries = null;
      this.processing = false;
      this.chartOptionsResult.annotations.xaxis[0].x = 0;
      this.chartOptionsResult.annotations.xaxis[1].x =
        -1 * Number(this.cats[1]["x1"].value);
      this.chartOptionsResult.annotations.xaxis[2].x =
        -1 * (Number(this.cats[1]["x1"].value) + Number(this.cats[1]["x2"].value));
    },
    async startCalculation() {
      this.reset();
      if (this.liveLoadModel && this.verifyInputs()) {
        this.processing = true;
        const mmax = this.calculateMMax();
        this.chartOptionsResult.yaxis.min = -1 * (mmax * 1.1);
        const delta = Number(this.inputs.L.value) / 500;
        const endX =
          Number(this.inputs.L.value) +
          Number(this.cats[1]["x1"].value) +
          Number(this.cats[1]["x2"].value);
        // set annotation label texts
        this.chartOptionsResult.annotations.xaxis[0].label.text = this.inputs.P1.value.toString();
        this.chartOptionsResult.annotations.xaxis[1].label.text = this.inputs.P2.value.toString();
        this.chartOptionsResult.annotations.xaxis[2].label.text = this.inputs.P3.value.toString();
        // get min function
        const getMin = a => { return Math.min(...a.map(e => Array.isArray(e) ? getMin(e) : e)) }
        // start timer
        this.intervalId = setInterval(async () => {
          this.chartOptionsResult.annotations.xaxis[0].x += delta;
          this.chartOptionsResult.annotations.xaxis[1].x += delta;
          this.chartOptionsResult.annotations.xaxis[2].x += delta;
          this.offset += delta;
          this.result = await functions.calculate(
            this.inputs,
            this.system,
            this.offset
          );
          this.resultSeries = [
            {
              name: "Moment",
              data: this.result
            }
          ];
          this.maxMoment = getMin(this.result).toFixed(0) * -1;
          if (this.offset > endX) {
            clearInterval(this.intervalId);
            this.processing = false;
          }
        }, 50);
      } else {
        this.alert = true;
      }
    },
    calculateMMax() {
      const l = Number(inputs.L.value);
      return (
        (Number(inputs.P1.value) * (l / 2 - Number(inputs.x1.value)) +
          (Number(inputs.P2.value) * l) / 2 +
          Number(inputs.P3.value) * (l / 2 + Number(inputs.x2.value))) /
          2 -
        Number(inputs.P3.value) * Number(inputs.x2.value)
      );
    },
    createCats() {
      var inputKeys = Object.keys(this.inputs);
      var groups = [];
      for (let i = 1; i <= this.categoryNo; i++) {
        groups.push(inputKeys.filter(key => this.inputs[key].cat === i));
      }
      var res = [];
      groups.forEach(group => {
        var cat = {};
        group.forEach(input => {
          cat[input] = this.inputs[input];
        });
        res.push(cat);
      });
      this.cats = res;
    },
    getImgUrl(pic) {
      return require("@/assets/imgs/calculators/" + pic);
    },
    verifyInputs() {
      // all fields in inputs.js are required for calculations - no need to check if required condition
      let verified = true;
      for (const key in this.inputs) {
        if (this.inputs.hasOwnProperty(key)) {
          if (!this.inputs[key].value) {
            verified = false;
            break;
          }
        }
      }
      return verified;
    }
  }
};
</script>
<style>
</style>