<template>
    <div>
        <!--Metric vs  Imperial Radio Boxes-->
        <v-layout wrap>
            <v-flex xs4>
                <div class="grey lighten-2 elevation-2" height="60">
                    <v-layout wrap>
                        <v-flex xs12>
                            <v-radio-group v-model="system" row>
                                <v-radio class="pl-5" color="indigo darken-3" label="SI" value="metric"></v-radio>
                                <!--<v-radio readonly color="indigo darken-3" label="Imperial" value="imperial"></v-radio>-->
                            </v-radio-group>
                        </v-flex>
                    </v-layout>
                </div>
            </v-flex>
        </v-layout>
        <!--Inputs-->
        <v-layout>
            <v-flex xs12 class="mt-1 mr-1">
                <v-text-field class="numberInputArea"
                              color="blue darken-4"
                              required
                              filled
                              hide-details="auto"
                              :suffix="inputs.yieldStrength.suffix[system]"
                              type="number"
                              v-model="inputs.yieldStrength.value"
                              :rules="numberRules"
                              :label="inputs.yieldStrength.label">

                </v-text-field>
            </v-flex>
        </v-layout>
        <v-layout>
            <!--Shear design-->
            <v-flex xs4>
                <div class="mt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
                    <v-layout wrap>
                        <v-flex xs10 class="my-3 pl-2">
                            <div>1. Double Shear Design</div>
                        </v-flex>
                        <v-flex xs2>
                            <v-tooltip top max-width="250" color="grey darken-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" style="margin-top:5px;">
                                        <v-icon color="grey darken-4">help</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 13px;">Shear Design</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </div>
                <v-card class="mr-1 mt-1 elevation-2">
                    <v-layout class="pa-3">
                        <v-flex>
                            <v-form v-model="valid[0]">
                                <v-layout>
                                    <v-flex xs9>
                                        <v-text-field class="numberInputArea"
                                                      color="blue darken-4"
                                                      type="number"
                                                      v-model="inputs.shearForce.value"
                                                      :label="inputs.shearForce.label"
                                                      hide-details="auto"
                                                      filled
                                                      required
                                                      :rules="numberRules"
                                                      :suffix="inputs.shearForce.suffix[system]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs3 class="pl-4">
                                        <v-checkbox v-model="inputs.isDoubleShear.value"
                                                    :label="inputs.isDoubleShear.label">
                                        </v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <v-flex xs12>
                                    <v-alert class="mt-4" text type="info">Required Diameter: {{requiredDiameter}} {{outputs.requiredDiameter.suffix[system]}} </v-alert>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field class="numberInputArea"
                                                  color="blue darken-4"
                                                  type="number"
                                                  v-model="selectedDiameter"
                                                  :label="inputs.selectedDiameter.label"
                                                  hide-details="auto"
                                                  filled
                                                  required
                                                  :rules="selectedDiameterRules"
                                                  :suffix="inputs.selectedDiameter.suffix[system]">
                                    </v-text-field>
                                </v-flex>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
            <!--Bearing design-->
            <v-flex xs4>
                <div class="mt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
                    <v-layout wrap>
                        <v-flex xs10 class="my-3 pl-2">
                            <div>2. Bearing Design</div>
                        </v-flex>
                        <v-flex xs2>
                            <v-tooltip top max-width="250" color="grey darken-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" style="margin-top:5px;">
                                        <v-icon color="grey darken-4">help</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 13px;">Bearing Design</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </div>
                <v-card class="mr-1 mt-1 elevation-2">
                    <v-layout class="pa-3">
                        <v-flex>
                            <v-form v-model="valid[0]">
                                <v-layout>
                                    <v-flex xs9>
                                        <v-text-field class="numberInputArea"
                                                      color="blue darken-4"
                                                      type="number"
                                                      v-model="inputs.shearForce.value"
                                                      :label="inputs.shearForce.label"
                                                      hide-details="auto"
                                                      filled
                                                      required
                                                      :rules="numberRules"
                                                      :suffix="inputs.shearForce.suffix[system]">
                                        </v-text-field>
                                    </v-flex>
                                    <v-flex xs3 class="pl-4">
                                        <v-checkbox v-model="inputs.isDoubleShear.value"
                                                    :label="inputs.isDoubleShear.label">
                                        </v-checkbox>
                                    </v-flex>
                                </v-layout>
                                <v-flex xs12>
                                    <v-alert class="mt-4" text type="info">Required Bearing Plate Thickness: {{requiredThickness}} {{outputs.requiredDiameter.suffix[system]}} </v-alert>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field class="numberInputArea"
                                                  color="blue darken-4"
                                                  type="number"
                                                  v-model="selectedThickness"
                                                  :label="inputs.selectedThickness.label"
                                                  hide-details="auto"
                                                  filled
                                                  required
                                                  :rules="selectedThicknessRules"
                                                  :suffix="inputs.selectedThickness.suffix[system]">
                                    </v-text-field>
                                </v-flex>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-flex>
            <!--Moment Design-->
            <v-flex xs4>
                <div class="mt-1 mr-1 pl-2 elevation-2 grey lighten-3" height="60">
                    <v-layout wrap>
                        <v-flex xs10 class="my-3 pl-2">
                            <div>3. Moment Design</div>
                        </v-flex>
                        <v-flex xs2>
                            <v-tooltip top max-width="250" color="grey darken-4">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" style="margin-top:5px;">
                                        <v-icon color="grey darken-4">help</v-icon>
                                    </v-btn>
                                </template>
                                <span style="font-size: 13px;">Moment Design</span>
                            </v-tooltip>
                        </v-flex>
                    </v-layout>
                </div>
                <v-card class="mr-1 mt-1 elevation-2">
                    <v-layout class="pa-3">
                        <v-flex>
                            <v-form v-model="valid[0]">
                                <v-flex xs12>
                                    <v-text-field class="numberInputArea"
                                                  color="blue darken-4"
                                                  type="number"
                                                  v-model="inputs.moment.value"
                                                  :label="inputs.moment.label"
                                                  hide-details="auto"
                                                  filled
                                                  required
                                                  :rules="numberRules"
                                                  :suffix="inputs.moment.suffix[system]">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-alert class="mt-4" :type="momentStatus.type">{{momentStatus.text}} </v-alert>
                                </v-flex>
                            </v-form>
                        </v-flex>
                    </v-layout>
                </v-card>
                <!-- Report -->
         
                    <v-tooltip top width="300" color="grey darken-3">
                        <template v-slot:activator="{ on }">
                            <v-btn block
                                   class="mt-2 mr-4"
                                   color="blue lighten-1"
                                   dark
                                   v-on="on"
                                   @click="generateReport">Create Report</v-btn>
                        </template>
                        <span style="font-size: 13px;">Report Generation will be started.</span>
                    </v-tooltip>
           
            </v-flex>
        </v-layout>
        <v-layout mt-2 wrap>
            <v-flex xs12>
                <v-layout wrap>
                    <!-- Load Test Data -->
                    <v-flex xs6>
                        <v-tooltip top width="200" color="grey darken-3">
                            <template v-slot:activator="{ on }">
                                <v-btn block color="grey lighten-1" v-on="on" @click="loadTestData">Test Data</v-btn>
                            </template>
                            <span style="font-size: 13px;">Default values will be filled.</span>
                        </v-tooltip>
                    </v-flex>
                    <!-- Clear -->
                    <v-flex xs6 class="px-2">
                        <v-tooltip top width="200" color="grey darken-3">
                            <template v-slot:activator="{ on }">
                                <v-btn block color="grey lighten-1" v-on="on" @click="reset">Clear</v-btn>
                            </template>
                            <span style="font-size: 13px;">All values will be erased.</span>
                        </v-tooltip>
                    </v-flex>
 
                </v-layout>
            </v-flex>
        </v-layout>
        <v-snackbar v-model="snackbar.show" color="error">
            {{ snackbar.text }}
            <v-btn dark text @click="snackbar.show = false">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import inputs  from "@/_calculators/src/pinDesign/inputs";
    import outputs from "@/_calculators/src/pinDesign/outputs";
    import { calculateMinDiameter, calculateMinThickness, calculateMomentStatus } from "@/_calculators/src/pinDesign/functions";
    import { GeneratePDFReport } from "@/_calculators/src/shared/report";

    export default {
        name: 'pinDesign',
        data() {
            return {
                snackbar: {
                    show: false,
                    text: ""
                },
                system: 'metric',
                valid: [true, true, true],
                inputs: inputs,
                outputs: outputs,
                selectedDiameter: 0,
                selectedThickness:0,
                numberRules: [
                    v => !!v || "Value is required",
                    v => (v && v > 0) || "Value must be positive"
                ],
                selectedDiameterRules: [
                    v => !!v || "Value is required",
                    v => (v && v > 0) || "Value must be positive",
                    v => v >= this.requiredDiameter || "Should be greater than required diameter"
                ],
                selectedThicknessRules: [
                    v => !!v || "Value is required",
                    v => (v && v > 0) || "Value must be positive",
                    v => v >= this.requiredThickness || "Should be greater than required plate thickness"
                ]
            };
        },
        computed: {
            requiredDiameter() {
                let d = calculateMinDiameter(
                    this.inputs.shearForce.value,
                    this.inputs.yieldStrength.value,
                    this.inputs.isDoubleShear.value,
                    this.system
                );
                this.selectedDiameter = d;
                return d;
            },
            requiredThickness() {
                let t = calculateMinThickness(
                    this.inputs.shearForce.value,
                    this.inputs.yieldStrength.value,
                    this.selectedDiameter,
                    this.inputs.isDoubleShear.value,
                    this.system
                );
                this.selectedThickness = t;
                return t;
            },
            momentStatus() {
                let s = calculateMomentStatus(
                    this.inputs.moment.value,
                    this.inputs.yieldStrength.value,
                    this.selectedDiameter,
                    this.system
                );
                return s ? { type: "success", text: "OK" } : { type: "error", text: "FAIL" };
            }
        },
        methods: {
            loadTestData() {
                if (this.system === 'metric') {
                    this.inputs.shearForce.value = 3000;
                    this.inputs.yieldStrength.value = 360;
                    this.inputs.moment.value = 31000;
                }
                else {
                    this.inputs.shearForce.value = 3000;
                    this.inputs.yieldStrength.value = 360;
                    this.inputs.moment.value = 31000;
                }
            },
            reset() {
                if (this.system === 'metric') {
                    this.inputs.shearForce.value = '';
                    this.inputs.yieldStrength.value = '';
                    this.inputs.moment.value = '';
                }
                else {
                    this.inputs.shearForce.value = '';
                    this.inputs.yieldStrength.value = '';
                    this.inputs.moment.value = '';
                }
                this.inputs.isDoubleShear.value = true;
            },
            generateReport() {
                try {
                    this.inputs.selectedDiameter.value = this.selectedDiameter;
                    this.inputs.selectedThickness.value = this.selectedThickness;
                    // create a results object
                    let results = {
                        requiredDiameter: this.requiredDiameter,
                        requiredThickness: this.requiredThickness,
                        isFlexureSafe: this.momentStatus.text
                    };
                    GeneratePDFReport(this.inputs, this.outputs, results, "Pin Design", "", this.system, "pinDesign.png")
                } catch (e) {
                    console.log(e);
                    this.snackbar.text = "Error creating report";
                    this.snackbar.show = true;
                }
            }
        }
    }
</script>

<style>

</style>