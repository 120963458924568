<template>
    <div>
        <v-card class="elevation-0">
            <v-card-title>
                <v-toolbar flat color="white">
                    <v-toolbar-title>
                        Alternatives
                    </v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-radio-group v-model="selectedAlternative" row class="pt-6">
                        <v-radio v-for="(alt, index) in alternativeList" :key="index" :label="alt.name"
                            :value="alt.name" :disabled="alt.decks.length===0"></v-radio>
                    </v-radio-group>
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <apexchart height="250" type="line" :options="chartOptions" :series="chartSeries"></apexchart>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import VueApexCharts from "vue-apexcharts";

    export default {
        name: "AlternativeVisualizationComponent",
        props: {
            alternativeList: {
                type: Array,
                required: true
            },
            stationList: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                selectedAlternative: "Pretension",
                chartOptions: {
                    chart: {
                        id: "alternative-visual-chart",
                        zoom: {
                            enabled: false
                        },
                        animations: {
                            enabled: false
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: "straight",
                        width: 1
                    },
                    title: {
                        text: undefined
                    },
                    xaxis: {
                        type: "numeric",
                        labels: {
                            show: true
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    yaxis: {
                        type: "numeric",
                        labels: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                    annotations: {
                        xaxis: []
                    }
                }
            }
        },
        created() {
            if (this.alternativeList[0].decks.length > 0) {
                this.selectedAlternative = "Pretension";
            } else if (this.alternativeList[1].decks.length > 1) {
                this.selectedAlternative = "Composite";
            } else {
                this.selectedAlternative = "Post-tension";
            }
        },
        computed: {
            chartSeries: function () {
                const alt = this.alternativeList.find(s => s.name === this.selectedAlternative);
                let valleyArray = [];
                let deckArray = [];
                if (alt.decks.length > 0) {
                    valleyArray = this.getValley();
                    deckArray = this.getDecks(alt);
                    this.chartOptions.annotations.xaxis = [];
                    const pierArray = this.getPiers(alt);
                    // only first level is reactive
                    this.chartOptions = {
                        ...this.chartOptions,
                        ...{
                            annotations: {
                                xaxis: pierArray
                            }
                        }
                    };
                }
                return [{
                        name: "Valley",
                        data: valleyArray
                    },
                    {
                        name: "Decks",
                        data: deckArray
                    }
                ]
            }
        },
        methods: {
            getValley() {
                const valleyArray = [];
                for (const station of this.stationList) {
                    valleyArray.push([station.distance, station.valleyElevation]);
                }
                return valleyArray;
            },
            getDecks(alternative) {
                const deckArray = [];
                for (const deck of alternative.decks) {
                    const ss = deck.startStation;
                    deckArray.push([ss.distance, ss.roadElevation]);
                }
                const lastSt = alternative.decks[alternative.decks.length - 1].endStation;
                deckArray.push([lastSt.distance, lastSt.roadElevation]);
                return deckArray;
            },
            getPiers(alternative) {
                const pierArray = [];
                for (const pier of alternative.piers) {
                    const pierAnn = this.createPierAnnotation(pier.station.distance);
                    pierArray.push(pierAnn)
                }
                return pierArray;
            },

            createPierAnnotation(distance) {
                return {
                    x: distance,
                    strokeDashArray: 0,
                    borderColor: "#775DD0",
                    label: {
                        borderColor: "#775DD0",
                        style: {
                            color: "#fff",
                            background: "#775DD0"
                        },
                        text: "Pier",
                        offsetY: -20
                    }
                }
            }
        }
    }
</script>

<style>

</style>