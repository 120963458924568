const testData = {
    metric: {
        verticalFactored: 100,
        verticalUnfactored: 80,
        longitudinalFactored: 100,
        longitudinalUnfactored: 80,
        transverseFactored: 100,
        transverseUnfactored: 80,
        elastomerType: "Fixed",
        allowedCompression: 1000,
        rotation: 1,
        shearModulus: 100,
        yieldStrength: 420,
        concreteStrength: 30,
        horizontalMovement: 1
    },
    imperial: {
        verticalFactored: 100,
        verticalUnfactored: 80,
        longitudinalFactored: 100,
        longitudinalUnfactored: 80,
        transverseFactored: 100,
        transverseUnfactored: 80,
        elastomerType: "Fixed",
        allowedCompression: 1000,
        rotation: 1,
        shearModulus: 100,
        yieldStrength: 420,
        concreteStrength: 30,
        horizontalMovement: 1
    }
}

export default testData;