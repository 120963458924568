function calculate(inputs, _system, offset) {
    let result = [[0,0]];
    let x = 0;
    // calculate load locations based on offset
    const L1 = offset;
    const L2 = offset - inputs.x1.value;
    const L3 = offset - inputs.x1.value - inputs.x2.value;
    const L = Number(inputs.L.value);
    // calculate effective loads based on load locations
    const P1 = (L1 <= 0 || L1 >= L) ? 0 : Number(inputs.P1.value);
    const P2 = (L2 <= 0 || L2 >= L) ? 0 : Number(inputs.P2.value);
    const P3 = (L3 <= 0 || L3 >= L) ? 0 : Number(inputs.P3.value);

    // calculate moment computation points
    let mcp = [];
    if (P1 !== 0) {
        mcp.push({
            x: L1,
            forces: [
                {
                    value: P2,
                    location: L2
                },
                {
                    value: P3,
                    location: L3
                }]
        });
    }
    if (P2 !== 0) {
        mcp.push({
            x: L2,
            forces: [
                {
                    value: P3,
                    location: L3
                }]
        });
    }
    if (P3 !== 0) {
        mcp.push({
            x: L3,
            forces: []
        });
    }
    // reverse the array for correct charting sequence
    mcp = mcp.reverse();
    // calculate moments for the moment calculation points
    mcp.forEach(momentPoint => {
        // calculate left support reaction Ra
        const Ra = (P1 * (L - L1) + P2 * (L - L2) + P3 * (L - L3)) / L;
        let M = Ra * momentPoint.x;
        momentPoint.forces.forEach(force=>{
            M -= force.value*(momentPoint.x - force.location);
        });
        result.push([momentPoint.x, -1 * M]);
    });
    // push the last moment as 0
    result.push([L, 0]);
    return result;
}

module.exports = {
    calculate
}