<template>
  <v-app v-if="user && (calculators.length > 0)">
    <v-layout justify-center row wrap>
      <v-flex xs12>
        <topBar @mobileOpenClose="mobileDrawer = !mobileDrawer" />

        <!-- Left Menu -->
        <v-navigation-drawer
          v-model="mobileDrawer"
          :temporary="true"
          dark
          app
          width="360"
        >
          <leftBar />
        </v-navigation-drawer>

        <div class="mx-2 my-3">
          <router-view />
        </div>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import leftBar from "@/components/static/leftBar";
import topBar from "@/components/static/topBar";
export default {
  components: {
    leftBar,
    topBar
  },
  computed: {
    ...mapState("users", ["user"]),
    ...mapState("general", ["calculators"])
  },
  created() {
    this.isMobile();
  },
  data() {
    return {
      mobile: true,
      mobileDrawer: true
    };
  },
  methods: {
    isMobile() {
      this.mobile = navigator.userAgent.search("Mobile") != -1 ? true : false;
    }
  }
};
</script>

<style>
</style>