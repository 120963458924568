function calculate(system, inputParameters) {
    const promise = new Promise(function (resolve, reject) {
        const beamAnalysisWorker = new Worker('./simpleBeamWorker.js', { type: "module" });
        beamAnalysisWorker.onmessage = e => { resolve(e.data); };
        beamAnalysisWorker.onerror = e => { reject(e); };
        beamAnalysisWorker.postMessage(inputParameters);
    });
    return promise;
}

export default calculate;