function calculate(system, inputs) {
    const promise = new Promise(function (resolve, reject) {
        const m = inputs.mass.value;
        // k: kN/m => E: kN/m2 (kPa) I: m4 L: m
        const k = 12 * inputs.modulusOfElasticity.value * 1000 * inputs.inertia.value / Math.pow(inputs.pierHeight.value, 3);
        const p = 2 * Math.PI * Math.sqrt(m / k);
        switch (inputs.loadingType.value) {
            case "acceleration":
                // compare recording dt and structure period
                const dt = inputs.acceleration.parameters.dt.value;
                if (dt === 0 || dt > 0.2 * p) {
                    throw new Error("Acceleration recording timestep is too low compared to pier period! Please use a recording with higher samples per second.")
                } else {
                    const worker = new Worker('./pierAccelerationWorker.js', { type: "module" });
                    worker.onmessage = e => { resolve(e.data); };
                    worker.onerror = e => { reject(e); };
                    worker.postMessage({ inputs: inputs, p: p, k: k });
                    break;
                }
            case "explosion":
                // check T and reject if larger than 10000 seconds
                if (inputs.explosion.parameters.T.value >= 10000) {
                    reject(Error("Loadings longer than 10000 seconds will cause browser instability and are not supported."));
                } else {
                    const worker = new Worker('./pierExplosionWorker.js', { type: "module" });
                    worker.onmessage = e => { resolve(e.data); };
                    worker.onerror = e => { reject(e); };
                    worker.postMessage({ inputs: inputs, p: p, k: k });
                    break;
                }
            default:
                reject(Error("Unknown loading type"));
        }
    });
    return promise;
}

export default calculate;