<template>
    <div>
        <inputsResults irType="p2ir1" :categoryNumber="1" :titles="['Superstructure Details']"
            :tooltips="['Enter span length and girder depth to compute spacing of girders. All values are positive.']"
            :inputs="inputs" :testData="testData" :outputs="outputs" :system="system" :result1="result1"
            :result2="result2" @CALC_METRIC="calcMetric" @CALC_IMP="calcImperial" @system="systemChanged"
            @CLEAR_RESULTS="clearResults" />

    </div>
</template>

<script>
    import inputsResults from '@/_calculators/static/inputsResults'
    import functions from '@/_calculators/src/selectionOfGirderDepth/functions'
    import inputs from '@/_calculators/src/selectionOfGirderDepth/inputs'
    import outputs from '@/_calculators/src/selectionOfGirderDepth/outputs'
    import testData from '@/_calculators/src/selectionOfGirderDepth/testData'
    export default {
        components: {
            inputsResults
        },
        data() {
            return {
                system: 'metric',
                inputs: inputs,
                outputs: outputs,
                testData: testData,
                result1: null,
                result2: null
            }
        },
        watch: {
            system() {
                this.clearResults();
            },
        },
        methods: {
            /* Static */
            systemChanged(val) {
                this.system = val;
            },
            calcMetric() {
                if (this.result1) {
                    this.result2 = this.result1;
                }
                this.result1 = functions.calculate(this.inputs, 'metric');
            },
            calcImperial() {
                if (this.result1) {
                    this.result2 = this.result1;
                }
                this.result1 = functions.calculate(this.inputs, 'imperial');
            },
            clearResults() {
                this.result1 = null;
                this.result2 = null;
            }
        }
    }
</script>

<style>

</style>