<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <v-data-table
          v-model="selected"
          show-select
          :headers="stationHeaders"
          :items="stations"
          item-key="distance"
        >
          <!-- Top slot -->
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Station Data (in {{ suffix }})</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="stationAddDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="primary"
                        icon
                        class="mr-4 mb-2"
                        v-on="on"
                        @click="showAddStation"
                      >
                        <v-icon large>add_circle_outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Add New Station</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="error" icon class="mb-2" v-on="on" @click="deleteStations">
                        <v-icon large>delete_outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Selected Stations</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="green" icon class="mb-2" v-on="on" @click="updateChart">
                        <v-icon large>refresh</v-icon>
                      </v-btn>
                    </template>
                    <span>Update Valley Geometry</span>
                  </v-tooltip>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn color="blue" icon class="mb-2" v-on="on" @click="loadSampleData">
                        <v-icon large>call_received</v-icon>
                      </v-btn>
                    </template>
                    <span>Load Sample Data</span>
                  </v-tooltip>
                </template>
                <v-card>
                  <v-card-title>Add Station</v-card-title>
                  <v-card-text>
                    <v-form v-model="stationValid">
                      <v-text-field
                        v-model="newStation.distance"
                        :rules="numberRules"
                        label="Distance"
                        required
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        v-model="newStation.roadElevation"
                        :rules="numberRules"
                        label="Road Elevation"
                        required
                        type="number"
                      ></v-text-field>
                      <v-text-field
                        v-model="newStation.valleyElevation"
                        :rules="numberRules"
                        label="Valley Elevation"
                        required
                        type="number"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="stationAddDialog = false">Close</v-btn>
                    <v-btn color="primary" text @click="addStation">Add</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <!-- item slots -->
          <template v-slot:item.roadElevation="props">
            <v-edit-dialog :return-value.sync="props.item.roadElevation" large persistent>
              <div>{{ props.item.roadElevation }}</div>
              <template v-slot:input>
                <div class="mt-4 title">Update</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.roadElevation"
                  :rules="numberRules"
                  label="Edit"
                  type="number"
                  aria-required
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:item.valleyElevation="props">
            <v-edit-dialog :return-value.sync="props.item.valleyElevation" large persistent>
              <div>{{ props.item.valleyElevation }}</div>
              <template v-slot:input>
                <div class="mt-4 title">Update</div>
              </template>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.valleyElevation"
                  :rules="numberRules"
                  label="Edit"
                  type="number"
                  aria-required
                  single-line
                  autofocus
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <!-- No data slot -->
          <template v-slot:no-data>
            <v-alert :value="true" color="info" icon="info" dense outlined>No stations defined</v-alert>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      bottom
      multi-line
      :timeout="snackbar.timeout"
    >
      {{snackbar.text}}
      <v-btn dark text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { Station } from "@/_calculators/src/costEstimation/inputs";

export default {
  name: "StationDataComponent",
  props: ["stations", "system"],
  data() {
    return {
      units: {
        metric: "meters",
        imperial: "feet"
      },
      stationHeaders: [
        { text: "Distance", value: "distance" },
        { text: "Road Elevation", value: "roadElevation" },
        { text: "Valley Elevation", value: "valleyElevation" }
      ],
      newStation: new Station(0, 0, 0),
      localStations: [],
      stationAddDialog: false,
      stationValid: true,
      numberRules: [v => !!v || "This field is required"],
      selected: [],
      snackbar: {
        show: false,
        color: "success",
        text: "Success",
        timeout: 3000
      }
    };
  },
  computed: {
    suffix: function() {
      return this.units[this.system];
    }
  },
  created() {
    this.localStations = this.stations;
  },
  methods: {
    loadSampleData() {
      this.localStations = [
        new Station(0, 1000, 1000),
        new Station(250, 1000, 985),
        new Station(300, 1000, 950),
        new Station(400, 1000, 950),
        new Station(500, 1000, 1000)
      ];
      this.$emit("update-stations", this.localStations);
    },
    showSnackbar(color, text) {
      this.snackbar.color = color;
      this.snackbar.text = text;
      this.snackbar.show = true;
    },
    showAddStation() {
      this.stationAddDialog = true;
    },
    deleteStations() {
      this.localStations = this.localStations.filter(
        s => !this.selected.includes(s)
      );
      this.selected = [];
      this.$emit("update-stations", this.localStations);
    },
    updateChart() {
      this.$emit("update-chart");
    },
    addStation() {
      // add a clone of new station to station array if there is no other station at given distance
      if (
        this.localStations.findIndex(
          q => q.distance === this.newStation.distance
        ) === -1
      ) {
        this.localStations.push(this.newStation.clone());
        // sort local stations
        this.localStations.sort((a, b) => {
          if (a.distance > b.distance) return 1;
          if (a.distance < b.distance) return -1;
          return 0;
        });
        this.$emit("update-stations", this.localStations);
      } else {
        this.showSnackbar("error", "Station already exists");
      }
    }
  }
};
</script>

<style>
</style>