<template>
  <div>
    <component
      :is="resultType"
      :outputs="outputs"
      :system="system"
      :result1="result1"
      :result2="result2"
      :changes="changes"
      :img1="img1"
      :img2="img2"
      :titles="titles"
    ></component>
  </div>
</template>

<script>
import c2 from "./resultTypes/c2";
import minReqNoOfStrands from "./resultTypes/minReqNoOfStrands";
import elongationOnStrandTendons from "./resultTypes/elongationOnStrandTendons";
import axialOnPileGroups from "./resultTypes/axialOnPileGroups";
import blisterDesign from "./resultTypes/blisterDesign";
import cellBoxFlexural from "./resultTypes/cellBoxFlexural";

export default {
  components: {
    c2,
    minReqNoOfStrands,
    elongationOnStrandTendons,
    axialOnPileGroups,
    blisterDesign,
    cellBoxFlexural
  },
  props: [
    "resultType",
    "outputs",
    "system",
    "result1",
    "result2",
    "img1",
    "img2",
    "titles"
  ],
  data() {
    return {
      changes: {}
    };
  },
  watch: {
    result2(val) {
      if (val) {
        this.changePerc();
      }
    }
  },
  methods: {
    changePerc() {
      var keys = Object.keys(this.result1);

      keys.forEach(key => {
        var r1 = this.result1[key];
        var r2 = this.result2[key];

        var result;
        var sign = null;
        if (r1 && r2) {
          result = (r1 * 100) / r2 - 100;

          if (result > -0.005 && result < 0.005) {
            sign = "swap_horiz";
          } else if (result > 0.005) {
            sign = "trending_up";
          } else if (result < -0.005) {
            sign = "trending_down";
          }
        } else {
          result = "";
        }
        this.changes[key] = {
          result,
          sign
        };
      });
    }
  }
};
</script>

<style>
</style>