var outputs = {
    Agc: {
        label1: 'Area of Composite Section',
        label2: 'Agc',
        cat: 2,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Composite Section',
            desc: ''
        }
    },
};

export default outputs;