<template>
  <v-layout wrap v-if="result1">
    <v-flex xs12>
      <v-toolbar class="blue darken-2" dark height="50">
        <v-spacer></v-spacer>
        <div style="font-size: 18px; font-weight: bold; ">RESULTS</div>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-flex>
    <v-flex xs12 class="pt-1">
      <v-layout wrap>
        <v-flex md4>
          <div class="mx-1 elevation-2 grey lighten-3" height="544">
            <div>
              <v-img v-if="Object.keys(result1).length === 5" :src="getImgUrl(img1)" max-height="544" contain>
              </v-img>
              <v-img v-if="Object.keys(result1).length === 10" :src="getImgUrl(img2)" max-height="544" contain>
              </v-img>
            </div>
          </div>
        </v-flex>
        <v-flex md4 v-for="(cat) in (Object.keys(result1).length === 10 ? 2 : 1)" :key="cat">
          <v-layout wrap>
            <v-flex>
              <div class="elevation-2 grey lighten-2 ml-1" height="60">
                <v-layout wrap>
                  <v-flex xs12 class="my-3 pl-2">
                    <div>{{titles[cat-1]}}</div>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
            <v-flex xs12 class="ml-1" v-for="(result, i) in outputs" :key="i">
              <v-card class="mt-2 elevation-3" v-if="cat === result.cat">
                <resultCard :result="result" :result1="result1" :result2="result2" :changes="changes" :i="i"
                  :system="system" />
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  /* ResultCard */
  import resultCard from '@/_calculators/static/resultCard'
  export default {
    components: {
      resultCard
    },
    props: ['outputs', 'system', 'result1', 'result2', 'changes', 'img1', 'img2', 'titles'],
    methods: {
      getImgUrl(pic) {
        return require('@/assets/imgs/calculators/' + pic)
      }
    },
  }
</script>

<style>

</style>