import { Station, Pier, Deck, Cost } from "./inputs";
import { Alternative } from "./outputs";
import { cr } from "./shared"

export default function Calculate(stations, options, unitPrices, system) {
    stations.sort((a, b) => {
        if (a.distance > b.distance)
            return 1;
        if (a.distance < b.distance)
            return -1;
        return 0;
    });
    const result = [];
    const firstStation = stations[0];
    const lastStation = stations[stations.length - 1];
    // L: total length
    const L = lastStation.distance - firstStation.distance;
    const lengthLimit = system === 'metric' ? 900 : 2953;
    // Throw an error if L >= length  limit
    if (L >= lengthLimit) {
        throw new Error(`Please do not use this tool for bridges with length >${lengthLimit}`);
    }
    const pretension = CalculatePretensionAlternative(L, options, stations, unitPrices, system);
    const composite = CalculateCompositeAlternative(L, options, stations, unitPrices, system);
    const postTension = CalculatePostTensionAlternative(L, stations, unitPrices, options.deckWidth, system);

    result.push(pretension);
    result.push(composite);
    result.push(postTension);

    return result;
}


function CalculatePretensionAlternative(totalLength, options, stations, unitPrices, system) {
    const piers = [];
    const decks = [];
    const result = new Alternative("Pretension", piers, decks, null);
    const firstStation = stations[0];
    const lastStation = stations[stations.length - 1];
    const numOfSpans = Math.ceil(totalLength / options.maxPretensionSpan);
    const spanLength = totalLength / numOfSpans;
    let prevStation = firstStation;
    try {
        for (let index = 1; index < numOfSpans; index++) {
            const distance = firstStation.distance + index * spanLength;
            const currPier = InterpolatePier(stations, distance);
            if (currPier.height <= options.maxPierHeight) {
                piers.push(currPier);
                const currDeck = new Deck(prevStation, currPier.station, "Pretension");
                decks.push(currDeck);
                prevStation = currPier.station;
            } else {
                // check next pier height
                const nextPier = InterpolatePier(stations, distance + spanLength);
                if (nextPier.height > options.maxPierHeight) {
                    throw new Error("invalid");
                } else {
                    // increment index to jump off one iteration
                    index++;
                    const currDeck = new Deck(prevStation, nextPier.station, "Composite");
                    decks.push(currDeck);
                    // check if the next pier is last station
                    if (nextPier.station.distance !== lastStation.distance) {
                        piers.push(nextPier);
                        prevStation = nextPier.station;
                    }
                }
            }
        }
        result.costs = CalculateAlternativeCost(result, unitPrices, options.deckWidth, system);
    } catch (error) {
        if (error.message === "invalid") {
            result.piers = [];
            result.decks = [];
        } else {
            throw error;
        }

    }
    return result;
}
function CalculateCompositeAlternative(totalLength, options, stations, unitPrices, system) {
    const piers = [];
    const decks = [];
    const type = "Composite";
    const result = new Alternative("Composite", piers, decks, null);
    const firstStation = stations[0];
    const lastStation = stations[stations.length - 1];
    const numOfSpans = Math.ceil(totalLength / options.maxCompositeSpan);
    const spanLength = totalLength / numOfSpans;
    let prevStation = firstStation;
    for (let index = 1; index < numOfSpans; index++) {
        const distance = firstStation.distance + index * spanLength;
        const pier = InterpolatePier(stations, distance);
        piers.push(pier);
        const deck = new Deck(prevStation, pier.station, type);
        decks.push(deck);
        prevStation = pier.station;
    }
    // add the last deck
    const lastDeck = new Deck(prevStation, lastStation, type);
    decks.push(lastDeck);
    result.costs = CalculateAlternativeCost(result, unitPrices, options.deckWidth, system);
    return result;
}

function CalculatePostTensionAlternative(totalLength, stations, unitPrices, deckWidth, system) {
    // Post-tension alternative
    let numberOfPiers = 1;
    const piers = [];
    const decks = [];
    const type = "Post-tension";
    const pierCountLimits = {
        metric: {
            one: 180,
            two: 460,
            three: 680
        },
        imperial: {
            one: 591,
            two: 1510,
            three: 2231
        }
    };
    const result = new Alternative("Post-tension", piers, decks, null);
    const firstStation = stations[0];
    const lastStation = stations[stations.length - 1];
    if (totalLength < pierCountLimits[system].one)
        numberOfPiers = 1;
    else if (totalLength >= pierCountLimits[system].one && totalLength < pierCountLimits[system].two)
        numberOfPiers = 2;
    else if (totalLength >= pierCountLimits[system].two && totalLength < pierCountLimits[system].three)
        numberOfPiers = 3;
    else
        numberOfPiers = 4;

    const numberOfSpans = numberOfPiers + 1;
    if (numberOfSpans === 2) {
        // single pier at middle
        const pierDistance = firstStation.distance + totalLength / 2;
        const pier = InterpolatePier(stations, pierDistance);
        piers.push(pier);
        const firstDeck = new Deck(firstStation, pier.station, type);
        const secondDeck = new Deck(pier.station, lastStation, type);
        decks.push(firstDeck);
        decks.push(secondDeck);
    } else {
        // outer spans are 0.55 and inner spans are 1 in ratio
        const innerSpanCount = numberOfSpans - 2;
        const unitSpanLength = totalLength / ((2 * 0.55) + innerSpanCount);
        const outerSpanLength = unitSpanLength * 0.55;
        const innerSpanLength = unitSpanLength;
        let pierDistance = firstStation.distance + outerSpanLength;
        const firstPier = InterpolatePier(stations, pierDistance);
        piers.push(firstPier);
        const firstDeck = new Deck(firstStation, firstPier.station, type);
        decks.push(firstDeck);
        let prevPier = null;
        let currPier = firstPier;
        for (let i = 1; i <= numberOfPiers - 1; i++) {
            pierDistance = firstPier.station.distance + i * innerSpanLength;
            currPier = InterpolatePier(stations, pierDistance);
            piers.push(currPier);
            const currDeck = prevPier ? new Deck(prevPier.station, currPier.station, type) : new Deck(firstPier.station, currPier.station, type);
            decks.push(currDeck);
            prevPier = currPier.clone();
        }
        pierDistance = lastStation.distance - outerSpanLength;
        const lastDeck = new Deck(currPier.station, lastStation, type);
        decks.push(lastDeck);
    }
    result.piers = piers;
    result.decks = decks;
    result.costs = CalculateAlternativeCost(result, unitPrices, deckWidth, system);
    return result;
}

function CalculateAlternativeCost(alternative, unitPrices, deckWidth, system) {
    if (system === "metric") {
        let superConcrete = 0;
        let subConcrete = 0;
        let strands = 0;
        let reinf = 0;
        let compSteel = 0;
        const isComposite = alternative.name === "Composite";
        for (const deck of alternative.decks) {
            const area = deck.length * deckWidth;
            superConcrete += CalculateSuperConcreteM(deck.length, area, isComposite);
            reinf += CalculateSuperReinfM(deck.length, area, isComposite);
            if (deck.type === "Composite") {
                compSteel += CalculateCompositeSteelM(deck.length);
            } else {
                strands += CalculateStrandsM(deck.length, area);
            }

        }
        for (const pier of alternative.piers) {
            // pier
            subConcrete += CalculateSubConcreteM(pier);
            reinf += CalculateSubReinfM(pier);
        }
        const concreteCost = superConcrete * unitPrices.postTensionConcrete + subConcrete * unitPrices.substructureConcrete;
        const strandCost = strands * unitPrices.pretensionStrand;
        const reinfCost = reinf * unitPrices.reinforcementSteel;
        const compCost = compSteel * unitPrices.compositeSteel;
        return new Cost(cr(superConcrete + subConcrete), cr(concreteCost), cr(strands), cr(strandCost), cr(reinf), cr(reinfCost), cr(compSteel), cr(compCost));
    } else {
        throw new Error("Imperial system not implemented yet");
    }
}

// Metric quantity functions
function CalculateSuperConcreteM(deckLength, deckArea, isComposite) {
    if (isComposite) {
        return 0.25 * deckArea
    } else {
        return (0.0094339 * deckLength + 0.1509) * deckArea
    }
}

function CalculateStrandsM(deckLength, deckArea) {
    return (0.625 * deckLength + 3.75) * deckArea / 1000
}

function CalculateSuperReinfM(deckLength, deckArea, isComposite) {
    if (isComposite) {
        return 0.25 * deckArea * 0.25;
    } else {
        return (0.649 * deckLength + 97.4603) * deckArea / 1000
    }
}

function CalculateSubConcreteM(pier) {
    const pierC = (0.022 * Math.pow(pier.height, 3));
    const foundationC = (0.039 * Math.pow(pier.height, 3));
    const pilesC = (0.654 * Math.pow(pier.height, 2));
    const subConcrete = pierC + foundationC + pilesC;
    return subConcrete;
}

function CalculateSubReinfM(pier) {
    const pierR = (0.009 * Math.pow(pier.height, 3));
    const foundationR = (0.004 * Math.pow(pier.height, 3));
    const pileR = (0.131 * Math.pow(pier.height, 2));
    const subR = pierR + foundationR + pileR;
    return subR;
}

function CalculateCompositeSteelM(deckLength) {
    return 0.0019 * Math.pow(deckLength, 3) - 0.1343 * Math.pow(deckLength, 2) + 5.351 * deckLength + 0.9151;
}

function GetNeighbors(stationList, pierDistance) {
    const nextStation = stationList.find(q => q.distance > pierDistance);
    const prevStation = stationList[stationList.findIndex(q => q.distance === nextStation.distance) - 1];
    return {
        previousStation: prevStation,
        nextStation: nextStation
    };
}

function InterpolatePier(stationList, pierDistance) {
    let targetPier = null;
    // check if there is a station at the exact distance
    const index = stationList.findIndex(q => q.distance === pierDistance);
    if (index !== -1) {
        const targetStation = stationList[index];
        targetPier = new Pier(targetStation);
    }
    else {
        const neighbors = GetNeighbors(stationList, pierDistance);
        // valley elevation interpolation
        const x1v = neighbors.previousStation.distance;
        const y1v = neighbors.previousStation.valleyElevation;
        const x2v = neighbors.nextStation.distance;
        const y2v = neighbors.nextStation.valleyElevation;
        // y = mx + n
        const mv = (y2v - y1v) / (x2v - x1v);
        const nv = y2v - mv * x2v;
        const valleyEl = mv * pierDistance + nv;
        // road elevation interpolation
        const x1r = neighbors.previousStation.distance;
        const y1r = neighbors.previousStation.roadElevation;
        const x2r = neighbors.nextStation.distance;
        const y2r = neighbors.nextStation.roadElevation;
        const mr = (y2r - y1r) / (x2r - x1r);
        const nr = y2r - mr * x2r;
        const roadEl = mr * pierDistance + nr;
        targetPier = new Pier(new Station(cr(pierDistance), cr(roadEl), cr(valleyEl)));
    }
    return targetPier;
}
