var metric = {
    fpu: 1860,
    fc: 45,
    akma: 420,
    tn: 19,
    talan: 150,
    en: 650,
    scap: 22,
    ppay: 40,
    av: 250,
    heg: 3000,
    ycap: 4,
    ccap: 26,
    cyuz: 75,
    phi: 0.9,
    phiv: 0.9
};

var imperial = {
    fpu: 269770,
    fc: 6526,
    akma: 60915,
    tn: 19,
    talan: 0.23,
    en: 26,
    scap: 0.87,
    ppay: 1.57,
    av: 9.84,
    heg: 118.11,
    ycap: 13.12,
    ccap: 1.02,
    cyuz: 75,
    phi: 0.9,
    phiv: 0.9
};



export default {
    metric,
    imperial
}