function calculate(inputs, system) {
    // ***** Helper Function (Do NOT Touch) ***** \\
    var h = (field) => inputs[field].value;

    // ***** Empty Result Object (Do NOT Touch) ***** \\
    var R = new Object();

    /* ..................................WORK HERE .................................. */
    // Variables 
    /* inputName = h('inputName') */
    var fpu = h('fpu'),
        fc = h('fc'),
        akma = h('akma'),
        tn = h('tn'),
        talan = h('talan'),
        en = h('en'),
        scap = h('scap'),
        ppay = h('ppay'),
        av = h('av'),
        heg = h('heg'),
        ycap = h('ycap'),
        ccap = h('ccap'),
        cyuz = h('cyuz'),
        phi = h('phi'),
        phiv = h('phiv');

    var fci = 0.8 * fc,
        talan = talan * tn,
        salan = Math.PI * scap * scap / 4,
        kcap = 2 * Math.sqrt(talan * 2 / Math.PI), //DİKKAT
        dc = 1.5 * kcap,
        dep = heg - ppay;


    //*** METRIC CALCULATIONS
    function metric() {
        var b1;
        //* Beton blok katsayısı, b1
        if (fc < 4 * 6.895) {
            b1 = 0.85;
        } else {
            b1 = 0.85 - 0.05 * (fc - 4 * 6.895) / 6.895;
        }

        if (b1 < 0.65) {
            b1 = 0.65;
        }
        var tyuk, fsapma, vr, sapara, fou, ncek, mu,
            aste, maste, natCompRein, natMinReqRein, natExpRein,
            acek, ncop, avf, nvf, dast, natMainRein, ccp,
            acp, fmn, vn1, vn2, vn, tmpVal;

        //* Tasarım yükü (Pu calculation)
        tyuk = 1.2 * cyuz / 100 * fpu * talan / 1000;

        //* Büküm bölgesinde sapma yükü (Fu calculation)
        fsapma = tyuk / ycap;

        //* Sapmaya karşı kesme direnimi (Vr calculation)
        vr = 0.75 * 0.33 * dc * Math.sqrt(fci);

        //* Sapma dış eksen (Fu calculation)
        fou = fsapma / Math.PI;

        //* Gereken blok arkası çekme donatısı
        let rArea = Math.PI * ccap * ccap / 4;
        ncek = (0.25 * tyuk / 1.2 * 1000) / (0.6 * akma * rArea);
        mu = tyuk * av / 1000 + 0.2 * tyuk * ppay / 1000;

        //* Tahmini ana donatı
        aste = mu * 1000 * 1000 / (akma * 0.9 * dep);
        maste = 0.04 * fc / akma * en * dep;
        natCompRein = aste / (rArea);
        natMinReqRein = maste / (rArea);

        //* Çekme donatısı
        acek = 0.2 * tyuk * 1000 / phi / akma;
        ncop = acek / (rArea);

        //* Sürtünme kesmesi
        vn1 = 0.2 * fc * en * dep / 1000;
        vn2 = 5.5 * en * dep / 1000;
        if (vn1 > vn2) {
            vn = vn2;
        } else {
            vn = vn1;
        }
        tmpVal = phiv * vn;

        /////////////////////////////////////////// RESULTS ///////////////////////////////////////////

        //* Design Tension Load Pu
        R.pu = tyuk;

        //* Curved Tendon - Inplane Design Force
        // Fu 
        R.FuInplane = fsapma;
        // Vr
        R.Vr = vr;
        // Required reinforcement
        // φ 
        R.reqRein1 = scap;
        // mm
        if (vr < fsapma) {
            sapara = 1000 / (fsapma * 1000 / (0.6 * akma * salan * 2));
            if (sapara > 3 * kcap) {
                sapara = kcap * 3;
            }
            if (sapara > 600) {
                sapara = 600;
            }
            sapara = sapara - (sapara % 10);
            R.reqRein2 = sapara;
        } else {
            R.reqRein2 = 'Not Req.';
        }
        //* Curved Tendon - Out of Plane Design Force
        // Fu 
        R.FuOutPlane = fou;
        // Message 
        if (fou < vr) {
            R.outplaneMsg = "No additional spiral reinforcement is required";
        } else {
            R.outplaneMsg = "Spiral reinforcement can be used at in plane";
        }
        //* Crack Control Reinforcement
        // Number 
        R.crackCntrl1 = Math.floor(ncek + 0.5);
        // φ
        R.crackCntrl2 = ccap;
        // Mu
        R.mu = mu;
        //* Blister and Rib Reinforcement
        // Computed Reinforcement
        R.compRein1 = Math.floor(natCompRein + 0.5);
        R.compRein2 = ccap;
        // Minimum Required Reinforcement
        R.minReqRein1 = Math.floor(natMinReqRein + 0.5);
        R.minReqRein2 = ccap;
        // Expected Reinforcement, As
        if (maste > aste) {
            aste = maste;
        }
        natExpRein = aste / (Math.PI * ccap * ccap / 4);
        R.expRein1 = Math.floor(natExpRein + 0.5);
        R.expRein2 = ccap;
        // Tension Reinforcement, An
        R.tensRein1 = Math.floor(ncop + 0.5);
        R.tensRein2 = ccap;
        // Required Tie Reinforcement, Ah
        if (phiv * vn < tyuk) {
            R.outBlisterMsg = "Reinforcement design is required";
            avf = tyuk * 1000 / 420 / phiv;
            nvf = avf / (Math.PI * scap * scap / 4);
            R.reqTieRein1 = Math.floor(nvf + 0.5);
        } else {
            R.outBlisterMsg = "At least half of main reinforcement is required for ties";
            avf = 0.5 * aste;
            nvf = avf / (Math.PI * scap * scap / 4);
            R.reqTieRein1 = Math.floor(nvf + 0.5);
        }
        R.reqTieRein2 = scap;
        // Required Main Reinforcement, As
        dast = 0.67 * avf + acek;
        if (dast > aste) {
            aste = dast;
        }
        natMainRein = aste / (Math.PI * ccap * ccap / 4);
        R.reqMainRein1 = Math.floor(natMainRein + 0.5);
        R.reqMainRein2 = ccap;
        // Concrete Strength Factor, β1
        R.beta1 = b1;
        // Compression Block c
        ccp = natExpRein * Math.PI * ccap * ccap / 4 * akma / (0.85 * fc * b1 * en);
        R.c = ccp;
        // a
        acp = b1 * ccp;
        R.a = acp;
        // 	φMn
        fmn = phi * natExpRein * Math.PI * ccap * ccap / 4 * akma * (dep - acp / 2) / 1000 / 1000;
        R.phiMn = fmn;
        // Concrete shear resistance
        // Vn (0.2 fc b d)
        R.Vn02 = vn1;
        // Vn (5.5 b d)
        R.Vn55 = vn2;
        // φVn
        R.phiVn = tmpVal;
    }


    //*** IMPERIAL CALCULATIONS
    function imperial() {
        var b1;
        //* Beton blok katsayısı, b1
        // fc = fc / 1000;
        if (fc < 4) {
            b1 = 0.85;
        } else {
            b1 = 0.85 - 0.05 * (fc - 4);
        }

        if (b1 < 0.65) {
            b1 = 0.65;
        }
        var tyuk, fsapma, vr, sapara, fou, ncek, mu,
            aste, maste, natCompRein, natMinReqRein, natExpRein,
            acek, ncop, avf, nvf, dast, natMainRein, ccp,
            acp, fmn, vn1, vn2, vn, tmpVal;

        //* Tasarım yükü (Pu calculation)
        tyuk = 1.2 * cyuz / 100 * fpu * talan / 1000;

        //* Büküm bölgesinde sapma yükü (Fu calculation)
        fsapma = tyuk / ycap;

        //* Sapmaya karşı kesme direnimi (Vr calculation)
        vr = 0.75 * 0.15 * dc * Math.sqrt(fci / 1000) * 12; //feet inch dönüşümü

        //* Sapma dış eksen (Fu calculation)
        fou = fsapma / Math.PI;

        //* Gereken blok arkası çekme donatısı
        ncek = (0.25 * tyuk / 1.2) / (0.6 * akma / 1000 * Math.PI * ccap * ccap / 4);
        mu = tyuk * av / 12 + 0.2 * tyuk * ppay / 12;

        //* Tahmini ana donatı
        aste = mu * 12 / (akma / 1000 * 0.9 * dep);
        maste = 0.04 * fc / akma * en * dep;
        natCompRein = aste / (Math.PI * ccap * ccap / 4);
        natMinReqRein = maste / (Math.PI * ccap * ccap / 4);

        //* Çekme donatısı
        acek = 0.2 * tyuk * 1000 / phi / akma;
        ncop = acek / (Math.PI * ccap * ccap / 4);

        //* Sürtünme kesmesi
        vn1 = 0.2 * fc * en * dep / 1000;
        vn2 = 0.8 * en * dep;
        if (vn1 > vn2) {
            vn = vn2;
        } else {
            vn = vn1;
        }
        tmpVal = phiv * vn;

        /////////////////////////////////////////// RESULTS ///////////////////////////////////////////

        //* Design Tension Load Pu
        R.pu = tyuk;

        //* Curved Tendon - Inplane Design Force
        // Fu 
        R.FuInplane = fsapma;
        // Vr
        R.Vr = Math.floor(vr);
        // Required reinforcement
        // φ 
        R.reqRein1 = scap;
        // mm
        if (vr < fsapma) {
            sapara = 12 / (fsapma / (0.6 * akma / 1000 * salan * 2));
            if (sapara > 3 * kcap) {
                sapara = kcap * 3;
            }
            if (sapara > 23.6) {
                sapara = 23.6;
            }
            //sapara = sapara - (sapara % 10);
            R.reqRein2 = sapara;
        } else {
            R.reqRein2 = 'Not Req.';
        }
        //* Curved Tendon - Out of Plane Design Force
        // Fu 
        R.FuOutPlane = Math.floor(fou);
        // Message 
        if (fou < vr) {
            R.outplaneMsg = "No additional spiral reinforcement is required";
        } else {
            R.outplaneMsg = "Spiral reinforcement can be used at in plane";
        }
        //* Crack Control Reinforcement
        // Number 
        R.crackCntrl1 = Math.floor(ncek + 0.5);
        // φ
        R.crackCntrl2 = ccap;
        // Mu
        R.mu = mu;
        //* Blister and Rib Reinforcement
        // Computed Reinforcement
        R.compRein1 = Math.floor(natCompRein + 0.5);
        R.compRein2 = ccap;
        // Minimum Required Reinforcement
        R.minReqRein1 = Math.floor(natMinReqRein + 0.5);
        R.minReqRein2 = ccap;
        // Expected Reinforcement, As
        if (maste > aste) {
            aste = maste;
        }
        natExpRein = aste / (Math.PI * ccap * ccap / 4);
        R.expRein1 = Math.floor(natExpRein + 0.5);
        R.expRein2 = ccap;
        // Tension Reinforcement, An
        R.tensRein1 = Math.round(ncop + 0.5);
        R.tensRein2 = ccap;
        // Required Tie Reinforcement, Ah
        if (phiv * vn < tyuk) {
            R.outBlisterMsg = "Reinforcement design is required";
            avf = tyuk * 1000 / 420 / phiv;
            nvf = avf / (Math.PI * scap * scap / 4);
            R.reqTieRein1 = Math.floor(nvf + 0.5);
        } else {
            R.outBlisterMsg = "At least half of main reinforcement is required for ties";
            avf = 0.5 * aste;
            nvf = avf / (Math.PI * scap * scap / 4);
            R.reqTieRein1 = Math.floor(nvf + 0.5);
        }
        R.reqTieRein2 = scap;
        // Required Main Reinforcement, As
        dast = 0.67 * avf + acek;
        if (dast > aste) {
            aste = dast;
        }
        natMainRein = aste / (Math.PI * ccap * ccap / 4);
        R.reqMainRein1 = Math.floor(natMainRein + 0.5);
        R.reqMainRein2 = ccap;
        // Concrete Strength Factor, β1
        R.beta1 = b1;
        // Compression Block c
        ccp = natExpRein * Math.PI * ccap * ccap / 4 * akma / (0.85 * fc * b1 * en);
        R.c = ccp;
        // a
        acp = b1 * ccp;
        R.a = acp;
        // 	φMn
        fmn = phi * natExpRein * Math.PI * ccap * ccap / 4 * akma * (dep - acp / 2) / 1000 / 12;
        R.phiMn = fmn;
        // Concrete shear resistance
        // Vn (0.2 fc b d)
        R.Vn02 = vn1;
        // Vn (5.5 b d)
        R.Vn55 = vn2;
        // φVn
        R.phiVn = tmpVal;
    }


    /* ..................................WORK HERE .................................. */

    // ***** Metric vs Imperial Check (Do NOT Touch) ***** \\ 
    if (system === 'metric') {
        metric();
        return R;
    } else if (system === 'imperial') {
        imperial();
        return R;
    } else {
        console.error('System is missing !')
    }

}

module.exports = {
    calculate
}