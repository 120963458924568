var outputs = {

    xmax: {
        label1: 'Max. Moment Location',
        label2: 'xmax',
        suffix: {
            metric: 'm',
            imperial: 'ft'
        },
        description: {
            title: 'Maximum Moment Location',
            desc: ''
        }
    },

    Mmax: {
        label1: 'Max. Live Load Moment',
        label2: 'Mmax',
        suffix: {
            metric: 'kN.m',
            imperial: 'kips.ft'
        },
        description: {
            title: 'Maximum Live Load Moment',
            desc: ''
        }
    },

};

export default outputs;