var m1 = {
  Ag: 0.55e6, //m2
  gammaConc: 25, //kN/m3
  L: 30, //m
};

var imp1 = {
  Ag: 864,
  gammaConc: 0.16, //kips/ft³
  L: 100, //ft
};

var m2 = {
  gammaConc: 25,
  L: 30,
  tslab: 0.25, //m
  s: 1.35
}

var imp2 = {
  gammaConc: 0.16,
  L: 100,
  tslab: 0.8,
  s: 4.4
}

var m3 = {
  gammaConc: 25,
  L: 30,
  N: 9,
  wspan: 12,
  tws: 0.05,
  tsidew: 0.25,
  wside: 3,
  gammaConcW: 22,
}

var imp3 = {
  gammaConc: 0.16,
  L: 100,
  N: 9,
  wspan: 40,
  tws: 0.15,
  tsidew: 0.8,
  wside: 10,
  gammaConcW: 0.15,
}

export default {
  i1: {
    metric: m1,
    imperial: imp1
  },
  i2: {
    metric: m2,
    imperial: imp2
  },
  i3: {
    metric: m3,
    imperial: imp3
  }
}