var metric = {
  L: 30, //m
  d: 1.4 //m
};

var imperial = {
  L: 100, //ft
  d: 4.6 //ft
};

export default {
  metric,
  imperial
}