<template>
  <div>
    <v-layout>
      <v-flex xs12>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Maximum Pretension Span"
                  v-model="options.maxPretensionSpan"
                  :rules="numberRules"
                  type="number"
                  :suffix="suffix"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Maximum Composite Span"
                  v-model="options.maxCompositeSpan"
                  :rules="numberRules"
                  type="number"
                  :suffix="suffix"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Maximum Pier Height"
                  v-model="options.maxPierHeight"
                  :rules="numberRules"
                  type="number"
                  :suffix="suffix"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Deck Width"
                  v-model="options.deckWidth"
                  :rules="numberRules"
                  type="number"
                  :suffix="suffix"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "OptionsComponent",
  props: ["options", "system"],

  data() {
    return {
      units: {
        metric: "m",
        imperial: "ft"
      },
      numberRules: [
        v => !!v || "Required",
        v => v > 0 || "Must be greater than zero"
      ]
    };
  },
  computed: {
    suffix: function() {
      return this.units[this.system];
    }
  }
};
</script>

<style>
</style>