<template>
  <div>
    <v-toolbar flat>
      <div class="headline">{{calculator.main.title}}</div>
    </v-toolbar>
    <div class="mx-3">
      <v-layout wrap>
        <v-flex md7 xl8 class="pa-1">
          <!-- VIDEO -->
          <v-card class="elevation-4" height="100%" :color="cardColor">
            <v-responsive :aspect-ratio="16/9" v-if="calculator.details.video">
              <iframe
                width="100%"
                height="100%"
                :src="`https://www.youtube.com/embed/${calculator.details.video}?rel=0`"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </v-responsive>
          </v-card>
        </v-flex>
        <!-- DESCRIPTION -->
        <v-flex md5 xl4 class="pa-1">
          <v-card
            class="pa-4 elevation-4"
            height="100%"
            :color="cardColor"
          >{{calculator.details.longDesc}}</v-card>
        </v-flex>
        <v-layout wrap class="px-1 py-2 ma-1 elevation-4">
          <v-flex xs12 x-offset class="d-flex justify-end">
            <v-btn class="mx-1" @click="goBack">BACK</v-btn>
            <v-btn
              class="mx-1"
              :href="'/docs/verification/' + calculator.name + '.pdf'"
              target="_new"
            >Verification</v-btn>
            <v-btn class="mx-1" outlined @click="goToCalculator(calculator)">Use Calculator</v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
    </div>
    <!-- Carousel -->

    <v-carousel v-if="calculator.details.carousel > 0">
      <v-carousel-item contain
        v-for="(item,i) in calculator.details.carousel"
        :key="i"
        :src="getImgUrl('tutorials' ,`${calculator.name}/${calculator.name}_${i+1}`, 'jpg')"
      ></v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import calculatorList from "@/_lists/calculatorList";
import calculatorRoutes from "@/_lists/calculatorRoutes";
import { mapState, mapActions } from "vuex";
export default {
  metaInfo: {
    title: "Calculator Information",
    meta: [
      {
        name: "description",
        content: "Design calculators for various bridge design aspects"
      },
      { name: "robots", content: "index, follow" },
      {
        name: "keywords",
        content:
          "bridge, calculator, girder, design, beam, post-tension, prestress, deck, blister"
      }
    ]
  },
  data() {
    return {
      calculatorList,
      calculatorRoutes,
      cardColor: "grey lighten-4"
    };
  },
  computed: {
    ...mapState("general", ["calculators"]),
    calculator() {
      var name = calculatorRoutes.find(
        route => route.path === this.$route.params.name
      ).name;
      return calculatorList.find(c => c.name === name);
    }
  },
  methods: {
    getImgUrl(url, pic, ext) {
      return require(`@/assets/imgs/${url}/${pic}.${ext}`);
    },
    goToCalculator(el) {
      var cal = this.calculators.find(c => c.name === el.name);
      if (cal) {
        this.$router.push({ name: el.name });
      } else {
        this.$router.push({ name: "payment" });
      }
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
</style>