<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 lg10>
        <v-container fill-height>
          <v-layout wrap>
            <v-flex xs12 sm4 md5>
              <v-card height="100%" dark class="back elevation-22">
                <v-container fill-height></v-container>
              </v-card>
            </v-flex>
            <v-flex xs12 sm8 md7>
              <v-card height="100%" class="elevation-22">
                <v-card-title>
                  <span class="grey--text title">Register New User</span>
                </v-card-title>
                <v-card-text>
                  <v-layout>
                    <v-flex xs5>
                      <v-text-field color="blue" v-model="user.name" label="Name" required></v-text-field>
                    </v-flex>
                    <v-flex xs6 offset-xs1>
                      <v-text-field color="blue" v-model="user.surname" label="Surname" required></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs12>
                      <v-text-field
                        color="blue"
                        v-model="user.email"
                        :rules="rules.emailRules"
                        label="E-mail"
                        required
                      ></v-text-field>
                      <v-text-field color="blue" v-model="user.company" label="Company"></v-text-field>
                      <v-text-field
                        color="blue"
                        v-model="user.pass"
                        label="Password"
                        type="password"
                        required
                      ></v-text-field>
                      <v-text-field
                        color="blue"
                        v-model="user.pass_conf"
                        label="Confirm Password"
                        type="password"
                        required
                      ></v-text-field>
                      <p v-show="error" style="color:red">{{error}}</p>
                    </v-flex>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="info" to="/login">Have account? Log In</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.prevent="createAccount"
                    color="info"
                    :loading="loading"
                    :disabled="loading"
                  >
                    Create Account
                    <template v-slot:loader>
                      <span class="custom-loader">
                        <v-icon light>cached</v-icon>
                      </span>
                    </template>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import firebase from "firebase/app";
import axios from "axios";
import "firebase/auth";
import db from "@/firebaseInit";
import { mapState, mapActions } from "vuex";

export default {
  computed: {
    ...mapState(["calculators", "currentUser"])
  },
  data() {
    return {
      error: null,
      loading: false,
      user: {
        name: "",
        surname: "",
        email: "",
        company: "",
        pass: "",
        pass_conf: ""
      },
      rules: {
        emailRules: [
          v => !!v || "E-mail is required",
          v => /.+@.+/.test(v) || "E-mail must be valid"
        ]
      }
    };
  },
  methods: {
    /// Creates a user account and on success redirects the new user to member dashboard
    async createAccount() {
      // start loading
      this.loading = true;
      // reset previous errors
      this.error = null;
      // validate form data
      this.registerValidation();
      // if there are no errors, proceed with registration
      if (!this.error) {
        try {
          // register the user to auth daatabase
          const newUser = await firebase
            .auth()
            .createUserWithEmailAndPassword(this.user.email, this.user.pass);
          // then add the user to user database
          let now = new Date();
          // add the user to the users Firestore database with the default tier
          // user's lastPurchaseDate and subscriptionEndDate will be registration date
          await db
            .collection("users")
            .doc(newUser.user.uid)
            .set({
              name: this.user.name,
              surname: this.user.surname,
              email: this.user.email,
              company: this.user.company,
              _id: newUser.user.uid,
              tier: "tier1",
              lastPurchaseDate: now,
              subscriptionEndDate: now
            });
          // then send new user notification email to company
          await axios.post("/send-mail", {
            name: this.user.name,
            surname: this.user.surname,
            email: this.user.email,
            templateName: "NewUserNotificationTemplate",
            target: "mcanyucel1@gmail.com"
          });
          // then send user welcome email
          await axios.post("/send-mail", {
            name: this.user.name,
            surname: this.user.surname,
            email: this.user.email,
            templateName: "NewUserWelcomeTemplate",
            target: this.user.email
          });
          // since the user is logged in automatically, direct him to member dashboard
          this.$router.push("/member/dashboard");
          // TODO what happens if the user cannot be written to the Firestore?
        } catch (error) {
          // stop loading
          this.loading = false;
          // update error
          this.error = error.message;
        }
      }
      // if there are validation errors
      else {
        this.loading = false;
      }
    },
    /// Checks user input validity
    registerValidation() {
      function hasNumber(myString) {
        return /\d/.test(myString);
      }
      // ERROR: If name or surname contains any number
      if (hasNumber(this.user.name) || hasNumber(this.user.surname)) {
        this.error = "Your name or surname can not contain any number!";
      }
      // ERROR: If name or surname has less than 3 chars
      else if (this.user.name.length < 2 || this.user.surname.length < 2) {
        this.error = "Your name or surname can not be less than 2 characters!";
      }
      // ERROR: If password is less than 6 chars
      else if (this.user.pass.length < 6) {
        this.error = "Your password too short ! (Use more than 6 characters)";
      }
      // ERROR: If password does not contain any NUMBER
      else if (!hasNumber(this.user.pass)) {
        this.error = "Your password has to contain at least one NUMBER";
      }
      // ERROR: If password does not contain any SPECIAL character
      else if (!/[|\\/~^:,;?!&%$@*+]/.test(this.user.pass)) {
        this.error =
          "Your password has to contain at least one SPECIAL character";
      }
      // ERROR: If pass & conf_pass is not equal
      else if (this.user.pass !== this.user.pass_conf) {
        this.error = "The password confirmation does not match the password!";
      }
      // READY: Create account
      else {
        this.error = null;
      }
    }
  }
};
</script>

<style scoped>
.back {
  background-image: url("https://images.pexels.com/photos/425443/pexels-photo-425443.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=1000&w=1000");
  background-size: cover;
}

.link {
  color: darkcyan;
  text-decoration: none;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>