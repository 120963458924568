var inputs = {

    moment: {
        label: '',
        value: null,
        cat: null,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: '',
            desc: ''
        }
    },
    fc: {
        label: 'Concrete Strength, fc',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Concrete Strength',
            desc: ''
        }
    },
    fy: {
        label: 'Steel Yield Strength, fy',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Steel Yield Strength',
            desc: ''
        }
    },
    fpu: {
        label: 'Ultimate Strength of Prestress, fpu',
        value: null,
        cat: 1,
        suffix: {
            metric: 'MPa',
            imperial: 'psi'
        },
        description: {
            title: 'Ultimate Strength of Prestress',
            desc: ''
        }
    },
    gammp: {
        label: 'Relaxation Constant, γp',
        value: null,
        cat: 1,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Relaxation Constant',
            desc: ''
        }
    },
    // ana başlık Area of Post - Tension (kategori 5)
    arst: {
        label: 'Area of Strand',
        value: null,
        cat: 5,
        suffix: {
            metric: 'mm²',
            imperial: 'in²'
        },
        description: {
            title: 'Area of Strand',
            desc: ''
        }
    },
    nst: {
        label: 'Number of Strand',
        value: null,
        cat: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Strand',
            desc: ''
        }
    },
    // Alt Başlık '# of Tendons'
    ntt: {
        label: 'Top Flange',
        value: null,
        cat: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Tendons on Top Flange',
            desc: ''
        }
    },
    ntw: {
        label: 'Web',
        value: null,
        cat: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Tendons on Web',
            desc: ''
        }
    },
    nbf: {
        label: 'Bottom Flange',
        value: null,
        cat: 5,
        suffix: {
            metric: '',
            imperial: ''
        },
        description: {
            title: 'Number of Tendons on Bottom Flange',
            desc: ''
        }
    },
    // Alt Başlık # of tendons
    // ana başlık Area of Post - Tension (kategori 5)
    dpu: {
        label: 'ub',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    dpg: {
        label: 'gb',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    dpa: {
        label: 'ab',
        value: null,
        cat: 2,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    wt: {
        label: 'w',
        value: null,
        cat: 4,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    b: {
        label: 'b',
        value: null,
        cat: 4,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    tt: {
        label: 'tt',
        value: null,
        cat: 4,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    tw: {
        label: 'tw',
        value: null,
        cat: 4,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    tb: {
        label: 'tb',
        value: null,
        cat: 4,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    h1: {
        label: 'h',
        value: null,
        cat: 4,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: '',
            desc: ''
        }
    },
    ploss: {
        label: 'Post-Tension Loss',
        value: null,
        cat: 3,
        suffix: {
            metric: '%',
            imperial: '%'
        },
        description: {
            title: 'Post-Tension Loss',
            desc: ''
        }
    },
    ardy: {
        label: 'Jacking Load %',
        value: null,
        cat: 3,
        suffix: {
            metric: '%',
            imperial: '%'
        },
        description: {
            title: 'Jacking Load %',
            desc: ''
        }
    },
    pp: {
        label: 'Concrete Cover',
        value: null,
        cat: 3,
        suffix: {
            metric: 'mm',
            imperial: 'in'
        },
        description: {
            title: 'Concrete Cover',
            desc: ''
        }
    }
};

export default inputs;