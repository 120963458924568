<template>
    <div>
        <inputsResults irType="p1i2r1" :categoryNumber="3" img1="liveLoad2.png"
            :titles="['Superstructure Details', 'Section Dimensions']" :tooltips="[
                'Max live load moment per lane can be determined from “Total Live Load” calculator. Lane number shall be based on the real data. All values shall be positive.',
                'Girder area, girder moment of inertia, girder center from bottom can be determined from “Concrete I Girder Properties” calculator. Girder spacing can be determined from “Selection of Girder Spacing and Depth” calculator. All values are positive.'
            ]" :inputs="inputs" :testData="testData" :outputs="outputs" :system="system" :result1="result1"
            :result2="result2" @CALC_METRIC="calcMetric" @CALC_IMP="calcImperial" @system="systemChanged"
            @CLEAR_RESULTS="clearResults" />

    </div>
</template>

<script>
    import inputsResults from '@/_calculators/static/inputsResults'
    import functions from '@/_calculators/src/liveLoad/L2/functions'
    import inputs from '@/_calculators/src/liveLoad/L2/inputs'
    import outputs from '@/_calculators/src/liveLoad/L2/outputs'
    import testData from '@/_calculators/src/liveLoad/L2/testData'

    export default {
        components: {
            inputsResults
        },
        data() {
            return {
                system: 'metric',
                inputs: inputs,
                outputs: outputs,
                testData: testData,
                result1: null,
                result2: null
            }
        },
        watch: {
            system() {
                this.clearResults();
            },
        },
        methods: {
            /* Static */
            systemChanged(val) {
                this.system = val;
            },
            calcMetric() {
                if (this.result1) {
                    this.result2 = this.result1;
                }
                this.result1 = functions.calculate(this.inputs, 'metric');
            },
            calcImperial() {
                if (this.result1) {
                    this.result2 = this.result1;
                }
                this.result1 = functions.calculate(this.inputs, 'imperial');
            },
            clearResults() {
                this.result1 = null;
                this.result2 = null;
            }
        }
    }
</script>

<style>

</style>